import { useLocalization } from '@experiences/locales';
import { useApolloTheme } from '@experiences/theme';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    DashboardUrls,
    getDashboardUrl,
} from '../../../services/insights/DashboardService';
import { getTenantsV2 } from '../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
} from '../../../store/selectors';
import InsightsDashboardComponent from './InsightsDashboardComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        iframeContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            paddingTop: '18px',
        },
    }
    ),
);

const InsightsDashboardId = 'UiPath-LI-AIU-Insights::aiu_organization_overview_org';

const InsightsDashboardComponentWrapper: React.FC = () => {

    const classes = useStyles();
    const accountName = useSelector(accountLogicalName);
    const partitionGlobalId = useSelector(accountGlobalId);
    const { themeId } = useApolloTheme();
    const language = useLocalization();

    const { data: tenantsWithInsightsService } = useSWR(
        {
            url: '/api/organization/tenant',
            organizationGuid: partitionGlobalId,
            accountName,
            serviceType: [ 'Insights' ],
            includeTenantServices: true,
            serviceStatus: 'Enabled',
            tenantStatus: 'Enabled',
        },
        getTenantsV2,
    );

    const {
        data: dashboardPayload, error, isValidating, mutate: mutateDashboard,
    } = useSWR(
        (tenantsWithInsightsService && tenantsWithInsightsService.length > 0 && tenantsWithInsightsService[0]) ? {
            url: DashboardUrls.GetDashboard,
            accountLogicalName: accountName,
            tenantName: tenantsWithInsightsService[0].name,
            id: InsightsDashboardId,
            locale: language,
            theme: themeId,
        } : null,
        getDashboardUrl, { revalidateOnMount: true }
    );

    useEffect(() => {
        mutateDashboard();
    }, [ language, mutateDashboard ]);

    if (!tenantsWithInsightsService || tenantsWithInsightsService.length === 0) {
        return null;
    }

    return (<>
        <div className={classes.iframeContainer}>
            <InsightsDashboardComponent
                dashboardPayload={!isValidating ? dashboardPayload : undefined}
                error={error} />
        </div>
    </>
    );
};

export default InsightsDashboardComponentWrapper;
