import {
    axiosDelete,
    get,
} from '../utility/Requests.default';
import type {
    DataSet,
    DataSource,
    Schema,
} from './Ecs.types';

// https://alpha.uipath.com/entity/IntegrationTest/ecs_/swagger/index.html
export async function getDataSets(organizationId: string, tenantId: string) {
    const url = `/${organizationId}/${tenantId}/ecs_/v1/datasets`;
    return await get<DataSet[]>(url, { ignoreBasePath: true });
}

export async function getDataSetById(organizationId: string, tenantId: string, dataSetId: string) {
    const url = `/${organizationId}/${tenantId}/ecs_/v1/datasets/${dataSetId}`;
    return await get<DataSet>(url, { ignoreBasePath: true });
}

export async function getDataSources(organizationId: string, tenantId: string) {
    const url = `/${organizationId}/${tenantId}/ecs_/v1/datasources`;
    return await get<DataSource[]>(url, { ignoreBasePath: true });
}

export async function getDataSourceById(organizationId: string, tenantId: string, dataSourceId: string) {
    const url = `/${organizationId}/${tenantId}/ecs_/v1/datasources/${dataSourceId}`;
    return await get<DataSource>(url, { ignoreBasePath: true });
}

export async function deleteDataSourceById(organizationId: string, tenantId: string, dataSourceId: string) {
    const url = `/${organizationId}/${tenantId}/ecs_/v1/datasources/${dataSourceId}`;
    return await axiosDelete(url, { ignoreBasePath: true });
}

export async function getSchemas(organizationId: string, tenantId: string) {
    const url = `/${organizationId}/${tenantId}/ecs_/v1/schemas`;
    return await get<Schema[]>(url, { ignoreBasePath: true });
}

export async function getSchemaById(organizationId: string, tenantId: string, schemaId: string) {
    const url = `/${organizationId}/${tenantId}/ecs_/v1/schemas?id=${schemaId}`;
    return await get<Schema>(url, { ignoreBasePath: true });
}

export async function deleteSchemaById(organizationId: string, tenantId: string, schemaId: string) {
    const url = `/${organizationId}/${tenantId}/ecs_/v1/schemas/${schemaId}`;
    return await axiosDelete(url, { ignoreBasePath: true });
}
