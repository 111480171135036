import axios from 'axios';

import type { IGridDataEntry } from './GridDataEntryTypes';

export class AuditLogService {
    static async fetchAuditLogsBatch(token: string, accountId: string): Promise<IGridDataEntry[]> {
        const auditLogApiBaseUrl = `https://alpha.uipath.com/${accountId}/llmops_/api/LLMOps/auditlogs/`;
        const url = `${auditLogApiBaseUrl}query?skip=0&take=100`;

        try {
            const headers = {
                'X-UiPath-Internal-AccountId': accountId,
                'X-UiPath-Internal-TenantId': accountId,
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            };

            const response = await axios.post(
                url,
                {
                    StartDate: '2024-01-01T00:00:00',
                    EndDate: '2024-12-31T23:59:59',
                },
                { headers }
            );

            const auditLogs = response.data;
            if (Array.isArray(auditLogs)) {
                return this.processAuditLogs(auditLogs);
            }

            console.error('Unexpected response format:', auditLogs);
            return [];

        } catch (error) {
            console.error('Failed to fetch audit logs:', error);
            return [];
        }
    }

    static processAuditLogs(auditLogs: any[]): IGridDataEntry[] {
        return auditLogs.map(this.processSingleAuditLog);
    }

    static processSingleAuditLog(log: any): IGridDataEntry {
        return {
            id: log.requestId,
            date: log.date,
            user: log.userId,
            tenant: log.tenantId,
            product: log.product,
            feature: log.feature,
            modelOutput: log.output,
            modelUsed: log.modelUsed,
            modelLocation: log.modelLocation,
            status: log.status,
        };
    }
}
