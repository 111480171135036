import type {
    SnackbarAnchorOrigin,
    SnackbarVariantType,
} from '@experiences/interfaces';
import { useSnackbar } from '@experiences/util';
import Fade from '@mui/material/Fade';
import React from 'react';

import { notificationType } from '../constants/Constant';

export const useUiSnackBar = () => {
    const { enqueueSnackbar } = useSnackbar();

    const createNotification = React.useCallback((
        message: React.ReactNode | string,
        type = notificationType.INPROGRESS,
        anchorOrigin: SnackbarAnchorOrigin = {
            horizontal: 'center',
            vertical: 'top',
        },
        autoHideDuration: number | null = 3000
    ) => {
        enqueueSnackbar(message, {
            variant: type as SnackbarVariantType,
            anchorOrigin,
            TransitionComponent: Fade,
            autoHideDuration,
        });
    }, [ enqueueSnackbar ]);

    return createNotification;
};
