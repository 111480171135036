import { UiText } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { notificationType } from '../../../common/constants/Constant';
import { EmailSettingKey } from '../../../common/constants/EmailSettingConstant';
import { useGetSetting } from '../../../common/hooks/useGetSetting';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import { type IEmailSettingsData } from '../../../common/interfaces/emailSetting';
import {
    deleteSettings,
    saveSetting,
    settingUrl,
} from '../../../services/identity/SettingService';
import { accountGlobalId } from '../../../store/selectors';
import {
    defaultEmailSettingsData,
    mapEmailSettingDataToKeyValuePairs,
    mapSettingArrayToEmailSettingsData,
} from '../../../util/setting/EmailSettingUtil';
import VerifyMailSettingsDialogBody from './VerifyMailSettingsDialogBody';

const useOrganizationEmailSettingsRevampViewModel = () => {
    const { formatMessage: translate } = useIntl();
    const location = useLocation();

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ mailSetting, setMailSetting ] = useState<'default' | 'custom'>('default');
    const [ verifiedSettings, setVerifiedSettings ] = useState(() => location?.state?.verifiedSettings ?? false);

    const createNotification = useUiSnackBar();
    const createDialog = useShowDialog();

    const keys = [
        EmailSettingKey.Domain,
        EmailSettingKey.EnableSsl,
        EmailSettingKey.FromDisplayName,
        EmailSettingKey.FromEmail,
        EmailSettingKey.Host,
        EmailSettingKey.Password,
        EmailSettingKey.Port,
        EmailSettingKey.UseDefaultCredentials,
        EmailSettingKey.UserName,
        EmailSettingKey.ConnectionTimeout,
    ];
    const {
        data: fetchedSettings, mutate,
    } = useGetSetting(keys);

    const methods = useForm<IEmailSettingsData>({
        mode: 'onChange',
        defaultValues: defaultEmailSettingsData,
    });
    const {
        reset, setValue, getValues, watch,
    } = methods;

    const originalData = useMemo(() => mapSettingArrayToEmailSettingsData(fetchedSettings), [ fetchedSettings ]);

    useEffect(() => {
        if (fetchedSettings) {
            const hostSetting = fetchedSettings.find(setting => setting.key === EmailSettingKey.Host);
            if (hostSetting?.value && hostSetting.value !== 'SendGrid') {
                setMailSetting('custom');
            }
            reset(originalData);
        }
    }, [ reset, fetchedSettings, originalData ]);

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'useDefaultCredentials' && value.useDefaultCredentials === true) {
                setValue('userName', '');
                setValue('password', '');
            }
        });
        return () => subscription.unsubscribe();
    }, [ setValue, watch ]);

    const onSubmit = async (data: IEmailSettingsData) => {
        try {
            if (verifiedSettings) {
                data.host = data.host?.trim();
                data.domain = data.domain?.trim();
                data.userName = data.useDefaultCredentials ? '' : data.userName?.trim();
                data.fromAddress = data.fromAddress?.trim();
                const savedData = await saveSetting(settingUrl, {
                    settings: mapEmailSettingDataToKeyValuePairs(data, originalData),
                    partitionGlobalId,
                });
                mutate(savedData);
                createNotification(translate({ id: 'CLIENT_SMTP_SETTINGS_SAVED_SUCCESSFULLY' }), notificationType.SUCCESS);
            } else {
                createNotification(translate({ id: 'CLIENT_SETTINGS_NOT_TESTED_ERROR' }), notificationType.ERROR);
            }
        } catch (error) {
            createNotification(translate({ id: 'CLIENT_SETTINGS_UPDATE_ERROR' }), notificationType.ERROR);
        }
    };

    const handleTestEmailSettings = useCallback(async () => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_TEST_MAIL_SETTINGS' }),
            hideActions: true,
            customDialogContent: VerifyMailSettingsDialogBody,
            customDialogContentProps: { emailSettingsData: getValues() },
        });
        setVerifiedSettings(proceed);
    }, [ createDialog, getValues, translate ]);

    const handleCancel = () => reset();

    const deleteCustomSetting = useCallback(async () => {
        const hostSetting = fetchedSettings?.find(setting => setting.key === EmailSettingKey.Host);
        if (hostSetting?.value !== 'SendGrid') {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_CUSTOM_SETTING_TITLE' }),
                body: <UiText>
                    {translate({ id: 'CLIENT_DELETE_CUSTOM_SETTING_DESCRIPTION' })}
                </UiText>,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_CONTINUE' }),
            });

            if (!proceed) {
                return false;
            }
        }

        reset({
            ...originalData,
            host: 'SendGrid',
        });

        await deleteSettings([ EmailSettingKey.Domain, EmailSettingKey.Host ], partitionGlobalId);
        await mutate();

        return true;
    }, [ createDialog, fetchedSettings, mutate, originalData, partitionGlobalId, reset, translate ]);

    return {
        fetchedSettings,
        mutate,
        methods,
        onSubmit,
        handleTestEmailSettings,
        handleCancel,
        mailSetting,
        setMailSetting,
        verifiedSettings,
        deleteCustomSetting,
    };
};

export default useOrganizationEmailSettingsRevampViewModel;
