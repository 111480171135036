import { useCentralErrorSetter } from '@experiences/error';
import type { IGlobalExternalClient } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import {
    useModalState,
    useShowDialog,
} from '@experiences/util';
import Box from '@mui/material/Box';
import { produce } from 'immer';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { notificationType } from '../../../common/constants/Constant';
import { ExternalAppRegistrations } from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    getAllAvailableApplications,
    getRegisteredApplication,
    globalOauthUrl,
    registerApplication,
    unregisterApplication,
} from '../../../services/identity/GlobalExternalClientService';
import { accountGlobalId } from '../../../store/selectors';

const useExternalApplicationRegistrationDetailsDrawerViewModel = () => {
    const { formatMessage: translate } = useIntl();

    const {
        open, close,
    } = useModalState(ExternalAppRegistrations);

    const {
        id, type,
    } = useParams() as { publisherId: string; id: string; type: 'register' | 'unregister' | 'update' };

    const partitionGlobalId = useSelector(accountGlobalId);

    const createDialog = useShowDialog();
    const createNotification = useUiSnackBar();
    const showErrorDialog = useCentralErrorSetter();

    const { mutate } = useSWRConfig();

    const {
        data: availableApps, mutate: mutateClients, isLoading: availableAppsLoading,
    } = useSWR<IGlobalExternalClient[]>(
        globalOauthUrl,
        getAllAvailableApplications,
    );

    const {
        data: registeredApps, mutate: updateRegisteredApps, isLoading: registeredAppsLoading,
    } = useSWR(
        type !== 'register' ? {
            url: globalOauthUrl,
            partitionGlobalId,
        } : null,
        getRegisteredApplication,
    );

    const globalApp = useMemo(() => {
        const appDetails = availableApps?.find(app => app.clientId === id);
        const registeredAppDetails = registeredApps?.find(app => app.clientId === id);

        return appDetails ? {
            ...appDetails,
            registrationTime: registeredAppDetails?.registrationTime,
            unRegisteredScopes: registeredAppDetails?.unRegisteredScopes,
        } : undefined;
    }, [ availableApps, id, registeredApps ]);

    const registerApplicationCallback = useCallback(async () => {
        try {
            await registerApplication(partitionGlobalId, id!, globalApp?.scopes ?? []);
            createNotification(translate({ id: 'CLIENT_APPLICATION_REGISTERED_SUCCESSFULLY' }), notificationType.SUCCESS);
            await mutate({
                url: globalOauthUrl,
                partitionGlobalId,
            });
            await mutateClients();
            await updateRegisteredApps();
            close();
        } catch (e) {
            createNotification(translate({ id: 'CLIENT_APPLICATION_FAILED_TO_REGISTER' }), notificationType.ERROR);
        }
    }, [
        close,
        createNotification,
        globalApp?.scopes,
        id,
        mutate,
        mutateClients,
        partitionGlobalId,
        translate,
        updateRegisteredApps,
    ]);

    const unregisterApplicationCallback = useCallback(async (appName: string) => {
        try {
            const proceed = await createDialog({
                icon: 'warning',
                title: translate({ id: 'CLIENT_UNREGISTER_APP_DIALOG_TITLE' }, { appName }),
                body: <Box>
                    <UiText>
                        {translate({ id: 'CLIENT_UNREGISTER_APP_DIALOG_DESCRIPTION' })}
                    </UiText>
                    <UiText style={{ marginTop: '20px' }} >
                        {translate({ id: 'CLIENT_UNREGISTER_APP_DIALOG_DESCRIPTION_2' })}
                    </UiText>
                </Box>,
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_UNREGISTER' }),
            });

            if (!proceed) {
                return;
            }

            await unregisterApplication(partitionGlobalId, id!);
            createNotification(translate({ id: 'CLIENT_APPLICATION_UNREGISTERED_SUCCESSFULLY' }), notificationType.SUCCESS);
            await updateRegisteredApps();
            await mutateClients();
            close();
        } catch (e) {
            createNotification(translate({ id: 'CLIENT_APPLICATION_FAILED_TO_UNREGISTER' }), notificationType.ERROR);
        }
    }, [ close, createDialog, createNotification, id, mutateClients, partitionGlobalId, translate, updateRegisteredApps ]);

    const updateApplicationCallback = useCallback(async () => {
        const updatedApp = produce(globalApp!, draft => {
            draft.scopes = draft.scopes.concat(draft.unRegisteredScopes ?? []);
        });

        try {
            await registerApplication(partitionGlobalId, updatedApp.clientId, updatedApp.scopes);
            createNotification(translate({ id: 'CLIENT_APPLICATION_UPDATED_SUCCESSFULLY' }), notificationType.SUCCESS);
            await updateRegisteredApps();
            await mutateClients();
            close();
        } catch (error) {
            showErrorDialog(translate({ id: 'CLIENT_APPLICATION_FAILED_TO_UPDATE' }));
        }
    }, [ close, createNotification, globalApp, mutateClients, partitionGlobalId, showErrorDialog, translate, updateRegisteredApps ]);

    return {
        loading: availableAppsLoading || registeredAppsLoading,
        open,
        close,
        globalApp,
        type,
        registerApplicationCallback,
        unregisterApplicationCallback,
        updateApplicationCallback,
    };
};

export default useExternalApplicationRegistrationDetailsDrawerViewModel;
