import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { LanguageCulture } from '@experiences/locales';
import { useLocalization } from '@experiences/locales';
import { getEnvVariableValue } from '@experiences/util';
import { useCallback } from 'react';

export enum ProductType {
    ORCHESTRATOR = 'orchestrator',
    AUTOMATION_CLOUD = 'automation-cloud',
    AUTOMATION_SUITE = 'automation-suite',
    FEDRAMP = 'automation-cloud-public-sector',
    STUDIO = 'studio',
    ROBOT = 'robot',
    ACTIVITIES = 'activities',
    AI_CENTER = 'ai-center',
    MARKETPLACE = 'marketplace',
    OVERVIEW = 'overview',
}

export enum DeliveryType {
    AUTOMATION_CLOUD = 'automation-cloud',
    AUTOMATION_SUITE = 'automation-suite',
    FEDRAMP = 'automation-cloud-public-sector',
    STANDALONE = 'standalone',
    OTHER = 'other',
}

interface ILanguageOption {
    overrideLanguage?: LanguageCulture;
    excludedLanguages?: string[];
}

interface ILinkOption {
    articleSlug?: string;
    product?: ProductType;
    delivery?: DeliveryType;
    version?: string;

}

/**
 * Generates documentation links following the structure: https://docs.uipath.com/{lang}/{product}/{delivery-option}/{version}/{article}
 * @param {ILanguageOption} langOptions - An object that overrides default language and optional list of languages to exclude.
 * @returns {function} A callback function that takes an optional parameter of type ILinkOption and returns
 * a documentation URL link based on the given parameters:
 * - product type (default type is 'automation-cloud')
 * - delivery type (default is defined based on the product build configurations),
 * - version (default is 'latest' or a given one in the product build configurations).
 **/
export const useDocumentationLinks = (langOptions?: ILanguageOption) => {
    const languageStorage = useLocalization();
    const language = langOptions?.excludedLanguages && langOptions.excludedLanguages.includes(languageStorage) ? 'en' : languageStorage;
    const isGov = window.env?.ENVIRONMENT?.includes('gov');
    const EnableUseGovLinks = useFeatureFlagValue(Features.EnableUseGovLinks.name);

    const getLocalizedLink = useCallback((options?: ILinkOption) => {
        let version;
        let productType: ProductType = options?.product ?? getEnvVariableValue('DOCUMENTATION_PRODUCT') ?? ProductType.AUTOMATION_CLOUD;
        let delivery;

        switch (process.buildConfigs.name) {
            case 'k8s':
                delivery = DeliveryType.AUTOMATION_SUITE;
                version = getEnvVariableValue('DOCUMENTATION_VERSION');
                break;
            case 'msi':
                delivery = DeliveryType.STANDALONE;
                version = getEnvVariableValue('DOCUMENTATION_VERSION');
                break;
            case 'default':
            default:
                delivery = DeliveryType.AUTOMATION_CLOUD;
                version = 'latest';
        }
        if (EnableUseGovLinks && isGov) {
            productType = ProductType.FEDRAMP;
            delivery = DeliveryType.FEDRAMP;
            version = 'latest';
        }
        const deliveryOption = options?.delivery ?? delivery;
        const versionNumber = options?.version ?? version;
        return `https://docs.uipath.com/${language}/${productType}/${deliveryOption}/${versionNumber}${options?.articleSlug ?? ''}`;
    },
    [ EnableUseGovLinks, isGov, language ],
    );

    return getLocalizedLink;
};
