import {
    AccountLicense,
    getRegionTranslationId,
} from '@experiences/constants';
import {
    TenantSelfServeMigrationEvent,
    TenantSettingsEvent,
} from '@experiences/telemetry';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import { DataResidencyCloudLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { MigrationAvailability } from '../../../common/interfaces/gws';
import { getScheduledTenantMigrationStatus } from '../../../services/global-workflow-service/TenantMigration';
import {
    getTenantById,
    tenantByIdUri,
} from '../../../services/organization/TenantService';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { UiStack } from '../../common/UiStack';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';
import { TenantStatusConstants } from '../TenantConstants';
import { useTenantOperationTrackerContext } from '../TenantOperationTrackerContextProvider';

const useStyles = makeStyles((theme) =>
    createStyles({
        moveTenantBoxDisabled: { backgroundColor: theme.palette.semantic.colorBackgroundDisabled },
        moveTenantTitleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        requestMoveTenant: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            border: 'solid 1px',
            borderColor: theme.palette.semantic.colorForegroundDeEmp,
            marginTop: '8px',
            borderRadius: '4px',
            padding: '0 12px',
            position: 'relative',
            height: '40px',
        },
        moveButtonWrapper: {
            position: 'absolute',
            right: '4px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        chipSpacer: { marginLeft: '8px' },
        infoLink: {
            fontSize: '14px',
            fontWeight: 600,
            margin: '12px 0 12px 0',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        icon: {
            height: '16px',
            width: '16px',
            marginLeft: '4px',
            justifyItems: 'center',
        },
        disabled: {
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            color: theme.palette.semantic.colorForegroundDisable,
            pointerEvents: 'none',
            borderColor: theme.palette.semantic.colorBorderDisabled,
        },
    }),
);

const ChangeRegion: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLinks = useDocumentationLinks();
    const { isFreeOrCommunityRevamp } = useCheckLicense();
    const { logEvent } = useTelemetryHelper();
    const { tenantId } = useParams() as { tenantId: string };
    const { checkOperationList } = useTenantOperationTrackerContext();

    const { data: tenant } = useSWR(
        tenantId ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const {
        data: migrationDetails, isValidating: isScheduledMigrationStatusLoading,
    } = useSWR(
        {
            tenantId,
            url: 'getScheduledTenantMigrationStatus',
        },
        getScheduledTenantMigrationStatus,
    );

    const navigateToMigration = useCallback(async () => {
        if (tenant && migrationDetails) {
            if (migrationDetails.status === MigrationAvailability.SCHEDULED ||
                migrationDetails.status === MigrationAvailability.RUNNING) {
                logEvent(TenantSelfServeMigrationEvent.ViewMigrationDetails);
                navigate(getRoute(RouteNames.EditTenantMigration.replace(':tenantId', tenant.id)));
            } else {
                logEvent(TenantSelfServeMigrationEvent.OpenMigration, { ServerRegion: tenant.region });
                navigate(getRoute(RouteNames.TenantMigration.replace(':tenantId', tenant.id)));
            }
        }
    }, [ tenant, migrationDetails, logEvent, navigate, getRoute ]);

    const moveButtonText = useMemo(() => {
        if (migrationDetails?.status === MigrationAvailability.SCHEDULED) {
            return translate({ id: 'CLIENT_CHANGE_REGION_SCHEDULED' });
        }
        if (migrationDetails?.status === MigrationAvailability.RUNNING) {
            return translate({ id: 'CLIENT_CHANGE_REGION_RUNNING' });
        }
        return translate({ id: 'CLIENT_CHANGE_REGION' });
    }, [ migrationDetails, translate ]);

    const isStatusDisabledOrUpdating = useMemo(() => (tenant?.id && checkOperationList(tenant.id)) ||
            (tenant?.status.toUpperCase() === TenantStatusConstants.DISABLED
            || tenant?.status.toUpperCase() === TenantStatusConstants.UPDATING), [ checkOperationList, tenant?.id, tenant?.status ]);

    return (
        <div
            data-cy="tenant-migration-section">
            <div className={classes.moveTenantTitleSection}>
                <UiText
                    color={isStatusDisabledOrUpdating
                        ? 'var(--color-foreground-disable)' : 'var(--color-foreground-de-emp)'}
                    variant={FontVariantToken.fontSizeMBold}
                >
                    {translate({ id: 'CLIENT_TENANT_REGION' })}
                </UiText>
                {isFreeOrCommunityRevamp &&
                    <UiUpgradeChip
                        className={classes.chipSpacer}
                        licenseType={AccountLicense.PRO}
                        title={translate({ id: 'CLIENT_CHIP_MIGRATION_HEADER' })}
                        description={translate({ id: 'CLIENT_CHIP_MIGRATION_DESCRIPTION' })}
                        iconDescription={translate({ id: 'CLIENT_CHIP_MIGRATION_ICON_DESCRIPTION' })}
                        icon={<LanguageOutlinedIcon />}
                        telemetryTitle={TenantSettingsEvent.ClickedProChipClickedUpgrade} />}
            </div>
            <div className={clsx(classes.requestMoveTenant,
                isFreeOrCommunityRevamp && classes.moveTenantBoxDisabled,
                isStatusDisabledOrUpdating && classes.disabled)}>
                <UiText
                    variant={FontVariantToken.fontSizeM}
                    data-cy="tenant-region">
                    {translate({ id: getRegionTranslationId(tenant?.region) })}
                </UiText>
                {!isFreeOrCommunityRevamp && (
                    <span className={classes.moveButtonWrapper}>
                        <UiProgressButton
                            size="small"
                            disabled={isScheduledMigrationStatusLoading || isStatusDisabledOrUpdating}
                            loading={isScheduledMigrationStatusLoading && !isStatusDisabledOrUpdating}
                            onClick={navigateToMigration}
                            data-cy="tenant-migration-button"
                            variant="text">
                            {moveButtonText}
                        </UiProgressButton>
                    </span>
                )}
            </div>
            <UiStack>
                <Link
                    className={classes.infoLink}
                    onClick={() => logEvent(TenantSelfServeMigrationEvent.ReadMore, { Location: 'tenants-settings-page' })}
                    target="_blank"
                    href={getLocalizedLinks({ articleSlug: DataResidencyCloudLink })}>
                    {translate({ id: 'CLIENT_READ_REGION_RESIDENCY' })}
                    <OpenInNewIcon className={classes.icon} />
                </Link>
            </UiStack>
        </div>
    );
};

export default ChangeRegion;
