import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type {
    IAccessPolicyEntity,
    ResolveDirectoryEntityType,
} from '@experiences/interfaces';
import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { elementToNode } from '@uipath/portal-shell-react';
import type {
    GridHeaderButtons,
    GridRowButtons,
    IColumn,
    IGridOptions,
    ISortModel,
    SelectionManager,
    SortManager,
} from '@uipath/portal-shell-types/components/angular-elements';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import { OrganizationAccessPolicy } from '../../../../common/constants/RouteNames';
import { useGetSetting } from '../../../../common/hooks/useGetSetting';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    accessPolicyUrl,
    getAccessPolicy,
    putAccessPolicy,
} from '../../../../services/identity/AccessPolicyService';
import {
    accountGlobalId,
    isAdminSelector,
} from '../../../../store/selectors';
import { determineIcon } from '../../helpers/determineIcon';
import { AccessPolicySettingKey } from './OrganizationAccessPolicySettingViewModel';

const useStyles = makeStyles(theme => createStyles({
    removeIcon: { color: theme.palette.semantic.colorErrorIcon },
    removeDialogBody: { marginBottom: '20px' },
    disabledIcon: { color: theme.palette.semantic.colorForegroundDisable },
    displayNameRow: {
        display: 'inline-flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    displayNameIcon: {
        fontSize: '36px',
        marginRight: '4px',
        marginTop: '-16px',
    },
}));

type IAccessPolicyEntityWithId = IAccessPolicyEntity & { id: string | number };

const useOrganizationAccessPolicyGridViewModel = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const createDialog = useShowDialog();
    const createNotification = useUiSnackBar();

    const setErrorMessage = useCentralErrorSetter();
    const { getErrorMessage } = useGetErrorInfo();

    const apGridRef = useRef<any>(null);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ sortOpts, setSortOpts ] = useState<Array<ISortModel<IAccessPolicyEntityWithId>>>([]);
    const [ index, setPageIndex ] = useState(0);
    const [ size, setPageSize ] = useState(10);
    const [ sortMgr, setSortMgr ] = useState<SortManager<IAccessPolicyEntityWithId>>();
    const [ selectionMgr, setSelectionMgr ] = useState<SelectionManager<IAccessPolicyEntityWithId>>();

    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);

    const { data: fetchedSettings } = useGetSetting(AccessPolicySettingKey);

    const isAccessPolicyEnabled = useMemo(() =>
        fetchedSettings?.find(setting => setting.key === AccessPolicySettingKey[0])?.value === 'true'
    , [ fetchedSettings ]);

    const {
        data: accessPolicyMembers, isValidating: loading, mutate,
    } = useSWR(
        {
            url: accessPolicyUrl,
            orgId: partitionGlobalId,
        },
        async ({
            url, orgId,
        }: { url: string; orgId: string }) => {
            const results = await getAccessPolicy({
                url,
                partitionGlobalId: orgId,
            });
            return results.map(result => ({
                ...result,
                id: result.identifier,
            }));
        },
    );

    const handleAddMembers = useCallback(
        () => navigate(getRoute(`${OrganizationAccessPolicy}/add`)),
        [ getRoute, navigate ],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const openDeleteDialog = async (rows: IAccessPolicyEntityWithId[]) => {
        if (rows.length === 1) {
            const entity = rows[0];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_REMOVE_SINGLE_USER_TITLE' }, { name: entity.displayName }),
                body: <div>
                    <UiText className={classes.removeDialogBody}>
                        {translate({
                            id: isAccessPolicyEnabled
                                ? 'CLIENT_REMOVE_SINGLE_USER_WARNING'
                                : 'CLIENT_REMOVE_SINGLE_USER_INFO',
                        })}
                    </UiText>
                    <UiText>
                        {translate({ id: 'CLIENT_REMOVE_SINGLE_USER_CHECK' })}
                    </UiText>
                </div>,
                icon: isAccessPolicyEnabled ? 'warning' : 'info',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_REMOVE' }),
            });

            if (!proceed) {
                return;
            }

            try {
                await putAccessPolicy(
                    partitionGlobalId,
                    {
                        entitiesToAdd: [],
                        entitiesToRemove: [ entity.identifier ],
                        entityType: entity.objectType.includes('User') ? 'user' : 'group',
                    },
                );
                createNotification(
                    translate({ id: 'CLIENT_ACCESS_POLICY_SUCCESSFULLY_REMOVED' }, { count: rows.length }),
                    notificationType.SUCCESS,
                );
                await mutate();
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            }
        } else if (rows.length > 1) {
            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_REMOVE_MULTIPLE_USERS_TITLE' }, { count: rows.length }),
                body: <div>
                    <UiText className={classes.removeDialogBody}>
                        {translate({
                            id: isAccessPolicyEnabled
                                ? 'CLIENT_REMOVE_MULTIPLE_USERS_WARNING'
                                : 'CLIENT_REMOVE_MULTIPLE_USERS_INFO',
                        }, { count: rows.length })}
                    </UiText>
                    <UiText>
                        {translate({ id: 'CLIENT_REMOVE_MULTIPLE_USERS_CHECK' })}
                    </UiText>
                </div>,
                icon: isAccessPolicyEnabled ? 'warning' : 'info',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_REMOVE' }),
            });

            if (!proceed) {
                return;
            }

            try {
                await Promise.all([
                    // Batch for users
                    putAccessPolicy(
                        partitionGlobalId,
                        {
                            entitiesToAdd: [],
                            entitiesToRemove: rows.filter(row => row.objectType.toLowerCase().includes('user')).map(row => row.identifier),
                            entityType: 'user',
                        },
                    ),
                    // Batch for groups
                    putAccessPolicy(
                        partitionGlobalId,
                        {
                            entitiesToAdd: [],
                            entitiesToRemove: rows.filter(row => row.objectType.toLowerCase().includes('group')).map(row => row.identifier),
                            entityType: 'group',
                        },
                    ),
                ]);
                createNotification(
                    translate({ id: 'CLIENT_ACCESS_POLICY_SUCCESSFULLY_REMOVED' }, { count: rows.length }),
                    notificationType.SUCCESS,
                );
                await mutate();
            } catch (error) {
                setErrorMessage(await getErrorMessage(error));
            }
        }
    };

    const extraActionHeaderButtons: Array<GridHeaderButtons<IAccessPolicyEntityWithId>> = useMemo(() => {
        const actionList: Array<GridHeaderButtons<IAccessPolicyEntityWithId>> = [];

        const addMembers: GridHeaderButtons<IAccessPolicyEntityWithId> = {
            id: 'addMembers',
            type: 'main',
            buttonType: 'mat-flat-button',
            icon: 'add',
            color: 'primary',
            text: translate({ id: 'CLIENT_ADD_MEMBERS' }),
            label: translate({ id: 'CLIENT_ADD_MEMBERS' }),
            onClick: handleAddMembers,
        };

        const bulkRemoveAction: GridHeaderButtons<IAccessPolicyEntityWithId> = {
            id: 'removeMembers',
            type: 'action',
            buttonType: 'mat-flat-button',
            icon: 'delete',
            color: 'warn',
            text: translate({ id: 'CLIENT_REMOVE' }),
            label: translate({ id: 'CLIENT_REMOVE' }),
            onClick: (rows) => openDeleteDialog(rows ?? []),
        };

        if (isAdmin) {
            actionList.push(...[ addMembers, bulkRemoveAction ]);
        }

        return actionList;
    }, [ handleAddMembers, isAdmin, openDeleteDialog, translate ]);

    const isAdminGroup = useCallback((row: IAccessPolicyEntityWithId) => row.displayName.toLowerCase() === 'administrators', []);

    const extraActionRowButtons: Array<GridRowButtons<IAccessPolicyEntityWithId>> = useMemo(() => {
        if (!isAdmin) {
            return [];
        }
        return [
            {
                id: 'accessPolicyRemove',
                label: translate({ id: 'CLIENT_REMOVE_MEMBER' }),
                dataCy: 'access-policy-remove',
                customTemplate: (row: IAccessPolicyEntityWithId) => elementToNode(
                    <Tooltip title={translate({
                        id: isAdminGroup(row)
                            ? 'CLIENT_ORG_POLICY_ADMIN_ERROR'
                            : 'CLIENT_REMOVE_MEMBER',
                    })}>
                        <IconButton
                            onClick={() => row.displayName.toLowerCase() !== 'administrators' && openDeleteDialog([ row ])}>
                            <RemoveCircleIcon className={clsx({
                                [classes.removeIcon]: !isAdminGroup(row),
                                [classes.disabledIcon]: isAdminGroup(row),
                            })} />
                        </IconButton>
                    </Tooltip>
                ),
            },
        ];
    }, [ classes.disabledIcon, classes.removeIcon, isAdmin, isAdminGroup, openDeleteDialog, translate ]);

    const columnDef: Array<IColumn<IAccessPolicyEntityWithId>> = useMemo(() => [
        {
            property: 'displayName',
            title: translate({ id: 'CLIENT_NAME' }),
            sortable: true,
            resizable: false,
            visible: true,
            customCell: row => elementToNode(<ApolloThemeProvider>
                <IntlProvider>
                    <span className={classes.displayNameRow}>
                        <i className={clsx(determineIcon(row.objectType as ResolveDirectoryEntityType), classes.displayNameIcon)} />
                        <UiText>
                            {row.displayName}
                        </UiText>
                    </span>
                </IntlProvider>
            </ApolloThemeProvider>),
        },
        {
            property: 'email',
            title: translate({ id: 'CLIENT_EMAIL' }),
            sortable: true,
            visible: true,
            resizable: false,
        },
    ], [ classes.displayNameIcon, classes.displayNameRow, translate ]);

    const renderedData = useMemo(() => {
        if (!accessPolicyMembers) {
            return [];
        }

        const finalResults = accessPolicyMembers.filter(item => item.displayName.toLowerCase().includes(searchTerm.toLowerCase()));

        finalResults.sort((prev: any, cur: any) => {
            for (const sort of sortOpts) {
                if (!sort || sort.direction === '' || prev[sort.field] === cur[sort.field]) {
                    continue;
                }
                if (sort.direction === 'asc') {
                    return prev[sort.field] > cur[sort.field] ? 1 : -1;
                }
                return prev[sort.field] < cur[sort.field] ? 1 : -1;
            }

            return 0;
        });

        return finalResults.slice(index * size, index * size + size);
    }, [ accessPolicyMembers, index, size, searchTerm, sortOpts ]);

    const configuration: IGridOptions<IAccessPolicyEntityWithId> = useMemo(() => ({
        loading,
        columns: columnDef,
        headerOptions: {
            search: true,
            searchTerm: (term: string) => setSearchTerm(term),
            gridHeaderButtons: extraActionHeaderButtons,
        },
        footerOptions: {
            length: (accessPolicyMembers ?? []).length,
            pageSizes: [ 5, 10, 25, 50 ],
            pageIndex: index,
            pageSize: size,
            pageChange: ({
                pageIndex, pageSize,
            }: {
                pageIndex: number; pageSize: number;
            }) => {
                index !== pageIndex && setPageIndex(pageIndex);
                size !== pageSize && setPageSize(pageSize);
            },
        },
        gridRowButtons: extraActionRowButtons,
        refreshable: true,
        data: renderedData,
        refresh: () => mutate(),
        onGridApi: ({
            sortManager, selectionManager,
        }: {
            sortManager: SortManager<IAccessPolicyEntityWithId>;
            selectionManager: SelectionManager<IAccessPolicyEntityWithId>;
        }) => {
            setSortMgr(sortManager);
            setSelectionMgr(selectionManager);
        },
        disableSelectionByEntry: row => row.displayName.toLowerCase() === 'administrators'
            ? translate({ id: 'CLIENT_ORG_POLICY_ADMIN_ERROR' })
            : null,
    }), [
        loading,
        columnDef,
        extraActionHeaderButtons,
        accessPolicyMembers,
        index,
        size,
        extraActionRowButtons,
        renderedData,
        mutate,
        translate,
    ]);

    useEffect(() => {
        if (apGridRef.current && configuration) {
            apGridRef.current.configuration = configuration;
        }
    }, [ configuration ]);

    useEffect(() => {
        if (accessPolicyMembers && selectionMgr) {
            (selectionMgr as any).clear();
        }
    }, [ accessPolicyMembers, selectionMgr ]);

    useEffect(() => {
        if (!sortMgr) {
            return;
        }

        sortMgr?.sort$.asObservable().subscribe((sort: ISortModel<IAccessPolicyEntityWithId>) => {
            setSortOpts((oldOpts) => {
                if (!sort.field) {
                    return oldOpts;
                }

                const oldOpt = oldOpts.find(opt => opt.field === sort.field);
                const noDirection = sort.direction === '';

                // This is to keep the current behavior which is a weird one, because
                // initial state is sorted asc but as secondary priority, and after that you cannot
                // ever get to that state since type field is hidden
                // So this logic is to keep sort asc as secondary priority in case it is reset to default
                const newSort = {
                    ...sort,
                    direction: noDirection
                        ? oldOpt
                            ? oldOpt.direction === 'asc'
                                ? 'desc'
                                : 'asc'
                            : ''
                        : sort.direction,
                } as ISortModel<IAccessPolicyEntityWithId>;

                const idx = oldOpts.findIndex(opt => opt.field === newSort.field);

                if (idx === -1) {
                    return [ newSort, ...oldOpts ];
                }

                return [
                    ...(noDirection ? [] : [ newSort ]),
                    ...oldOpts.slice(0, idx),
                    ...oldOpts.slice(idx + 1, idx + 2),
                    ...(noDirection ? [ newSort ] : []),
                    ...oldOpts.slice(idx + 2),
                ];
            });
        });

        sortMgr.sort$.next({
            direction: 'asc',
            field: 'displayName',
            title: translate({ id: 'CLIENT_DISPLAY_NAME' }),
        });

        sortMgr.sort$.next({
            direction: 'asc',
            field: 'email',
            title: translate({ id: 'CLIENT_EMAIL' }),
        });
    }, [ sortMgr, translate ]);

    return {
        apGridRef,
        memberCount: accessPolicyMembers?.length ?? 0,
    };
};

export default useOrganizationAccessPolicyGridViewModel;
