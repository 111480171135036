import {
    getPriceString,
    MONTHLY_PLAN_TYPE,
    useEcommerce,
    useEcommerceTelemetry,
} from '@experiences/ecommerce';
import { useLocalization } from '@experiences/locales';
import { UiText } from '@experiences/ui-common';
import {
    formatDate,
    useModalState,
    useShowDialog,
    useSnackbar,
} from '@experiences/util';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import {
    makeStyles,
    useTheme,
} from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import {
    billingUrl,
    cancelLicensePendingChange,
    getLicensePendingChange,
} from '../../services/licensing/BillingService';
import { accountLogicalName } from '../../store/selectors';
import { UiDrawer } from '../common/UiDrawer';

const useStyles = makeStyles(theme =>
    createStyles({
        label: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '24px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            margin: '4px 0px',
        },
        labelAndValueContainer: { marginTop: '16px' },
        pendingChangesTableContainer: { marginTop: '24px' },
        pendingChangesTableHeader: {
            fontWeight: 600,
            fontSize: '12px',
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '12px',
        },
        pendingChangesTableItem: { marginBottom: '15px' },
        divider: {
            margin: '9px 0px 24px 0px',
            borderColor: theme.palette.semantic.colorBorderDeEmp,
            borderWidth: '1px',
        },
        cancelPendingChangeDialogContent: { width: '446px' },
        plusTax: {
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
    }),
);

const EcommerceManageLicensePendingChangeComponent: React.FC = () => {
    const theme = useTheme();
    const classes = useStyles();
    const {
        open, close,
    } = useModalState(RouteNames.Licensing);
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const [ loading, setLoading ] = useState(false);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const currentAccountName = useSelector(accountLogicalName);
    const language = useLocalization();
    const { enqueueSnackbar } = useSnackbar();
    const logEcommerceEvent = useEcommerceTelemetry();
    const location = useLocation();
    const subscriptionScheduleId = useMemo(() => location?.state?.subscriptionScheduleId, [ location ]);
    const { currentSkuPackageDetails } = useEcommerce();

    const { mutate } = useSWRConfig();

    const {
        data: licensePendingChange, isValidating: isLoading, mutate: mutatePendingChange,
    } = useSWR(
        {
            url: `${billingUrl}/planDetails/licensePendingChangeBanner`,
            accountName: currentAccountName,
            stripeSubscriptionScheduleId: subscriptionScheduleId,
        },
        getLicensePendingChange,
    );

    const licensePendingChangeCanceledSuccessfullyMessage = useMemo(() =>
        (<UiText
            style={{ color: theme.palette.semantic.colorForegroundInverse }}
            data-cy="license-pending-change-canceled-successfully-message">
            {translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_SUCCESS_TOAST' })}
        </UiText>),
    [ translate, theme ]);

    const onCancelLicensePendingChange = useCallback(async () => {
        if (!licensePendingChange || !subscriptionScheduleId) {
            return;
        }

        const currentSubscriptionPriceString = getPriceString(licensePendingChange.subscriptionPriceIgnoringPendingChange,
            licensePendingChange.currency, language, undefined, false);

        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_DIALOG_TITLE' }),
            body: (<div className={classes.cancelPendingChangeDialogContent}>
                {currentSkuPackageDetails.planType === MONTHLY_PLAN_TYPE ?
                    translate(
                        { id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_DIALOG_BODY_MONTHLY' },
                        { currentSubscriptionPaymentAmount: currentSubscriptionPriceString },
                    ) : translate(
                        { id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_DIALOG_BODY_ANNUAL' },
                        { currentSubscriptionPaymentAmount: currentSubscriptionPriceString },
                    )}
                <span className={classes.plusTax}>
                    {translate({ id: 'CLIENT_TAX' })}
                </span>
            </div>),
            icon: 'warning',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
            dataCy: 'cancel-license-pending-change-confirmation-dialog',
        });

        if (proceed) {
            const decreasedProducts = Object.assign(
                {},
                ...licensePendingChange.pendingChangeItems
                    .filter(p => p.currentQuantity - p.plannedQuantity > 0)
                    .map(p => ({ [p.productCode]: p.currentQuantity - p.plannedQuantity })),
            );
            try {
                setLoading(true);
                logEcommerceEvent('Licenses.CancelPendingDecrease', {
                    StripeSubscriptionScheduleId: subscriptionScheduleId,
                    DecreasedProducts: decreasedProducts,
                });
                await cancelLicensePendingChange(currentAccountName, subscriptionScheduleId);
                await mutate({
                    accountName: currentAccountName,
                    url: `${billingUrl}/planDetails/licenseInfoSection`,
                });
                await mutatePendingChange();
                setLoading(false);
                close(true);
                enqueueSnackbar(licensePendingChangeCanceledSuccessfullyMessage, { variant: 'success' });

            } catch (error: any) {
                setLoading(false);
                setShowDrawerError(true);
                logEcommerceEvent('Licenses.CancelPendingDecreaseError', {
                    StripeSubscriptionScheduleId: subscriptionScheduleId,
                    DecreasedProducts: decreasedProducts,
                });
            }
        }
    },
    [
        licensePendingChange,
        subscriptionScheduleId,
        language,
        createDialog,
        translate,
        classes.cancelPendingChangeDialogContent,
        classes.plusTax,
        currentSkuPackageDetails.planType,
        logEcommerceEvent,
        currentAccountName,
        mutate,
        mutatePendingChange,
        close,
        enqueueSnackbar,
        licensePendingChangeCanceledSuccessfullyMessage,
    ],
    );

    return (
        <UiDrawer
            title={translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_TITLE' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            error={{
                showError: showDrawerError,
                message: translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_FAILURE_MESSAGE' }),
            }}
            loading={!licensePendingChange || isLoading || loading}
        >
            <>
                <div
                    className={classes.labelAndValueContainer}
                    data-cy="pending-license-change-changed-by">
                    <InputLabel className={classes.label}>
                        {translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_CHANGED_BY_LABEL' })}
                    </InputLabel>
                    <UiText>
                        {`${licensePendingChange?.changedByFirstName} ${licensePendingChange?.changedByLastName ?? ''}`}
                    </UiText>
                </div>

                <div
                    className={classes.labelAndValueContainer}
                    data-cy="pending-license-change-changed-on">
                    <InputLabel className={classes.label}>
                        {translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_CHANGED_ON_LABEL' })}
                    </InputLabel>
                    <UiText>
                        {formatDate(licensePendingChange?.changedOn, language)}
                    </UiText>
                </div>

                <Grid
                    container
                    columns={{ xs: 12 }}
                    className={classes.pendingChangesTableContainer}
                    key='license-pending-change-container'>
                    <Grid
                        item
                        xs={8}
                        key='license-pending-change-items-product-header'>
                        <UiText className={classes.pendingChangesTableHeader}>
                            {translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_LICENSE_TYPE_HEADER' })}
                        </UiText>
                    </Grid>
                    <Grid
                        item
                        container
                        justifyContent="center"
                        xs={2}
                        key='license-pending-change-items-current-quantity-header'>
                        <UiText className={classes.pendingChangesTableHeader}>
                            {translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_CURRENT_QUANTITY_HEADER' })}
                        </UiText>
                    </Grid>
                    <Grid
                        item
                        xs={1} />
                    <Grid
                        item
                        container
                        justifyContent="center"
                        xs={1}
                        key='license-pending-change-items-planned-quantity-header'>
                        <UiText className={classes.pendingChangesTableHeader}>
                            {translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_NEW_QUANTITY_HEADER' })}
                        </UiText>
                    </Grid>

                    {licensePendingChange?.pendingChangeItems?.map((pendingChangeItem) => (
                        <>
                            <Grid
                                item
                                xs={8}
                                key={`pending-change-item-${pendingChangeItem.productCode}`}
                                data-cy={`pending-change-item-${pendingChangeItem.productCode}`}
                                className={classes.pendingChangesTableItem}>
                                <UiText>
                                    {translate({ id: `CLIENT_PRODUCT_${pendingChangeItem.productCode}` })}
                                </UiText>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent="center"
                                xs={2}
                                key={`pending-change-item-${pendingChangeItem.productCode}-current-quantity`}
                                data-cy={`pending-change-item-${pendingChangeItem.productCode}-current-quantity`}
                                className={classes.pendingChangesTableItem}>
                                <UiText>
                                    {pendingChangeItem.currentQuantity}
                                </UiText>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent="center"
                                xs={1}
                                key={`pending-change-item-${pendingChangeItem.productCode}-linking-column`}
                                data-cy={`pending-change-item-${pendingChangeItem.productCode}-linking-column`}
                                className={classes.pendingChangesTableItem}>
                                <UiText>
                                    {translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_PREPOSITION_BETWEEN_QUANTITY_CHANGES' })}
                                </UiText>
                            </Grid>
                            <Grid
                                item
                                container
                                justifyContent="center"
                                xs={1}
                                key={`pending-change-item-${pendingChangeItem.productCode}-planned-quantity`}
                                data-cy={`pending-change-item-${pendingChangeItem.productCode}-planned-quantity`}
                                className={classes.pendingChangesTableItem}>
                                <UiText>
                                    {pendingChangeItem.plannedQuantity}
                                </UiText>
                            </Grid>
                        </>
                    ))}
                </Grid>

                <Divider className={classes.divider} />

                <Button
                    variant="outlined"
                    onClick={onCancelLicensePendingChange}
                    data-cy="cancel-pending-license-change">
                    {translate({ id: 'CLIENT_ECOMMERCE_LICENSE_PENDING_CHANGE_CANCEL_CHANGE_BUTTON' })}
                </Button>
            </>
        </UiDrawer>
    );
};

export default EcommerceManageLicensePendingChangeComponent;
