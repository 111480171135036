import { UiErrorBoundary } from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import { ApProgressSpinner } from '@uipath/portal-shell-react';
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';

import { accountLogicalName } from '../../store/selectors';
import { useModuleUrl } from './useModuleUrl';
import { useRemoteModuleViewModel } from './useRemoteModuleViewModel';

const useStyles = makeStyles(() => ({
    container: {
        textAlign: 'center',
        width: '100%',
    },
}));

const RemoteModuleInner = ({ serviceName }: { serviceName: string }) => {
    const classes = useStyles();
    const host = useRef<HTMLDivElement>(null);
    const currentAccountLogicalName = useSelector(accountLogicalName);
    const moduleUrl = useModuleUrl(serviceName);
    const {
        remoteEntryLoaded, error,
    } = useRemoteModuleViewModel(serviceName, host, currentAccountLogicalName, moduleUrl);

    if (error) {
        throw new Error(error);
    }

    return (
        <div
            className={classes.container}
            ref={host}>
            {!remoteEntryLoaded ? <ApProgressSpinner /> : ''}
        </div>
    );
};

// This component is used to load a remote module based on the service name.
// It is strongly recommended that the service name matches the OMS service name.
export const RemoteModuleComponent = ({ serviceName }: { serviceName: string }) => (
    <UiErrorBoundary>
        <RemoteModuleInner serviceName={serviceName} />
    </UiErrorBoundary>
);
