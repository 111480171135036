import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { getEnvVariableValue } from '@experiences/util';
import {
    useCallback,
    useEffect,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isHostModeSelector } from '../../store/selectors';
import buildTitleMap from './Constant/buildTitleMap';

export const useTabName = () => {
    const { formatMessage: translate } = useIntl();
    const location = useLocation();
    const hostMode = useSelector(isHostModeSelector);

    const EnableAdminRoutesRefactor = useFeatureFlagValue(Features.EnableAdminRoutesRefactor.name);

    const getTitle = useCallback(
        (pathname: string) => {
            const includesPathTitle = buildTitleMap(hostMode);
            const baseRoute = process.buildConfigs.tabNameRoute + '/';
            const routeString = pathname.substr(pathname.indexOf(baseRoute) + baseRoute.length);

            if (EnableAdminRoutesRefactor && includesPathTitle.get(`/${routeString}`)) {
                return translate({ id: includesPathTitle.get(`/${routeString}`) });
            }

            const path = routeString.substring(0, routeString.indexOf('/')) || routeString.substring(0);
            return includesPathTitle.get(path)
                ? translate({ id: includesPathTitle.get(path) }, { productName: getEnvVariableValue('PRODUCT_NAME') })
                : getEnvVariableValue('PRODUCT_NAME');
        },
        [ hostMode, EnableAdminRoutesRefactor, translate ],
    );

    useEffect(() => {
        document.title = getTitle(location.pathname);
    }, [ getTitle, location.pathname ]);

    return { getTitle };
};
