import { useAuthContext } from '@experiences/util';
import {
    useEffect,
    useState,
} from 'react';

import { getModuleFederationUrl } from './ModuleVersionService';
import { RemoteModuleMapping } from './RemoteModuleMapping';

// This hook is used to get the module federation URL for a given service name.
// It fetches the module federation URL from the backend and returns it.
export const useModuleUrl = (serviceName: string) => {
    const [ moduleUrl, setModuleUrl ] = useState<string>('');
    const { token } = useAuthContext();

    useEffect(() => {
        const fetchModuleUrl = async () => {
            if (RemoteModuleMapping[serviceName] === undefined) {
                throw new Error(`Remote module ${serviceName} is not defined.`);
            }
            const url = await getModuleFederationUrl({
                moduleFederationVersionUrl: RemoteModuleMapping[serviceName].moduleFederationVersionPath,
                accessToken: token,
            });
            setModuleUrl(url);
        };

        fetchModuleUrl();
    }, [ serviceName, token ]);

    return moduleUrl;
};
