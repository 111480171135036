import type { IPagination } from '@experiences/interfaces';
import { getFormattedDate } from '@experiences/util';

import type {
    AuditEventQueryResultDto,
    AuditEventSourceDto,
    IAuditLog,
} from '../../component/audit/interfaces/auditLog';
import type { DateTimeRange } from '../../component/common/UiDatePicker/UiDatePicker';
import {
    get,
    getFile,
} from '../utility/Requests.default';

export const auditUrl = process.buildConfigs.auditApiBaseRoute;

export const organizationAuditUrl = '/orgaudit_/api';

export const tenantAuditUrl = 'tenantaudit_/api';

export function mapAuditResponseRowIds(response: AuditEventQueryResultDto, start = 0): AuditEventQueryResultDto {
    return {
        ...response,
        auditEvents: response.auditEvents?.map((e, i) => ({
            ...e,
            createdOn: getFormattedDate(e.createdOn, 'ddd, MMM D, YYYY H:mm'),
            rowId: i + start,
        })),
    };
}

export interface IAuditLogResponse {
    totalCount: number;
    results: IAuditLog[];
}

export function getAuditLogs({
    pagination, language, partitionGlobalId,
}: {
    pagination: IPagination;
    language: string;
    partitionGlobalId: string;
}) {
    return get<IAuditLogResponse>(`${auditUrl}/${partitionGlobalId}`, {
        urlParams: { language },
        pagination,
    });
}

export function downloadAuditLogs(language: string, partitionGlobalId: string) {
    return getFile(`${auditUrl}/${partitionGlobalId}/download`, { urlParams: { language } });
}

export function getAuditSources({ url }: { url: string }) {
    return get<AuditEventSourceDto[]>(url, { stripPortalPath: true });
}

export async function getAuditEvents({
    url, sourceFilter, categoryFilter, actionFilter, timeFilter,
}: { url: string; sourceFilter: string[]; categoryFilter: string[]; actionFilter: string[]; timeFilter: DateTimeRange }) {
    const response = await get<AuditEventQueryResultDto>(url, {
        stripPortalPath: true,
        urlParams: {
            source: sourceFilter,
            target: categoryFilter,
            type: actionFilter,
            from: timeFilter.from?.toISOString(),
            to: timeFilter.to?.toISOString(),
        },
    });

    return mapAuditResponseRowIds(response);
}

export async function getNextAuditEvents(url: string, start: number) {
    const res = await get<AuditEventQueryResultDto>(url, {
        stripPortalPath: true,
        ignoreBasePath: true,
    });

    return mapAuditResponseRowIds(res, start);
}
