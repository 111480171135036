import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { ApButton } from '@uipath/portal-shell-react';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../../common/constants/Constant';
import { EnforcementType } from '../../../../common/constants/IPRestrictionConstant';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type { IIPNetwork } from '../../../../common/interfaces/iprestriction';
import {
    deleteBulkIpNetworks,
    deleteIpNetwork,
} from '../../../../services/access-policy/IPNetworkService';
import { accountGlobalId } from '../../../../store/selectors';
import { UiDeleteButton } from '../../../common/UiDeleteButton/UiDeleteButton';
import {
    SpacingToken,
    UiStack,
} from '../../../common/UiStack';
import { validateIp } from './IPRestrictionUtil';

const IPRestrictionDeleteDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, currentIp, ipRange, ipStatus, refreshCallback,
}) => {
    const createNotification = useUiSnackBar();
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const [ loading, setLoading ] = useState(false);

    const isBulkDelete = useMemo(() => Array.isArray(ipRange), [ ipRange ]);
    const inIpRange = useMemo(() => {
        if (isBulkDelete) {
            const deleteList = ipRange.map((range: IIPNetwork) => range.ipNetwork);
            return validateIp (currentIp, deleteList);
        }
        return validateIp(currentIp, [ ipRange.ipNetwork ]);

    }
    , [ currentIp, ipRange, isBulkDelete ]);

    const enforcementEnabled = useMemo(() => ipStatus === EnforcementType.ENABLED, [ ipStatus ]);

    const deleteAndClose = useCallback(async () => {
        try {
            setLoading(true);
            if (isBulkDelete) {
                const deleteIpNetworks = ipRange.map((range: IIPNetwork) => range.ipNetwork);
                await deleteBulkIpNetworks(partitionGlobalId, deleteIpNetworks);
            } else {
                await deleteIpNetwork(partitionGlobalId, ipRange.id);
            }
            createNotification(
                isBulkDelete
                    ? translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_BULK_SUCCESS_NOTIFICATION' })
                    : translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_SUCCESS_NOTIFICATION' }, { 0: ipRange.name }),
                notificationType.SUCCESS,
            );
            refreshCallback();
            closeDialog(true);
        } catch (error) {
            createNotification(
                isBulkDelete
                    ? translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_BULK_FAILURE_NOTIFICATION' })
                    : translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_FAILURE_NOTIFICATION' }, { 0: ipRange.name }),
                notificationType.ERROR,
            );
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
            setLoading(false);
        } finally {
            setLoading(false);
        }
    }, [
        isBulkDelete,
        createNotification,
        translate,
        ipRange,
        refreshCallback,
        closeDialog,
        partitionGlobalId,
        getErrorObject,
        getErrorMessage,
        setErrorMessage,
    ]);

    const deleteBlocked = useMemo(() =>
        <UiText data-cy="delete-blocked-message">
            {translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_BLOCKED' })}
        </UiText>
    , [ translate ]);

    const deleteMessage = useMemo(() =>

        <UiText data-cy="delete-confirmation-ask">
            {isBulkDelete
                ? translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_ASK_BULK' })
                : <FormattedMessage
                    id="CLIENT_IP_RESTRICTION_DELETE_ASK"
                    values={{ 0: ipRange.name }}
                />}
        </UiText>
    , [ ipRange.name, isBulkDelete, translate ]);

    const deleteWarning = useMemo(() =>
        <UiText data-cy="delete-confirmation-type-warning">
            {isBulkDelete
                ? translate({ id: 'CLIENT_IP_RESTRICTION_DELETE_WARNING_BULK' })
                : <FormattedMessage
                    id="CLIENT_IP_RESTRICTION_DELETE_WARNING"
                    values={{ 0: ipRange.name }}
                />}
        </UiText>

    , [ ipRange.name, isBulkDelete, translate ]);

    const deleteContent = useMemo(() => {
        if (enforcementEnabled && inIpRange) {
            return deleteBlocked;
        } else if (enforcementEnabled && !inIpRange) {
            return (
                <UiStack
                    direction="column"
                    gap={SpacingToken.XS}>
                    {deleteWarning}
                    {deleteMessage}
                </UiStack>
            );
        }
        return deleteMessage;

    }, [ deleteBlocked, deleteMessage, deleteWarning, enforcementEnabled, inIpRange ]);

    const buttons = useMemo(() => (
        <UiStack
            gap={SpacingToken.XS}
            justify="end">
            <ApButton
                onClick={() => closeDialog()}
                variant={enforcementEnabled && inIpRange ? 'primary' : 'tertiary'}
                data-cy="close-button-confirmation"
                label={translate({ id: enforcementEnabled && inIpRange ? 'CLIENT_OK' : 'CLIENT_CANCEL' })}
            />
            {!(enforcementEnabled && inIpRange) &&
            <UiDeleteButton
                data-cy="delete-button-confirmation"
                loading={loading}
                onClick={deleteAndClose}
            />}
        </UiStack>
    ), [ closeDialog, deleteAndClose, enforcementEnabled, inIpRange, loading, translate ]);

    return (
        <UiStack
            direction="column"
            gap={SpacingToken.M}>
            {deleteContent}
            {buttons}
        </UiStack>
    );
};

export default IPRestrictionDeleteDialogBody;
