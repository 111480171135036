import type { ILabelModelObject } from '@experiences/interfaces';
import { PermissionType } from '@experiences/interfaces';
import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import { elementToNode } from '@uipath/portal-shell-react';
import type {
    DataManager,
    FilterManager,
    GridHeaderButtons,
    GridRowButtons,
    IColumn,
    IGridOptions,
} from '@uipath/portal-shell-types/components/angular-elements';
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    Router,
    UNSAFE_NavigationContext,
    useLocation,
    useParams,
} from 'react-router-dom';
import useSWR, { useSWRConfig } from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import {
    getKeyValues,
    getPermissions,
    tagsUrl,
} from '../../../../services/orchestrator/TagsService.default';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../../services/organization/TenantService';
import {
    accountGlobalId,
    accountLogicalName,
    isAdminSelector,
} from '../../../../store/selectors';
import { hasPermission } from '../../addedit/properties/TenantTagsUtil';
import { useTagsDeleteDialog } from '../../common/useTagsDeleteDialog';
import { useTagsDisabled } from '../../common/useTagsDisabled';
import TenantTagsPropertiesMoreActions from './TenantTagsPropertiesMoreActions';

const DEFAULT_PAGE_SIZE = 25;
const DEFAULT_PAGE_INDEX = 0;

const useTenantTagPropertiesViewModel = () => {
    const { tenantId } = useParams() as { tenantId: string };
    const navigate = useNavigateWithParams();
    const location = useLocation();
    const navigator = useContext(UNSAFE_NavigationContext).navigator;
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const createNotification = useUiSnackBar();

    // Redux state
    const organizationName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);
    const accountId = useSelector(accountGlobalId);

    // Component state
    const refreshState = useState(false);
    const [ refresh, setRefresh ] = refreshState;

    const apGridRef = useRef<any>(null);
    const [ index, setPageIndex ] = useState(DEFAULT_PAGE_INDEX);
    const [ size, setPageSize ] = useState(DEFAULT_PAGE_SIZE);
    const [ searchTerm, setSearchTerm ] = useState('');
    const [ filterMgr, setFilterMgr ] = useState<FilterManager<ILabelModelObject>>();
    const [ dataMgr, setDataMgr ] = useState<DataManager<ILabelModelObject>>();

    const { mutate } = useSWRConfig();

    const { data: tenant } = useSWR(
        (tenantId && !process.buildConfigs.showForMSI) ?
            {
                url: organizationManagementTenantUri,
                id: tenantId,
            } : null,
        getTenantById,
    );

    const { data: permissions } = useSWR(
        (organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId && tenant) ?
            {
                url: `${tagsUrl}/permissions`,
                accountLogicalName: organizationName,
                tenantName: tenant?.name,
                selectedAccountId: accountId,
            } : null,
        getPermissions,
    );

    const {
        data: propertiesData, isValidating: loading, mutate: propertiesMutate,
    } = useSWR(
        (organizationName && tenant?.name) || (process.buildConfigs.showForMSI && accountId && tenant) ?
            {
                url: `${tagsUrl}/keyValue`,
                pagination: {
                    top: size,
                    skip: index * size,
                    searchTerm,
                },
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            } : null,
        getKeyValues,
    );

    const changePageIndex = useCallback((pageIndex: number) => {
        setPageIndex(pageIndex);
        if (tenant) {
            mutate({
                url: `${tagsUrl}/keyValue`,
                pagination: {
                    top: size,
                    skip: pageIndex * size,
                    searchTerm,
                },
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            });
        }
    }, [ accountId, mutate, organizationName, searchTerm, size, tenant ]);

    const displayValues = useCallback((values: string[] | undefined) => {
        if (!values) {
            return values;
        }
        if (values.length > 4) {
            return values.slice(0, 4).concat([ ' ' + translate({ id: 'CLIENT_AND_MORE' }) ]);
        }
        return values;
    }, [ translate ]);

    const changePageSize = useCallback((pageSize: number) => {
        setPageSize(pageSize);
        if (tenant) {
            mutate({
                url: `${tagsUrl}/keyValue`,
                pagination: {
                    top: pageSize,
                    skip: index * pageSize,
                    searchTerm,
                },
                accountLogicalName: organizationName,
                tenantName: tenant.name,
                selectedAccountId: accountId,
            });
        }
    }, [ accountId, index, mutate, organizationName, searchTerm, tenant ]);

    useEffect(() => {
        propertiesData?.results.map((iterator) => {
            iterator.values = displayValues(iterator.values);
        });
    }, [ displayValues, propertiesData ]);

    const openDeleteDialog = useTagsDeleteDialog('Property', organizationName, tenantId, () => {
        setRefresh(true);
        propertiesMutate();
        createNotification(translate({ id: 'CLIENT_TENANT_TAGS_DELETE_SUCCESS' }), notificationType.SUCCESS);
    });

    const { checkDisabled } = useTagsDisabled();

    const handleAddProperty = useCallback(() => {
        navigate(getRoute(process.buildConfigs.showForMSI
            ? `${RouteNames.TenantTagsPropertiesMsi}/add/key`
            : `${RouteNames.TenantTagsProperties}/add/key`.replace(':tenantId', tenantId)));
    }, [ getRoute, navigate, tenantId ]);

    const extraActionHeaderButtons: Array<GridHeaderButtons<ILabelModelObject>> = useMemo(() => {
        const actionList: Array<GridHeaderButtons<ILabelModelObject>> = [];
        const addProperty: GridHeaderButtons<ILabelModelObject> = {
            id: 'addProperty',
            type: 'main',
            buttonType: 'mat-flat-button',
            color: 'primary',
            text: translate({ id: 'CLIENT_ADD_PROPERTY' }),
            label: translate({ id: 'CLIENT_ADD_PROPERTY' }),
            onClick: handleAddProperty,
            disabled: checkDisabled(permissions, [ PermissionType.View, PermissionType.Create ]),
        };

        const bulkDeleteAction: GridHeaderButtons<ILabelModelObject> = {
            id: 'deleteProperties',
            type: 'action',
            buttonType: 'mat-flat-button',
            icon: 'delete',
            color: 'warn',
            text: translate({ id: 'CLIENT_DELETE' }),
            label: translate({ id: 'CLIENT_DELETE' }),
            onClick: (rows) => openDeleteDialog(rows ?? []),
            disabled: checkDisabled(permissions, [ PermissionType.View, PermissionType.Delete ]),
        };
        isAdmin && actionList.push(...[ addProperty, bulkDeleteAction ]);

        return actionList;
    }, [ checkDisabled, handleAddProperty, isAdmin, openDeleteDialog, permissions, translate ]);

    const extraActionRowButtons: Array<GridRowButtons<ILabelModelObject>> = useMemo(() => {
        if (!isAdmin) {
            return [];
        }
        return [
            ...(
                isAdmin ? [
                    {
                        id: 'showMoreActions',
                        label: translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' }),
                        icon: 'add',
                        dataCy: 'ap-data-grid-more-actions-button',
                        customTemplate: (row: ILabelModelObject) => elementToNode(
                            <Router
                                navigator={navigator}
                                location={location.pathname}>
                                <ApolloThemeProvider>
                                    <IntlProvider>
                                        <TenantTagsPropertiesMoreActions
                                            navigate={navigate}
                                            getRoute={getRoute}
                                            row={row}
                                            tenantId={tenantId}
                                            permissions={permissions}
                                            onDelete={openDeleteDialog} />
                                    </IntlProvider>
                                </ApolloThemeProvider>
                            </Router>
                        ),

                    },
                ] : []
            ),
        ];
    }, [
        isAdmin,
        translate,
        navigator,
        location.pathname,
        navigate,
        getRoute,
        tenantId,
        permissions,
        openDeleteDialog,
    ]);

    const columnDef: Array<IColumn<ILabelModelObject>> = useMemo(() => [
        {
            property: 'name',
            title: translate({ id: 'CLIENT_KEY' }),
            sortable: false,
            visible: true,
        },
        {
            property: 'dataType',
            title: translate({ id: 'CLIENT_VALUE_TYPE' }),
            sortable: false,
            visible: true,
        },
        {
            property: 'values',
            title: translate({ id: 'CLIENT_VALUES' }),
            sortable: false,
            visible: true,
        },
        {
            property: 'description',
            title: translate({ id: 'CLIENT_DESCRIPTION' }),
            sortable: false,
            visible: true,
        },
        {
            property: 'referencesCount',
            title: translate({ id: 'CLIENT_TAGGED_OBJECTS' }),
            sortable: false,
            visible: true,
        },
    ], [ translate ]);

    const filterData = useCallback((filterMethods: any, results: any[] | undefined): ILabelModelObject[] | undefined => {

        if (filterMethods.length && results) {
            return results.filter(entry => filterMethods.every((filter: any) => filter(entry)));
        }
        return results;

    }, [ ]);

    useEffect(() => {
        if (!filterMgr || !dataMgr) {
            return;
        }

        filterMgr.filter$.asObservable().subscribe((filters) => {
            const filterMethods = filters.map(filter => (entry: any) => entry.dataType === filter.value);

            (dataMgr as any).update(filterData(filterMethods, propertiesData?.results));
        });

    }, [ dataMgr, filterData, filterMgr, propertiesData?.results, translate ]);

    const configuration: IGridOptions<any> = useMemo(() => ({
        loading,
        columns: columnDef,
        headerOptions: {
            search: true,
            searchTerm: (term: string) => setSearchTerm(term),
            gridHeaderButtons: extraActionHeaderButtons,
        },
        refreshable: true,
        data: propertiesData?.results ?? [],
        refresh: propertiesMutate,
        footerOptions: {
            length: propertiesData?.totalCount,
            pageSizes: [ 5, 10, 25, 50 ],
            pageIndex: index,
            pageSize: size,
            pageChange: ({
                pageIndex, pageSize,
            }: {
                pageIndex: number; pageSize: number;
            }) => {
                index !== pageIndex && changePageIndex(pageIndex);
                size !== pageSize && changePageSize(pageSize);
            },
        },
        toggleColumns: true,
        gridRowButtons: extraActionRowButtons,
        onGridApi: ({
            filterManager, dataManager,
        }: {
            filterManager: FilterManager<ILabelModelObject>;
            dataManager: DataManager<ILabelModelObject>;
        }) => {
            setFilterMgr(filterManager);
            setDataMgr(dataManager);
        },
        selectable: hasPermission(permissions, [ PermissionType.View, PermissionType.Delete ]),
    }), [
        loading,
        columnDef,
        extraActionHeaderButtons,
        propertiesData?.results,
        propertiesData?.totalCount,
        propertiesMutate,
        index,
        size,
        extraActionRowButtons,
        permissions,
        changePageIndex,
        changePageSize,
    ]);

    useEffect(() => {
        if (apGridRef.current && configuration) {
            apGridRef.current.configuration = configuration;
        }
    }, [ configuration ]);

    return {
        refresh,
        organizationName,
        tenantId,
        tenantName: tenant?.name,
        isAdmin,
        openDeleteDialog,
        refreshState,
        apGridRef,
        displayValues,
        columnDef,
        filterData,
        configuration,
        gridHeaderButtons: extraActionHeaderButtons,
        handleAddProperty,
    };
};

export default useTenantTagPropertiesViewModel;
