import {
    UiCopyButton,
    UiDialog,
    UiText,
} from '@experiences/ui-common';
import { useRouteResolver } from '@experiences/util';
import LaunchIcon from '@mui/icons-material/Launch';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApAlertBar,
    ApButton,
    ApLink,
    ApTextField,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import * as RouteNames from '../../common/constants/RouteNames';
import {
    accountLogicalName,
    isAdminSelector,
} from '../../store/selectors';
import {
    SpacingToken,
    UiStack,
} from './UiStack';

const RefreshTokenPopUp: React.FC<{
    close: () => void;
    refreshToken: string;
    tenantName: string;
    clientId: string;
    handleRevoke: () => void;
}> = ({
    close, refreshToken, tenantName, clientId, handleRevoke,
}) => {
    const { formatMessage: translate } = useIntl();
    const getRoute = useRouteResolver();
    const accountName = useSelector(accountLogicalName);
    const isAdmin = useSelector(isAdminSelector);

    const getField = useCallback((label: string, value: string, dataCy: string, ariaLabel: string) => (
        <UiStack
            align="end">
            <ApTextField
                style={{ width: '100%' }}
                readOnly
                data-cy={dataCy}
                value={value}
                label={label}
            />
            <UiCopyButton
                style={{ marginBottom: '4px' }}
                textToCopy={value}
                aria-label={ariaLabel}
                data-cy={`${dataCy}-copy-button`}
            />
        </UiStack>
    ), [ ]);

    const apiConnectionData = useMemo(() => (
        <UiStack direction='column'>
            {getField(translate({ id: 'CLIENT_USER_KEY' }), refreshToken, 'APIAccessUserKey', translate({ id: 'COPY_CLIENT_USER_KEY' }))}
            {getField(translate({ id: 'CLIENT_ACCOUNT_LOGICAL_NAME' }), accountName, 'APIAccessAccountName', translate({ id: 'CLIENT_COPY_LOGICAL_NAME' }))}
            {getField(translate({ id: 'CLIENT_TENANT_NAME_CREATE_EDIT' }), tenantName, 'APIAccessTenantLogicalName', translate({ id: 'COPY_CLIENT_TENANT_NAME_CREATE_EDIT' }))}
            {getField(translate({ id: 'CLIENT_CLIENT_ID' }), clientId, 'APIAccessClientId', translate({ id: 'COPY_CLIENT_CLIENT_ID' }))}
        </UiStack>
    ), [ accountName, clientId, getField, refreshToken, tenantName, translate ]);

    return (
        <UiDialog
            close={close}
            title={translate({ id: 'CLIENT_API_ACCESS' })}
            width="small"
            actions={[
                <UiStack
                    key="actions"
                    grow={1}
                    justify="between">
                    <ApButton
                        variant="destructive"
                        label={translate({ id: 'CLIENT_REVOKE_ACCESS' })}
                        data-cy="refresh-token-popup-revoke-button"
                        onClick={handleRevoke}
                    />
                    <ApButton
                        key="primaryButton"
                        variant="primary"
                        label={translate({ id: 'CLIENT_OK' })}
                        data-cy="refresh-token-popup-submit"
                        onClick={close} />
                </UiStack>,
            ]}
        >
            <UiStack
                direction="column"
                gap={SpacingToken.S}>

                {isAdmin && (
                    <ApAlertBar
                        status={'warning' as any}
                        cancelable={false}>
                        <UiText data-cy="external-api-access-text">
                            {translate({ id: 'CLIENT_EXTERNAL_APP_API_ACCESS' })}
&nbsp;
                            <ApLink
                                aria-label={translate({ id: 'CLIENT_VIEW_DESCRIPTION' })}
                                target='_blank'
                                href={getRoute(RouteNames.ExternalApplications)}>
                                {translate({ id: 'CLIENT_VIEW' })}
                                <LaunchIcon
                                    style={{
                                        fontSize: '14px',
                                        marginLeft: '4px',
                                    }}
                                />
                            </ApLink>

                        </UiText>
                    </ApAlertBar>
                )}

                <UiText
                    color="var(--color-foreground-de-emp)"
                    variant={FontVariantToken.fontSizeLBold}>
                    {translate({ id: 'CLIENT_CONNECT_VIA_API' })}
                </UiText>

                {apiConnectionData}

                <div style={{ width: 480 }}>
                    <UiText>
                        {translate({ id: 'CLIENT_REVOKE_DESCRIPTION' })}
                    </UiText>
                </div>
            </UiStack>

        </UiDialog>
    );
};

export default RefreshTokenPopUp;
