import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useServiceDependency } from '../../component/tenants/subcomponents/helpers/useServiceDependency';
import {
    TenantsContextProvider,
    useTenantsContext,
} from '../../component/tenants/TenantsContextProvider';
import type {
    IService,
    ITenant,
} from '../interfaces/tenant/tenant';

interface ITenantIdProviderProps {
    tenant: ITenant | undefined;
    tenantId: string;
    tenantName: string;
    services?: IService[];
}

const TenantIdProviderContext = React.createContext<ITenantIdProviderProps>({
    tenant: undefined,
    tenantId: '',
    tenantName: '',
    services: undefined,
});

export const useTenantIdContext = () => React.useContext(TenantIdProviderContext);

const TenantIdProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
    const { tenantId } = useParams<{ tenantId: string }>();

    const { tenantsWithoutServices } = useTenantsContext();

    const tenant = useMemo(() => tenantsWithoutServices?.find(tenantIterator => tenantIterator.id === tenantId),
        [ tenantId, tenantsWithoutServices ]);

    const tenantName = useMemo(() => tenant?.name, [ tenant ]);

    const { servicesToHide } = useServiceDependency();

    const services = useMemo(() => tenantsWithoutServices
        ?.find(t => t.id === tenantId)
        ?.tenantServiceInstances
        ?.filter(service => !servicesToHide.includes(service.serviceType)),
    [ servicesToHide, tenantId, tenantsWithoutServices ]);

    return <TenantIdProviderContext.Provider value={{
        tenant,
        tenantId: tenantId ?? '',
        tenantName: tenantName ?? '',
        services: services ?? [],
    }}>
        {children}
    </TenantIdProviderContext.Provider>;
};

const TenantProvider: React.FC<PropsWithChildren> = ({ children }) => <TenantsContextProvider>
    <TenantIdProvider>
        {children}
    </TenantIdProvider>
</TenantsContextProvider>;

export default TenantProvider;
