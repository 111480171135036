import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import { PortalBreadcrumb } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';

import type { IBreadCrumb } from '../../common/interfaces/breadcrumb';

const AdminBreadCrumbs: React.FC<{ breadCrumbTrail: IBreadCrumb[]; disablePadding?: boolean }> = ({
    breadCrumbTrail, disablePadding = false,
}) => {
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();

    const breadcrumbCallbackRef = useCallback((ref: HTMLPortalBreadcrumbElement | null) => {
        const navigateToBreadcrumb = (event: any) => {
            navigate(getRoute(event.detail));
        };
        ref?.addEventListener('breadcrumbClicked', navigateToBreadcrumb);
    }, [ getRoute, navigate ]);

    const breadcrumbTrail = useMemo(() => breadCrumbTrail.map((crumb) => (
        {
            id: crumb.link,
            name: crumb.name === 'host' ? translate({ id: 'CLIENT_HOST' }) : crumb.name,
        }),
    ), [ breadCrumbTrail, translate ]);

    return (
        <PortalBreadcrumb
            ref={breadcrumbCallbackRef}
            links={breadcrumbTrail}
            active={breadCrumbTrail[breadCrumbTrail.length - 1]?.link}
            data-cy='admin-bread-crumb-navigation'
            disablePadding={disablePadding}
        />
    );
};

export default AdminBreadCrumbs;
