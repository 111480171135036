import { useCentralErrorSetter } from '@experiences/error';
import type { ILabelModelObject } from '@experiences/interfaces';
import { PortalTagManagementEvent } from '@experiences/telemetry';
import { useShowDialog } from '@experiences/util';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    deleteKeyValue,
    deleteLabel,
} from '../../../services/orchestrator/TagsService.default';
import {
    getTenantById,
    organizationManagementTenantUri,
} from '../../../services/organization/TenantService';
import { accountGlobalId } from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { TagDeleteErrorDialog } from '../errors/TagDeleteErrorDialog';
import TenantTagsDeleteDialogBody from './TenantTagsDeleteDialogBody';

export const useTagsDeleteDialog = (deleteType: 'Property' | 'Label', organizationName: string, tenantId: string, callback: () => void) => {
    const createDialog = useShowDialog();
    const { formatMessage: translate } = useIntl();
    const showErrorMessage = useCentralErrorSetter();
    const { logEvent } = useTelemetryHelper();

    const accountId = useSelector(accountGlobalId);

    const { data: tenant } = useSWR(
        (tenantId && !process.buildConfigs.showForMSI) ?
            {
                url: organizationManagementTenantUri,
                id: tenantId,
            } : null,
        getTenantById,
    );

    const openDeleteDialog = useCallback(
        async (rows: ILabelModelObject[] | ILabelModelObject) => {
            try {

                const keys = Array.isArray(rows) ? rows.map(row => row.key) : [ rows.key ];
                const deleteCount = Array.isArray(rows)
                    ? rows.reduce((sum, row) => sum + row.referencesCount, 0)
                    : rows.referencesCount;

                let proceed = true;
                if (deleteCount > 0) {
                    proceed = await createDialog({
                        title: translate({
                            id: deleteType === 'Property'
                                ? 'CLIENT_TENANT_TAGS_DELETE_PROPERTY_HEADER'
                                : 'CLIENT_TENANT_TAGS_DELETE_LABEL_HEADER',
                        }),
                        customDialogContent: TenantTagsDeleteDialogBody,
                        customDialogContentProps: {
                            taggedObjectsCount: deleteCount,
                            deleteType,
                        },
                        icon: 'error',
                    });
                }
                if (proceed) {
                    if (deleteType === 'Property') {
                        await deleteKeyValue(organizationName, tenant?.name, keys, accountId);
                        logEvent(
                            PortalTagManagementEvent.DeleteProperty,
                            { InvocationMethod: Array.isArray(rows) ? 'Grid Header Button' : 'Row Action Button' },
                        );
                    } else {
                        await deleteLabel(organizationName, tenant?.name, keys, accountId);
                        logEvent(
                            PortalTagManagementEvent.DeleteLabel,
                            { InvocationMethod: Array.isArray(rows) ? 'Grid Header Button' : 'Row Action Button' },
                        );
                    }
                    callback();
                }
            } catch (e) {
                showErrorMessage(<TagDeleteErrorDialog
                    error={e as Error}
                    type={deleteType} />,
                );
            }
        },
        [
            createDialog,
            translate,
            deleteType,
            callback,
            organizationName,
            tenant,
            accountId,
            logEvent,
            showErrorMessage,
        ],
    );
    return openDeleteDialog;
};
