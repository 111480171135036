import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { EntityEnumType } from '@experiences/interfaces';
import { Entity } from '@experiences/interfaces';
import { useNavigateWithParams } from '@experiences/util';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import * as RouteNames from '../../../common/constants/RouteNames';
import { isHostModeSelector } from '../../../store/selectors';
import GroupsPageComponent from '../GroupsPageComponent';
import GroupsPageComponentV2 from '../GroupsPageComponentV2';
import RobotsPageComponent from '../RobotsPageComponent';
import RobotsPageComponentV2 from '../RobotsPageComponentV2';
import UsersCISPageComponent from '../UsersCISPageComponent';
import UsersCISPageComponentV2 from '../UsersCISPageComponentV2';

const TabPanel: React.FC<{ value: EntityEnumType; gridRefactorEnabled: boolean }> = ({
    value, gridRefactorEnabled,
}) => {
    switch (value) {
        case Entity.USERS:
            return gridRefactorEnabled ? <UsersCISPageComponentV2 /> : <UsersCISPageComponent />;
        case Entity.ROBOTS:
            return gridRefactorEnabled ? <RobotsPageComponentV2 /> : <RobotsPageComponent />;
        case Entity.GROUPS:
            return gridRefactorEnabled ? <GroupsPageComponentV2 /> : <GroupsPageComponent />;
    }

    return null;
};

const UsersPageTabs: React.FC<{ entityType: EntityEnumType }> = ({ entityType }) => {
    const { formatMessage: translate } = useIntl();
    const [ tabValue, setTabValue ] = useState<EntityEnumType>(entityType ?? Entity.USERS);

    const EnableGridRefactor = useFeatureFlagValue(Features.EnableGridRefactor.name);

    const navigate = useNavigateWithParams();
    const isHostMode = useSelector(isHostModeSelector);

    // TODO: Might be redundant
    const handleChange = useCallback((_: any, newValue: string) => setTabValue(newValue as EntityEnumType),
        [ setTabValue ]);

    return (
        <>
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className="default">
                <Tab
                    value={Entity.USERS}
                    label={translate({ id: 'CLIENT_USERS' })}
                    onClick={() => navigate(RouteNames.Users)}
                    data-cy="users-tab"
                    className="default"
                />
                {!isHostMode && (
                    <Tab
                        value={Entity.ROBOTS}
                        label={translate({ id: 'CLIENT_ROBOT_ACCOUNTS' })}
                        onClick={() => navigate(RouteNames.Robots)}
                        data-cy="robots-tab"
                        className="default"
                    />
                )}
                {!isHostMode && (
                    <Tab
                        value={Entity.GROUPS}
                        label={translate({ id: 'CLIENT_GROUPS' })}
                        onClick={() => navigate(RouteNames.Groups)}
                        data-cy="groups-tab"
                        className="default"
                    />
                )}
            </Tabs>
            <TabPanel
                value={tabValue}
                gridRefactorEnabled={EnableGridRefactor} />
        </>
    );
};

export default UsersPageTabs;
