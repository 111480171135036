import { get } from '@experiences/util';

interface IModuleVersions {
    moduleVersionUrl: string;
}

export async function getModuleFederationUrl({
    moduleFederationVersionUrl, accessToken,
}: { moduleFederationVersionUrl: string; accessToken: string }): Promise<string> {
    try {
        return await get<IModuleVersions>(moduleFederationVersionUrl, {
            accessToken,
            stripPortalPath: true,
        }).then(r => r.moduleVersionUrl);
    } catch (error) {
        throw new Error(`Federated module ${moduleFederationVersionUrl} was unable to be loaded.`);
    }
}
