import type { IBlob } from '@experiences/interfaces/src/blob';
import type { AxiosError } from 'axios';

import {
    getBlob,
    post,
} from '../utility/Requests.default';
import type { AutopilotSolution } from './A4EServiceTypes';
import { A4EError } from './A4EServiceTypes';

export async function getLatestAutopilotPackageVersion(tenantName: string): Promise<AutopilotSolution> {
    try {
        // TODO change this
        return await post<AutopilotSolution>('http://localhost:8081/api/solution', {
            stripPortalPath: true,
            ignoreBasePath: true,
        });
    } catch (error) {
        const axiosError = error as AxiosError;
        throw new A4EError('Failed to get latest Autopilot package version', axiosError.response?.status ?? 500, [ '500' ]);
    }
}

export async function getLatestAutopilotPackageUrl(tenantName: string, version: string): Promise<string> {
    try {
        // TODO change this
        return await post<string>(`http://localhost:8081/api/solution/url/${version}`, {
            stripPortalPath: true,
            ignoreBasePath: true,
        });
    } catch (error) {
        const axiosError = error as AxiosError;
        throw new A4EError('Failed to get latest Autopilot package url', axiosError.response?.status ?? 500, [ '500' ]);
    }
}

export async function getAutopilotPackage(url: string): Promise<IBlob> {
    try {
        return await getBlob(url, {
            stripPortalPath: true,
            ignoreBasePath: true,
            includeCredentials: false,
            headers: {},
        });
    } catch (error) {
        const axiosError = error as AxiosError;
        throw new A4EError('Failed to download latest Autopilot package', axiosError.response?.status ?? 500, [ '500' ]);
    }
}
