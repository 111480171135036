import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useShowDialog,
} from '@experiences/util';
import {
    ApButton,
    ApLink,
} from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { ReadArticleLink } from '../../../common/constants/documentation/SAMLDocumentationLinks.default';
import { SecuritySettingsSAMLProvisioningRules } from '../../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { AuthenticationSettingType } from '../../../services/identity/AuthenticationSettingService';
import {
    SpacingToken,
    UiStack,
} from '../../common/UiStack';

type AuthenticationSettingTypeKeys = keyof typeof AuthenticationSettingType;
type AuthenticationSettingTypeValues = typeof AuthenticationSettingType[AuthenticationSettingTypeKeys];

const IdentityProviderSuccessDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, provider,
}) => {
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks();
    const navigate = useNavigateWithParams();

    const isAAD = useMemo(
        () => provider === AuthenticationSettingType.AAD,
        [ provider ],
    );

    return <UiStack
        direction="column"
        m={SpacingToken.Micro}
        gap={SpacingToken.M}>
        <UiText>
            {translate(
                { id: `CLIENT_AUTH_SETTINGS_${provider?.toUpperCase()}_ENABLED_SUCCESSFULLY` },
                {
                    readArticleLink: (
                        <ApLink
                            href={getLocalizedLink({ articleSlug: ReadArticleLink })}
                            target="_blank"
                            rel="noopener noreferrer">
                            {translate({ id: `CLIENT_AUTH_SETTINGS_READ_THIS_ARTICLE${isAAD ? '_CAPS' : ''}` })}
                        </ApLink>
                    ),
                },
            )}
        </UiText>
        <UiStack
            direction="row"
            gap={SpacingToken.XS}
            justify="end">
            {!isAAD && <ApButton
                onClick={() => {
                    navigate(SecuritySettingsSAMLProvisioningRules, { replace: true });
                    closeDialog();
                }}
                data-cy="saml-provisioning-rules-button"
                label={translate({ id: 'CLIENT_VIEW_PROVISIONING_RULES' })}
                variant="tertiary"
            />}
            {isAAD && <ApButton
                data-cy="secondary-button-confirmation"
                data-testid="ui-dialog-cancel-button"
                onClick={() => closeDialog(true)}
                label={translate({ id: 'CLIENT_DO_IT_LATER' })}
                variant="tertiary"
            />}
            <ApButton
                variant="primary"
                data-cy="primary-button-confirmation"
                data-testid="ui-dialog-ok-button"
                onClick={() => closeDialog(true)}
                label={translate({ id: isAAD ? 'CLIENT_SIGN_OUT' : 'CLIENT_DONE' })}
            />
        </UiStack>
    </UiStack>;
};

export const useShowSuccessConfirmationDialog = (provider: AuthenticationSettingTypeValues) => {
    const { formatMessage: translate } = useIntl();

    const isAAD = useMemo(() => provider === AuthenticationSettingType.AAD, [ provider ]);

    const createDialog = useShowDialog();

    return async () => createDialog({
        title: translate(
            { id: 'CLIENT_AUTH_SETTINGS_SSO_ENABLED_SUCCESSFULLY' },
            { ssoType: translate({ id: isAAD ? 'CLIENT_AZURE_AD' : 'CLIENT_SAML_SSO' }) },
        ),
        customDialogContent: IdentityProviderSuccessDialogBody,
        customDialogContentProps: { provider },
        icon: 'success',
    });
};
