import type { IGridDataEntry } from './GridDataEntryTypes';

interface GridFilters {
    searchTerm?: string;
    sort?: Array<{ field: keyof IGridDataEntry; direction: 'asc' | 'desc' }>;
    product?: string;
}

export function processGridData(
    data: IGridDataEntry[],
    filters: GridFilters
): IGridDataEntry[] {
    const {
        searchTerm = '', sort = [], product = '',
    } = filters;

    let updatedData = [ ...data ]; // Start with a copy of the data

    // Handle product filtering
    if (product && product !== 'all') {
        updatedData = updatedData.filter(item => item.product === product);
    }

    // Handle sorting
    if (sort && sort.length > 0) {
        const {
            field, direction,
        } = sort[0];
        updatedData.sort((a, b) => {
            if (a[field] < b[field]) {
                return direction === 'asc' ? -1 : 1;
            }
            if (a[field] > b[field]) {
                return direction === 'asc' ? 1 : -1;
            }
            return 0;
        });
    }

    // Handle search
    if (searchTerm) {
        updatedData = updatedData.filter(item =>
            Object.values(item).some(value =>
                value.toString().toLowerCase()
                    .includes(searchTerm.toLowerCase())
            )
        );
    }

    return updatedData;
}

export function filterByProduct(
    model: { value?: string | null | number | boolean | Date | any } | null, // Accept broader types
    distinctProducts: Array<{ label: string; value: string }>,
    processedAuditData: IGridDataEntry[],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    processGridData: (data: IGridDataEntry[], filters: any) => IGridDataEntry[]
): { selectedProduct: { label: string; value: string } | undefined; filteredData: IGridDataEntry[] } {
    // Ensure model.value is a string
    const stringValue = typeof model?.value === 'string' ? model?.value : null;

    if (stringValue === 'all') {
        return {
            selectedProduct: undefined, // Reset product
            filteredData: processGridData(processedAuditData, {}), // Show all data
        };
    } else if (stringValue) {
        const selectedValue = stringValue;
        const selectedProduct = {
            label: distinctProducts.find(p => p.value === selectedValue)?.label || '',
            value: selectedValue,
        };
        // Pass the product filter to processGridData
        const filteredData = processGridData(processedAuditData, { product: selectedValue });

        return {
            selectedProduct,
            filteredData,
        }; // Filter data based on selected product
    }
    // In case model or model.value is null or undefined
    return {
        selectedProduct: undefined,
        filteredData: processGridData(processedAuditData, {}),
    };
}

// Function to handle row click
export function handleRowSelection(
    filteredData: IGridDataEntry[],
    row: IGridDataEntry,
    setSelectedRecord: (record: IGridDataEntry | null) => void,
    setCurrentIndex: (index: number) => void
): void {
    const index = filteredData.findIndex(item => item.id === row.id);

    setSelectedRecord(row);
    setCurrentIndex(index);
}

// Function to handle next record selection
export function handleNextRecord(
    filteredData: IGridDataEntry[],
    currentIndex: number,
    setSelectedRecord: (record: IGridDataEntry | null) => void,
    setCurrentIndex: (index: number) => void
): void {
    const newIndex = currentIndex + 1;

    if (newIndex < filteredData.length) {
        setSelectedRecord(filteredData[newIndex]);
        setCurrentIndex(newIndex);
    } else {
        setSelectedRecord(null); // No next record, reset selection
        setCurrentIndex(currentIndex); // Keep the index unchanged
    }
}

// Function to handle previous record selection and update the state
export function handlePreviousRecord(
    filteredData: IGridDataEntry[],
    currentIndex: number,
    setSelectedRecord: (record: IGridDataEntry | null) => void,
    setCurrentIndex: (index: number) => void
): void {
    const newIndex = currentIndex - 1;

    if (newIndex >= 0) {
        setSelectedRecord(filteredData[newIndex]);
        setCurrentIndex(newIndex);
    } else {
        setSelectedRecord(null); // If there are no previous records, reset the selection
        setCurrentIndex(currentIndex); // Keep the index unchanged
    }
}

