import { OrgConsentEvent } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import Switch from '@mui/material/Switch';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { ApTooltip } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import {
    type Consent,
    deleteConsent,
    updateConsent,
} from '../../services/identity/ConsentService';
import { accountGlobalId } from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import {
    SpacingToken,
    UiStack,
} from '../common/UiStack';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    cardContainer: {
        width: '320px',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: theme.palette.semantic.colorBorderDeEmp,
        borderRadius: '8px',
    },
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

interface ConsentCardProps {
    consent: Consent;
    onDeleted: () => void;
}

export const ConsentCard = ({
    consent, onDeleted,
}: ConsentCardProps) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ enabled, setEnabled ] = useState(consent.isActive);
    const createDialog = useShowDialog();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const partitionGlobalId = useSelector(accountGlobalId);
    const createNotification = useUiSnackBar();
    const { logEvent } = useTelemetryHelper();

    const onEditClick = useCallback(() => {
        logEvent(OrgConsentEvent.EditConsent, { consentId: consent.id });
        navigate(getRoute(RouteNames.UserConsentEdit.replace(':id', consent.id)));
    }, [ consent.id, getRoute, logEvent, navigate ]);

    const onDeleteClick = useCallback(async () => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_USER_DELETE_CONSENT_TITLE' }),
            body: translate({ id: 'CLIENT_USER_DELETE_CONSENT_SUBTITLE' }),
            icon: 'error',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
        });

        if (proceed) {
            await deleteConsent({
                partitionGlobalId,
                consentId: consent.id,
            });
            await createNotification(
                translate({ id: 'CLIENT_ORG_CONSENT_DELETE_SUCCESS' }),
                notificationType.SUCCESS
            );
            logEvent(OrgConsentEvent.DeleteConsent, { consentId: consent.id });
            onDeleted();
        }
    }, [ createDialog, translate, logEvent, partitionGlobalId, consent.id, createNotification, onDeleted ]);

    const onActivateDeactivateChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
        let proceed = false;
        if (e.target.checked) {
            proceed = await createDialog({
                title: translate({ id: 'CLIENT_USER_TOGGLE_CONSENT_ACTIVE_TITLE' }),
                body: (
                    <>
                        <FormattedMessage id="CLIENT_USER_TOGGLE_CONSENT_ACTIVE_SUBTITLE" />
                        <ul>
                            <FormattedMessage
                                id="CLIENT_USER_TOGGLE_CONSENT_ACTIVE_BULLETS"
                                values={{
                                    li: (chunk: React.ReactNode[]) => <li>
                                        {chunk}
                                    </li>,
                                }}
                            />
                        </ul>
                    </>
                ),
                icon: 'warning',
                showCancel: true,
                unclosable: true,
                primaryButtonText: translate({ id: 'CLIENT_ACTIVATE_BUTTON' }),
            });
        } else {
            proceed = await createDialog({
                title: translate({ id: 'CLIENT_USER_TOGGLE_CONSENT_INACTIVE_TITLE' }),
                body: translate({ id: 'CLIENT_USER_TOGGLE_CONSENT_INACTIVE_SUBTITLE' }),
                icon: 'warning',
                showCancel: true,
                unclosable: true,
                primaryButtonText: translate({ id: 'CLIENT_DEACTIVATE_ACTION' }),
            });
        }

        if (proceed) {
            await updateConsent({
                partitionGlobalId,
                consentId: consent.id,
                body: {
                    ...consent,
                    userReconsentNeeded: false,
                    isActive: !e.target.checked,
                },
            });
            setEnabled(!e.target.checked);
            await createNotification(
                translate({ id: 'CLIENT_ORG_CONSENT_SAVE_SUCCESS' }),
                notificationType.SUCCESS
            );
            logEvent(e.target.checked ? OrgConsentEvent.ActivateConsent : OrgConsentEvent.DeactivateConsent, { consentId: consent.id });
        }
    }, [ createDialog, translate, logEvent, consent, partitionGlobalId, createNotification ]);

    return (
        <UiStack
            gap={SpacingToken.S}
            p={SpacingToken.S}
            direction="column"
            className={classes.cardContainer}>
            <UiStack
                direction="column"
                className={classes.truncate}>
                <ApTooltip title={consent.content.title}>
                    <UiText
                        variant={FontVariantToken.fontSizeLBold}
                        className={classes.truncate}>
                        {consent.content.title}
                    </UiText>
                </ApTooltip>
                <UiText variant={FontVariantToken.fontSizeXsBold}>
                    {`${translate({ id: 'CLIENT_DATE_CREATED' })}: ${new Date(consent.creationTime).toLocaleDateString()}`}
                </UiText>
            </UiStack>

            <UiStack direction="column">
                <UiText variant={FontVariantToken.fontSizeS}>
                    <FormattedMessage
                        id="CLIENT_USER_CONSENT_USER_RECORD_COUNT"
                        values={{
                            count: consent.userRecordCount ?? 0,
                            link: consent.userRecordCount && consent.userRecordCount > 0 ?
                                <UiText
                                    display="inline"
                                    variant={FontVariantToken.fontSizeSBold}>
                                    <Link
                                        className={classes.a}
                                        data-cy="consent-view-user-records"
                                        onClick={() => logEvent(OrgConsentEvent.ViewUserRecords, { consentId: consent.id })}
                                        to={`?consentId=${consent.id}`}>
                                        {translate({ id: 'CLIENT_USER_CONSENT_SEE_USER_LIST' })}
                                    </Link>
                                </UiText> : '',
                        }}
                    />
                </UiText>
            </UiStack>

            <UiStack justify="between">
                <FormControlLabel
                    control={<Switch
                        checked={enabled}
                        onChange={onActivateDeactivateChange}
                        color="primary"
                        data-cy="consent-status-switch"
                    />}
                    label={translate({ id: enabled ? 'CLIENT_ACTIVE' : 'CLIENT_INACTIVE' })}
                />
                <UiStack>
                    <IconButton
                        onClick={onEditClick}
                        data-cy="consent-edit-button">
                        <EditOutlinedIcon />
                    </IconButton>
                    <IconButton
                        onClick={onDeleteClick}
                        data-cy="consent-delete-button">
                        <DeleteOutlinedIcon />
                    </IconButton>
                </UiStack>
            </UiStack>
        </UiStack>
    );
};
