import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import type { PropsWithChildren } from 'react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { AiTrustLayerSetting } from '../../common/constants/RouteNames';
import { routePaths } from '../../common/constants/routePaths';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider from '../../common/providers/BreadcrumbProvider';
import CheckGuard from '../../container/helpers/CheckGuard';
import type { UiRouteObject } from '../../container/routeConfigs/UiRouteObject';

const AiTrustLayerComponent = React.lazy(() =>
    import('./AiTrustLayerComponent').then(module => ({ default: module.AiTrustLayerComponent }))
);

const AiTrustSettingsProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const EnableGovernanceModuleFederation = useFeatureFlagValue(Features.EnableGovernanceModuleFederation.name);

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: routePaths.admin,
            name: organizationName,
        },
        {
            index: 1,
            link: routePaths.adminAiTrustSettings,
            name: translate({ id: 'CLIENT_AI_TRUST_LAYER' }),
        },
    ], [ organizationName, translate ]);

    return <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
        {CheckGuard(EnableGovernanceModuleFederation, <>
            {children}
        </>)}
    </BreadcrumbProvider>;
};

export const AiTrustRoutes: UiRouteObject = {
    path: routePaths.adminAiTrustSettings,
    element: (
        <AiTrustSettingsProvider>
            <UiSuspensefulOutlet />
        </AiTrustSettingsProvider>
    ),
    children: [
        {
            index: true,
            redirectUrls: [ AiTrustLayerSetting ],
            element: <AiTrustLayerComponent />,
        },
    ],
};
