import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import {
    UiProgressButton,
    UiText,
    useUiDataContext,
} from '@experiences/ui-common';
import {
    useModalState,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import Info from '@mui/icons-material/Info';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import clsx from 'clsx';
import { produce } from 'immer';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormProvider,
    useFieldArray,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useParams,
} from 'react-router-dom';
import useSWR from 'swr';

import {
    CharacterLimitForNames,
    notificationType,
} from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import type {
    IpSecPolicy,
    IVpnGatewayConnectionCreateEditPayload,
} from '../../services/hypervisor';
import {
    createVpnGatewayConnection,
    editVpnGatewayConnection,
    getVpnGatewayConnection,
    vpnGatewaysUri,
} from '../../services/hypervisor';
import { accountLogicalName } from '../../store/selectors';
import UiForm from '../common/UiForm';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import { validateIPv4Range } from '../securitySettings/subcomponents/IPRestriction/IPRestrictionUtil';
import type { IVpnGatewayConnectionGridContext } from './interfaces/gateway';
import { VpnGatewayIPSecPolicyComponent } from './VpnGatewayIPSecPolicyComponent';
import useVpnGatewayViewModel from './VpnGatewayViewModel';

const IPSEC_SA_KB_DEFAULT = 102400000;
const IPSEC_SA_SECONDS_DEFAULT = 27000;

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...{
        defaultConnectionLayout: {
            position: 'relative',
            width: '50%',
            boxSizing: 'border-box',
        },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        cancelButton: { marginRight: '10px' },
        button: { width: '100px ' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        textFieldLabel: {
            display: 'inline-flex',
            paddingTop: '25px',
        },
        addressSpaceSection: {
            display: 'flex',
            alignItems: 'center',
        },
        deleteButton: {
            color: theme.palette.semantic.colorErrorIcon,
            top: '14px',
            width: '24px',
            scale: '1.2',
        },
        firstChild: { top: '30px' },
        addMoreButton: {
            width: '150px',
            right: '28px',
            paddingTop: '20px',
        },
        loader: {
            position: 'absolute',
            top: '50%',
            left: '90%',
        },
        tooltipIcon: {
            color: theme.palette.semantic.colorInfoIcon,
            scale: '0.75',
            position: 'relative',
            bottom: '2px',
        },
        additionalConfig: {
            paddingTop: '30px',
            width: '35%',
        },
        ipsecToggle: {
            position: 'relative',
            left: '2px',
            paddingTop: '10px',
        },
        ipsecToggleLabel: {
            marginTop: '20px',
            marginRight: '10px',
            position: 'relative',
            bottom: '5px',
        },
        ipSecInfo: {
            display: 'flex',
            paddingTop: '10px',
        },
        ipSecInfoIcon: { marginRight: '10px' },
    },
}));

export const VpnGatewayAddEditConnectionComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        type, vpnGatewayKey, tenantId,
    } = useParams() as { type: 'add' | 'edit'; vpnGatewayKey: string; tenantId: string };
    const expressRouteEnabled = useFeatureFlagValue(Features.ExpressRouteEnabled.name);
    const ipSecPolicyEnabled = useFeatureFlagValue(Features.IPSecPolicyEnabled.name);
    const accountName = useSelector(accountLogicalName);
    const location = useLocation();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const createNotification = useUiSnackBar();

    const gatewayType = useMemo(() => location?.state?.gatewayType, [ location ]);

    const { breadcrumbs } = useBreadcrumbs();

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const { gatewayData } = useVpnGatewayViewModel();

    const {
        data: connectionData, error, isLoading, mutate: mutateConnection,
    } = useSWR(
        type === 'edit' && gatewayData ? {
            url: vpnGatewaysUri,
            accountLogicalName: accountName,
            selectedTenantId: tenantId,
            vpnGatewayConnectionKey: location.state?.connection?.Key satisfies string,
        } : null,
        getVpnGatewayConnection,
    );

    useEffect(() => {
        if (!isLoading && error?.response?.status === 500) {
            navigate(getRoute(RouteNames.VpnGateway).replace(':tenantId', tenantId));
        }
    }, [ isLoading, error, tenantId, navigate, getRoute ]);

    const {
        data: contextData, setData,
    } = useUiDataContext<IVpnGatewayConnectionGridContext>();

    const { close } =
        useModalState(RouteNames.VpnGatewayConnections.replace(':tenantId', tenantId).replace(':vpnGatewayKey', vpnGatewayKey));

    const methods = useForm({
        shouldUnregister: false,
        defaultValues: {
            Name: connectionData?.Name ?? '',
            SharedKey: '',
            AuthKey: '',
            TargetIpAddress: connectionData?.TargetIpAddress ?? '',
            addressSpaces: connectionData?.TargetAddressSpaces.map(val => ({ addressSpace: val })) ?? [ { addressSpace: '' } ],
            ExpressRouteTargetResourceId: connectionData?.ExpressRouteTargetResourceId ?? '',
            IpSecPolicy: connectionData?.IpSecPolicy ?? {
                IkeEncryption: '',
                IkeIntegrity: '',
                IPsecEncryption: '',
                IPsecIntegrity: '',
                PfsGroup: '',
                DhGroup: '',
                SaLifeTimeSeconds: IPSEC_SA_KB_DEFAULT,
                SaDataSizeKilobytes: IPSEC_SA_SECONDS_DEFAULT,
            },
        },
    });

    const {
        register, handleSubmit, control, formState: { errors }, setValue, watch, reset,
    } = methods;

    const {
        fields, append, remove,
    } = useFieldArray({
        control,
        name: 'addressSpaces',
    });

    const addressSpaces = watch('addressSpaces');

    const addAddressSpace = useCallback(() => {
        append({ addressSpace: '' });
    }, [ append ]);

    const removeAddressSpace = useCallback((index: number) => {
        remove(index);
    }, [ remove ]);

    const [ ipSecEnabled, setIpSecEnabled ] = useState(false);

    const constructPayloadData = useCallback((payload: IVpnGatewayConnectionCreateEditPayload, data: any) => {
        if (gatewayType === 'ExpressRoute' && expressRouteEnabled) {
            payload.SharedKey = data.AuthKey;
            if (type === 'add') {
                payload.ExpressRouteTargetResourceId = data.ExpressRouteTargetResourceId;
            }
        } else {
            payload.SharedKey = data.SharedKey;
            payload.TargetIpAddress = data.TargetIpAddress;
            payload.TargetAddressSpaces = addressSpaces.map(val => val.addressSpace);
            payload.IpSecPolicy = ipSecEnabled ? {
                IkeEncryption: data.IpSecPolicy.IkeEncryption,
                IkeIntegrity: data.IpSecPolicy.IkeIntegrity,
                IPsecEncryption: data.IpSecPolicy.IPsecEncryption,
                IPsecIntegrity: data.IpSecPolicy.IPsecIntegrity,
                PfsGroup: data.IpSecPolicy.PfsGroup,
                DhGroup: data.IpSecPolicy.DhGroup,
                SaLifeTimeSeconds: Number(data.IpSecPolicy.SaLifeTimeSeconds),
                SaDataSizeKilobytes: Number(data.IpSecPolicy.SaDataSizeKilobytes),
            } as IpSecPolicy : undefined;
        }
    }, [ gatewayType, expressRouteEnabled, addressSpaces, ipSecEnabled, type ]);

    const onSubmit = useCallback(
        async (data: any) => {
            try {
                const payload: IVpnGatewayConnectionCreateEditPayload = { Name: data.Name };
                constructPayloadData(payload, data);
                if (type === 'add') {
                    await createVpnGatewayConnection(payload, accountName, tenantId, vpnGatewayKey);
                    setData(produce(contextData, (draftState) => {
                        draftState.refresh = true;
                    }));
                    createNotification(translate({ id: 'CLIENT_VPN_CONNECTION_CREATED' }, { 0: payload.Name }), notificationType.SUCCESS);
                } else if (type === 'edit' && connectionData) {
                    await editVpnGatewayConnection(payload, accountName, tenantId, connectionData.Key);
                    setData(produce(contextData, (draftState) => {
                        draftState.refresh = true;
                    }));
                    createNotification(translate({ id: 'CLIENT_VPN_CONNECTION_UPDATED' }, { 0: payload.Name }), notificationType.SUCCESS);
                    mutateConnection();
                }
                close();
            } catch (error) {
                const errorObject = await getErrorObject(error);
                const errorData = errorObject.response?.data;
                const errorResponse = isString(errorData) ? errorData : await getErrorMessage(errorObject);
                setErrorMessage(errorResponse);
            }
        }, [
            constructPayloadData,
            type,
            connectionData,
            close,
            accountName,
            tenantId,
            vpnGatewayKey,
            setData,
            contextData,
            createNotification,
            translate,
            mutateConnection,
            getErrorObject,
            getErrorMessage,
            setErrorMessage,
        ],
    );

    useEffect(() => {
        if (type === 'edit' && connectionData) {
            reset({
                Name: connectionData?.Name ?? '',
                TargetIpAddress: connectionData?.TargetIpAddress ?? '',
                addressSpaces: connectionData?.TargetAddressSpaces.map(val => ({ addressSpace: val })) ?? [ { addressSpace: '' } ],
                ExpressRouteTargetResourceId: connectionData?.ExpressRouteTargetResourceId ?? '',
                IpSecPolicy: {
                    IkeEncryption: connectionData?.IpSecPolicy?.IkeEncryption,
                    IkeIntegrity: connectionData?.IpSecPolicy?.IkeIntegrity,
                    DhGroup: connectionData?.IpSecPolicy?.DhGroup,
                    IPsecEncryption: connectionData?.IpSecPolicy?.IPsecEncryption,
                    IPsecIntegrity: connectionData?.IpSecPolicy?.IPsecIntegrity,
                    PfsGroup: connectionData?.IpSecPolicy?.PfsGroup,
                    SaDataSizeKilobytes: connectionData?.IpSecPolicy?.SaDataSizeKilobytes ?? IPSEC_SA_KB_DEFAULT,
                    SaLifeTimeSeconds: connectionData?.IpSecPolicy?.SaLifeTimeSeconds ?? IPSEC_SA_SECONDS_DEFAULT,
                },
            });
        }
    }, [ connectionData, reset, setValue, type ]);

    useEffect(() => {
        if (connectionData?.IpSecPolicy != null) {
            setIpSecEnabled(true);
        } else {
            setIpSecEnabled(false);
        }
    }, [ connectionData ]);

    function isGuid(value: string) {
        // eslint-disable-next-line no-useless-escape
        const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(value);
    }

    function validatePSKValue(sharedKey: string) {
        const invalidChars = [ ' ', '-', '~' ];
        return (!sharedKey || sharedKey.length > 128 || sharedKey.split('').some(x => invalidChars.includes(x)));
    }

    if (gatewayData == null || (Object.keys(gatewayData).length === 0)) {
        navigate(getRoute(RouteNames.VpnGateway).replace(':tenantId', tenantId));
    }

    if (type === 'edit' && (isLoading || !connectionData)) {
        return (
            <CircularProgress className={classes.loader} />
        );
    }

    const connectionNameWarning = translate({ id: 'CLIENT_CONNECTION_WARN_NAME' }, {
        0: CharacterLimitForNames.maxNameLength,
        1: 2,
    });

    return (
        <UiPageContainer
            position='left'
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
        >
            <UiText
                align='left'
                variant={FontVariantToken.fontSizeH4}>
                {type === 'edit' ? translate({ id: 'CLIENT_EDIT_CONNECTION' }) : translate({ id: 'CLIENT_CONNECTION_CREATE' })}
            </UiText>
            <UiForm
                actions={<div className={classes.actions}>
                    <Button
                        className={clsx(classes.cancelButton, classes.button)}
                        onClick={() => close()}
                        color="primary"
                        data-cy="add-edit-vpn-connection-discard"
                    >
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        innerButtonClass={classes.button}
                        disabled={false}
                        type="submit"
                        variant="contained"
                        loading={isLoading}
                        data-cy="vpn-connection-add-edit-button"
                    >
                        { type === 'add' ? translate({ id: 'CLIENT_CREATE_ACTION' }) : translate({ id: 'CLIENT_UPDATE' }) }
                    </UiProgressButton>
                </div>}
                onSubmit={handleSubmit(onSubmit)}>
                <div className={classes.defaultConnectionLayout}>
                    <TextField
                        className={classes.textFieldLabel}
                        data-cy="gateway-connection-name-input"
                        disabled={false}
                        label={translate({ id: 'CLIENT_CONNECTION_NAME' })}
                        InputLabelProps={{ id: 'CLIENT_NAME' }}
                        fullWidth
                        variant="outlined"
                        defaultValue={connectionData?.Name}
                        required
                        helperText={errors?.Name?.message}
                        error={!!errors?.Name}
                        inputProps={register('Name', {
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_CONNECTION_NAME' }) },
                            ),
                            maxLength: {
                                value: CharacterLimitForNames.maxNameLength,
                                message: connectionNameWarning,
                            },
                            minLength: {
                                value: 2,
                                message: connectionNameWarning,
                            },
                        })}
                    />
                    { gatewayType === 'ExpressRoute' ?
                        <TextField
                            data-cy='connection-auth-key-input'
                            disabled={false}
                            label={
                                <div className={classes.textFieldLabel}>
                                    {
                                        translate({ id: 'CLIENT_AUTHORIZATION_KEY' })
                                    }
                                    <Tooltip
                                        title={translate({ id: 'CLIENT_GATEWAY_AUTH_KEY_TOOLTIP' })}
                                        placement='right-end'>
                                        <Info className={classes.tooltipIcon} />
                                    </Tooltip>
                                </div>
                            }
                            fullWidth
                            variant="outlined"
                            required
                            helperText={errors?.AuthKey?.message}
                            placeholder={type === 'edit' ? '********' : ''}
                            error={!!errors?.AuthKey}
                            inputProps={register('AuthKey', {
                                required: translate(
                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                    { 0: translate({ id: 'CLIENT_AUTHORIZATION_KEY' }) },
                                ),
                                validate: (value: string) =>
                                    isGuid(value) || translate({ id: 'CLIENT_GATEWAY_AUTH_KEY_ERROR' }),
                            })}
                        />
                        : <TextField
                            data-cy='connection-shared-key-input'
                            disabled={false}
                            label={
                                <div className={classes.textFieldLabel}>
                                    { translate({ id: 'CLIENT_SHARED_KEY' }) }
                                </div>
                            }
                            fullWidth
                            variant="outlined"
                            required
                            helperText={errors?.SharedKey?.message}
                            placeholder={type === 'edit' ? '********' : ''}
                            error={!!errors?.SharedKey}
                            inputProps={register('SharedKey', {
                                required: translate(
                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                    { 0: translate({ id: 'CLIENT_SHARED_KEY' }) },
                                ),
                                validate: (sharedKey: string) =>
                                    !validatePSKValue(sharedKey) ||
                                            translate({ id: 'CLIENT_SHARED_KEY_ERROR_MESSAGE' }),
                            })}
                        />}
                    {
                        expressRouteEnabled && gatewayType === 'ExpressRoute' ?
                            (
                                <div>
                                    <TextField
                                        data-cy='exp-resource-id-input'
                                        disabled={type === 'edit'}
                                        label={<div className={classes.textFieldLabel}>
                                            { translate({ id: 'CLIENT_RESOURCE_ID' }) }
                                            <Tooltip
                                                title={translate({ id: 'CLIENT_GATEWAY_RESOURCE_ID_AUTH_KEY_TOOLTIP' })}
                                                placement='right-end'>
                                                <Info className={classes.tooltipIcon} />
                                            </Tooltip>
                                        </div>}
                                        fullWidth
                                        variant="outlined"
                                        required
                                        defaultValue={connectionData?.ExpressRouteTargetResourceId}
                                        helperText={errors?.ExpressRouteTargetResourceId?.message}
                                        error={!!errors?.ExpressRouteTargetResourceId}
                                        inputProps={register('ExpressRouteTargetResourceId', {
                                            required: translate(
                                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                                { 0: translate({ id: 'CLIENT_RESOURCE_ID' }) },
                                            ),
                                        })}
                                    />
                                </div>
                            )
                            :
                            <TextField
                                className={classes.textFieldLabel}
                                data-cy="public-ip-input"
                                disabled={false}
                                label={translate({ id: 'CLIENT_PUBLIC_IP_DEVICE' })}
                                fullWidth
                                variant="outlined"
                                required
                                defaultValue={connectionData?.TargetIpAddress}
                                helperText={errors?.TargetIpAddress?.message}
                                error={!!errors?.TargetIpAddress}
                                inputProps={register('TargetIpAddress', {
                                    required: translate(
                                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                        { 0: translate({ id: 'CLIENT_PUBLIC_IP_DEVICE' }) },
                                    ),
                                    validate: (value: string) =>
                                        validateIPv4Range(value, value) || translate({ id: 'CLIENT_VPN_GATEWAY_ADDRESS_ERROR_MESSAGE' }),
                                })}
                            />
                    }
                    { gatewayType !== 'ExpressRoute' &&
                    <div>
                        { fields.map((val, idx) => (
                            <div
                                className={classes.addressSpaceSection}
                                key={idx}
                                data-cy='address-space-section'
                            >
                                <TextField
                                    className={idx === 0 ? '' : classes.textFieldLabel}
                                    data-cy={`address-space-input-${idx}`}
                                    fullWidth
                                    variant="outlined"
                                    label={idx === 0 ?
                                        <div className={classes.textFieldLabel}>
                                            { translate({ id: 'CLIENT_ADDRESS_SPACE_DEVICE' }) }
                                            <Tooltip
                                                title={translate({ id: 'CLIENT_VPN_CONNECTION_ADDRESS_SPACE_INFO_TEXT' })}
                                                placement='right-end'>
                                                <Info className={classes.tooltipIcon} />
                                            </Tooltip>
                                        </div> : undefined}
                                    defaultValue={val.addressSpace}
                                    helperText={errors.addressSpaces?.[idx]?.addressSpace?.message}
                                    error={!!errors.addressSpaces?.[idx]?.addressSpace}
                                    inputProps={register(`addressSpaces.${idx}.addressSpace`, {
                                        required: translate(
                                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                            { 0: translate({ id: 'CLIENT_ADDRESS_SPACE' }) },
                                        ),
                                        validate: (value: string) =>
                                            validateIPv4Range(value, value) ||
                                            translate({ id: 'CLIENT_VPN_GATEWAY_ADDRESS_ERROR_MESSAGE' }),
                                    },
                                    )}
                                    required
                                />
                                <Button
                                    className={clsx(classes.deleteButton, idx === 0 ? classes.firstChild : '')}
                                    size='medium'
                                    startIcon={<RemoveCircle />}
                                    data-cy={'vpn-remove-address-space-button-' + idx}
                                    aria-label={`Remove address space ${idx + 1}`}
                                    onClick={() => removeAddressSpace(idx)}
                                    disabled={idx === 0}
                                />
                            </div>)) }
                        <Button
                            className={classes.addMoreButton}
                            variant='text'
                            data-cy='add-more-address-space-button'
                            startIcon={<AddIcon />}
                            onClick={() => addAddressSpace()}
                            aria-label='Add more address spaces'
                        >
                            {translate({ id: 'CLIENT_ADD_MORE' })}
                        </Button>
                    </div>}
                </div>
                {ipSecPolicyEnabled && gatewayType === 'Vpn' && <>
                    <div className={classes.additionalConfig}>
                        <UiText
                            align='left'
                            variant={FontVariantToken.fontSizeH4}>
                            {translate({ id: 'CLIENT_VPN_ADDITIONAL_CONFIGURATION' })}
                        </UiText>
                        <FormControlLabel
                            className={classes.ipsecToggle}
                            control={
                                <Switch
                                    data-cy='ip-sec-policy-toggle-switch'
                                    checked={ipSecEnabled}
                                    onChange={(event) => setIpSecEnabled(event.target.checked)}
                                    color="primary"
                                />
                            }
                            label={
                                <div>
                                    <span className={classes.ipsecToggleLabel}>
                                        {translate({ id: 'CLIENT_VPN_CUSTOM_IPSEC_POLICY_LABEL' })}
                                    </span>
                                    <InfoOutlined />
                                </div>
                            }
                        />
                        <UiText
                            className={classes.ipSecInfo}
                            align="left">
                            <InfoOutlined className={classes.ipSecInfoIcon} />
                            {translate({ id: 'CLIENT_VPN_CUSTOM_IPSEC_POLICY_INFO' })}
                        </UiText>
                    </div>
                    {ipSecEnabled && <FormProvider {...methods}>
                        <VpnGatewayIPSecPolicyComponent />
                    </FormProvider> }
                </>}
            </UiForm>
        </UiPageContainer>
    );
};

export const VpnGatewayAddEditConnectionComponentWithProviders: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const location = useLocation();

    const {
        type, tenantId, vpnGatewayKey,
    } = useParams<{
        type: 'add' | 'edit'; tenantId: string; vpnGatewayKey: string;
    }>();

    const gatewayType = useMemo(() => location?.state?.gatewayType, [ location ]);

    const breadCrumbLinks = useMemo(() =>
        [
            {
                index: 0,
                link: RouteNames.TenantHome.replace(':tenantId', tenantId ?? ''),
                name: location.state?.tenantName ? location.state.tenantName : translate({ id: 'CLIENT_TENANT' }),
            },
            {
                index: 1,
                link: RouteNames.VpnGateway.replace(':tenantId', tenantId ?? ''),
                name: location.state?.gatewayName ? location.state.gatewayName :
                    translate({ id: gatewayType === 'ExpressRoute' ? 'CLIENT_EXPRESSROUTE' : 'CLIENT_GATEWAY' }),
            },
            {
                index: 2,
                link: RouteNames.VpnGatewayConnections.replace(':tenantId', tenantId ?? '').replace(':vpnGatewayKey', vpnGatewayKey ?? ''),
                name: translate({ id: 'CLIENT_CONNECTIONS' }),
            },
            {
                index: 3,
                link: '',
                name: type === 'edit' ? translate({ id: 'CLIENT_EDIT_CONNECTION' }) : translate({ id: 'CLIENT_CONNECTION_CREATE' }),
            },
        ],
    [ tenantId, location, translate, gatewayType, vpnGatewayKey, type ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <VpnGatewayAddEditConnectionComponent />
    </BreadcrumbProvider>;
};

export default VpnGatewayAddEditConnectionComponent;
