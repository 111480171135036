import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React from 'react';
import { useIntl } from 'react-intl';

const useStyles = makeStyles(() =>
    createStyles({
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        confirmationButton: { marginRight: '10px' },
    }),
);

const EncryptionConfirmationDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, success,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    return (
        <>
            <UiText data-cy="encryption-confirmation-message">
                {translate({ id: success ? 'CLIENT_ENCRYPTION_CONFIRMATION_SUCCESS' : 'CLIENT_ENCRYPTION_CONFIRMATION_FAILURE' })}
            </UiText>

            <div className={classes.buttonContainer}>
                <Button
                    className={classes.confirmationButton}
                    onClick={() => closeDialog()}
                    color="primary"
                    data-cy="encryption-confirmation-button"
                >
                    {translate({ id: 'CLIENT_OK' })}
                </Button>
            </div>
        </>
    );
};

export default EncryptionConfirmationDialogBody;
