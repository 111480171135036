import type { ITenant } from '@experiences/interfaces';
import {
    UiDataContextProvider,
    UiText,
    useUiDataContext,
} from '@experiences/ui-common';
import { FontVariantToken } from '@uipath/apollo-core';
import type { PropsWithoutRef } from 'react';
import React, {
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import { useTenantIdContext } from '../../common/providers/TenantIdProvider';
import {
    getTenantById,
    tenantByIdUri,
} from '../../services/organization/TenantService';
import { UiResizablePageContainer } from '../common/UiPageContainer/UiResizablePageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import { AuditDetailsComponent } from './AuditDetailsComponent';
import { AuditGridComponent } from './AuditGridComponent';
import { AuditPreviewToggle } from './AuditPreviewToggle';
import type { IAuditDetailsContext } from './interfaces/auditLog';

const AuditPageComponent = () => {
    const { formatMessage: translate } = useIntl();

    const { tenant: tenantContext } = useTenantIdContext();

    const {
        data, setData,
    } = useUiDataContext<IAuditDetailsContext>();

    const tenant = useMemo(() => data.tenant ?? tenantContext, [ data.tenant, tenantContext ]);

    useEffect(() => {
        if (tenant) {
            // if user switches via tenant switcher, we close the resizable drawer
            // by resetting the details and summary to empty strings
            const reset = data.tenant?.id !== tenant.id ? {
                id: '',
                details: '',
                summary: '',
            } : {};

            // save tenant data in the audit context provider
            setData(prev => ({
                ...prev,
                ...reset,
                tenant,
            }));
        }
    }, [ data.tenant?.id, setData, tenant ]);

    const { breadcrumbs } = useBreadcrumbs();

    return <UiResizablePageContainer
        breadcrumb={<div style={{
            display: 'flex',
            justifyContent: 'space-between',
        }}>
            <AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />
            <AuditPreviewToggle tenant={tenant} />
        </div>}
        header={
            <UiText variant={FontVariantToken.fontSizeM}>
                {translate ({ id: 'CLIENT_AUDIT_HEADER' })}
            </UiText>
        }
        drawer={
            <AuditDetailsComponent />
        }
        showDrawer={!!data.row}
    >
        <AuditGridComponent />
    </UiResizablePageContainer>;
};

const AuditPage = (props: PropsWithoutRef<{ tenant?: ITenant }>) => (
    <UiDataContextProvider<IAuditDetailsContext> initialState={{
        row: undefined,
        tenant: props.tenant,
        virtualScrollManager: undefined,
    }}>
        <AuditPageComponent />
    </UiDataContextProvider>
);

export const AuditPageWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const { tenantId } = useParams() as { tenantId: string };

    const { data: tenant } = useSWR(
        tenantId ? {
            url: tenantByIdUri,
            id: tenantId,
        } : null,
        getTenantById,
    );

    const breadCrumbLinks = useMemo(() => {
        if (tenant?.name) {
            return [
                {
                    index: 0,
                    link: RouteNames.OrganizationAdminHome,
                    name: organizationName,
                },
                {
                    index: 1,
                    link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                    name: tenant.name,
                },
                {
                    index: 2,
                    link: RouteNames.TenantAudit.replace(':tenantId', tenantId),
                    name: translate({ id: 'CLIENT_AUDIT_LOG' }),
                },
            ];
        }

        return [
            {
                index: 0,
                link: RouteNames.OrganizationAdminHome,
                name: organizationName,
            },
            {
                index: 1,
                link: RouteNames.Audit,
                name: translate({ id: 'CLIENT_AUDIT_LOG' }),
            },
        ];
    },
    [ organizationName, tenant, tenantId, translate ]);

    return (
        <BreadcrumbProvider
            breadcrumbs={breadCrumbLinks}
            legacy>
            <AuditPage tenant={tenant} />
        </BreadcrumbProvider>
    );
};

export default AuditPage;
