import type {
    ILabelModel,
    ILabelModelObject,
    ILabelModelPaginationResult,
    IPagination,
    IValueModelPaginatedResult,
    PermissionType,
} from '@experiences/interfaces';
import urljoin from 'url-join';

import store from '../../store';
import {
    axiosDelete,
    get,
    post,
    put,
} from '../utility/Requests.default';
import {
    cleanLabelTagResponse,
    cleanValueTagResponse,
    DEFAULT_SEARCH_FILTERS,
    mapObjectId,
    mapPagination,
} from './TagsUtil';

export interface ITagLabelDto extends Partial<ILabelModelObject>{}

export const tagsUrl = `${window.location.origin}/ResourceCatalog`;

const getHeaders = (selectedAccountId: string) => ({
    extendRequestHeaders: 'true',
    'X-UiPath-Internal-AccountId': selectedAccountId,
    'X-UiPath-Internal-TenantId': selectedAccountId,
});

export async function getPermissions({ selectedAccountId }: {
    url: string;
    accountLogicalName: string;
    tenantName: string | undefined;
    selectedAccountId: string;
}) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, '/Entities/Tags/Permissions');
    const res = await get<PermissionType[]>(url, { headers: getHeaders(selectedAccountId) });
    return res.map(permission => permission.toLowerCase()) as PermissionType[];
}

export async function getLabels({
    pagination, selectedAccountId,
}: {
    url: string;
    pagination: IPagination;
    accountLogicalName: string;
    tenantName: string | undefined;
    selectedAccountId: string;
}) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags`);
    const response = await get<ILabelModelPaginationResult>(url, {
        urlParams: {
            ...DEFAULT_SEARCH_FILTERS,
            ...mapPagination(pagination),
            type: 'Label',
        },
        headers: getHeaders(selectedAccountId),
    });

    return cleanLabelTagResponse(response);
}

export async function getKeyValues({
    pagination, selectedAccountId,
}: {
    url: string;
    pagination: IPagination;
    accountLogicalName: string;
    tenantName: string | undefined;
    selectedAccountId: string;
}) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags`);
    const response = await get<ILabelModelPaginationResult>(url, {
        urlParams: {
            ...DEFAULT_SEARCH_FILTERS,
            ...mapPagination(pagination),
            type: 'KeyValue',
        },
        headers: getHeaders(selectedAccountId),
    });

    return cleanLabelTagResponse(response);
}

export async function getLabel({
    id, selectedAccountId,
}: {
    url: string;
    accountLogicalName: string;
    tenantName: string | undefined;
    id: string;
    selectedAccountId: string;
}) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags/${id}`);
    const response = await get<ILabelModel>(url, { headers: getHeaders(selectedAccountId) });
    return mapObjectId(response);
}

export async function createLabel(
    _accountLogicalName: string,
    _tenantName: string | undefined,
    payload: ITagLabelDto,
    selectedAccountId: string,
) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags`);
    payload.type = 'Label';
    return post(url, {
        body: payload,
        headers: getHeaders(selectedAccountId),
    });
}

export async function createKeyValue(
    _accountLogicalName: string,
    _tenantName: string | undefined,
    payload: ITagLabelDto,
    selectedAccountId: string,
) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags`);
    payload.type = 'KeyValue';
    return post(url, {
        body: payload,
        headers: getHeaders(selectedAccountId),
    });
}

export async function updateLabel(
    _accountLogicalName: string,
    _tenantName: string | undefined,
    id: string | undefined,
    payload: ITagLabelDto,
    selectedAccountId: string,
) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags/${id}`);
    return put(url, {
        body: payload,
        headers: getHeaders(selectedAccountId),
    });
}

export async function deleteLabel(
    _accountLogicalName: string,
    _tenantName: string | undefined,
    keys: string[],
    selectedAccountId: string,
) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags`);
    return axiosDelete(url,
        {
            urlParams: {
                keys,
                type: 'Label',
            },
            headers: getHeaders(selectedAccountId),
        },

    );
}

export async function deleteKeyValue(
    _accountLogicalName: string,
    _tenantName: string | undefined,
    keys: string[],
    selectedAccountId: string,
) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags`);
    return axiosDelete(url,
        {
            urlParams: {
                keys,
                type: 'KeyValue',
            },
            headers: getHeaders(selectedAccountId),
        },
    );
}

export async function getValuesForKey({
    pagination, key, selectedAccountId,
}: {
    pagination: IPagination;
    accountLogicalName: string;
    tenantName: string | undefined;
    key: string;
    selectedAccountId: string;
}) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags/${key}/Values`);
    const response = await get<IValueModelPaginatedResult>(url, {
        urlParams: {
            ...DEFAULT_SEARCH_FILTERS,
            ...mapPagination(pagination),
        },
        headers: getHeaders(selectedAccountId),
    });
    return cleanValueTagResponse(response);
}

export async function createValuesForKey(
    _accountLogicalName: string,
    _tenantName: string | undefined,
    key: string,
    payload: { labelKey: string; values: string[]; accountKey: string; tenantKey: string },
    selectedAccountId: string,
) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags/${key}/Values`);
    return post(url, {
        body: payload,
        headers: getHeaders(selectedAccountId),
    });
}

export async function deleteValueFromKey(
    _accountLogicalName: string,
    _tenantName: string | undefined,
    key: string,
    valueKey: string,
    selectedAccountId: string,
) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags/${key}/Values/${valueKey}`);
    return axiosDelete(url, { headers: getHeaders(selectedAccountId) });
}

export async function deleteValuesFromKey(
    _accountLogicalName: string,
    _tenantName: string | undefined,
    key: string,
    valueKeys: string[],
    selectedAccountId: string,
) {
    const resourceCatalogUrl = store.getState().profile.resourceCatalogUrl;
    const baseUrl = resourceCatalogUrl ? resourceCatalogUrl : tagsUrl;
    const url = urljoin(baseUrl, `/Tags/${key}/Values`);
    return axiosDelete(url,
        {
            urlParams: { valueKeys },
            headers: getHeaders(selectedAccountId),
        },
    );
}
