import { getFormattedDate } from '@experiences/util';

import { getNextAuditEvents } from '../../../services/audit/AuditService';

const DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';

export function getNextEvents(start: number, nextUrl?: string | null) {
    return nextUrl ?
        getNextAuditEvents(nextUrl, start) :
        null ;
}

export function getSuggestValue(value: string[]) {
    return value?.map(s => ({
        id: s,
        text: s,
    }));
}

export function getSearchSourceFactory(search?: string, inputs?: string[]) {
    const uniqueInputs = [ ...new Set(inputs) ];

    return Promise.resolve({
        data: uniqueInputs.filter(input => search ? input?.includes(search) : true).map((name, i) => ({
            id: name,
            text: name,
            value: name,
        })),
        total: uniqueInputs.filter(input => search ? input?.includes(search) : true).length,
    });
}

export function getDateFilterString(from?: Date, to?: Date) {
    return from && to ?
        {
            id: 'CLIENT_DATE_CREATED_FROM_TO',
            args: {
                0: getFormattedDate(from, DATE_TIME_FORMAT),
                1: getFormattedDate(to, DATE_TIME_FORMAT),
            },
        } :
        { id: 'CLIENT_DATE_CREATED' };
}
