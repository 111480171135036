import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    getEnvVariableValue,
    useFormKeysValidation,
} from '@experiences/util';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import {
    Controller,
    useFormContext,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { AllowedDomainsAndAttributeMappingSaml } from '../../../common/constants/documentation/SAMLDocumentationLinks.default';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import type { ISamlFormData } from '../../../common/interfaces/cis/saml';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        pageDescription: {
            marginBottom: '20px',
            maxWidth: '520px',
        },
        field: {
            marginBottom: '16px',
            maxWidth: '420px',
        },
        fieldLabel: {
            display: 'inline-flex',
            alignItems: 'center',
            gap: '5px',
        },
        formSection: {
            display: 'flex',
            flexDirection: 'column',
        },
        labelWithHelp: {
            display: 'flex',
            flexDirection: 'row',
            marginTop: '15px',
        },
        sectionLabel: {
            fontWeight: 600,
            lineHeight: '20px',
        },
        infoIcon: {
            width: '18px',
            height: '18px',
            marginLeft: '4px',
            marginTop: '1px',
            cursor: 'pointer',
        },
        tooltip: { display: 'flex' },
        tooltipIcon: {
            width: '16px',
            height: '16px',
            marginLeft: '4px',
        },
        checkboxField: {
            maxWidth: '450px',
            marginTop: '-10px',
        },
    }),
}));

const SecuritySettingsSAMLProvisioningSettings: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks();
    const attributeMappingLink = getLocalizedLink({
        articleSlug: AllowedDomainsAndAttributeMappingSaml,
        product: getEnvVariableValue('DOCUMENTATION_PRODUCT'),
        version: getEnvVariableValue('DOCUMENTATION_VERSION'),
    });

    const {
        register,
        control,
        formState: { errors },
    } = useFormContext<ISamlFormData>();

    const activeKeys = useMemo(() => [ 'ProvisioningSetting.AllowedDomains', 'ProvisioningSetting.AttributeMapper.DisplayName' ], []);

    useFormKeysValidation(activeKeys);

    return (
        <div className={classes.formSection}>
            <UiText className={classes.pageDescription}>
                {translate({ id: 'CLIENT_AUTH_SETTINGS_SAML_PROVISIONING_DESCRIPTION' })}
            </UiText>
            <div className={classes.labelWithHelp}>
                <UiText className={classes.sectionLabel}>
                    {translate({ id: 'CLIENT_ALLOWED_DOMAINS' })}
                </UiText>
                <Tooltip
                    arrow
                    title={
                        <div className={classes.tooltip}>
                            {translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELP_TEXT' })}
                            <OpenInNewIcon className={classes.tooltipIcon} />
                        </div>
                    }
                >
                    <a
                        className={classes.a}
                        href={attributeMappingLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={translate({ id: 'CLIENT_ALLOWED_DOMAINS_HELP_TEXT' })}
                    >
                        <HelpOutlineIcon className={classes.infoIcon} />
                    </a>
                </Tooltip>
            </div>
            <Controller
                control={control}
                rules={{ required: true }}
                name="ProvisioningSetting.AllowedDomains"
                render={({ field }) => (
                    <TextField
                        {...field}
                        label={translate({ id: 'CLIENT_ALLOWED_DOMAINS_INSTRUCTIONS' })}
                        InputLabelProps={{ id: 'allowedDomainsLabel' }}
                        inputProps={{ 'aria-labelledby': 'allowedDomainsLabel' }}
                        variant="outlined"
                        InputProps={{ className: 'Tall' }}
                        fullWidth
                        className={classes.field}
                        error={!!errors.ProvisioningSetting?.AllowedDomains}
                        helperText={
                            errors.ProvisioningSetting?.AllowedDomains?.type === 'required' &&
                            translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })
                        }
                        data-cy="configure-jit-allowed-domains-field"
                    />
                )}
            />
            <Controller
                name="ProvisioningSetting.AccountLinkConfirmation"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                    <FormControlLabel
                        className={classes.checkboxField}
                        control={
                            <Checkbox
                                checked={field.value}
                                onChange={e => field.onChange(e.target.checked)}
                                color="primary"
                                data-cy="configure-jit-account-link-confirmation-checkbox"
                            />
                        }
                        label={<div>
                            {translate({ id: 'CLIENT_AUTH_SETTINGS_SAML2_CONFIRMATION' })}
                        </div>}
                    />
                )}
            />

            <div className={classes.labelWithHelp}>
                <UiText className={classes.sectionLabel}>
                    {translate({ id: 'CLIENT_ATTRIBUTE_MAPPING' })}
                </UiText>
                <Tooltip
                    arrow
                    title={
                        <div style={{ display: 'flex' }}>
                            {translate({ id: 'CLIENT_ATTRIBUTE_MAPPING_HELP_TEXT' })}
                            <OpenInNewIcon className={classes.tooltipIcon} />
                        </div>
                    }
                >
                    <a
                        className={classes.a}
                        href={attributeMappingLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label={translate({ id: 'CLIENT_ATTRIBUTE_MAPPING_HELP_TEXT' })}
                    >
                        <HelpOutlineIcon className={classes.infoIcon} />
                    </a>
                </Tooltip>
            </div>
            <TextField
                className={classes.field}
                required
                variant='outlined'
                fullWidth
                error={!!errors.ProvisioningSetting?.AttributeMapper?.DisplayName}
                inputProps={{
                    'aria-label': translate({ id: 'CLIENT_DISPLAY_NAME' }),
                    ...register('ProvisioningSetting.AttributeMapper.DisplayName', {
                        required: true,
                        minLength: 1,
                        maxLength: 256,
                        validate: p => !!p.trim(),
                    }),
                }}
                label={
                    <div className={classes.fieldLabel}>
                        {translate({ id: 'CLIENT_DISPLAY_NAME' })}
                        <Tooltip
                            title={translate({ id: 'CLIENT_DISPLAY_NAME_TOOLTIP' })}>
                            <PortalCustomIcon
                                name='info'
                                size='15px' />
                        </Tooltip>
                    </div>
                }
                data-cy="configure-jit-display-name-field"
                helperText={
                    errors.ProvisioningSetting?.AttributeMapper?.DisplayName?.type === 'required' &&
                            translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_DISPLAY_NAME' }) },
                            )
                }
            />
            <TextField
                label={translate({ id: 'CLIENT_FIRST_NAME' })}
                InputLabelProps={{ id: 'firstNameLabel' }}
                inputProps={{
                    'aria-labelledby': 'firstNameLabel',
                    ...register('ProvisioningSetting.AttributeMapper.FirstName', { maxLength: 256 }),
                }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                error={!!errors.ProvisioningSetting?.AttributeMapper?.FirstName}
                data-cy="configure-jit-first-name-field"
            />
            <TextField
                label={translate({ id: 'CLIENT_LAST_NAME' })}
                InputLabelProps={{ id: 'firstNameLabel' }}
                inputProps={{
                    'aria-labelledby': 'lastNameLabel',
                    ...register('ProvisioningSetting.AttributeMapper.LastName', { maxLength: 256 }),
                }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                error={!!errors.ProvisioningSetting?.AttributeMapper?.LastName}
                data-cy="configure-jit-last-name-field"
            />
            <TextField
                label={translate({ id: 'CLIENT_JOB_TITLE' })}
                InputLabelProps={{ id: 'jobTitleLabel' }}
                inputProps={{
                    'aria-labelledby': 'jobTitleLabel',
                    ...register('ProvisioningSetting.AttributeMapper.JobTitle'),
                }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                error={!!errors.ProvisioningSetting?.AttributeMapper?.JobTitle}
                data-cy="configure-jit-job-title-field"
            />
            <TextField
                label={translate({ id: 'CLIENT_DEPARTMENT' })}
                InputLabelProps={{ id: 'departmentLabel' }}
                inputProps={{
                    'aria-labelledby': 'departmentLabel',
                    ...register('ProvisioningSetting.AttributeMapper.Department'),
                }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                error={!!errors.ProvisioningSetting?.AttributeMapper?.Department}
                data-cy="configure-jit-department-field"
            />
            <TextField
                label={translate({ id: 'CLIENT_CITY' })}
                InputLabelProps={{ id: 'cityLabel' }}
                inputProps={{
                    'aria-labelledby': 'cityLabel',
                    ...register('ProvisioningSetting.AttributeMapper.City'),
                }}
                variant="outlined"
                InputProps={{ className: 'Tall' }}
                fullWidth
                className={classes.field}
                error={!!errors.ProvisioningSetting?.AttributeMapper?.City}
                data-cy="configure-jit-city-field"
            />

        </div>
    );
};

export default SecuritySettingsSAMLProvisioningSettings;
