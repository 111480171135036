import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { ApButton } from '@uipath/portal-shell-react';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { deleteDirectoryIntegrationSetting } from '../../../services/identity/AuthenticationSettingService';
import { deleteDirectoryConnection } from '../../../services/identity/DirectoryConnectionService';
import {
    accountGlobalId,
    isHostModeSelector,
} from '../../../store/selectors';
import { UiDeleteButton } from '../../common/UiDeleteButton/UiDeleteButton';
import {
    SpacingToken,
    UiStack,
} from '../../common/UiStack';

const DeleteSSOConfigurationDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, connectionId, refreshCallback,
}) => {
    const { formatMessage: translate } = useIntl();
    const partitionGlobalId = useSelector(accountGlobalId);
    const isHostMode = useSelector(isHostModeSelector);

    const [ loading, setLoading ] = useState(false);
    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const deleteAndClose = useCallback(async () => {
        try {
            setLoading(true);
            if (isHostMode) {
                await deleteDirectoryConnection(connectionId);

            } else {
                await deleteDirectoryIntegrationSetting(partitionGlobalId);
            }
            closeDialog();
            setLoading(false);
            refreshCallback();
        } catch (error) {
            setLoading(false);
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
        }
    }, [
        closeDialog,
        connectionId,
        getErrorMessage,
        getErrorObject,
        isHostMode,
        partitionGlobalId,
        refreshCallback,
        setErrorMessage,
    ]);

    return (
        <UiStack
            direction="column"
            gap={SpacingToken.M}>
            <UiText data-cy="delete-sso-warning">
                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DELETE_TEXT_LINE_1_REVAMP' })}
            </UiText>
            <UiText data-cy="delete-sso-ask">
                {translate({ id: 'CLIENT_AUTH_SETTINGS_CONFIGURE_AAD_DELETE_TEXT_LINE_2' })}
            </UiText>

            <UiStack
                justify="end"
                gap={SpacingToken.XS}>
                <ApButton
                    variant="tertiary"
                    data-cy="delete-sso-cancel-button"
                    onClick={() => closeDialog()}
                    label={translate({ id: 'CLIENT_CANCEL' })}
                />
                <UiDeleteButton
                    loading={loading}
                    data-cy="delete-sso-confirmation-button"
                    onClick={deleteAndClose}
                />
            </UiStack>
        </UiStack>
    );
};

export default DeleteSSOConfigurationDialogBody;
