import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    ApTab,
    ApTabs,
} from '@uipath/portal-shell-react';
import React, {
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import A4EComponent from './a4e/A4EComponent';
import { AuditTab } from './tabs/AuditTab';
import { ContextGroundingTab } from './tabs/ContextGroundingTab';
import { SettingsTab } from './tabs/SettingsTab';

const AiTrustLayerTabs = {
    AUDIT: 'audit',
    CONTEXTGROUNDING: 'context-grounding',
    SETTINGS: 'settings',
    USAGE: 'usage',
    A4E: 'a4e',
};

export const AiTrustLayerComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const { breadcrumbs } = useBreadcrumbs();
    const [ value, setValue ] = useState(AiTrustLayerTabs.SETTINGS);
    const EnableAiTrustLayerContextGrounding = useFeatureFlagValue(Features.EnableAiTrustLayerContextGrounding.name);
    const EnableAiTrustLayerAudit = useFeatureFlagValue(Features.EnableAiTrustLayerAudit.name);
    const EnableAiTrustLayerA4E = useFeatureFlagValue(Features.EnableAiTrustLayerA4E.name);

    return (
        <UiPageContainer
            header={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            disableGutter={[ 'top' ]}
            position='left'>
            <ApTabs
                value={value}
                onValueChanged={(event) => setValue(event.detail)}>
                <ApTab
                    value={AiTrustLayerTabs.SETTINGS}
                    label={translate({ id: 'CLIENT_AI_TRUST_LAYER_SETTINGS_TAB' })}
                    data-cy="settings-tab"
                />
                {EnableAiTrustLayerContextGrounding && <ApTab
                    value={AiTrustLayerTabs.CONTEXTGROUNDING}
                    label={translate({ id: 'CLIENT_AI_TRUST_LAYER_CONTEXT_GROUNDING_TAB' })}
                    data-cy="context-grounding-tab"
                />}
                {EnableAiTrustLayerAudit && <ApTab
                    value={AiTrustLayerTabs.AUDIT}
                    label={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_TAB' })}
                    data-cy="audit-tab"
                />}
                {EnableAiTrustLayerA4E && <ApTab
                    value={AiTrustLayerTabs.A4E}
                    label={translate({ id: 'CLIENT_AI_TRUST_LAYER_A4E_TAB' })}
                    data-cy="a4e-tab"
                />}
            </ApTabs>
            {value === AiTrustLayerTabs.SETTINGS && <SettingsTab />}
            {value === AiTrustLayerTabs.CONTEXTGROUNDING && <ContextGroundingTab />}
            {value === AiTrustLayerTabs.AUDIT && <AuditTab />}
            {value === AiTrustLayerTabs.A4E && <A4EComponent />}
        </UiPageContainer>
    );
};

// TODO: This is for the legacy routes, to be deleted in the future.
export const AiTrustLayerComponentWithProviders: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            index: 1,
            link: RouteNames.AiTrustLayerSetting,
            name: translate({ id: 'CLIENT_AI_TRUST_LAYER' }),
        },
    ], [ organizationName, translate ]);

    return (
        <BreadcrumbProvider breadcrumbs={breadCrumbLinks}>
            <AiTrustLayerComponent />
        </BreadcrumbProvider>
    );
};
