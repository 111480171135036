import { useCentralErrorSetter } from '@experiences/error';
import {
    portalTelemetry,
    UsersEvent,
} from '@experiences/telemetry';
import { useUiDataContext } from '@experiences/ui-common';
import {
    useModalState,
    validateUsername,
} from '@experiences/util';
import TextField from '@mui/material/TextField';
import {
    ApButton,
    ApChipInput,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    Controller,
    FormProvider,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useParams,
} from 'react-router-dom';
import { mutate } from 'swr';

import {
    notificationType,
    QUOTA_REACHED_MESSAGE,
} from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import useSimpleGroup from '../../../common/hooks/SimpleGroup';
import { useLoginProvider } from '../../../common/hooks/useLoginProvider';
import { useOrchRoleAssignmentForAX } from '../../../common/hooks/useOrchRoleAssignmentForAX';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type {
    IBulkInviteUserResponse,
    IUserCIS,
} from '../../../common/interfaces/cis/user';
import getUsersInPartition, { userPartitionUrl } from '../../../services/identity/UserPartitionService';
import {
    inviteUsers,
    putUser,
} from '../../../services/identity/UserService';
import { allocateUserLicenses } from '../../../services/licensing/UserLicenseService';
import { AX_DIRECTORY_ID } from '../../../services/orchestrator/OrchRoleAssignmentService';
import {
    accountGlobalId,
    accountType,
    companyName,
    EnableUserLicensingSelector,
    organizationLanguage,
    userGlobalId,
} from '../../../store/selectors';
import reduceGroupCIS, {
    mapInviteUserDto,
    mapUserDto,
} from '../../../util/UserGroupUtil';
import validateEmail from '../../../util/validators/EmailValidator';
import { UiDrawer } from '../../common/UiDrawer';
import UiForm from '../../common/UiForm';
import {
    SpacingToken,
    UiStack,
} from '../../common/UiStack';
import type {
    InviteEditUserData,
    SimpleGroup,
} from '../interfaces/user';
import EditGroupMembershipFormComponent from './EditGroupMembershipFormComponent';
import {
    ConfirmInviteSuccessComponent,
    ConfirmInviteSuccessLicensingComponent,
} from './SuccessMessageComponents';

const InviteEditUserComponent: React.FC<{ type: 'invite' | 'edit' }> = ({ type }) => {
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const { state } = useLocation();

    const { setData } = useUiDataContext<{ refresh: boolean }>();

    const {
        open, close: closeModal,
    } = useModalState(RouteNames.Users);

    const close = useCallback((refresh?: boolean) => {
        setData({ refresh: refresh ?? false });
        closeModal();
    }, [ closeModal, setData ]);

    const orgLanguage = useSelector(organizationLanguage);
    const currentUserId = useSelector(userGlobalId);
    const partitionGlobalId = useSelector(accountGlobalId);
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const orgName = useSelector(companyName);
    const subscriptionPlan = useSelector(accountType);

    const user = useMemo(() => state?.user || ({} as IUserCIS), [ state ]);

    const { groups } = useSimpleGroup(true);
    const loginProvider = useLoginProvider();

    const [ loading, setLoading ] = useState(false);
    const [ showConfirmInvite, setShowConfirmInvite ] = useState(false);
    const [ showDrawerError, setShowDrawerError ] = useState(false);
    const [ errorMessageId, setErrorMessageId ] = useState('CLIENT_INVITE_USER_GENERIC_ERROR');
    const [ confirmInviteResponse, setConfirmInviteResponse ] = useState<IBulkInviteUserResponse | undefined>(undefined);
    const setErrorMessage = useCentralErrorSetter();
    const {
        showUseOrchRoleAssignmentDialogForAX, isLoadingTenantOrchRoleInfo,
    } = useOrchRoleAssignmentForAX();

    const methods = useForm<InviteEditUserData>({
        mode: 'onChange',
        defaultValues: {
            userName: '',
            email: type === 'invite' ? [] : '',
            groupIds: {} as SimpleGroup,
        },
    });
    const {
        control,
        setError,
        setValue,
        clearErrors,
        handleSubmit,
        reset,
        formState: {
            isDirty, isValid, errors,
        },
        watch,
    } = methods;

    useEffect(() => {
        if (type === 'invite' && groups) {
            const simpleGroup = reduceGroupCIS(groups);
            reset({
                userName: '',
                email: [],
                groupIds: simpleGroup,
            });
        }
        if (type === 'edit' && groups && user) {
            const simpleGroup = reduceGroupCIS(groups, user.groupIDs);
            reset({
                userName: `${user.name}${user.surname ? ' ' + user.surname : ''}`,
                email: user.email,
                groupIds: simpleGroup,
            });
        }
    }, [ reset, user, groups, type ]);

    const checkEmailAlreadyExists = useCallback(
        async (email: string) => {
            const response = await getUsersInPartition({
                pagination: {
                    top: 1,
                    skip: 0,
                    searchTerm: email,
                },
                partitionGlobalId,
            });
            return response.results.some(result => result.email?.trim().toLowerCase() === email.trim().toLowerCase());
        },
        [ partitionGlobalId ],
    );

    const onSubmit = useCallback(
        async (data: InviteEditUserData) => {
            setLoading(true);
            try {
                if (type === 'invite') {
                    const response = await inviteUsers(mapInviteUserDto(data), loginProvider, orgLanguage, orgName);
                    const successUsers = response.users.filter(u => u.success === true);
                    const failedUserEmails =
                        '\r\n' +
                        response.users
                            .filter(u => u.success === false)
                            .map(u => u.email + ' - ' + u.errorMsg)
                            .join('\r\n');

                    if (failedUserEmails.trim()) {
                        setShowDrawerError(true);
                        setErrorMessageId(translate({ id: 'CLIENT_INVITE_USER_SPECIFIC_ERROR' }, { 0: failedUserEmails }));
                    }

                    setConfirmInviteResponse(response);

                    portalTelemetry.trackEvent({
                        name: UsersEvent.Invited,
                        properties: {
                            Message: 'INV-USERS-00 - Invited Users',
                            Code: 'INV-USERS-00',
                            SubscriptionType: subscriptionPlan,
                            NoOfAddedUsers: (successUsers.length).toString(),
                        },
                    });

                    if (EnableUserLicensing) {
                        await allocateUserLicenses(partitionGlobalId, { userIds: successUsers.map(u => u.id) });
                    }
                } else {
                    await putUser(user.id, mapUserDto(data), currentUserId === user.id);
                }
            } catch (error) {
                if ((error as any)?.response?.data?.Message === QUOTA_REACHED_MESSAGE) {
                    setErrorMessage(translate({ id: 'CLIENT_QUOTA_REACHED' }));
                    return;
                }

                setShowDrawerError(true);
                setErrorMessageId('CLIENT_INVITE_USER_GENERIC_ERROR');
                return;
            }

            await mutate((key: any) => key?.url === `${userPartitionUrl}/licenses`);
            setLoading(false);
            if (type === 'edit') {
                if (data.groupIds[AX_DIRECTORY_ID]) {
                    showUseOrchRoleAssignmentDialogForAX();
                }
                createNotification(translate({ id: 'CLIENT_USER_UPDATED' }), notificationType.SUCCESS);
                close(true);
            } else {
                setShowConfirmInvite(true);
            }
        },
        [
            type,
            partitionGlobalId,
            orgLanguage,
            orgName,
            loginProvider,
            EnableUserLicensing,
            user.id,
            currentUserId,
            createNotification,
            setErrorMessage,
            translate,
            close,
            subscriptionPlan,
            showUseOrchRoleAssignmentDialogForAX,
        ],
    );

    return (
        <UiDrawer
            title={type === 'invite' ? translate({ id: 'CLIENT_INVITE_USER' }) : translate({ id: 'CLIENT_EDIT_USER' })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(showConfirmInvite),
            }}
            loading={!groups.length || isLoadingTenantOrchRoleInfo}
            error={{
                showError: showDrawerError,
                message: translate({ id: errorMessageId }),
            }}
        >
            {showConfirmInvite ? (
                EnableUserLicensing ? (
                    <ConfirmInviteSuccessLicensingComponent
                        users={confirmInviteResponse?.users}
                        close={close} />
                ) : (
                    <ConfirmInviteSuccessComponent close={close} />
                )
            ) : (
                <UiForm
                    onSubmit={handleSubmit(onSubmit)}
                    actions={
                        <UiStack
                            justify="end"
                            gap={SpacingToken.XS}>
                            <ApButton
                                variant="tertiary"
                                onClick={close as any}
                                label={translate({ id: 'CLIENT_CANCEL' })}
                                data-cy="invite-edit-cancel-button" />
                            <ApButton
                                variant="primary"
                                label={type === 'invite' ? translate({ id: 'CLIENT_INVITE_BUTTON' }) : translate({ id: 'CLIENT_SAVE' })}
                                onClick={handleSubmit(onSubmit)}
                                loading={loading}
                                disabled={
                                    !isDirty ||
                                    !isValid ||
                                    !!errors.email ||
                                    (Array.isArray(watch('email')) && watch('email').length === 0)
                                }
                                data-cy="invite-edit-submit-button" />
                        </UiStack>
                    }
                    isDrawer
                >
                    <UiStack
                        direction="column"
                        mt={SpacingToken.L}
                        gap={SpacingToken.M}>

                        {type === 'edit' && (
                            <Controller
                                name="userName"
                                control={control}
                                rules={{
                                    required: true,
                                    validate: { valid: value => validateUsername(value) },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        required
                                        label={translate({ id: 'CLIENT_DISPLAY_NAME' })}
                                        variant="outlined"
                                        fullWidth
                                        InputProps={{ className: 'Tall' }}
                                        error={!!errors.userName}
                                        helperText={
                                            (errors.userName?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })) ||
                                            (errors.userName?.type === 'valid' && translate({ id: 'CLIENT_INVALID_USERNAME_ERROR' }))
                                        }
                                        data-cy="invite-edit-username"
                                    />
                                )}
                            />
                        )}

                        {type === 'invite' && (
                            <ApChipInput
                                required
                                limit={10}
                                data-cy="invite-chip-input"
                                label={translate({ id: 'CLIENT_EMAIL' })}
                                placeholder={translate({ id: 'CLIENT_INVITE_USERS_EMAIL_PLACE_HOLDER' })}
                                keyboardInsertKeys={[ 'Enter', 'Tab', ' ', ',', ';' ]}
                                onSelectedItemsChanged={ev => {
                                    const bad = ev.detail.filter(t => t.state === 'invalid');
                                    if (ev.detail.length > 10) {
                                        setError('email', { type: 'invalid' });
                                    } else if (bad.length > 0) {
                                        setError('email', {
                                            type: 'invalid',
                                            message: [ ...new Set(bad.map(t => t.errorMessage)) ].join('\n'),
                                        });
                                    } else {
                                        clearErrors('email');
                                        setValue('email', ev.detail.map(t => t.id), {
                                            shouldValidate: true,
                                            shouldDirty: true,
                                        });
                                    }
                                }}
                                errorMessage={errors.email?.message}
                                validateSingleItemAsync={async inputStr => {
                                    if (!validateEmail(inputStr)) {
                                        return {
                                            id: inputStr,
                                            title: inputStr,
                                            state: 'invalid',
                                            errorMessage: translate({ id: 'CLIENT_MAIL_ID_VALIDATION' }),
                                        };
                                    }
                                    const emailExists = await checkEmailAlreadyExists(inputStr);
                                    return {
                                        id: inputStr,
                                        title: inputStr,
                                        state: emailExists ? 'invalid' : 'valid',
                                        errorMessage: emailExists ? translate({ id: 'CLIENT_EXISTING_MAIL_ID' }) : undefined,
                                    };
                                }}
                            />
                        )}

                        {type === 'edit' && (
                            <Controller
                                control={control}
                                name="email"
                                rules={{ required: true }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        required
                                        label={translate({ id: 'CLIENT_EMAIL' })}
                                        variant="outlined"
                                        error={!!errors.email}
                                        helperText={errors.email?.type === 'required' && translate({ id: 'CLIENT_REQUIRED_FIELD_ERROR' })}
                                        fullWidth
                                        InputProps={{ className: 'Tall' }}
                                        disabled={type === 'edit' && !!user}
                                    />
                                )}
                            />
                        )}

                        <FormProvider {...methods}>
                            <EditGroupMembershipFormComponent groups={groups} />
                        </FormProvider>
                    </UiStack>
                </UiForm>
            )}
        </UiDrawer>
    );
};

export const InviteEditUserComponentWithParams: React.FC = () => {
    const { type } = useParams<{ type: 'invite' | 'edit' }>();

    return <InviteEditUserComponent type={type ?? 'invite'} />;
};

export default InviteEditUserComponent;
