import { routePaths } from '../../../common/constants/routePaths';

export default function buildTitleMap(hostMode: boolean) {
    const includesPathTitle = new Map([
        // Common routes
        [ 'existingaccount', 'CLIENT_EXISTING_ACCOUNT_TITLE' ],
        [ 'missingaccount', 'CLIENT_MISSING_ACCOUNT_TITLE' ],
        [ 'completeSignup', 'CLIENT_SIGNUP_TITLE' ],
        [ 'completeInvite', 'CLIENT_SIGNUP_TITLE' ],
        [ 'acceptInvite', 'CLIENT_ACCEPT_INVITE_TITLE' ],
        [ 'emailverification', 'CLIENT_EMAIL_VERIFICATION_TITLE' ],
        [ 'signup', 'CLIENT_SIGNUP_TITLE' ],
        [ 'automationkit', 'CLIENT_AUTOMATION_KIT_TITLE' ],
        [ 'unregistered', 'CLIENT_UNREGISTERED_TITLE' ],
        [ 'genericerror', 'CLIENT_GENERIC_ERROR_TITLE' ],
        [ 'home', 'CLIENT_HOME_TITLE' ],
        [ 'notification', 'CLIENT_NOTIFICATION_TITLE' ],
        [ 'notification-settings', 'CLIENT_NOTIFICATION_SETTING_TITLE' ],
        [ 'profile', 'CLIENT_PREFERENCES_TITLE' ],
        [ 'personalAccessToken', 'CLIENT_PERSONAL_ACCESS_TOKEN_TITLE' ],
        [ 'themeSettings', 'CLIENT_THEME_SETTINGS_TITLE' ],
        [ 'accessibilitySettings', 'CLIENT_ACCESSIBILITY_SETTINGS_TITLE' ],
        [ 'navigationSettings', 'CLIENT_NAVIGATION_TITLE' ],
        [ 'privacyAndSecuritySettings', 'CLIENT_PRIVACY_AND_SECURITY_TITLE' ],

        // Old routes
        [ 'robots', 'CLIENT_ROBOTS_TITLE' ],
        [ 'buypro', 'CLIENT_ECOMMERCE_TITLE' ],
        [ 'service', 'CLIENT_SERVICE_INSTANCE_DETAILS_TITLE' ],
        [ 'serviceinstances', 'CLIENT_SERVICEINSTANCE_TITLE' ],
        [ 'users', 'CLIENT_USERS_TITLE' ],
        [ 'licensing', 'CLIENT_LICENSE_TITLE' ],
        [ 'resourceCenter', 'CLIENT_RESOURCE_TITLE' ],
        [ 'auditLog', 'CLIENT_AUDITLOG_TITLE' ],
        [ 'organizations', 'CLIENT_ORGANIZATION_TITLE' ],
        [ 'externalApps', 'CLIENT_EXTERNAL_APPLICATIONS_TITLE' ],
        [ 'groups', 'CLIENT_GROUPS_TITLE' ],
        [ 'authsettings', 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ 'azure', 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ 'authenticationsettings', 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ 'sessionpolicy', 'CLIENT_SESSION_POLICY_TITLE' ],
        [ 'iprestriction', 'CLIENT_IP_RESTRICTION_TITLE' ],
        [ 'manageAccess', 'CLIENT_MANAGE_ACCESS' ],
        [ 'encryption', 'CLIENT_ENCRYPTION_TITLE' ],
        [ 'accessrestriction', 'CLIENT_ACCESS_RESTRICTION_TITLE' ],

        // New routes
        // Global app routes
        [ 'resource-center', 'CLIENT_RESOURCE_TITLE' ],

        // Admin
        [ routePaths.adminIdentitiesUsers, 'CLIENT_USERS_TITLE' ],
        [ routePaths.adminIdentitiesRobots, 'CLIENT_ROBOTS_TITLE' ],
        [ routePaths.adminIdentitiesGroups, 'CLIENT_GROUPS_TITLE' ],

        [ routePaths.adminLicenses, 'CLIENT_LICENSE_TITLE' ],
        [ routePaths.adminLicensesBuyProPresets, 'CLIENT_ECOMMERCE_TITLE' ],

        [ routePaths.adminAudit, 'CLIENT_AUDITLOG_TITLE' ],

        [ routePaths.adminSecurity, 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ routePaths.adminSecurityAuthentication, 'CLIENT_AUTHENTICATION_SETTINGS_TITLE' ],
        [ routePaths.adminSecuritySessionPolicy, 'CLIENT_SESSION_POLICY_TITLE' ],
        [ routePaths.adminSecurityIpRestriction, 'CLIENT_IP_RESTRICTION_TITLE' ],
        [ routePaths.adminSecurityEncryption, 'CLIENT_ENCRYPTION_TITLE' ],
        [ routePaths.adminSecurityAccessRestriction, 'CLIENT_ACCESS_RESTRICTION_TITLE' ],

        [ routePaths.adminExternalApps, 'CLIENT_EXTERNAL_APPLICATIONS_TITLE' ],
        [ routePaths.adminExternalAppsOauth, 'CLIENT_EXTERNAL_APPLICATIONS_TITLE' ],
        [ routePaths.adminExternalAppsPat, 'CLIENT_PERSONAL_ACCESS_TOKEN_TITLE' ],
        [ routePaths.adminExternalAppsRegistrations, 'CLIENT_APP_REGISTRATIONS' ],

        [ routePaths.adminUserConsent, 'CLIENT_USER_CONSENT' ],

        [ routePaths.adminTenant, 'CLIENT_TENANT' ],
        [ routePaths.adminTenantServices, 'CLIENT_TENANTS' ],
        [ routePaths.adminTenantLicenses, 'CLIENT_LICENSE_TITLE' ],
        [ routePaths.adminTenantTags, 'CLIENT_TAGS' ],
        [ routePaths.adminTenantManageAccess, 'CLIENT_MANAGE_ACCESS' ],
        [ routePaths.adminTenantManageAccessCheckAccess, 'CLIENT_CHECK_ACCESS' ],
        [ routePaths.adminTenantGateway, 'CLIENT_GATEWAY' ],
        [ routePaths.adminTenantAudit, 'CLIENT_AUDITLOG_TITLE' ],
        [ routePaths.adminTenantSettings, 'CLIENT_TENANT_SETTINGS' ],

        [ routePaths.adminSettings, 'CLIENT_GLOBAL_SETTINGS_TITLE' ],

        // On-prem
        [ 'organizations', 'CLIENT_ORGANIZATION_TITLE' ],
    ]);

    const settingsTitle = hostMode ? 'CLIENT_GLOBAL_SETTINGS_TITLE' : 'CLIENT_ACCOUNT_SETTINGS_TITLE';

    includesPathTitle.set('emailsettings', settingsTitle);
    includesPathTitle.set('settings', settingsTitle);
    includesPathTitle.set(routePaths.adminEmailSettings, settingsTitle);

    return includesPathTitle;
}
