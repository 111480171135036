import { usePersistentState } from '@experiences/util';
import { makeStyles } from '@mui/styles';
import type { KeyboardEvent } from 'react';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import type { UiPageContainerProps } from './UiPageContainer';
import UiPageContainer from './UiPageContainer';

const MIN_DRAWER_WIDTH_VW = 20;
const MAX_DRAWER_WIDTH_VW = 35;

const keyboardIncrement = 0.5;

interface UiResizablePageContainerProps extends Omit<UiPageContainerProps, 'position'> {
    drawer: React.ReactNode;
    showDrawer: boolean;
}

const useStyles = makeStyles((theme) => ({
    divider: {
        cursor: 'ew-resize',
        width: '5px',
        padding: '4px 0 0',
        zIndex: 99,
        backgroundColor: theme.palette.semantic.colorBorderDeEmp,
        boxShadow: '1px',
        '&:hover, &:active': { backgroundColor: theme.palette.semantic.colorPrimaryHover },

    },
}));

export const UiResizablePageContainer: React.FC<UiResizablePageContainerProps> = ({
    children, drawer, showDrawer, ...rest
}) => {
    const classes = useStyles();

    const [ isResizingState, setIsResizingState ] = useState(false);
    const [ drawerWidth, setDrawerWidth, setPersistentDrawerWidth ] = usePersistentState(location.pathname, 0);

    const isResizingRef = useRef(isResizingState);

    const setIsResizing = (isResizing: boolean) => {
        isResizingRef.current = isResizing;
        setIsResizingState(isResizing);
    };

    const handleMouseDown = useCallback(() => {
        setIsResizing(true);
    }, []);

    const handleMouseUp = useCallback(() => {
        setIsResizing(false);
    }, []);

    const isValidWidthVw = useCallback((width: number) => width > MIN_DRAWER_WIDTH_VW && width < MAX_DRAWER_WIDTH_VW, []);

    const handleKeyDown = useCallback((e: KeyboardEvent) => {
        let width = drawerWidth;

        if (e.key === 'ArrowLeft') {
            width += keyboardIncrement;
        } else if (e.key === 'ArrowRight') {
            width -= keyboardIncrement;
        }

        if (isValidWidthVw(width)) {
            setPersistentDrawerWidth(width);
        }
    }, [ drawerWidth, isValidWidthVw, setPersistentDrawerWidth ]);

    const handleMouseMove = useCallback((e: MouseEvent) => {
        // do nothing on mouse move if not resizing
        if (!isResizingRef.current) {
            return;
        }

        e.preventDefault();

        const offsetRightPx =
          document.body.offsetWidth - (e.clientX - document.body.offsetLeft);

        const offsetRightVw = offsetRightPx * 100 / window.innerWidth;

        // only update the width if it is valid
        if (isValidWidthVw(offsetRightVw)) {
            setPersistentDrawerWidth(offsetRightVw);
        }
    }, [ isValidWidthVw, setPersistentDrawerWidth ]);

    useEffect(() => {
        showDrawer ? setPersistentDrawerWidth(MIN_DRAWER_WIDTH_VW, true) : setDrawerWidth(0);
    }, [ setDrawerWidth, setPersistentDrawerWidth, showDrawer ]);

    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };
    }, [ handleMouseMove, handleMouseUp ]);

    return (
        <>
            <UiPageContainer
                position='farleft'
                offsetWidth={drawerWidth ? `${drawerWidth}vw - 9px` : '0px'}
                maxWidth='2400px'
                {...rest} >
                {children}
            </UiPageContainer>
            <div
                id="dragger"
                role="button"
                tabIndex={0}
                onMouseDown={handleMouseDown}
                onKeyDown={handleKeyDown}
                className={classes.divider}
                style={{ display: drawerWidth === 0 ? 'none' : 'block' }}
            />
            <div
                style={{
                    width: `${drawerWidth}vw`,
                    display: drawerWidth === 0 ? 'none' : 'flex',
                    zIndex: 99,
                }}
            >
                {drawer}
            </div>
        </>
    );
};
