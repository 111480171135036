import type { IService } from '../../../common/interfaces/tenant/tenant';

export const listOfServices = (tenantServiceInstances: IService[] | undefined, servicesToHide: string[]) => {
    const serviceList = tenantServiceInstances;
    const tempServices = serviceList
        ?.filter(service => !servicesToHide.includes(service.serviceType))
        .filter(item => item.serviceType !== 'orchestrator');
    const orchestrator = serviceList?.find(item => item.serviceType === 'orchestrator');
    if (orchestrator) {
        tempServices?.unshift(orchestrator);
    }
    return tempServices;
};
