import { useLocalization } from '@experiences/locales';
import {
    UiSafeImg,
    UiText,
} from '@experiences/ui-common';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';

import { UiDrawer } from '../../common/UiDrawer';
import AppRegistrationTabs from './AppRegistrationTabs';
import useExternalApplicationRegistrationDetailsDrawerViewModel from './ExternalApplicationRegistrationDetailsDrawerViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        titleContainer: {
            display: 'flex',
            flexDirection: 'row',
        },
        titleIcon: {
            height: '72px',
            width: '72px',
            marginRight: '12px',
        },
        titleName: {
            fontSize: '16px',
            fontWeight: 600,
            lineHeight: '24px',
            marginBottom: '4px',
            color: theme.palette.semantic.colorForeground,
        },
        titlePublisher: {
            fontSize: '12px',
            fontWeight: 600,
            lineHeight: '16px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            marginBottom: '12px',
        },
        chip: {
            height: '16px',
            fontSize: '10px',
            fontWeight: 600,
            lineHeight: '16px',
            marginBottom: '8px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            '&:hover': {
                color: theme.palette.semantic.colorForegroundDeEmp,
                backgroundColor: theme.palette.semantic.colorBackgroundDisabled,
            },
        },
        sectionHeader: { borderBottom: `1px solid ${theme.palette.semantic.colorBorderDeEmp}` },
    })
);

const ExternalApplicationRegistrationDetailsDrawerComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const locale = useLocalization();

    const {
        loading,
        open,
        close,
        globalApp,
        type,
    } = useExternalApplicationRegistrationDetailsDrawerViewModel();

    const constructTitle = useMemo(() => (
        <Box className={classes.titleContainer}>
            <UiSafeImg
                className={classes.titleIcon}
                src={globalApp?.logoUri}
                alt={globalApp?.name}
            />
            <Box>
                <UiText className={classes.titleName}>
                    {globalApp?.name}
                </UiText>
                <UiText className={classes.titlePublisher}>
                    {translate({ id: 'CLIENT_BY' }, { user: globalApp?.publisherName ?? '' })}
                </UiText>
                {globalApp?.lastUpdatedTime && <Chip
                    className={classes.chip}
                    size="small"
                    clickable={false}
                    label={translate({
                        id: type === 'register'
                            ? 'CLIENT_LAST_UPDATED_ON'
                            : 'CLIENT_REGISTERED_ON',
                    },
                    {
                        date: new Date(type === 'register'
                            ? globalApp.lastUpdatedTime ?? ''
                            : globalApp.registrationTime ?? ''
                        )
                            .toLocaleDateString(locale, {
                                month: 'short',
                                year: 'numeric',
                                day: 'numeric',
                            }),
                    })}
                />}
            </Box>
        </Box>
    ), [
        classes.chip,
        classes.titleContainer,
        classes.titleIcon,
        classes.titleName,
        classes.titlePublisher,
        globalApp?.lastUpdatedTime,
        globalApp?.logoUri,
        globalApp?.name,
        globalApp?.publisherName,
        globalApp?.registrationTime,
        locale,
        translate,
        type,
    ]);

    return <UiDrawer
        loading={loading}
        customTitle={constructTitle}
        drawerProps={{
            anchor: 'right',
            open,
            onClose: () => close(),
        }}
        width='large'
        themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
    >
        <Box style={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
        }}>
            {type === 'update' && <Box style={{
                marginTop: '24px',
                marginBottom: '24px',
                marginRight: '24px',
            }}>
                <PortalAlertBar
                    status="error">
                    <UiText>
                        <b>
                            {`${translate({ id: 'CLIENT_IMPORTANT' })}: `}
                        </b>
                        {translate({ id: 'CLIENT_PUBLISHER_UPDATED_MESSAGE' })}
                    </UiText>
                </PortalAlertBar>
            </Box>}
            <Box style={{
                flexGrow: 1,
                flexShrink: 0,
            }}>
                <AppRegistrationTabs
                    globalApp={globalApp}
                    registered={type !== 'register'} />
            </Box>
        </Box>
    </UiDrawer>;
};

export default ExternalApplicationRegistrationDetailsDrawerComponent;
