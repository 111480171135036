import {
    getAutopilotPackage,
    getLatestAutopilotPackageUrl,
    getLatestAutopilotPackageVersion,
} from '../../../../services/a4e/A4EService';
import type {
    AutopilotSolution,
    SolutionDeployment,
    SolutionsGetDeploymentsRequest,
    SolutionsPackageResponse,
} from '../../../../services/a4e/A4EServiceTypes';
import { A4EError } from '../../../../services/a4e/A4EServiceTypes';
import {
    deploySolutionPackage,
    getDeployments,
    getDeploymentStatus,
    getPackage,
    getPackagePublishStatus,
    getPipelineDeploymentStatus,
    uninstallDeployment,
    uploadPackage,
} from '../../../../services/a4e/A4ESolutionService';

const AUTOPILOT_SOLUTION_PACKAGE_NAME = 'AutopilotForEveryone';
const AUTOPILOT_SOLUTION_PACKAGE_FOLDER_NAME = 'Autopilot';

const PACKAGE_READY_TIMEOUT = 2 * 60 * 1000; // 2 min

export type SolutionStatus = {
    deploymentState?: SolutionDeploymentState;
    latestAutopilotFeedVersion?: AutopilotSolution;
    deployedAutopilotVersion?: string;
    instanceId?: string;
    pipelineDeploymentId?: string;
};

export const enum SolutionDeploymentState {
    NONE = -1,
    NOT_INSTALLED = 0,
    INSTALLING = 1,
    INSTALLED = 2,
    UNINSTALLING = 3,
    UPDATE_AVAILABLE = 4,
    UPDATING = 5,
}

const enum PackageState {
    ACTIVE = 'Active',
    READY = 'Ready',
    PENDING = 'Pending',
}

const enum PipelineDeploymentStatus {
    DEPLOYMENT_IN_PROGRESS = 'DeploymentInProgress',
    DEPLOYMENT_SUCCEEDED = 'DeploymentSucceeded',
}

const enum DeploymentOperation {
    INSTALL = 'Install',
    UNINSTALL = 'Uninstall',
    VERSION_CHANGE = 'VersionChange',
}

const enum DeploymentOperationStatus {
    DRAFT = 'Draft',
    IN_PROGRESS = 'InProgress',
    SUCCESSFUL = 'Successful',
    SUCCESSFUL_UNINSTALL = 'SuccessfulUninstall',
}

/**
 * Gets the {@link SolutionStatus} of the Autopilot solution.
 * Checks whether:
 * 1. If there is a deployment status for the Autopilot solution
 * 2. If there is a status, checks the current state of the deployment
 * 3. If the deployment is installed, checks if there is an update available
 * If {@link getPackage} fails with error code 3001, it means the package is not installed.
 * @param tenantName The selected tenant
 * @returns The current status with state and package information
 * @throws {A4EError}
 */
export const getAutopilotSolutionStatus = async (tenantName: string): Promise<SolutionStatus> => {
    try {
        return await getStatusFromDeployments(tenantName);
    } catch (error) {
        const getPackageError = error as A4EError;
        if (getPackageError.errorsCodes.includes('3001')) {
            return { deploymentState: SolutionDeploymentState.NOT_INSTALLED };
        }
        throw error;
    }
};

const getStatusFromDeployments = async (tenantName: string): Promise<SolutionStatus> => {
    const request: SolutionsGetDeploymentsRequest = {
        tenantName,
        orderByColumn: 'startTime',
        orderByDirection: 'Descending',
        packageName: AUTOPILOT_SOLUTION_PACKAGE_NAME,
        take: 10,
    };
    const response = await getDeployments(request);
    if (response.count === 0) {
        return { deploymentState: SolutionDeploymentState.NOT_INSTALLED };
    }

    const deployment = response.values[0];
    let state = SolutionDeploymentState.NOT_INSTALLED;
    if (deployment.operation === DeploymentOperation.INSTALL) {
        state = deployment.operationStatus === DeploymentOperationStatus.SUCCESSFUL ? SolutionDeploymentState.INSTALLED : SolutionDeploymentState.INSTALLING;
    } else if (deployment.operation === DeploymentOperation.VERSION_CHANGE) {
        switch (deployment.operationStatus) {
            case DeploymentOperationStatus.DRAFT:
                state = SolutionDeploymentState.NOT_INSTALLED;
                break;
            case DeploymentOperationStatus.SUCCESSFUL:
                state = SolutionDeploymentState.INSTALLED;
                break;
            default:
                state = SolutionDeploymentState.UPDATING;
        }
    } else if (deployment.operation === DeploymentOperation.UNINSTALL) {
        const isUninstalled = deployment.operationStatus === DeploymentOperationStatus.SUCCESSFUL || deployment.operationStatus === DeploymentOperationStatus.SUCCESSFUL_UNINSTALL;
        state = isUninstalled ? SolutionDeploymentState.NOT_INSTALLED : SolutionDeploymentState.UNINSTALLING;
    }

    const latestAutopilotFeedVersion = await getLatestAutopilotPackageVersion(tenantName);
    const currentSolutionStatus = {
        deploymentState: state,
        latestAutopilotFeedVersion,
        deployedAutopilotVersion: deployment.packageVersion,
        instanceId: deployment.instanceId,
    };

    // Only check for updates if we are installed
    if (state === SolutionDeploymentState.INSTALLED) {
        return await getSolutionStatusWithCurrentPackages(tenantName, latestAutopilotFeedVersion, deployment, currentSolutionStatus);
    }

    return currentSolutionStatus;
};

const getSolutionStatusWithCurrentPackages = async (
    tenantName: string,
    latestAutopilotFeedVersion: AutopilotSolution, deployment: SolutionDeployment,
    currentSolutionStatus: SolutionStatus
): Promise<SolutionStatus> => {
    const latestSolutionPackage = await getPackage({
        tenantName,
        packageName: AUTOPILOT_SOLUTION_PACKAGE_NAME,
        packageVersion: '',
    });

    const isUpdateAvailable =
    comparePackageVersions(latestAutopilotFeedVersion.version, latestSolutionPackage.packageVersion) > 0 ||
        comparePackageVersions(latestSolutionPackage.packageVersion, deployment.packageVersion) > 0;
    const deploymentState = isUpdateAvailable ? SolutionDeploymentState.UPDATE_AVAILABLE : currentSolutionStatus.deploymentState;
    return {
        ...currentSolutionStatus,
        deploymentState,
    };
};

/**
 * Compare two package versions, ex. 1.0.0 and 1.0.1
 * @param version1 Ex. 1.0.0
 * @param version2 Ex. 1.0.1
 * @returns {number} Returns -1 if version1 is less than version2, 0 if they are equal, and 1 if version1 is greater than version2
 */
export const comparePackageVersions = (version1: string, version2: string): number => {
    const segments1 = version1.split('.').map(Number);
    const segments2 = version2.split('.').map(Number);
    const maxLength = Math.max(segments1.length, segments2.length);

    for (let i = 0; i < maxLength; i++) {
        const segment1 = segments1[i] || 0;
        const segment2 = segments2[i] || 0;

        if (segment1 > segment2) {
            return 1;
        }
        if (segment1 < segment2) {
            return -1;
        }
    }

    return 0;
};

/**
 * Gets the pipeline deployment status of the Autopilot solution. The pipeline deployment includes validation as well
 * as the actual deployment.
 * @param tenantName The selected tenant
 * @param pipelineDeploymentId The deployment id returned from the {@link deployAutopilotSolution} function
 * @returns {Promise<SolutionDeploymentState>} Returns the if the deployment is installing, updating or installed
 * @throws {A4EError}
 */
export const getAutopilotPipelineDeploymentStatus = async (tenantName: string, pipelineDeploymentId: string): Promise<SolutionDeploymentState> => {
    const response = await getPipelineDeploymentStatus({
        tenantName,
        pipelineDeploymentId,
    });
    if (response.status === PipelineDeploymentStatus.DEPLOYMENT_SUCCEEDED) {
        return SolutionDeploymentState.INSTALLED;
    }
    return response.deploymentResult.operation === DeploymentOperation.VERSION_CHANGE ? SolutionDeploymentState.UPDATING : SolutionDeploymentState.INSTALLING;
};

/**
 * Gets the deployment status of the Autopilot solution.
 * @param tenantName The selected tenant
 * @param instanceId The instance id of the deployment
 * @returns {Promise<SolutionDeploymentState>} Returns the if the deployment is installing, updating, installed, uninstalling or not installed
 * @throws {A4EError}
 */
export const getAutopilotDeploymentStatus = async (tenantName: string, instanceId: string): Promise<SolutionDeploymentState> => {
    const response = await getDeploymentStatus({
        tenantName,
        instanceId,
    });
    if (response.operation === DeploymentOperation.INSTALL) {
        return response.status === DeploymentOperationStatus.SUCCESSFUL ? SolutionDeploymentState.INSTALLED : SolutionDeploymentState.INSTALLING;
    } else if (response.operation === DeploymentOperation.VERSION_CHANGE) {
        return response.status === DeploymentOperationStatus.SUCCESSFUL ? SolutionDeploymentState.INSTALLED : SolutionDeploymentState.UPDATING;
    }
    return response.status === DeploymentOperationStatus.SUCCESSFUL_UNINSTALL ? SolutionDeploymentState.NOT_INSTALLED : SolutionDeploymentState.UNINSTALLING;
};

/**
 * Deploys the Autopilot solution by uploading the package, waiting for the package to be ready, and then deploying the solution.
 * If the package already exists, it will deploy the existing package.
 * @param tenantName The selected tenant
 * @returns {Promise<SolutionStatus>} Returns the solution status with the latest package andn pipeline deployment id
 * @throws {A4EError}
 */
export const deployAutopilot = async (tenantName: string): Promise<SolutionStatus> => {
    const latestAutopilotSolutionVersion = await getLatestAutopilotPackageVersion(tenantName);

    try {
        let latestSolutionPackage: SolutionsPackageResponse | undefined;
        try {
            latestSolutionPackage = await getPackage({
                tenantName,
                packageName: AUTOPILOT_SOLUTION_PACKAGE_NAME,
                packageVersion: '',
            });
        } catch (error) {
            const getPackageError = error as A4EError;
            // Check if package does not exist
            if (!getPackageError.errorsCodes.includes('3001')) {
                throw error;
            }
        }

        // Check if the latest Autopilot solution package is newer than the current uploaded solution package and upload
        if (!latestSolutionPackage || comparePackageVersions(latestAutopilotSolutionVersion.version, latestSolutionPackage.packageVersion) > 0) {
            const autopilotPackageUrl = await getLatestAutopilotPackageUrl(tenantName, latestAutopilotSolutionVersion.name);
            const autopilotPackageFile = await getAutopilotPackage(autopilotPackageUrl);
            const packageResponse = await uploadPackage(tenantName, autopilotPackageFile.data);
            await waitForPackageToBeReady(tenantName, packageResponse);
            return await deployAutopilotSolution(tenantName, latestAutopilotSolutionVersion, packageResponse);
        }
    } catch (error) {
        const uploadPackageError = error as A4EError;
        // Check if package already exists
        if (!uploadPackageError.errorsCodes.includes('3005')) {
            throw error;
        }
    }

    const packageResponse = await getPackage({
        tenantName,
        packageName: AUTOPILOT_SOLUTION_PACKAGE_NAME,
        packageVersion: '',
    });
    return await deployAutopilotSolution(tenantName, latestAutopilotSolutionVersion, packageResponse);
};

const waitForPackageToBeReady = async (tenantName: string, solutionPackage: SolutionsPackageResponse): Promise<void> => {
    const startTime = Date.now();
    const request = {
        tenantName,
        packageName: solutionPackage.packageName,
        packageVersion: solutionPackage.packageVersion,
    };
    while (Date.now() - startTime < PACKAGE_READY_TIMEOUT) {
        const response = await getPackagePublishStatus(request);
        if (response.status !== PackageState.PENDING) {
            return;
        }
        await new Promise(resolve => setTimeout(resolve, 1000));
    }

    // We timed out waiting for the package to be ready
    throw new A4EError('Timed out waiting for package', 500, [ '500' ]);
};

const deployAutopilotSolution = async (tenantName: string, autopilotSolution: AutopilotSolution, packageResponse: SolutionsPackageResponse): Promise<SolutionStatus> => {
    const deploySolutionResponse = await deploySolutionPackage({
        tenantName,
        deploymentName: AUTOPILOT_SOLUTION_PACKAGE_NAME,
        packageName: packageResponse.packageName,
        packageVersion: packageResponse.packageVersion,
        solutionFolderName: AUTOPILOT_SOLUTION_PACKAGE_FOLDER_NAME,
    });
    return {
        latestAutopilotFeedVersion: autopilotSolution,
        pipelineDeploymentId: deploySolutionResponse.pipelineDeploymentId,
    };
};

/**
 * Uninstalls the Autopilot solution.
 * @param tenantName The selected tenant
 * @returns {Promise<string>} Returns the instance id
 * @throws {A4EError}
 */
export const uninstallAutopilotDeployment = async (tenantName: string): Promise<string> => {
    const response = await uninstallDeployment({
        tenantName,
        deploymentName: AUTOPILOT_SOLUTION_PACKAGE_NAME,
    });
    return response.instanceId;
};
