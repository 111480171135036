import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    UiText,
    useUiDataContext,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import type { SxProps } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import { produce } from 'immer';
import isString from 'lodash/isString';
import type { ReactElement } from 'react';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../common/constants/RouteNames';
import type { ITenant } from '../../common/interfaces/tenant/tenant';
import {
    deleteVpnGateway,
    redeployVpnGateway,
} from '../../services/hypervisor';
import type { IVpnGatewayContext } from './interfaces/gateway';
import VpnGatewayDeleteDialogBody from './VpnGatewayDeleteDialogBody';
import useVpnGatewayViewModel from './VpnGatewayViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        cardContent: {
            padding: '24px 24px 16px 24px',
            '&:last-child': { paddingBottom: '36px' },
        },
        titleSection: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: '8px 0 12px 0',
        },
        titleText: {
            marginLeft: '12px',
            fontWeight: 600,
            fontSize: '16px',
            color: theme.palette.semantic.colorForeground,
        },
        titleHeader: {
            display: 'block',
            marginLeft: '10px',
        },
        descriptionText: {
            fontWeight: 400,
            fontSize: '12px',
            textTransform: 'capitalize',
        },
        cardAction: {
            position: 'absolute',
            bottom: '5px',
            right: '0px',
        },
        chip: {
            textAlign: 'center',
            textDecoration: 'none',
            margin: '9px -2px 0px 8px',
            fontSize: '11px',
            fontWeight: 600,
        },
        provisioningStatus: {
            backgroundColor: Tokens.Colors.ColorYellow200,
            color: Tokens.Colors.ColorWarningTextLight,
            borderColor: Tokens.Colors.ColorWarningIconLight,
            border: '2px solid',
        },
        failedStatus: {
            backgroundColor: Tokens.Colors.ColorErrorBackgroundLight,
            color: Tokens.Colors.ColorErrorTextLight,
            borderColor: Tokens.Colors.ColorErrorIconLight,
            border: '2px solid',
        },
        deployedStatus: {
            backgroundColor: Tokens.Colors.ColorSuccessBackgroundLight,
            color: Tokens.Colors.ColorSuccessTextLight,
            borderColor: Tokens.Colors.ColorSuccessIconLight,
            border: '2px solid',
        },
        refreshButton: {
            display: 'inline-block',
            margin: '3px 0px -4px -7px',
            lineHeight: '18px',
            paddingTop: '8px',
            '&:hover': {
                backgroundColor: 'transparent !important',
                color: Tokens.Colors.ColorIconDefaultLight,
            },
            fontSize: '13px',
        },
        statusSection: { display: 'flex' },
        showMoreButton: {
            width: '36px',
            height: '36px',
            padding: '8px',
            left: '16px',
            color: theme.palette.semantic.colorIconDefault,
            float: 'right',
            top: '7px',
        },
        addConnection: {
            top: '7px',
            right: '15px',
        },
        loader: {
            margin: '100px auto',
            display: 'flex',
        },
    }),
);

export const VpnGatewayCard: React.FC<{
    titleIcon: ReactElement<any, any>;
    dataCy: string;
    sx?: SxProps;
    style?: React.CSSProperties;
    tenant: ITenant;
    accountName: string;
    gwKey: string;
    disableAdd: boolean;
}> = ({
    tenant, accountName, gwKey, sx, style, dataCy, titleIcon, disableAdd,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ anchorEl, setAnchorEl ] = useState<null | HTMLElement>(null);
    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const {
        gatewayData, gatewayLoading, mutateGateway,
    } = useVpnGatewayViewModel();
    const gatewayState = gatewayData?.State.toLowerCase();
    const {
        data: contextData, setData,
    } = useUiDataContext<IVpnGatewayContext>();

    const createDialog = useShowDialog();

    const handleRefreshButton = useCallback(async () => {
        try {
            mutateGateway();
        } catch (error) {
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
        }
    }, [ mutateGateway, setErrorMessage, getErrorMessage, getErrorObject ]);

    const handleRetryButton = useCallback(async () => {
        await redeployVpnGateway(accountName, tenant.id, gwKey);
        setData(produce(contextData, (draftState) => {
            draftState.refresh = true;
        }));
    }, [ setData, contextData, accountName, tenant.id, gwKey ]);

    const deploymentStatusConstants = {
        deployed: 'deployed',
        provisioning: 'provisioning',
        failed: 'failed',
        deploying: 'deploying',
    };

    const openDeleteDialog = useCallback(
        async () => {
            await createDialog({
                title: translate({ id: 'CLIENT_DELETE_SERVICE' }, { 0: gatewayData?.Name }),
                customDialogContent: VpnGatewayDeleteDialogBody,
                customDialogContentProps: {
                    gateway: gatewayData,
                    tenant,
                    accountName,
                    deleteFunction: deleteVpnGateway,
                    isConnection: false,
                },
                icon: 'error',
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                showCancel: true,
            });
            setData(produce(contextData, (draftState) => {
                draftState.refresh = true;
                return;
            }));
        }, [ createDialog, translate, contextData, setData, gatewayData, accountName, tenant ]);

    const moreActions = useMemo(() => [
        {
            label: translate({ id: 'CLIENT_CONNECTIONS' }),
            click: () => navigate(getRoute(`${RouteNames.VpnGatewayConnections}`).replace(':tenantId', tenant.id)
                .replace(':vpnGatewayKey', gwKey),
            {
                state: {
                    tenantName: tenant.name,
                    gatewayName: gatewayData?.Name ?? '',
                    gatewayType: gatewayData?.GatewayType ?? '',
                },
            }),
            dataCy: 'connections',
            ariaLabel: translate({ id: 'CLIENT_CONNECTIONS' }),
            disabled: gatewayData?.State ? gatewayState === deploymentStatusConstants.provisioning : true,
        },
        {
            label: translate({ id: 'CLIENT_EDIT' }),
            click: () => navigate(getRoute(`${RouteNames.VpnGateway}/edit`).replace(':tenantId', tenant.id)),
            dataCy: 'enable-service',
            ariaLabel: translate({ id: 'CLIENT_EDIT' }),
            disabled: gatewayData?.State ? (gatewayState === deploymentStatusConstants.provisioning
                || gatewayState === deploymentStatusConstants.deploying) : true,
        },
        {
            label: translate({ id: 'CLIENT_DELETE' }),
            click: openDeleteDialog,
            dataCy: 'disable-service',
            ariaLabel: translate({ id: 'CLIENT_DELETE' }),
            disabled: gatewayData?.State ? (gatewayState === deploymentStatusConstants.provisioning
                || gatewayState === deploymentStatusConstants.deploying) : true,
        },
    ], [
        translate,
        gatewayData?.State,
        gatewayData?.Name,
        gatewayData?.GatewayType,
        gatewayState,
        deploymentStatusConstants.provisioning,
        deploymentStatusConstants.deploying,
        openDeleteDialog,
        navigate,
        getRoute,
        tenant.id,
        tenant.name,
        gwKey,
    ]);

    const chipStyleClass = (status: string) => {
        switch (status?.toLowerCase()) {
            case deploymentStatusConstants.deployed:
                return classes.deployedStatus;
            case deploymentStatusConstants.failed:
                return classes.failedStatus;
            case deploymentStatusConstants.provisioning:
            case deploymentStatusConstants.deploying:
                return classes.provisioningStatus;
        }
    };

    const chipStatusString = (status: string) => {
        switch (status?.toLowerCase()) {
            case deploymentStatusConstants.deployed:
                return 'CLIENT_DEPLOYED';
            case deploymentStatusConstants.failed:
                return 'CLIENT_FAILED';
            case deploymentStatusConstants.provisioning:
                return 'CLIENT_PROVISIONING';
            case deploymentStatusConstants.deploying:
                return 'CLIENT_DEPLOYING';
        }
    };

    if (gatewayLoading || !gatewayData) {
        return <CircularProgress
            data-cy='loader'
            className={classes.loader} />;
    }

    return (
        <Card
            sx={sx}
            style={style}
            data-cy={dataCy}
            aria-label="vpn-gateway-card">
            <CardContent classes={{ root: classes.cardContent }}>
                <div className={classes.titleSection}>
                    {titleIcon}
                    <div className={classes.titleHeader}>
                        <div>
                            <UiText
                                className={classes.titleText}>
                                {gatewayData.Name}
                            </UiText>
                        </div>
                        <div className={classes.statusSection}>
                            <Chip
                                className={clsx(classes.chip, chipStyleClass(gatewayData.State))}
                                label={translate({ id: chipStatusString(gatewayData.State) })}
                                size="small"
                            />
                            { gatewayData.State.toLowerCase() === deploymentStatusConstants.failed ? <Button
                                className={classes.refreshButton}
                                size='small'
                                variant="text"
                                onClick={handleRetryButton}
                                data-cy="ui-grid-retry-button"
                                aria-label='retry-button'
                            >
                                {translate({ id: 'CLIENT_RETRY' })}
                            </Button> : <Button
                                className={classes.refreshButton}
                                size='small'
                                variant="text"
                                startIcon={<RefreshIcon />}
                                disabled={gatewayData.State.toLowerCase() !== deploymentStatusConstants.deployed}
                                onClick={handleRefreshButton}
                                data-cy="ui-grid-refresh-button"
                                aria-label='refresh-button'
                            />}
                        </div>
                    </div>
                </div>
                <UiText data-cy="gateway-card-type-text">
                    <span className={classes.descriptionText}>
                        {translate({ id: 'CLIENT_GATEWAY_TYPE' })}
                        { gatewayData.GatewayType ? ': ' + gatewayData.GatewayType : '' }
                    </span>
                </UiText>
                <UiText data-cy="gateway-card-public-ip-text">
                    <span className={classes.descriptionText}>
                        {translate({ id: 'CLIENT_VPN_GATEWAY_PUBLIC_IP_LABEL' })}
                        { gatewayData.PublicIp ? ' ' + gatewayData.PublicIp : '' }
                    </span>
                </UiText>
                <UiText data-cy="gateway-card-region-text">
                    <span className={classes.descriptionText}>
                        {translate({ id: 'CLIENT_VPN_GATEWAY_REGION_LABEL' })}
                        {' ' + gatewayData.Location}
                    </span>
                </UiText>
                <UiText data-cy="gateway-card-connections-count-text">
                    <span className={classes.descriptionText}>
                        {translate({ id: 'CLIENT_VPN_GATEWAY_CONNECTIONS_LABEL' })}
                        { gatewayData.Connections ? ' ' + gatewayData.Connections.length : ' ' + 0 }
                    </span>
                </UiText>
                <UiText data-cy="gateway-card-vnet-text">
                    <span className={classes.descriptionText}>
                        {translate({ id: 'CLIENT_VPN_GATEWAY_VNET_LABEL' })}
                        {' ' + gatewayData.VnetAddressSpace}
                    </span>
                </UiText>
                { gatewayData && gatewayData.State.toLowerCase() === deploymentStatusConstants.deployed ?
                    <Button
                        className={classes.addConnection + ' hidden'}
                        variant='text'
                        startIcon={<AddIcon />}
                        data-cy='vpn-gateway-add-connections-button'
                        onClick={() => navigate(getRoute(`${RouteNames.VpnGatewayConnections}/add`).replace(':tenantId', tenant.id)
                            .replace(':vpnGatewayKey', gwKey),
                        {
                            state: {
                                tenantName: tenant.name,
                                gatewayName: gatewayData ? gatewayData.Name : '',
                                gatewayType: gatewayData ? gatewayData.GatewayType : '',
                            },
                        })}
                        disabled={disableAdd}
                    >
                        {translate({ id: 'CLIENT_ADD_CONNECTION' })}
                    </Button> : null }
                <Tooltip title={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}>
                    <IconButton
                        aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                        className={classes.showMoreButton}
                        onClick={event => setAnchorEl(event.currentTarget)}
                        data-cy="ui-grid-show-more-actions-button"
                    >
                        <MoreVertIcon />
                    </IconButton>
                </Tooltip>
                <Menu
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    data-cy="ui-grid-show-more-actions-menu"
                >
                    {moreActions.map((action, i) =>
                        (
                            <MenuItem
                                key={i}
                                disabled={action.disabled}
                                onClick={action.click}
                                data-cy='menu-item'
                                aria-labelledby={action.ariaLabel}
                                role="button"
                                tabIndex={i}
                            >
                                <ListItemText primary={action.label} />
                            </MenuItem>
                        ),
                    )}
                </Menu>
            </CardContent>
        </Card>
    );
};
