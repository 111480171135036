import { useUiDataContext } from '@experiences/ui-common';
import type { ListRange } from '@uipath/apollo-angular-elements';
import {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useParams } from 'react-router';
import useSWR from 'swr';

import {
    getAuditEvents,
    getAuditSources,
    organizationAuditUrl,
    tenantAuditUrl,
} from '../../../services/audit/AuditService';
import type {
    AuditEventQueryResultDto,
    IAuditDetailsContext,
} from '../interfaces/auditLog';
import { useAuditGridFilterModel } from './AuditGridFilterModel';
import { getNextEvents } from './AuditGridUtils';

export function useAuditGridViewModel() {
    const { tenantId } = useParams() as { tenantId: string };

    const filterModel = useAuditGridFilterModel();

    const {
        isClearing, sourceFilter, categoryFilter, actionFilter, timeFilter,
    } = filterModel;

    const { data: { virtualScrollManager } } = useUiDataContext<IAuditDetailsContext>();

    const [ events, setAuditEvents ] = useState<any>([]);

    const setInitialAuditRowEvents = (results: AuditEventQueryResultDto) => {
        if (!results.auditEvents) {
            return;
        }

        setAuditEvents(virtualScrollManager?.getInfiniteDataList(results.auditEvents, 0, results.next == null));

        return results;
    };

    const {
        data, isLoading: eventsLoading, isValidating: validating,
    } = useSWR(virtualScrollManager && !isClearing ? {
        url: tenantId ? `/${tenantId}/${tenantAuditUrl}/query/events` : `${organizationAuditUrl}/query/events`,
        sourceFilter: sourceFilter.debouncedValue,
        categoryFilter: categoryFilter.debouncedValue,
        actionFilter: actionFilter.debouncedValue,
        timeFilter: timeFilter.value,
    } : null, (args) => getAuditEvents(args).then(setInitialAuditRowEvents));

    const {
        data: sources, isLoading: sourcesLoading,
    } = useSWR({ url: tenantId ? `/${tenantId}/${tenantAuditUrl}/query/sources` : `${organizationAuditUrl}/query/sources` }, getAuditSources);

    const onRangeLoaded = useCallback(async (range: ListRange) => {
        if (range.start === 0) {
            return;
        }

        const nextEvents = await getNextEvents(range.start, data?.next);

        setAuditEvents(virtualScrollManager?.getInfiniteDataList(nextEvents?.auditEvents ?? [], range.start, nextEvents == null));
    }, [ data?.next, virtualScrollManager ]);

    useEffect(() => {
        virtualScrollManager?.scrollTo(0);

        virtualScrollManager?.rangeLoad({
            start: 0,
            end: 0,
        });
    }, [ virtualScrollManager ]);

    useEffect(() => {
        if (!virtualScrollManager || !data) {
            return;
        }

        // setup subscription for subsequent loads based on scroll position
        const subscription = virtualScrollManager.rangeLoad$.subscribe(onRangeLoaded);

        return () => subscription.unsubscribe();
    }, [ virtualScrollManager, onRangeLoaded, data ]);

    return {
        ...filterModel,
        events,
        sources,
        validating,
        loading: eventsLoading || sourcesLoading,
    };
}
