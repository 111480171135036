import type {
    IGlobalExternalClient,
    IScope,
} from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import type { FC } from 'react';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';

import { useGetTranslatedScopeDescription } from '../../../util/ScopeDescriptionUtil';
import { UiPanelWithActions } from '../../common/UiForm';
import useExternalApplicationRegistrationDetailsDrawerViewModel from './ExternalApplicationRegistrationDetailsDrawerViewModel';

const AppRegistrationTab = {
    Overview: 'overview',
    Details: 'details',
    UpdatedScopes: 'updatedScopes',
};

const useStyles = makeStyles(theme => createStyles({
    tabs: { marginBottom: '16px' },
    drawerContent: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        flex: 1,
    },
    detailsContainer: {},
    detailsDescription: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        marginBottom: '16px',
    },
    scopesBox: {
        padding: '8px',
        overflowY: 'auto',
    },
    accessScopes: {
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '20px',
        color: theme.palette.semantic.colorForegroundEmp,
        padding: '8px',
        paddingLeft: '16px',
    },
    warningColor: { color: theme.palette.semantic.colorErrorText },
    disclaimer: {
        fontSize: '12px',
        fontWeight: 400,
        lineHeight: '16px',
        color: theme.palette.semantic.colorForegroundDeEmp,
        marginBottom: '24px',
        marginTop: '12px',
    },
    actions: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
    },
    cancelButton: { marginRight: '10px' },
    updateButton: { marginLeft: '12px' },
    divider: { color: theme.palette.semantic.colorBorderDeEmp },
}));

const mapProductToName = (product: string) => {
    switch (product.toUpperCase()) {
        case 'OR':
            return 'Orchestrator API';
        case 'TM':
            return 'TestManager';
        case 'DataService':
            return 'Data Service API';
        case 'PM':
            return 'Platform Management API access';
        case 'DU':
            return 'Document understanding';
        case 'AIC':
            return 'AI Center API access';
        case 'ACADEMY':
            return 'Academy';
    }
};

const TabPanel: React.FC<{ value: string; globalApp?: IGlobalExternalClient; registered: boolean }> = ({
    value, globalApp, registered,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const [ isOpen, setIsOpen ] = useState<Record<string, boolean>>({});

    const handleClick = (product: string) => {
        setIsOpen(prev => ({
            ...prev,
            [product]: !prev[product],
        }));
    };

    const getTranslatedScopeDescription = useGetTranslatedScopeDescription();

    const collectAndCreateScopesView = useCallback((scopes: IScope[]) => {
        const productObject: Record<string, string[]> = {};
        scopes.forEach(scope => {
            const product = mapProductToName(scope.name.split('.')[0]) ?? '';
            if (!productObject[product]) {
                productObject[product] = [ getTranslatedScopeDescription(scope) ];
            } else {
                productObject[product].push(getTranslatedScopeDescription(scope));
            }
        });
        return Object.entries(productObject);
    }, [ getTranslatedScopeDescription ]);

    const descriptionText = useMemo(() => {
        if (value === AppRegistrationTab.UpdatedScopes) {
            return translate({ id: 'CLIENT_APP_DETAILS_DESCRIPTION_UPDATED' });
        }

        return translate({
            id: registered
                ? 'CLIENT_APP_DETAILS_DESCRIPTION_REGISTERED'
                : 'CLIENT_APP_DETAILS_DESCRIPTION_UNREGISTERED',
        });
    }, [ registered, translate, value ]);

    const scopesViewBuilder = useCallback((appScopes: IScope[]) => <Box className={classes.detailsContainer}>
        <UiText>
            {descriptionText}
        </UiText>
        <List
            className={classes.scopesBox}
            subheader={
                <ListSubheader
                    className={classes.accessScopes}
                    component="div"
                    id="access-scopes-subheader">
                    {translate({ id: 'CLIENT_ACCESS_SCOPES' })}
                </ListSubheader>
            }
        >
            {collectAndCreateScopesView(appScopes).map(([ product, scopes ]) => <>
                <ListItemButton
                    key={`app-scope-${product}`}
                    onClick={() => handleClick(product)}
                    data-cy={`app-scope-${product}`}
                >
                    <ListItemText primary={`${product} (${scopes.length})`} />
                    {isOpen[product] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                <Collapse
                    in={isOpen[product]}
                    timeout="auto"
                    unmountOnExit>
                    {scopes.map((scope, i) => <List
                        key={scope}
                        component="div"
                        disablePadding>
                        <ListItemButton sx={{ pl: 4 }}>
                            <ListItemText
                                primary={scope}
                                data-cy={`scope-description-${product}-${i}`}
                            />
                        </ListItemButton>
                    </List>)}
                </Collapse>
                <Divider className={classes.divider} />
            </>)}
        </List>
    </Box>, [ classes, collectAndCreateScopesView, descriptionText, isOpen, translate ]);

    const tabContent = useMemo(() => {
        switch (value) {
            case AppRegistrationTab.UpdatedScopes:
                return scopesViewBuilder(globalApp?.unRegisteredScopes ?? []);
            case AppRegistrationTab.Overview:
                return <UiText>
                    {globalApp?.description}
                </UiText>;
            case AppRegistrationTab.Details:
                return scopesViewBuilder(globalApp?.scopes ?? []);
        }
    }, [ globalApp, scopesViewBuilder, value ]);

    return (
        <Box className={classes.drawerContent}>
            {tabContent}
            <UiText className={classes.disclaimer}>
                <span className={classes.warningColor}>
                *
                </span>
                {translate({ id: 'CLIENT_GLOBAL_APP_DISCLAIMER' })}
            </UiText>
        </Box>
    );
};

const AppRegistrationTabs: FC<{ globalApp?: IGlobalExternalClient; registered: boolean }> = ({
    globalApp, registered,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        close,
        type,
        registerApplicationCallback,
        unregisterApplicationCallback,
        updateApplicationCallback,
    } = useExternalApplicationRegistrationDetailsDrawerViewModel();

    const [ tabValue, setTabValue ] = useState(
        type === 'update'
            ? AppRegistrationTab.UpdatedScopes
            : AppRegistrationTab.Overview
    );

    const handleChange = useCallback((_: any, newValue: string) => setTabValue(newValue), [ setTabValue ]);

    return (
        <UiPanelWithActions
            actions={
                <div className={classes.actions}>
                    <Button
                        data-cy="scopes-add-edit-cancel-button"
                        className={classes.cancelButton}
                        onClick={() => close()}>
                        {translate({ id: 'CLIENT_CLOSE' })}
                    </Button>
                    <Button
                        className={registered ? 'warning' : 'primary'}
                        variant={type !== 'update' ? 'contained' : 'outlined'}
                        onClick={() => !registered
                            ? registerApplicationCallback()
                            : unregisterApplicationCallback(globalApp?.name ?? '')}
                        data-cy="scopes-add-edit-submit-button"
                    >
                        {registered ? translate({ id: 'CLIENT_UNREGISTER' }) : translate({ id: 'CLIENT_REGISTER' })}
                    </Button>
                    {type === 'update' && <Button
                        className={classes.updateButton}
                        variant="contained"
                        onClick={() => updateApplicationCallback()}
                        data-cy="scopes-review-update-button">
                        {translate({ id: 'CLIENT_UPDATE' })}
                    </Button>}
                </div>
            }
            isDrawer
            addScrollPadding
        >
            <Tabs
                value={tabValue}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                className={clsx('default', classes.tabs)}>
                {type === 'update' && <Tab
                    value={AppRegistrationTab.UpdatedScopes}
                    label={translate({ id: 'CLIENT_UPDATED_SCOPES' })}
                    onClick={() => setTabValue(AppRegistrationTab.UpdatedScopes)}
                    data-cy="app-registration-updated-scopes"
                    className="default"
                />}
                <Tab
                    value={AppRegistrationTab.Overview}
                    label={translate({ id: 'CLIENT_OVERVIEW' })}
                    onClick={() => setTabValue(AppRegistrationTab.Overview)}
                    data-cy="app-registration-overview"
                    className="default"
                />
                <Tab
                    value={AppRegistrationTab.Details}
                    label={translate({ id: 'CLIENT_DETAILS' })}
                    onClick={() => setTabValue(AppRegistrationTab.Details)}
                    data-cy="app-registration-default"
                    className="default"
                />
            </Tabs>
            <TabPanel
                value={tabValue}
                globalApp={globalApp}
                registered={registered}
            />
        </UiPanelWithActions>
    );
};

export default AppRegistrationTabs;
