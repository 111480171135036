import {
    UiDialog,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { getConnections } from '../../../services/notification-preferences';
import { accountGlobalId } from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';
import type {
    IConnectionDetail,
    ISlackChannel,
    ISlackConnectionConfiguration,
} from '../interfaces/notificationSettings';
import { UiSlackChannelPicker } from './SlackConfiguration/UiSlackChannelPicker';

const useStyles = makeStyles((theme) => ({
    form: {
        marginTop: Tokens.Padding.PadXxxl,
        minWidth: '480px',
        minHeight: '200px',
    },
    inputLabel: {
        fontWeight: Tokens.FontFamily.FontBrandH4Weight,
        fontSize: Tokens.FontFamily.FontMSize,
        color: theme.palette.semantic.colorForegroundDeEmp,
        marginBottom: Tokens.Padding.PadXxl,
    },
    selectinput: { width: '100%' },
}));

export const SlackPublisherSettings: React.FC<{
    connectionId: string;
    defaultChannelName: string;
    defaultChannelId: string;
    publisherChannelName?: string;
    publisherChannelId?: string;
    publisherName: string;
    onChange: (value: ISlackChannel) => void;
    close: () => void;
}> = ({
    connectionId, defaultChannelId, defaultChannelName, publisherChannelId, publisherChannelName, publisherName, onChange, close,
}) => {

    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const [ selectSlackChannel, setSelectSlackChannel ] = React.useState<ISlackChannel | undefined>(
        publisherChannelId && publisherChannelName ? {
            id: publisherChannelId,
            name: publisherChannelName,
        } : {
            id: defaultChannelId,
            name: defaultChannelName,
        },
    );
    const { handleSubmit } = useForm<ISlackConnectionConfiguration>();
    const [ selectedSlackConnector ] = React.useState<string>(connectionId);
    const onSubmit = useCallback(() => {
        onChange?.(selectSlackChannel ?? {
            id: '',
            name: '',
        });
        close();
    }, [ close, onChange, selectSlackChannel ]);

    const accountId = useSelector(accountGlobalId);
    const { selectedTenant: { id: tenantId } } = useTenantsContext();
    const { data: connections } = useSWR(
        tenantId ? {
            selectedTenantId: tenantId,
            selectedAccontId: accountId,
        } : null,
        getConnections,
    );

    return (
        <UiDialog
            title={translate({ id: 'CLIENT_SLACK_CHANNEL_DIALOG_TITLE' }, { 0: publisherName })}
            close={() => close()}
            dialogProps={{
                open: true,
                maxWidth: 'xl',
            }}
            actions={[
                <Button
                    key="cancel"
                    data-cy="button-cancel"
                    onClick={close}
                    color="primary">
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>,
                <Button
                    key="configure"
                    data-cy="button-configure"
                    variant="contained"
                    onClick={handleSubmit(onSubmit)}
                    color="primary">
                    {translate({ id: 'CLIENT_CONFIRM' })}
                </Button>,
            ]}
        >
            <UiForm
                className={classes.form}
                onSubmit={handleSubmit(onSubmit)}
                id="slackpublisherConfigurationForm"
            >
                <UiText
                    className={classes.inputLabel}
                    id="senderLogoLabel">
                    {translate({ id: 'CLIENT_SLACK_CONNECTION_DIALOG_INPUT_LABEL' })}
                </UiText>
                <Select
                    value={selectedSlackConnector}
                    inputProps={{
                        id: 'currency',
                        name: 'currency',
                        style: {
                            height: '100%',
                            paddingTop: '0px',
                            paddingBottom: '0px',
                        },
                    }}
                    disabled
                    className={classes.selectinput}
                    variant='outlined'
                >
                    {connections?.connections.map((connection: IConnectionDetail, i: number) => (
                        <MenuItem
                            key={i}
                            value={connection.id}
                            aria-label={connection.connectorName}
                        >
                            {connection.name}
                        </MenuItem>
                    ))}
                </Select>

                <UiSlackChannelPicker
                    showTooltip={false}
                    labelText={translate({ id: 'CLIENT_SLACK_CHANNEL_DIALOG_INPUT_LABEL' })}
                    searchOnly
                    helpText={translate({ id: 'CLIENT_SLACK_CHANNEL_DIALOG_HELP_TEXT' })}
                    connectionId={selectedSlackConnector ?? ''}
                    value={selectSlackChannel ?? {
                        id: '',
                        name: '',
                    }}
                    onChange={(slackEntries: ISlackChannel[]) => {
                        if (slackEntries.length > 0 && slackEntries[0]) {
                            setSelectSlackChannel(slackEntries[0]);
                        } else {
                            setSelectSlackChannel(undefined);
                        }
                    }}
                />
            </UiForm>
        </UiDialog>
    );
};

