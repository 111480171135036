import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IExternalClient } from '@experiences/interfaces';
import {
    UiCopyButton,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { makeStyles } from '@mui/styles';
import React, {
    useCallback,
    useEffect,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import {
    deleteExternalClient,
    externalClientUrl,
    getExternalClients,
} from '../../services/identity/ExternalClientService';
import { accountGlobalId } from '../../store/selectors';
import { UiGrid } from '../common/UiGrid';
import type { IActionHeader } from '../common/UiGrid/grid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import {
    SpacingToken,
    UiStack,
} from '../common/UiStack';

const useStyles = makeStyles(theme => ({
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    container: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: theme.palette.semantic.colorBackgroundSecondary,
        paddingLeft: '8px',
        paddingRight: '8px',
        border: `1px solid ${theme.palette.semantic.colorBorderDeEmp}`,
        borderRadius: '6px',
    },
}));

const ExternalApplicationsPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const location = useLocation();

    const getRoute = useRouteResolver();
    const createDialog = useShowDialog();
    const createNotification = useUiSnackBar();
    const setErrorMessage = useCentralErrorSetter();

    const partitionGlobalId = useSelector(accountGlobalId);

    const { getErrorMessage } = useGetErrorInfo();

    const {
        data, isValidating, error, mutate,
    } = useSWR(
        {
            url: externalClientUrl,
            partitionGlobalId,
        },
        getExternalClients,
    );

    const handleExternalClient = useCallback(async (externalClient: IExternalClient) => {
        await createDialog({
            title: translate({ id: 'CLIENT_EXTERNAL_APP_CREATED_SUCCESSFULLY' }),
            body: (
                <>
                    <UiText>
                        {translate({
                            id: externalClient.secret
                                ? 'CLIENT_EXTERNAL_APP_CREATED_SUCCESSFULLY_DESCRIPTION'
                                : 'CLIENT_EXTERNAL_APP_CREATED_SUCCESSFULLY_DESCRIPTION_WITHOUT_SECRET',
                        })}
                    </UiText>
                    <UiStack
                        mt={SpacingToken.M}
                        gap={SpacingToken.XS}
                        direction="column">
                        <UiText>
                            {translate({ id: 'CLIENT_EXTERNAL_APP_APP_ID' })}
                        </UiText>
                        <div className={classes.container}>
                            <UiText className={classes.truncate}>
                                {externalClient.id}
                            </UiText>
                            <UiCopyButton textToCopy={externalClient.id as string} />
                        </div>
                    </UiStack>
                    {externalClient.secret && (
                        <UiStack
                            mt={SpacingToken.M}
                            gap={SpacingToken.XS}
                            direction="column">
                            <UiText>
                                {translate({ id: 'CLIENT_EXTERNAL_APP_APP_SECRET' })}
                            </UiText>
                            <div className={classes.container}>
                                <UiText className={classes.truncate}>
                                    {externalClient.secret}
                                </UiText>
                                <UiCopyButton textToCopy={externalClient.secret as string} />
                            </div>
                        </UiStack>
                    )}
                </>
            ),
            icon: 'success',
            primaryButtonText: translate({ id: 'CLIENT_CLOSE' }),
        });
        navigate(location.pathname, { replace: true });
    }, [ classes, createDialog, location.pathname, navigate, translate ]);

    useEffect(() => {
        if (location?.state?.externalClient) {
            const { externalClient } = location.state;
            handleExternalClient(externalClient);
        }
    }, [ createDialog, handleExternalClient, location.state, navigate, translate ]);

    const actions: IActionHeader[] = useMemo(() => [
        {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_ADD_APPLICATION' }),
            tooltip: translate({ id: 'CLIENT_ADD_APPLICATION' }),
            actionType: GridActionType.Main,
            icon: <AddIcon />,
            click: () => navigate(getRoute(`${RouteNames.ExternalApplications}/add`)),
            variant: 'contained',
            dataCy: 'ui-grid-add-application-button',
        },
    ], [ getRoute, navigate, translate ]);

    return (
        <div style={{ padding: '0px' }}>
            <UiText style={{ marginBottom: '12px' }}>
                {translate({ id: 'CLIENT_EXTERNAL_APP_DESCRIPTION' })}
            </UiText>
            <UiGrid<IExternalClient>
                data-cy="external-apps-ui-grid"
                loading={isValidating}
                search
                searchPlaceholder={translate({ id: 'CLIENT_SEARCH_USER_TEXT' })}
                pagination
                error={error}
                tableHeight='500px'
                extraActionButtons={actions}
                columns={[
                    {
                        accessor: 'name',
                        Header: translate({ id: 'CLIENT_EXTERNAL_APP_NAME' }),
                        sortType: 'alphanumeric',
                        width: 40,
                    },
                    {
                        accessor: 'isConfidential',
                        Header: translate({ id: 'CLIENT_EXTERNAL_APP_TYPE' }),
                        width: 40,
                        disableSortBy: true,
                        Cell: ({ row }) => row.original.isConfidential
                            ? <>
                                {translate({ id: 'CLIENT_EXTERNAL_APP_CONFIDENTIAL_APPLICATION' })}
                            </>
                            : <>
                                {translate({ id: 'CLIENT_EXTERNAL_APP_NON_CONFIDENTIAL_APPLICATION' })}
                            </>,
                    },
                    {
                        accessor: 'id',
                        Header: translate({ id: 'CLIENT_EXTERNAL_APP_APPLICATION_ID' }),
                        sortType: 'alphanumeric',
                        width: 20,
                    },
                ]}
                data={data ?? []}
                refresh={() => mutate()}
                rowActions={[
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_EDIT' }),
                        tooltip: translate({ id: 'CLIENT_EDIT' }),
                        actionType: GridActionType.Row,
                        icon: <EditOutlinedIcon />,
                        click: row => {
                            navigate(getRoute(`${RouteNames.ExternalApplications}/edit/${row.original.id}`));
                        },
                        dataCy: 'edit-external-app-button',
                    },
                    {
                        type: ButtonType.Icon,
                        label: translate({ id: 'CLIENT_DELETE' }),
                        tooltip: translate({ id: 'CLIENT_DELETE' }),
                        actionType: GridActionType.Row,
                        icon: <DeleteForeverOutlinedIcon />,
                        click: async row => {
                            const proceed = await createDialog({
                                title: translate({ id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_APPLICATION_TITLE' }),
                                body: (
                                    <UiText>
                                        {translate(
                                            { id: 'CLIENT_EXTERNAL_APP_CONFIRM_DELETE_APPLICATION' },
                                            { appName: row.original.name },
                                        )}
                                    </UiText>
                                ),
                                icon: 'warning',
                                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                                showCancel: true,
                            });

                            if (proceed) {
                                try {
                                    await deleteExternalClient(row.original.id, partitionGlobalId);
                                    createNotification(
                                        translate({ id: 'CLIENT_EXTERNAL_APP_DELETE_APPLICATION_SUCCESS' }, { 0: row.original.name }),
                                        notificationType.SUCCESS
                                    );
                                    mutate();
                                } catch (deleteError) {
                                    setErrorMessage(await getErrorMessage(deleteError));
                                }
                            }
                        },
                        dataCy: 'delete-external-app-button',
                    },
                ]}
            />
        </div>
    );
};

export default ExternalApplicationsPageComponent;
