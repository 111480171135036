/* eslint-disable max-len */
import { getRegionTranslationId } from '@experiences/constants';
import { useLocalization } from '@experiences/locales';
import { TenantSelfServeMigrationEvent } from '@experiences/telemetry';
import { UiText } from '@experiences/ui-common';
import {
    formatMonthDay,
    useModalState,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { ApButton } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import * as RouteNames from '../../../common/constants/RouteNames';
import { MigrationAvailability } from '../../../common/interfaces/gws';
import {
    cancelTenantMove,
    getScheduledTenantMigrationStatus,
} from '../../../services/global-workflow-service/TenantMigration';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { UiDrawer } from '../../common/UiDrawer';
import {
    SpacingToken,
    UiStack,
} from '../../common/UiStack';

const useStyles = makeStyles({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        height: 'calc(100vh - 64px)',
        paddingTop: '30px',
    },
    loading: { margin: 'auto' },
});

export const TenantMigrationDetailsDrawer: React.FC = () => {
    const classes = useStyles();
    const createDialog = useShowDialog();
    const { logEvent } = useTelemetryHelper();
    const [ isButtonLoading, setIsButtonLoading ] = useState(false);
    const { tenantId } = useParams() as { tenantId: string };
    const { formatMessage: translate } = useIntl();
    const language = useLocalization();
    const getRoute = useRouteResolver();
    const {
        open, close,
    } = useModalState(getRoute(RouteNames.TenantSettings.replace(':tenantId', tenantId)));

    const {
        data: migrationDetails, error, mutate,
    } = useSWR({
        tenantId,
        url: 'getScheduledTenantMigrationStatus',
    }, getScheduledTenantMigrationStatus);

    const handleCancellationFailure = useCallback(async () => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_MIGRATION_CANCELLATION_FAILED' }),
            body: translate({ id: 'CLIENT_UNABLE_TO_CANCEL_TENANT_MIGRATION' }),
            icon: 'error',
            showCancel: false,
            unclosable: true,
            primaryButtonText: translate({ id: 'CLIENT_CLOSE' }),
        });
        if (proceed) {
            mutate();
            close(false, { replace: true });
        }
    }, [ createDialog, mutate, translate, close ]);

    const cancelMigration = useCallback(async () => {
        setIsButtonLoading(true);

        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_CANCEL_REQUEST' }),
            body: translate({ id: 'CLIENT_CANCEL_SCHEDULED_MIGRATION' }),
            icon: 'warning',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_CONFIRM' }),
        });

        if (proceed) {
            try {
                logEvent(TenantSelfServeMigrationEvent.CancelMigration);
                await cancelTenantMove(tenantId);
                mutate();
                close(false, { replace: true });
            } catch (e) {
                await handleCancellationFailure();
            }
        }
        setIsButtonLoading(false);
    }, [ close, createDialog, translate, logEvent, mutate, tenantId, handleCancellationFailure ]);

    const title = useMemo(() => {
        if (!migrationDetails) {
            return '';
        }
        if (migrationDetails.status === MigrationAvailability.RUNNING) {
            return translate({ id: 'CLIENT_TENANT_MIGRATION_INPROGRESS_MIGRATION_TITLE' });
        }
        return translate({ id: 'CLIENT_TENANT_MIGRATION_SCHEDULE_MIGRATION_TITLE' });
    }, [ migrationDetails, translate ]);

    useEffect(() => {
        if (migrationDetails?.status === MigrationAvailability.ENABLED) {
            close(false, { replace: true });
        }
    }, [ migrationDetails, close ]);

    return (
        <UiDrawer
            title={title}
            width="small"
            error={{
                showError: !!error,
                message: translate({ id: 'CLIENT_CONFIGURE_AAD_GENERIC_ERROR' }),
            }}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}>

            <div className={classes.container}>
                {!migrationDetails && <CircularProgress className={classes.loading} />}

                {!!migrationDetails && (migrationDetails.status === MigrationAvailability.SCHEDULED ||
                    migrationDetails.status === MigrationAvailability.RUNNING) && (
                    <UiStack
                        direction="column"
                        gap={SpacingToken.L}>
                        <UiStack direction="column">
                            <UiText
                                color="var(--color-foreground-de-emp)"
                                variant={FontVariantToken.fontSizeMBold}>
                                {translate({ id: 'CLIENT_TENANT_MIGRATION_FROM_REGION' })}
                            </UiText>
                            <UiText>
                                {translate({ id: getRegionTranslationId(migrationDetails.sourceRegion) }) }
                            </UiText>
                        </UiStack>

                        <UiStack direction="column">
                            <UiText
                                color="var(--color-foreground-de-emp)"
                                variant={FontVariantToken.fontSizeMBold}>
                                {translate({ id: 'CLIENT_TENANT_MIGRATION_TO_REGION' })}
                            </UiText>
                            <UiText>
                                {translate({ id: getRegionTranslationId(migrationDetails.destinationRegion) }) }
                            </UiText>
                        </UiStack>

                        <UiStack direction="column">
                            <UiText
                                color="var(--color-foreground-de-emp)"
                                variant={FontVariantToken.fontSizeMBold}>
                                {translate({ id: 'CLIENT_TENANT_MIGRATION_DOWNTIME_WINDOW' })}
                            </UiText>
                            <UiText>
                                {`${(migrationDetails.timeOfDayStartIntervalTicks / (60 * 60 * 1e7)).toString().padStart(2, '0')}:00 UTC - ${(migrationDetails.timeOfDayEndIntervalTicks / (60 * 60 * 1e7)).toString().padStart(2, '0')}:00 UTC`}
                            </UiText>
                        </UiStack>

                        {migrationDetails.status === MigrationAvailability.SCHEDULED && (
                            <UiStack direction="column">
                                <UiText
                                    color="var(--color-foreground-de-emp)"
                                    variant={FontVariantToken.fontSizeMBold}>
                                    {translate({ id: 'CLIENT_TENANT_MIGRATION_NEXT_WINDOW' })}
                                </UiText>
                                <UiText>
                                    {formatMonthDay(migrationDetails.scheduleStartDate, language)}
                                    &nbsp;-&nbsp;
                                    {formatMonthDay(migrationDetails.scheduleEndDate, language)}
                                </UiText>
                            </UiStack>
                        )}
                    </UiStack>
                )}
                <ApButton
                    label={translate({ id: 'CLIENT_CANCEL_REQUEST' })}
                    variant="secondary"
                    loading={isButtonLoading}
                    onClick={cancelMigration}
                    disabled={!migrationDetails || migrationDetails.status !== MigrationAvailability.SCHEDULED}
                    data-cy="tenant-migration-cancel-button" />
            </div>
        </UiDrawer>
    );
};
