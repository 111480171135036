import { AccountLicense } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    useInterval,
    useShowDialog,
} from '@experiences/util';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import {
    AsyncJobStatus,
    notificationType,
} from '../../../common/constants/Constant';
import {
    LegacyLicenseManagementLink,
    LicensingManagementOptionsLink,
} from '../../../common/constants/documentation/DocumentationLinks.default';
import {
    DeliveryType,
    ProductType,
    useDocumentationLinks,
} from '../../../common/hooks/useDocumentationLink';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    getMigrateUserLicensingPendingJobId,
    getMigrateUserLicensingResult,
    getUserLicenseManagementStatus,
    startMigrateUserLicensing,
    updateUserLicenseManagementStatus,
} from '../../../services/licensing/UserLicenseService';
import { accountType } from '../../../store/selectors';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        userLicenseManagementTitle: {
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '20px',
            color: theme.palette.semantic.colorForeground,
            marginBottom: '4px',
        },
        userLicenseManagementHelper: {
            lineHeight: '20px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        userLicensingButton: {
            marginTop: '16px',
            marginBottom: '24px',
            width: 'fit-content',
        },
        userLicensingButtonRevamp: {
            marginTop: '16px',
            marginBottom: '16px',
            width: '120px',
        },
        userLicensingInnerButtonRevamp: { width: '120px' },
        userLicenseManagementActions: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        userLicensingLink: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
        },
    }),
}));

interface IUserLicenseManagementConfig {
    enabled: boolean;
}

const UserLicensingSettingsComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const setErrorMessage = useCentralErrorSetter();

    const { getErrorMessage } = useGetErrorInfo();
    const getLocalizedLink = useDocumentationLinks({ excludedLanguages: [ 'es-MX', 'ko', 'pt', 'tr', 'ru' ] });

    const userAccountType = useSelector(accountType);
    const [ licensingLoading, setLicensingLoading ] = useState(false);

    const isUserCommunityAccountType = useMemo(() => AccountLicense[userAccountType] === AccountLicense.COMMUNITY, [ userAccountType ]);

    const {
        data: userLicenseManagementConfig, mutate,
    } = useSWR<IUserLicenseManagementConfig, Error>(
        '/api/organization/user-license/enable',
        getUserLicenseManagementStatus,
    );

    const [ jobId, setJobId ] = useState('');
    const createNotification = useUiSnackBar();

    const userLicensingStatusLoading = useMemo(() => licensingLoading || !userLicenseManagementConfig,
        [ licensingLoading, userLicenseManagementConfig ]);

    const EnableUserLicensingMigration = useFeatureFlagValue(
        Features.EnableUserLicensingMigration.name,
    );

    const EnableSwitchOffUserLicensingAfterMigration = useFeatureFlagValue(
        Features.EnableSwitchOffUserLicensingAfterMigration.name,
    );

    const { data: initialPendingJobId } = useSWR<string, Error>(
        EnableUserLicensingMigration ? '/api/license_/UserLicense/migrate/pending' : null,
        getMigrateUserLicensingPendingJobId,
    );

    useEffect(() => {
        if (initialPendingJobId && EnableUserLicensingMigration) {
            createNotification(translate({ id: 'CLIENT_USER_LICENSING_INPROGRESS' }), notificationType.INPROGRESS);
            setLicensingLoading(true);
            setJobId(initialPendingJobId);
        }
    }, [ EnableUserLicensingMigration, createNotification, initialPendingJobId, translate ]);

    const formatLinks = useCallback((msg: React.ReactNode[]) => (
        <a
            className={classes.a}
            href={getLocalizedLink({ articleSlug: LicensingManagementOptionsLink })}
            target="_blank"
            rel="noopener noreferrer"
            data-cy="automation-docs-link"
        >
            {msg}
        </a>
    ), [ classes.a, getLocalizedLink ]);

    const formatBold = useCallback((msg: React.ReactNode[]) => (<b>
        {msg}
    </b>), []);

    const enableDialogBody = useMemo(
        () => (
            <div>
                <UiText>
                    <FormattedMessage
                        id="CLIENT_ENABLE_USER_LICENSE_MANAGEMENT_WARNING_P1"
                        values={{ b: formatBold }}
                    />
                </UiText>
                <br />
                <UiText>
                    <FormattedMessage
                        id={EnableUserLicensingMigration
                            ? 'CLIENT_ENABLE_USER_LICENSE_MANAGEMENT_WARNING_MIGRATION_P2'
                            : 'CLIENT_ENABLE_USER_LICENSE_MANAGEMENT_WARNING_P2'}
                        values={{
                            a: formatLinks,
                            b: formatBold,
                        }}
                    />
                </UiText>
                <br />
                <UiText>
                    <FormattedMessage
                        id={EnableUserLicensingMigration
                            ? 'CLIENT_ENABLE_USER_LICENSE_MANAGEMENT_WARNING_MIGRATION_P3'
                            : 'CLIENT_ENABLE_USER_LICENSE_MANAGEMENT_WARNING_P3'}
                        values={{
                            a: formatLinks,
                            b: formatBold,
                        }}
                    />
                </UiText>
                <br />
                <UiText>
                    {translate({ id: 'CLIENT_WARN_USER_LICENSE_MANAGEMENT_ASK_CONFIRM' })}
                </UiText>
            </div>
        ),
        [ formatBold, EnableUserLicensingMigration, formatLinks, translate ],
    );

    const disableDialogBody = useMemo(
        () => (
            <div>
                <UiText>
                    {translate({ id: 'CLIENT_DISABLE_USER_LICENSE_MANAGEMENT_WARNING_P1' })}
                </UiText>
                <br />
                <UiText>
                    {process.buildConfigs.userLicenseManagementCloud ? (
                        <FormattedMessage
                            id="CLIENT_DISABLE_USER_LICENSE_MANAGEMENT_WARNING_P2"
                            values={{
                                b: (msg: React.ReactNode[]) => (<b>
                                    {msg}
                                </b>),
                            }}
                        />
                    ) : (
                        translate({ id: 'CLIENT_DISABLE_USER_LICENSE_MANAGEMENT_WARNING_P2_ON_PREM' })
                    )}
                </UiText>
                <br />
                <UiText>
                    <FormattedMessage
                        id="CLIENT_DISABLE_USER_LICENSE_MANAGEMENT_WARNING_P3"
                        values={{
                            a: (msg: React.ReactNode[]) =>
                                (
                                    <a
                                        className={classes.a}
                                        href={getLocalizedLink({ articleSlug: LegacyLicenseManagementLink })}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {msg}
                                    </a>
                                ),
                        }}
                    />
                </UiText>
                <br />
                <UiText>
                    {translate({ id: 'CLIENT_WARN_USER_LICENSE_MANAGEMENT_ASK_CONFIRM' })}
                </UiText>
            </div>
        ),
        [ translate, classes.a, getLocalizedLink ],
    );

    const alreadyEnabledAndDoNotAllowDisabling = useMemo (() => (isUserCommunityAccountType ||
        (EnableUserLicensingMigration && !EnableSwitchOffUserLicensingAfterMigration)) && userLicenseManagementConfig?.enabled,
    [
        EnableSwitchOffUserLicensingAfterMigration,
        EnableUserLicensingMigration,
        isUserCommunityAccountType,
        userLicenseManagementConfig?.enabled,
    ]);

    const setUserLicenseManagementStatus = useCallback(
        async (status: 'ENABLE' | 'DISABLE') => await createDialog({
            title: `${translate({ id: `CLIENT_${status}_USER_LICENSE_MANAGEMENT` })}`,
            body: status === 'ENABLE' ? enableDialogBody : disableDialogBody,
            icon: status === 'ENABLE' ? 'warning' : 'error',
            primaryButtonText: `${translate({ id: `CLIENT_${status}` })}`,
            showCancel: true,
        }),
        [ createDialog, translate, enableDialogBody, disableDialogBody ],
    );

    const onUserMigrationEnded = useCallback(async () => {
        setJobId('');
        setLicensingLoading(false);
        mutate();
    }, [ mutate ]);

    const checkIfUserMigrationEnded = useCallback(async () => {
        if (userLicenseManagementConfig?.enabled) {
            onUserMigrationEnded();
            return;
        }

        try {
            const response = await getMigrateUserLicensingResult(jobId);
            if (response.status === AsyncJobStatus.Completed) {
                createNotification(translate({ id: 'CLIENT_USER_LICENSING_SUCCESS' }), notificationType.SUCCESS);
                onUserMigrationEnded();
            } else if (response.status === AsyncJobStatus.Failed) {
                createNotification(translate({ id: 'CLIENT_USER_LICENSING_FAILED' }), notificationType.ERROR);
                setErrorMessage(translate({ id: 'CLIENT_USER_LICENSING_FAILED_DETAILS' }, { 0: jobId }));
                onUserMigrationEnded();
            }
        } catch (error) {
            onUserMigrationEnded();
            createNotification(translate({ id: 'CLIENT_USER_LICENSING_FAILED' }), notificationType.ERROR);
            setErrorMessage(await getErrorMessage(error));
        }
    }, [ userLicenseManagementConfig?.enabled, onUserMigrationEnded, jobId, createNotification, translate, setErrorMessage, getErrorMessage ]);

    useInterval(
        () => checkIfUserMigrationEnded(),
        jobId ? 5000 : null,
    );

    const updateUserLicensingManagement = useCallback(
        async (checked: boolean) => {
            const proceed = checked
                ? await setUserLicenseManagementStatus('ENABLE')
                : await setUserLicenseManagementStatus('DISABLE');
            if (proceed) {
                try {
                    setLicensingLoading(true);

                    if (checked) {
                        if (EnableUserLicensingMigration) {
                            createNotification(translate({ id: 'CLIENT_USER_LICENSING_STARTED' }),
                                notificationType.INPROGRESS);
                            const resultJobId = await startMigrateUserLicensing();
                            setJobId(resultJobId);
                        } else {
                            await updateUserLicenseManagementStatus(true);
                            setLicensingLoading(false);
                            mutate();
                        }
                    } else {
                        await updateUserLicenseManagementStatus(false);
                        setLicensingLoading(false);
                        mutate();
                    }
                } catch (error) {
                    setErrorMessage(await getErrorMessage(error));
                    mutate();
                    setLicensingLoading(false);
                }
            }
        },
        [
            setUserLicenseManagementStatus,
            EnableUserLicensingMigration,
            createNotification,
            translate,
            mutate,
            setErrorMessage,
            getErrorMessage,
        ],
    );

    return alreadyEnabledAndDoNotAllowDisabling ?
        (
            <>
                <UiText className={classes.userLicenseManagementTitle}>
                    {translate({ id: 'CLIENT_USER_LICENSE_MANAGEMENT' })}
                </UiText>
                <UiText className={classes.userLicenseManagementHelper}>
                    { translate({ id: 'CLIENT_USER_LICENSING_MANAGEMENT_ALREADY_ENABLED' })}
                </UiText>
            </>
        )
        : (
            <>
                <UiText className={classes.userLicenseManagementTitle}>
                    {translate({ id: 'CLIENT_USER_LICENSE_MANAGEMENT' })}
                </UiText>
                <UiText className={classes.userLicenseManagementHelper}>
                    {userLicenseManagementConfig?.enabled
                        ? translate({ id: 'CLIENT_DISABLE_USER_LICENSE_DESCRIPTION_REVAMP' })
                        : translate({ id: 'CLIENT_ENABLE_USER_LICENSE_DESCRIPTION' })}
                </UiText>
                <div className={classes.userLicenseManagementActions}>
                    <UiProgressButton
                        loading={userLicensingStatusLoading}
                        variant="outlined"
                        className={classes.userLicensingButtonRevamp}
                        innerButtonClass={classes.userLicensingInnerButtonRevamp}
                        data-cy="enable-disable-user-licensing-button"
                        onClick={() => updateUserLicensingManagement(!userLicenseManagementConfig?.enabled)}
                    >
                        {userLicenseManagementConfig?.enabled
                            ? translate({ id: 'CLIENT_DISABLE' })
                            : translate({ id: 'CLIENT_ENABLE' })}
                    </UiProgressButton>
                    <Link
                        href={getLocalizedLink({
                            articleSlug: LicensingManagementOptionsLink,
                            product: ProductType.OVERVIEW,
                            delivery: DeliveryType.OTHER,
                            version: 'latest',
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline="none"
                        className={classes.userLicensingLink}
                    >
                        {translate({ id: 'CLIENT_USER_MANAGEMENT_HELPER' })}
                    </Link>
                </div>
            </>
        );
};

export default UserLicensingSettingsComponent;
