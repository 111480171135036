import {
    UiDialog,
    UiText,
} from '@experiences/ui-common';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import { IPRestrictionImportCsvLink } from '../../../../common/constants/documentation/DocumentationLinks.default';
import { useDocumentationLinks } from '../../../../common/hooks/useDocumentationLink';
import type { BulkInviteIpRangeData } from '../../../../common/interfaces/iprestriction';
import { UiGrid } from '../../../common/UiGrid';
import useIPRestrictionBulkImportViewModel from './IPRestrictionBulkImportViewModel';

const useStyles = makeStyles(() =>
    createStyles({
        backdrop: { zIndex: 1300 },
        cellSpan: {
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
        },
        input: { display: 'none' },
        circularProgress: {
            color: Tokens.Colors.ColorWhite,
            marginRight: '20px',
        },
        progressText: {
            fontSize: '24px',
            color: Tokens.Colors.ColorWhite,
        },
    }),
);

const BulkInviteUserComponent: React.FC = () => {
    const classes = useStyles();
    const getLocalizedLinks = useDocumentationLinks();
    const { formatMessage: translate } = useIntl();
    const {
        inputRef,
        loading,
        fileErrorId,
        progressMessage,
        dialogActions,
        showErrorSummary,
        badEntries,
        total,
        close,
        setFile,
    } = useIPRestrictionBulkImportViewModel();

    const getCell = useCallback((text: string) => <Tooltip
        arrow
        placement="bottom-start"
        title={text}>
        <span className={classes.cellSpan}>
            {text}
        </span>
    </Tooltip>, [ classes.cellSpan ]);

    const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files?.[0]) {
            setFile(e.target.files[0]);
        }
    }, [ setFile ]);

    return (
        <>
            <input
                data-cy="file-input"
                onChange={handleChange}
                accept=".csv"
                type="file"
                className={classes.input}
                ref={inputRef}
            />
            <Backdrop
                open={loading}
                className={classes.backdrop}>
                <CircularProgress
                    className={classes.circularProgress}
                    size={44}
                    variant="indeterminate"
                />
                <UiText className={classes.progressText}>
                    {progressMessage}
                </UiText>
            </Backdrop>
            <UiDialog
                actions={dialogActions}
                title={
                    showErrorSummary && !loading
                        ? translate({ id: 'CLIENT_ERROR_SUMMARY' })
                        : translate({ id: 'CLIENT_UPLOAD_CSV' })
                }
                dataCy='ip-restriction-bulk-import-dialog'
                close={close}
                width='640px'
                dialogProps={{
                    open: !loading,
                    maxWidth: 'xl',
                }}
            >
                {showErrorSummary ? (
                    <>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                            <UiText
                                style={{ marginBottom: '24px' }}
                                data-cy="import-error-summary">
                                {fileErrorId
                                    ? translate({ id: fileErrorId })
                                    : translate({ id: 'CLIENT_IP_RESTRICTION_BULK_ERROR_SUMMARY' }, {
                                        0: badEntries.length,
                                        1: total,
                                    })}
                            </UiText>
                            <Link
                                href={getLocalizedLinks({ articleSlug: IPRestrictionImportCsvLink })}
                                target='_blank'
                                rel='noopener noreferrer'
                                underline='none'
                                data-cy='import-csv-link'
                            >
                                {translate({ id: 'CLIENT_CSV_DOCS' })}
                            </Link>
                        </div>

                        {!fileErrorId && (
                            <UiGrid<BulkInviteIpRangeData>
                                data-cy="error-summary-grid"
                                tableHeight={`${60 * badEntries?.length + 120}px`}
                                columns={[
                                    {
                                        accessor: 'ip',
                                        Header: translate({ id: 'CLIENT_IP_RANGE' }),
                                        Cell: ({ row }) => getCell(row.original.ip),
                                        width: 100,
                                        disableSortBy: true,
                                    },
                                    {
                                        accessor: 'errors',
                                        Header: translate({ id: 'CLIENT_FAILED_REASON' }),
                                        Cell: ({ row }) => {
                                            const errors = row.original.errors.map(id => translate({ id })).join(' ');
                                            return getCell(errors);
                                        },
                                        width: 100,
                                        disableSortBy: true,
                                    },
                                ]}
                                data={badEntries}
                            />
                        )}
                    </>
                ) : (
                    <>
                        <UiText>
                            {translate({ id: 'CLIENT_IP_RESTRICTION_CSV_INFO' })}
                        </UiText>
                        <Link
                            href={getLocalizedLinks({ articleSlug: IPRestrictionImportCsvLink })}
                            target='_blank'
                            rel='noopener noreferrer'
                            underline='none'
                            data-cy='import-csv-link'
                        >
                            {translate({ id: 'CLIENT_CSV_DOCS' })}
                        </Link>
                    </>
                )}
            </UiDialog>
        </>
    );
};

export default BulkInviteUserComponent;
