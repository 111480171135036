import './index.css';

import {
    Features,
    getFeatureFlagValue,
} from '@experiences/feature-flags';
import { initializeTelemetry } from '@experiences/telemetry';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import checkHtmlJSMismatch from './common/htmlJSMismatchCheck';
import { registerSmartling } from './register-smartling';
import { getRouter } from './Router';
// @ts-ignore
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line no-console
console.log('CLOUDRPA_VERSION', window.env.CLOUDRPA_VERSION);

document.title = 'Sign Up';

initializeTelemetry();
checkHtmlJSMismatch();
registerSmartling();

if (getFeatureFlagValue(Features.EnableEcommerceIntegration.name)) {
    if ((window as any).ActiveXObject) {
        (window as any).ActiveXObject = null;
    }

    const script = document.createElement('script');
    script.src = '//cdn-0.d41.co/tags/ff-2.min.js';
    script.type = 'text/javascript';
    document.getElementsByTagName('body')[0].appendChild(script);
}

async function initApp() {
    const router = await getRouter();

    const container = document.getElementById('apollo-shell') as HTMLElement;
    const root = createRoot(container);

    root.render(
        <App router={router} />,
    );
}

initApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
