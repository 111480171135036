import { Entitlements } from '@experiences/constants';
import { useGetErrorInfo } from '@experiences/error';
import {
    UiAlertBanner,
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { PortalCustomIcon } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { flushSync } from 'react-dom';
import {
    useFieldArray,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import {
    useLocation,
    useParams,
} from 'react-router-dom';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type {
    IIPNetwork,
    IPRestrictionFormData,
} from '../../../../common/interfaces/iprestriction';
import {
    addIpNetwork,
    getCurrentIp,
    getIpNetworkByOrganizationId,
    ipNetworkUrl,
    updateIpNetwork,
} from '../../../../services/access-policy/IPNetworkService';
import { consumeEntitlement } from '../../../../services/licensing/EntitlementsService';
import { accountGlobalId } from '../../../../store/selectors';
import { UiDrawer } from '../../../common/UiDrawer';
import UiForm from '../../../common/UiForm';
import { ipCurrentKey } from './IPRestrictionComponent';
import { isCIDR } from './IPRestrictionUtil';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        addButton: {
            height: '32px',
            width: '100px',
        },
        alertBanner: {
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginBottom: '24px',
            marginTop: '24px',
        },
        cancelButton: {
            height: '32px',
            marginRight: '10px',
            width: '100px',
        },
        firstChild: { top: '30px' },
        firstChildError: { top: '20px' },
        form: {
            display: 'flex',
            flexDirection: 'column',
        },
        input: { width: '100%' },
        inputContainer: { marginBottom: '20px' },
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        inputMargin: { marginBottom: '12px' },
        textMargin: { marginBottom: '20px' },
        alertMessage: {
            fontWeight: 400,
            fontSize: '14px',
            margin: '0px 7px',
        },
        link: { marginLeft: '8px' },
        ipRangeRow: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: '8px',
        },
        deleteButton: {
            color: theme.palette.semantic.colorErrorIcon,
            marginLeft: '18px',
        },
        deleteButtonError: { marginBottom: '20px' },
        textFieldLabel: { marginBottom: '8px' },
        addMoreButton: {
            alignSelf: 'flex-start',
            marginTop: '8px',
        },
        snackbar: {
            position: 'relative',
            marginTop: 'auto',
        },
        container: {
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
        },
        alert: {
            marginTop: 'auto',
            marginBottom: '50px',
            minWidth: '296px',
        },
    }),
);

const defaultIPRestrictionData: IPRestrictionFormData = {
    name: '',
    ipNetworks: [ { ipRange: '' } ],
};

const AddEditIPRestrictionComponent: React.FC<{ type: 'add' | 'edit' }> = ({ type }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { getErrorMessage } = useGetErrorInfo();
    const createNotification = useUiSnackBar();

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ openToast, setOpenToast ] = useState(false);

    const location: {
        state?: {
            ipRange: IIPNetwork;
        };
    } = useLocation();
    const {
        open, close,
    } = useModalState(RouteNames.IPRestriction);

    const methods = useForm<IPRestrictionFormData>({
        mode: 'onSubmit',
        defaultValues: defaultIPRestrictionData,
    });

    const {
        control, handleSubmit, reset, getValues, formState: {
            errors, isDirty,
        }, setError, register, clearErrors,
    } = methods;

    const {
        fields, append, remove,
    } = useFieldArray({
        control,
        name: 'ipNetworks',
    });

    const {
        data: ipRangesData, mutate,
    } = useSWR(
        {
            url: ipNetworkUrl,
            organizationId: partitionGlobalId,
        },
        getIpNetworkByOrganizationId,
    );

    const { data: currentUserIp } = useSWR<string, Error>([ ipCurrentKey ], getCurrentIp);

    const currentIp = useMemo(() => currentUserIp ?? '', [ currentUserIp ]);
    const ipRange = useMemo(() => location?.state?.ipRange ?? ({} as IIPNetwork), [ location ]);

    const [ errorMessage, setErrorMessage ] = useState<string>(translate({ id: 'CLIENT_ADD_IP_RANGE_GENERIC_ERROR' }));
    const [ loading, setLoading ] = useState(false);
    const [ showDrawerError, setShowDrawerError ] = useState(false);

    const initialFormData: IPRestrictionFormData | undefined = useMemo(() => {
        if (type === 'edit' && ipRange) {
            return {
                name: ipRange.name,
                ipNetworks: [ { ipRange: ipRange.ipNetwork } ],
            };
        }
    }, [ type, ipRange ]);

    useEffect(() => {
        if (initialFormData) {
            reset(initialFormData);

        }
    }, [ reset, initialFormData ]);

    const validateForm = useCallback((data: { name: string; ipNetworks: string | any[] }) => {
        let validForm = true;
        // check empty label
        if (data.name.length === 0) {
            setError('name', { type: 'required' });
            return false;
        }
        const ipRangesArr = ipRangesData?.map(val => val.ipNetwork) ?? [];
        data.name = data.name.trim();

        // check for invalid CIDR format and duplicate addresses
        const hashMap = new Map<string, boolean>();
        for (const ip of ipRangesArr) {
            hashMap.set(ip, true);
        }

        for (let i = 0; i < data.ipNetworks.length; i++) {
            const address = data.ipNetworks[i]?.ipRange?.trim();
            if (address.length === 0) {
                setError(`ipNetworks.${i}.ipRange`, { type: 'required' });
                validForm = false;
            } else if (!isCIDR(address)) {
                setError(`ipNetworks.${i}.ipRange`, { type: 'invalid' });
                validForm = false;
            }

            if (hashMap.get(address)) {
                setError(`ipNetworks.${i}.ipRange`, { type: 'duplicate' });
                validForm = false;
            } else {
                hashMap.set(address, true);
            }
        }

        if (validForm) {
            // Flush sync is required to hide the errors properly on submit
            flushSync(() => {
                setOpenToast(false);
                clearErrors();
            });
        } else {
            // Flush sync is required to show the errors properly on submit
            flushSync(() => {
                setOpenToast(true);
            });
        }
        return validForm;
    }, [ clearErrors, ipRangesData, setError ]);

    const onSubmit = async (data: any) => {
        try {
            let isValidForm = true;
            if (JSON.stringify(data.ipNetworks) !== JSON.stringify(initialFormData?.ipNetworks)) {
                isValidForm = validateForm(data);
            }

            if (isValidForm && type === 'add') {
                data.ipNetworks = data.ipNetworks.map((item: { ipRange: any }) => item?.ipRange);
                await consumeEntitlement(Entitlements.IpRestrictions);
                await addIpNetwork(partitionGlobalId, { ...data });
                createNotification(
                    translate({ id: 'CLIENT_IP_RESTRICTION_ADD_SUCCESS_NOTIFICATION' }, { 0: data.name }),
                    notificationType.SUCCESS,
                );
                mutate();
                close();
            } else if (isValidForm && type === 'edit') {
                data.ipNetwork = data.ipNetworks[0]?.ipRange;
                delete data.ipNetworks;
                await updateIpNetwork(partitionGlobalId, ipRange.id, { ...data });
                createNotification(
                    translate({ id: 'CLIENT_IP_RESTRICTION_EDIT_SUCCESS_NOTIFICATION' }, { 0: ipRange.name }),
                    notificationType.SUCCESS,
                );
                mutate();
                close();
            }
        } catch (error) {
            setLoading(false);
            if (type === 'add') {
                const name = getValues('name');
                createNotification(
                    translate({ id: 'CLIENT_IP_RESTRICTION_ADD_FAILURE_NOTIFICATION' }, { 0: name }),
                    notificationType.ERROR,
                );
            }
            setShowDrawerError(true);
            setErrorMessage(await getErrorMessage(error));
            close();
        } finally {
            setLoading(false);
        }
    };

    const addIpNetworkEntry = useCallback(() => {
        append({ ipRange: '' });
    }, [ append ]);

    const removeIpNetworkEntry = useCallback((_: any, index: number | number[] | undefined) => {
        remove(index);
    }, [ remove ]);

    return (
        <UiDrawer
            title={type === 'add' ?
                translate({ id: 'CLIENT_ADD_IP_RANGE' }) :
                translate({ id: 'CLIENT_EDIT_IP_RANGE' }, { 0: ipRange.name })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            error={{
                showError: showDrawerError,
                message: errorMessage,
            }}
        >
            <UiForm
                onSubmit={handleSubmit(onSubmit)}
                actions={
                    <div className={classes.actions}>
                        <Button
                            className={classes.cancelButton}
                            onClick={() => close()}
                            color="primary"
                            data-cy="add-edit-ip-restriction-cancel-button"
                        >
                            {translate({ id: 'CLIENT_CANCEL' })}
                        </Button>
                        <UiProgressButton
                            className={classes.addButton}
                            innerButtonClass={classes.addButton}
                            type="submit"
                            loading={loading}
                            disabled={!isDirty || Object.entries(errors).length > 0}
                            variant="contained"
                            data-cy="add-edit-ip-restriction-submit-button"
                        >
                            {type === 'edit' ? translate({ id: 'CLIENT_SAVE' }) : translate({ id: 'CLIENT_ADD' })}
                        </UiProgressButton>
                    </div>
                }
                isDrawer
                heightOffset='0px'
                dataCy='add-edit-iprestriction-form'
            >
                <div className={classes.container}>
                    <div className={classes.inputContainer}>
                        <div className={classes.alertBanner}>
                            {type === 'add' && (
                                <UiAlertBanner
                                    type='info'
                                    closeable={false}
                                >
                                    <div className={classes.alertMessage}>
                                        {translate({ id: 'CLIENT_ADD_IP_REMINDER' })}
                                    </div>
                                </UiAlertBanner>

                            )}
                            {openToast &&
                                <UiAlertBanner
                                    type='error'
                                    closeable={false}
                                    data-cy='add-edit-ip-restriction-generic-error'
                                >
                                    {translate({ id: 'CLIENT_IP_RESTRICTION_IP_RANGE_GENERIC_ERROR' })}
                                </UiAlertBanner>}
                        </div>
                        <UiText className={clsx(classes.inputLabel, classes.inputMargin)}>
                            {translate({ id: 'CLIENT_CURRENT_IP' })}
                        </UiText>
                        <UiText
                            data-cy="add-edit-ip-restrict-current"
                            className={classes.textMargin}>
                            {currentIp?.length > 0 ? currentIp : translate({ id: 'CLIENT_IP_RESTRICTION_GET_CURRENT_IP_ERROR' })}
                        </UiText>
                        <TextField
                            inputProps={register('name', { required: true })}
                            label={translate({ id: 'CLIENT_IP_RESTRICTION_IP_SET_NAME' })}
                            variant="outlined"
                            className={clsx(classes.input, classes.textMargin)}
                            required
                            error={!!errors.name}
                            helperText={
                                errors.name?.type === 'required' &&
                                translate(
                                    { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                    { 0: translate({ id: 'CLIENT_NAME' }) },
                                )
                            }
                            data-cy="add-edit-ip-restrict-name"
                        />
                        <FormControl component='fieldset'>
                            <InputLabel component='legend'>
                                {translate({ id: 'CLIENT_IP_RESTRICTION_ADD_IP_RANGE' })}
                            </InputLabel>
                            <UiText className={classes.textFieldLabel}>
                                {translate({ id: 'CLIENT_IP_RESTRICTION_ADD_IP_RANGE_DESCRIPTION' })}
                            </UiText>
                            { fields.map((val, idx) => (
                                <div
                                    className={classes.ipRangeRow}
                                    key={val.id}
                                    data-cy={`add-edit-ip-restriction-ip-ranges-row-${idx}`}
                                >
                                    <TextField
                                        disabled={false}
                                        error={!!errors.ipNetworks?.[idx]?.ipRange}
                                        fullWidth
                                        variant="outlined"
                                        defaultValue={val.ipRange}
                                        helperText={
                                            (errors.ipNetworks?.[idx]?.ipRange?.type === 'required' && translate(
                                                { id: 'CLIENT_REQUIRED_FIELD_ERROR' },
                                            )) ||
                                                (errors.ipNetworks?.[idx]?.ipRange?.type === 'invalid' && translate(
                                                    { id: 'CLIENT_IP_RESTRICTION_IP_RANGE_INVALID_ERROR' },
                                                )) ||
                                                (errors.ipNetworks?.[idx]?.ipRange?.type === 'duplicate' && translate(
                                                    { id: 'CLIENT_IP_RESTRICTION_IP_RANGE_DUPLICATE_ERROR' },
                                                ))
                                        }
                                        inputProps={{
                                            'aria-label': translate(
                                                { id: 'CLIENT_IP_RESTRICTION_IP_RANGE_INPUT_ARIA' }, { 0: idx + 1 }
                                            ),
                                            ...register(`ipNetworks.${idx}.ipRange`),
                                        }}
                                        InputProps={{
                                            endAdornment: (
                                                (!!errors.ipNetworks?.[idx]?.ipRange) && <InputAdornment position="end">
                                                    <PortalCustomIcon
                                                        name='error'
                                                        size='24px' />
                                                </InputAdornment>
                                            ),
                                        }}
                                        data-cy={`add-edit-ip-restriction-ip-ranges-row-${idx}-ip-range`}

                                    />
                                    {type === 'add' && <IconButton
                                        disabled={fields.length <= 1}
                                        className={clsx({
                                            [classes.deleteButton]: true,
                                            [classes.deleteButtonError]: !!errors.ipNetworks?.[idx]?.ipRange,
                                        })}
                                        data-cy={`add-edit-ip-restriction-ip-ranges-row-${idx}-delete-button`}
                                        onClick={() => removeIpNetworkEntry(val, idx)}
                                        aria-label={translate(
                                            { id: 'CLIENT_IP_RESTRICTION_DELETE_BUTTON_ARIA' }, { 0: idx + 1 }
                                        )}
                                    >
                                        <RemoveCircleIcon />
                                    </IconButton>}
                                </div>)) }
                        </FormControl>

                        {type === 'add' && <Button
                            variant='text'
                            startIcon={<AddIcon />}
                            onClick={() => addIpNetworkEntry()}
                            data-cy='add-edit-ip-restriction-ip-ranges-row-add-button'
                        >
                            {translate({ id: 'CLIENT_ADD_MORE' }) }
                        </Button>}
                    </div>
                </div>
            </UiForm>
        </UiDrawer>
    );
};

export const AddEditIPRestrictionComponentWithParams: React.FC = () => {
    const { type } = useParams() as { type: 'add' | 'edit'; id: string };

    return <AddEditIPRestrictionComponent type={type} />;
};

export default AddEditIPRestrictionComponent;
