import { UiText } from '@experiences/ui-common';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import token from '@uipath/apollo-core/lib';
import {
    ApDropdown,
    ApDropdownItem,
} from '@uipath/portal-shell-react';
import type { ApDropdownCustomEvent } from '@uipath/portal-shell-types';
import { AlertDurationEnum } from '@uipath/portal-shell-util';
import React, { useCallback } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

const useStyles = makeStyles(theme =>
    createStyles({
        divider: {
            marginBottom: token.Spacing.SpacingXl,
            borderColor: theme.palette.semantic.colorBorderDeEmp,
        },
        mainHeading: { marginBottom: token.Padding.PadXxl },
        mainHeadingArea: { marginBottom: token.Spacing.SpacingXl },
        subHeading: {
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: token.FontFamily.FontWeightBold,
        },
        mainText: { color: theme.palette.semantic.colorForegroundDeEmp },
        AlertDurationArea: {
            display: 'flex',
            gap: token.Spacing.SpacingXxl,
        },
        dropdown: { width: '215px' },
    }),
);

const ProfileAccessibilitySettingsComponent: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const selectedDuration = JSON.stringify(window.PortalShell?.Alert.alertDuration);
    const durationTranslationKey = {
        [AlertDurationEnum.s3]: 'CLIENT_DURATION_SECONDS',
        [AlertDurationEnum.s5]: 'CLIENT_DURATION_SECONDS_DEFAULT',
        [AlertDurationEnum.s10]: 'CLIENT_DURATION_SECONDS',
        [AlertDurationEnum.s30]: 'CLIENT_DURATION_SECONDS',
        [AlertDurationEnum.s60]: 'CLIENT_DURATION_1_MINUTE',
        ['null']: 'CLIENT_UNTIL_DISMISS',
    };

    const durationDropdownItems = Object.entries(durationTranslationKey).map(([ key, value ]) => (<ApDropdownItem
        value={key}
        label={translate({ id: value }, { 0: parseInt(key) / 1000 })}
        key={`ap-dropdown-item-${key}`}
    />));

    const handleSelectDurationChange = useCallback((event: ApDropdownCustomEvent<string | string[]>) => {
        if (window.PortalShell) {
            window.PortalShell.Alert.alertDuration = JSON.parse(event.detail as string) ?? AlertDurationEnum.NODISMISS;
        }
    }, [ ]);

    return (
        <>
            <div className={classes.mainHeadingArea}>
                <UiText
                    className={classes.mainHeading}
                    variant={FontVariantToken.fontSizeLBold}
                    role="heading"
                    aria-level={1}>
                    {translate({ id: 'CLIENT_ACCESSIBILITY' })}
                </UiText>
                <UiText
                    className={classes.mainText}
                    data-cy="profile-accessibility-settings-description">
                    {translate({ id: 'CLIENT_ACCESSIBILITY_SETTINGS_DESCRIPTION' })}
                </UiText>
            </div>

            <Divider className={classes.divider} />

            <div className={classes.AlertDurationArea}>
                <div>
                    <UiText
                        className={classes.subHeading}
                        variant={FontVariantToken.fontSizeMBold}
                        role="heading"
                        aria-level={2}>
                        {translate({ id: 'CLIENT_ACCESSIBILITY_SETTINGS_ALERT_TIMING_HEADER' })}
                    </UiText>
                    <UiText className={classes.mainText}>
                        <FormattedMessage
                            id='CLIENT_ACCESSIBILITY_SETTINGS_ALERT_TIMING_DESCRIPTION'
                            values={{
                                u: (msg: React.ReactNode[]) => <u>
                                    {msg}
                                </u>,
                            }}
                        />
                    </UiText>
                </div>

                <div>
                    <ApDropdown
                        data-cy="alert-duration-dropdown"
                        label={translate({ id: 'CLIENT_SHOW_ALERTS_FOR' })}
                        selectedValue={selectedDuration}
                        onSelectedValueChanged={handleSelectDurationChange}
                        className={classes.dropdown}>
                        {durationDropdownItems}
                    </ApDropdown>
                </div>
            </div>
        </>
    );
};

export default ProfileAccessibilitySettingsComponent;
