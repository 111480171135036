/* eslint-disable max-len */
import { NotificationAdminSettingsEvent } from '@experiences/telemetry';
import { UiProgressButton } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import Tokens from '@uipath/apollo-core';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { notificationType } from '../../../common/constants/Constant';
import * as RouteNames from '../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import {
    getSubscriptionProfile,
    SubscriptionProfile,
    updateProfileNotificationSubscription,
} from '../../../services/notification-preferences';
import { accountGlobalId } from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import UiForm from '../../common/UiForm';
import { UiPanel } from '../../common/UiPanel/UiPanel';
import { useTenantsContext } from '../../tenants/TenantsContextProvider';
import { hasAnyChangeInPublisherPreferences } from '../helpers/topicsGrouper';
import type {
    IProfileSubscriptionPayload,
    IProfileUpdateSubscriptionPayload,
    IPublisherProfileSubscriptionPayload,
} from '../interfaces/notificationSettings';
import {
    NotificationMode,
    ProfileType,
} from '../interfaces/notificationSettings';
import NotificationTabsComponent from '../NotificationTabsComponent';
import {
    initialNotificationSettingsReducerState,
    notificationSettingsReducer,
} from '../reducers';
import { ActionTypes } from '../reducers/actionTypes';
import NotificationModePreferencesComponent from '../v2/NotificationModePreferencesComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        headerLayout: {
            display: 'flex',
            flexDirection: 'column',
        },
        breadcrumbText: {
            fontSize: Tokens.FontFamily.FontMSize,
            lineHeight: Tokens.FontFamily.FontLinkLineHeight,
            fontWeight: Tokens.FontFamily.FontWeightDefault,
        },
        breadcrumbClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        breadcrumbLastClickable: {
            cursor: 'pointer',
            color: theme.palette.semantic.colorForeground,
        },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        mainContentPlaceholder: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - 120px)',
        },
        restoreDefaultSubscriptionBlock: { margin: '20px 0' },
        restoreDefaultSubscriptionLink: {
            marginLeft: Tokens.Padding.PadXxxl,
            fontWeight: Tokens.FontFamily.FontWeightSemibold,
            cursor: 'pointer',
            textDecoration: 'none',
        },
        margintop: { marginTop: '-99px' },
        margintopNoData: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: 'calc(100% - 120px)',
            marginTop: '20px',
        },
        dividerSpacing: {
            marginTop: '-15px',
            marginBottom: '10px',
            color: theme.palette.semantic.colorBorderDeEmp,
        },
    }),
);

const NotificationSettingsEditProfilePage: React.FC<{
    type: string;
    identifier: string;
    displayName: string;
    local: boolean;
    hasEmail: boolean;
}> = ({
    type, identifier, displayName, local, hasEmail,
}) => {
    const navigate = useNavigateWithParams();
    const accountId = useSelector(accountGlobalId);
    const getRoute = useRouteResolver();
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const { handleSubmit } = useForm();
    const { logEvent } = useTelemetryHelper();
    const { selectedTenant: { id: tenantId } } = useTenantsContext();

    const createNotification = useUiSnackBar();

    const [ profileSubscriptionsPayload, setProfileSubscriptionsPayload ] = React.useState<IProfileSubscriptionPayload[]>([]);
    const [ publisherProfileSubscriptionsPayload, setPublisherProfileSubscriptionsPayload ] = React.useState<IPublisherProfileSubscriptionPayload[]>([]);
    const profileRequestUrl = SubscriptionProfile + '?Name=' + identifier;

    const [ isSaveLoading, setisSaveLoading ] = React.useState<boolean>(false);

    const handleProfileError = useCallback(
        (e: Error) => {
            const errorMessage = e?.message;
            createNotification(`${translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_UPDATE_FAILED' })} ${errorMessage ? ' - ' + errorMessage : ''}`, notificationType.ERROR);
        },
        [ createNotification, translate ],
    );

    const updateSelectedOption = useCallback((data: IProfileSubscriptionPayload[], publisherData?: IPublisherProfileSubscriptionPayload[]) => {
        const payloadState = profileSubscriptionsPayload;
        data.forEach((topicItem: IProfileSubscriptionPayload) => {
            const saveItem = payloadState?.find(x => x.topicId === topicItem.topicId
                && x.notificationMode === topicItem.notificationMode);
            if (saveItem !== undefined) {
                saveItem.isMandatory = topicItem.isMandatory;
                saveItem.isSubscribed = topicItem.isSubscribed;
                saveItem.isVisible = topicItem.isVisible;
            } else {
                payloadState?.push(topicItem);
                setProfileSubscriptionsPayload(payloadState);
            }

        });
        const expandedPublisherData = [ ...publisherProfileSubscriptionsPayload ];
        publisherData?.forEach((publisherItem: IPublisherProfileSubscriptionPayload) => {
            const saveItem = expandedPublisherData.find(x => x.publisherId === publisherItem.publisherId);
            if (saveItem !== undefined) {
                saveItem.channelName = publisherItem.channelName;
                saveItem.channelKey = publisherItem.channelKey;
                saveItem.notificationMode = publisherItem.notificationMode;
            } else {
                expandedPublisherData.push(publisherItem);
            }
        });

        setPublisherProfileSubscriptionsPayload(expandedPublisherData);

    }, [ profileSubscriptionsPayload, publisherProfileSubscriptionsPayload ]);
    const [ publishersData, setPublishersData ] = useState<any>();
    const [ isValidating, setIsValidating ] = useState<boolean>(true);
    const [ error, setError ] = useState<boolean>(false);
    const [ notificationSettings, dispatch ] = React.useReducer(
        notificationSettingsReducer,
        initialNotificationSettingsReducerState,
    );

    useEffect(() => {
        if (accountId && profileRequestUrl && tenantId) {
            const fetchPublishers = async () => {
                try {
                    const data = await getSubscriptionProfile(profileRequestUrl, tenantId, accountId);
                    setPublishersData(data);
                    setIsValidating(false);
                    dispatch({
                        type: ActionTypes.NS_INITIALIZE_NOTIFICATION_SETTINGS,
                        data: [ ...data.publishers ],
                    });
                } catch (err) {
                    setError(true);
                }
            };
            fetchPublishers();
        }
    }, [ accountId, profileRequestUrl, tenantId ]);

    const tabList = useMemo(
        () =>
            notificationSettings
                .publishersWithGroupedTopics
                ?.map((publisher: any) => publisher.displayName),
        [ notificationSettings.publishersWithGroupedTopics ],
    );

    const isPageReady = useMemo(() => tabList.length && !isValidating, [ tabList.length, isValidating ]);
    const noPublishersDataFound = useMemo(
        () => publishersData && notificationSettings.publishers.length === 0,
        [ publishersData, notificationSettings.publishers.length ]);
    const { currentPublisher } = useMemo(
        () => ({ currentPublisher: notificationSettings.publishersWithGroupedTopics[notificationSettings.tabIndex] }),
        [ notificationSettings.publishersWithGroupedTopics, notificationSettings.tabIndex ],
    );

    const showRestoreDefault = useMemo(() => {
        if (currentPublisher?.topicGroups !== undefined) {
            return hasAnyChangeInPublisherPreferences(currentPublisher.topicGroups, true);
        }
    }, [ currentPublisher?.topicGroups ]);

    const showOnlyIfCurrentPublishHasEmail = useCallback(() => {
        if (type === ProfileType.User) {
            return true;
        }
        let hasEmailMode = false;
        currentPublisher.topicGroups.forEach((topicGroup) => {
            if (topicGroup.topics.find(topic => topic.modes!.find(mode => mode.name === NotificationMode.Email)) !== undefined) {
                hasEmailMode = true;
            }
        });
        return hasEmailMode;

    }, [ currentPublisher?.topicGroups, type ]);

    const restoreDefaultSubscriptions = useCallback(async () => {
        dispatch({
            type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS,
            data: { publisherIndex: currentPublisher.publisherIndex },
        });
        try {
            const payloadState = profileSubscriptionsPayload;
            currentPublisher.topicGroups.forEach((topicGroup) => {
                for (let i = 0; i < topicGroup.topics.length; i++) {
                    const topic = topicGroup.topics[i];
                    topic.modes?.forEach(mode => {

                        const saveItem = payloadState?.find(x => x.topicId === topic.topicId
                            && x.notificationMode === mode.name);
                        if (saveItem !== undefined) {
                            saveItem.isMandatory = false;
                            saveItem.isSubscribed = mode.isSubscribedByDefault ?? true;
                            saveItem.isVisible = true;
                        } else {
                            const topicEmailState: IProfileSubscriptionPayload = {
                                topicId: topic.topicId,
                                isSubscribed: mode.isSubscribedByDefault ?? true,
                                notificationMode: mode.name,
                                isMandatory: false,
                                isVisible: true,
                            };
                            payloadState?.push(topicEmailState);
                        }
                    });
                }
            });
            setProfileSubscriptionsPayload(payloadState);
            dispatch({
                type: ActionTypes.NS_TOGGLE_PUBLISHER_SUBSCRIPTION_RESET,
                data: {
                    publisherIndex: currentPublisher.publisherIndex,
                    isSubscribed: true,
                },
            });
        } catch (e) {
            handleProfileError(e as Error);
            dispatch({
                type: ActionTypes.NS_RESTORE_DEFAULT_SUBSCRIPTIONS_UPDATE_FAILED,
                data: { publisherIndex: currentPublisher.publisherIndex },
            });
        }
    }, [ currentPublisher?.publisherIndex, currentPublisher?.topicGroups, handleProfileError, profileSubscriptionsPayload ]);

    const SaveProfileSubscription = useCallback(async () => {
        setisSaveLoading(true);
        const saveProfileType = type === ProfileType.NewUserGroup ? ProfileType.UserGroup : type;
        if (type === ProfileType.NewUserGroup) {
            logEvent(NotificationAdminSettingsEvent.AddUserProfile);
        }
        const payloadState = profileSubscriptionsPayload;
        const payLoad: IProfileUpdateSubscriptionPayload = {
            name: identifier,
            description: displayName,
            displayName,
            externalUserGroupId: identifier === 'Default' ? '' : identifier,
            profileType: saveProfileType,
            tenantId,
            profileSubscriptions: payloadState,
            publisherSubscriptionProfiles: publisherProfileSubscriptionsPayload,
            hasEmail,
            isLocalGroup: local,
        };
        try {
            await updateProfileNotificationSubscription(payLoad, tenantId);
            createNotification(
                translate({ id: 'CLIENT_NS_PROFILE_SUCCESSFULLY_CHANGED' }),
                notificationType.SUCCESS,
            );

            setisSaveLoading(false);
            setProfileSubscriptionsPayload([]);
            navigate(getRoute(RouteNames.NotificationSettings));
        } catch (error) {
            setisSaveLoading(false);
            handleProfileError(error as Error);
        }
    }, [ type, profileSubscriptionsPayload, publisherProfileSubscriptionsPayload, identifier, displayName, tenantId, createNotification, translate, navigate, getRoute, handleProfileError ]);

    const getNotificationSettingsUiV2 = () => <>

        <NotificationModePreferencesComponent
            {...{
                notificationSettings,
                dispatch,
                handleError: handleProfileError,
                isManagedMode: true,
                updateSelectedOption,
                isUserGroup: type !== ProfileType.User,
                hasEmail,
                isLocalGroup: local,
            }} />
    </>;
    return (
        <UiPanel
            header={{
                marginTop: '-99px',
                title: (
                    <div className={classes.headerLayout}>
                        <Breadcrumbs separator=">">
                            <span
                                className={clsx(classes.breadcrumbText, classes.breadcrumbClickable)}
                                onClick={() => navigate(getRoute(RouteNames.NotificationSettings))}
                            >
                                {translate({ id: 'CLIENT_MANAGE_NOTIFICATIONS' })}
                            </span>
                            <span
                                className={clsx(classes.breadcrumbText, classes.breadcrumbClickable)}
                                onClick={() => navigate(getRoute(RouteNames.NotificationSettings))}
                            >
                                {translate({ id: 'CLIENT_ALERTING_GROUP_CONFIGURATION' })}
                            </span>
                            <span
                                className={clsx(classes.breadcrumbText, classes.breadcrumbLastClickable)}
                            >
                                {displayName}
                            </span>
                        </Breadcrumbs>
                    </div>
                ),
            }}
            data-cy="edit-profile-panel"

        >
            <Divider className={classes.dividerSpacing} />
            <div>
                {isPageReady ? (<>
                    <UiForm
                        heightOffset="0px"
                        onSubmit={handleSubmit}
                        id="editResource"
                        actions={
                            <div className={classes.actions}>
                                <Button
                                    className={classes.cancelButton}
                                    color="primary"
                                    onClick={() => navigate(getRoute(RouteNames.NotificationSettings))}
                                >
                                    {translate({ id: 'CLIENT_CANCEL' })}
                                </Button>
                                <UiProgressButton
                                    loading={isSaveLoading}
                                    disabled={
                                        profileSubscriptionsPayload.length === 0 && type !== ProfileType.NewUserGroup && publisherProfileSubscriptionsPayload.length === 0
                                    }
                                    onClick={() => {
                                        SaveProfileSubscription();
                                    }}
                                    variant="contained"
                                    data-cy="add-edit-submit-button"
                                >
                                    {translate({ id: 'CLIENT_SAVE' })}
                                </UiProgressButton>
                            </div>
                        }
                        centerChild
                    >
                        {showRestoreDefault && (
                            <div className={classes.restoreDefaultSubscriptionBlock}>
                                <PortalAlertBar
                                    status="warning"
                                    cancelable={false}>
                                    <div>
                                        {translate({ id: 'CLIENT_MODIFIED_NOTIFICATION_PREFERENCES' })}
                                        <Link
                                            className={classes.restoreDefaultSubscriptionLink}
                                            onClick={restoreDefaultSubscriptions}>
                                            {translate({ id: 'CLIENT_NOTIFICATION_PREFERENCES_RESTORE_DEFAULT_SUBSCRIPTIONS' })}
                                        </Link>
                                    </div>
                                </PortalAlertBar>
                            </div>
                        ) }
                        <NotificationTabsComponent
                            {...{
                                notificationSettings,
                                tabsList: tabList,
                                dispatch,
                            }}
                        />
                        { showOnlyIfCurrentPublishHasEmail() && getNotificationSettingsUiV2()}
                        { !showOnlyIfCurrentPublishHasEmail() && <>
                            <Divider style={{ marginTop: '1px' }} />
                            <div className={classes.margintopNoData}>
                                <div>
                                    {translate({ id: 'CLIENT_ADMINCONFIG_NO_EMAIL_DATA_FOUND' })}
                                </div>
                            </div>
                        </>}
                    </UiForm>
                </>
                ) : null}
                {!isPageReady && error ? (
                    <div className={classes.mainContentPlaceholder}>
                        <div>
                            {translate({ id: 'CLIENT_FAILED_TO_FETCH_DATA' }) +
                            '. ' +
                            translate({ id: 'CLIENT_TRIAL_REQUESTED_FAILED' })}
                        </div>
                    </div>
                ) : null}
                {(!isPageReady && !error) && noPublishersDataFound ? (
                    <div className={classes.mainContentPlaceholder}>
                        <div>
                            {translate({ id: 'CLIENT_NO_USAGE_DATA_FOUND' })}
                        </div>
                    </div>
                ) : null}
                {(!isPageReady && !error) && !noPublishersDataFound && (
                    <div className={classes.mainContentPlaceholder}>
                        <CircularProgress
                            style={{ margin: '200px' }}
                            thickness={2}
                            size={36} />
                    </div>
                )}
            </div>
        </UiPanel>
    );
};

export default NotificationSettingsEditProfilePage;
