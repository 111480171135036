import { UiText } from '@experiences/ui-common';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React from 'react';
import { useIntl } from 'react-intl';

import type { IInsightsDashboard } from '../../../services/insights/DashboardService';
import LoadingStateSkeletonComponent from './LoadingStateSkeletonComponent';

const useStyles = makeStyles(theme =>
    createStyles({
        iframe: {
            '--navigationHeight': '194px',
            borderWidth: '0px',
            width: '100vw',
            height: `calc(100vh - var(--navigationHeight))`,
            maxHeight: `100vh`,
        },
        insightsDashboardError: {
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
            fontWeight: 600,
            marginTop: '24px',
            marginLeft: '24px',
            marginBottom: '6px',
        },
    }
    ),
);

const InsightsDashboardComponent: React.FC<{ dashboardPayload: IInsightsDashboard | undefined; error: any }> = ({
    dashboardPayload, error,
}) => {

    const { formatMessage: translate } = useIntl();
    const classes = useStyles();
    const hasValidDashboardPayload = dashboardPayload && !dashboardPayload.error &&
        dashboardPayload.isSuccess && dashboardPayload.result.folderPermissionsSuccess;

    if (hasValidDashboardPayload) {
        return (
            <iframe
                id="insights-dashboard"
                title={translate({ id: 'CLIENT_INSIGHTS_DASHBOARD_TITLE' })}
                src={dashboardPayload.result.url}
                scrolling="no"
                data-testid="insights-dashboard"
                className={classes.iframe}
            />
        );
    }

    if (dashboardPayload && !hasValidDashboardPayload || error) {
        return (
            <UiText
                className={classes.insightsDashboardError}
                data-testid="insights-error">
                {translate({ id: 'CLIENT_INSIGHTS_DASHBOARD_ERROR' })}
            </UiText>);
    }

    return (<LoadingStateSkeletonComponent
        childGridColumns={6}
        childGridElements={[ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 ]} />);
};

export default InsightsDashboardComponent;
