import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useCallback } from 'react';
import {
    Controller,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { AuthenticationSettingType } from '../../../services/identity/AuthenticationSettingService';
import { isAdminSelector } from '../../../store/selectors';
import UiForm from '../../common/UiForm';
import { DirectoryIntegrationComponent } from './DirectoryIntegrationComponent';
import ExternalIdentityProvidersFormButtonsComponent from './ExternalIdentityProvidersFormButtonsComponent';
import type { IAuthSettingsData } from './ExternalIdentityProvidersFormViewModel.default';
import useExternalIdentityProvidersForm, { initialData } from './ExternalIdentityProvidersFormViewModel.default';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        radioGroup: { alignItems: 'flex-start' },
        radioPrimary: {
            fontWeight: 600,
            fontSize: '14px',
        },
        headerText: {
            fontWeight: 600,
            fontSize: '16px',
        },
        radioButtonLabel: {
            display: 'flex',
            flexDirection: 'column',
            paddingTop: '16px',
        },
    }),
}));

export const ExternalIdentityProvidersFormComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const isAdmin = useSelector(isAdminSelector);

    const DisableGoogleMicrosoftAndInviteAuthSettings = useFeatureFlagValue(
        Features.DisableFeatureFedRamp.name,
    );

    const {
        control, formState: { isDirty }, reset, handleSubmit,
    } = useForm<IAuthSettingsData>({
        mode: 'onSubmit',
        defaultValues: initialData,
    });

    const {
        loading, onSubmit,
    } = useExternalIdentityProvidersForm(reset);

    const handleCancel = useCallback(() => {
        reset();
    }, [ reset ]);

    const radioButtonLabel = useCallback((primaryText: string, secondaryText: string) => (
        <div className={classes.radioButtonLabel}>
            <UiText className={classes.radioPrimary}>
                {translate({ id: primaryText })}
            </UiText>
            <UiText style={{ fontSize: '12px' }}>
                {translate({ id: secondaryText })}
            </UiText>
        </div>), [ classes.radioButtonLabel, classes.radioPrimary, translate ]);

    return <UiForm
        centerChild
        onSubmit={handleSubmit(onSubmit)}
        actions={
            isDirty && <ExternalIdentityProvidersFormButtonsComponent
                cancel={handleCancel}
                loading={loading}
                disabled={!isAdmin} />
        }>
        {!DisableGoogleMicrosoftAndInviteAuthSettings && (
            <>
                <UiText className={classes.headerText}>
                    {translate({ id: 'CLIENT_SIGN_IN_OPTIONS_FOR_LOCAL_ACCOUNTS' })}
                </UiText>

                <span style={{ paddingLeft: '12px' }}>
                    <Controller
                        control={control}
                        name="directConnection"
                        render={({ field }) => (
                            <RadioGroup
                                className={classes.radioGroup}
                                {...field}>
                                <FormControlLabel
                                    control={<Radio onChange={e => field.onChange(AuthenticationSettingType.NoSetting)} />}
                                    value={AuthenticationSettingType.NoSetting}
                                    color="primary"
                                    label={radioButtonLabel('CLIENT_ALL_AVAILABLE_METHODS', 'CLIENT_AUTH_SETTINGS_ANY_AVAILABLE_METHOD_REVAMP')}
                                    data-cy="nosettings-enforce-signin"
                                />
                                {!process.buildConfigs.disableIDPs && (
                                    <FormControlLabel
                                        control={<Radio onChange={e => field.onChange(AuthenticationSettingType.Google)} />}
                                        value={AuthenticationSettingType.Google}
                                        color="primary"
                                        label={radioButtonLabel('CLIENT_GOOGLE_SIGN_IN', 'CLIENT_GOOGLE_SIGN_IN_DESCRIPTION')}
                                        data-cy="google-enforce-signin"
                                    />
                                )}
                                {!process.buildConfigs.disableIDPs && (
                                    <FormControlLabel
                                        control={<Radio onChange={e => field.onChange(AuthenticationSettingType.Microsoft)} />}
                                        value={AuthenticationSettingType.Microsoft}
                                        color="primary"
                                        label={radioButtonLabel('CLIENT_MICROSOFT_SIGN_IN', 'CLIENT_MICROSOFT_SIGN_IN_DESCRIPTION')}
                                        data-cy="microsoft-enforce-signin"
                                    />
                                )}
                            </RadioGroup>
                        )}
                    />
                </span>
            </>
        )}
        <DirectoryIntegrationComponent />
    </UiForm>;
};
