import RefreshIcon from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import React, {
    useEffect,
    useRef,
} from 'react';
import { useIntl } from 'react-intl';

export const UiRefreshButton: React.FC<{
    loading: boolean;
    onClick: () => void;
}> = ({
    loading, onClick,
}) => {
    const { formatMessage: translate } = useIntl();
    const buttonRef = useRef<HTMLButtonElement>(null);
    const wasLoading = useRef(loading);

    useEffect(() => {
        if (wasLoading.current && !loading && buttonRef.current) {
            buttonRef.current.focus();
        }
        wasLoading.current = loading;
    }, [ loading ]);

    return (
        <Button
            size="small"
            variant="text"
            disabled={loading}
            startIcon={loading ? <CircularProgress
                size={16}
                thickness={4} /> : <RefreshIcon />}
            onClick={onClick}
            data-cy="ui-grid-refresh-button"
            aria-label={translate({ id: 'CLIENT_REFRESH' })}
            ref={buttonRef}
        >
            {translate({ id: 'CLIENT_REFRESH' })}
        </Button>
    );
};
