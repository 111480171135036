import {
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';

import { getSub } from '../auth/utils/Token';

export const StudioDownload = {
    RightNav: 'RightNav widget',
    RightNavLink: 'RightNav link',
    FREBanner: 'FRE banner',
    ResourceCenter: 'Resource center',
};

export async function logStudioDownload(
    studioEdition: 'Enterprise Cloud' | 'Enterprise Trial' | 'Community Edition' | 'Enterprise',
    location: string,
    fre = false,
) {
    const sub = await getSub();
    portalTelemetry.trackTrace({
        message: 'Studio Download',
        severityLevel: SeverityLevel.Info,
        properties: {
            sub,
            studioEdition,
            location,
            fre,
        },
    });
}

export async function logFREEnabled() {
    const sub = await getSub();
    portalTelemetry.trackTrace({
        message: 'FRE Enabled',
        severityLevel: SeverityLevel.Info,
        properties: { sub },
    });
}
