import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { useTheme } from '@mui/material/styles';
import { ApButton } from '@uipath/portal-shell-react';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import {
    SpacingToken,
    UiStack,
} from '../common/UiStack';

interface IConsentContentChangesDialogBodyProps extends IUiDialogHookCustomContent {
    userRecordCount: number;
    formReset: () => void;
    setFormReconsent: (reconsent: boolean) => void;
}

export const ConsentContentChangesDialogBody: React.FC<IConsentContentChangesDialogBodyProps> = ({
    closeDialog, userRecordCount, formReset, setFormReconsent,
}) => {
    const theme = useTheme();
    const { formatMessage: translate } = useIntl();
    const [ reconsent, setReconsent ] = useState(false);

    return <>
        <UiText
            color={theme.palette.semantic.colorForeground}
            id="reconsent-label">
            {translate({ id: 'CLIENT_CONSENT_CONTENT_CHANGES_BODY' }, { numUsers: userRecordCount })}
        </UiText>
        <RadioGroup
            aria-labelledby="reconsent-label"
            name="reconsent"
            value={reconsent}
            onChange={(e) => setReconsent(e.target.value === 'true')}
            style={{ paddingLeft: '12px' }}
        >
            <FormControlLabel
                value={false}
                control={<Radio color="primary" />}
                label={translate({ id: 'CLIENT_CONSENT_CONTENT_CHANGES_KEEP_RECORDS' })}
            />
            <FormControlLabel
                value
                control={<Radio color="primary" />}
                label={translate({ id: 'CLIENT_CONSENT_CONTENT_CHANGES_DELETE_RECORDS' })}
            />
        </RadioGroup>
        <UiStack
            direction='row'
            align='center'
            justify='end'
            gap={SpacingToken.XS}
        >
            <ApButton
                onClick={() => {
                    formReset();
                    closeDialog(false);
                }}
                variant='secondary'
                label={translate({ id: 'CLIENT_DISCARD_CHANGES' })}
            />
            <ApButton
                onClick={() => {
                    setFormReconsent(reconsent);
                    closeDialog(true);
                }}
                variant='primary'
                label={translate({ id: 'CLIENT_CONFIRM' })}
            />
        </UiStack>
    </>;
};
