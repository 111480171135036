import { AccountLicense } from '@experiences/constants';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useRouteResolver } from '@experiences/util';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import { AiTrustLayerComponentWithProviders } from '../../component/aiTrustLayer/AiTrustLayerComponent';
import { AuditDialogComponentLegacy } from '../../component/audit/AuditDialogComponentLegacy';
import { AuditPageWithProvider } from '../../component/audit/AuditPage';
import { AuditLegacyRoutingRedirectWithProvider } from '../../component/audit/AuditRedirect';
import { ConfigureSSOComponent } from '../../component/authsettings/subcomponents/ConfigureSSOComponent';
import { CreateEditSAMLProvisioningRuleComponentWithParams } from '../../component/authsettings/subcomponents/CreateEditSAMLProvisioningRuleComponent';
import { SAMLProvisioningRulesComponentWithProviders } from '../../component/authsettings/subcomponents/SAMLProvisioningRulesComponent';
import EcommerceManageLicensePendingChangeComponent from '../../component/ecommerce/EcommerceManageLicensePendingChangeComponent';
import EcommerceMonthlyUpsellComponent from '../../component/ecommerce/EcommerceMonthlyUpsellComponent';
import ExternalApplicationRegistrationDetailsDrawerComponent
    from '../../component/externalApps/appRegistration/ExternalApplicationRegistrationDetailsDrawerComponent';
import { ExternalAppsWithProvider } from '../../component/externalApps/ExternalApps';
import ExternalApplicationsPATSettingsDrawerComponent
    from '../../component/externalApps/pat/ExternalApplicationsPATSettingsDrawerComponent';
import { CreateEditExternalApplicationsWithProviders } from '../../component/externalApps/subcomponents/CreateEditExternalApplicationsPageComponent';
import { ExternalApplicationsScopesDrawerComponentWithParams } from '../../component/externalApps/subcomponents/ExternalApplicationsScopesDrawerComponent';
import { LicensesWithParams } from '../../component/licensing/Licenses';
import ActivateLicenseOfflineComponent from '../../component/licensing/subcomponents/ActivateLicenseOfflineComponent';
import ActivateLicenseOnlineComponent from '../../component/licensing/subcomponents/ActivateLicenseOnlineComponent';
import { AddEditGroupAllocationComponentWithParams } from '../../component/licensing/subcomponents/AddEditGroupAllocationComponent';
import AddUserAllocationComponent from '../../component/licensing/subcomponents/AddUserAllocationComponent';
import EcommerceSuccessComponent from '../../component/licensing/subcomponents/EcommerceSuccessComponent';
import EditUserAllocationComponent from '../../component/licensing/subcomponents/EditUserAllocationComponent';
import InheritedLicenseComponent from '../../component/licensing/subcomponents/InheritedLicenseComponent';
import ViewUserAllocationsInGroupComponent from '../../component/licensing/subcomponents/ViewUserAllocationsInGroupComponent';
import UpgradeToEnterpriseDialogComponent from '../../component/licensing/UpgradeToEnterpriseDialogComponent';
import { ManageAccessRoutes } from '../../component/manageAccess/ManageAccessRoutes';
import { OrganizationEmailSettingsRevampComponentWithProvider } from '../../component/organizationsettings/email/OrganizationEmailSettingsRevampComponent';
import OrganizationAdminHomeComponent from '../../component/organizationsettings/OrganizationAdminHome';
import { OrganizationSettingsPageComponentWithProvider } from '../../component/organizationsettings/OrganizationSettingsPageComponent';
import AccountLogicalNameWarningDialogComponent
    from '../../component/organizationsettings/subcomponents/AccountLogicalNameWarningDialogComponent';
import { OrgMigrationDetailsDrawer }
    from '../../component/organizationsettings/subcomponents/OrgMigration/OrgMigrationDetailsDrawer';
import { CreateEditOrgConsentPageComponent } from '../../component/orgConsent/CreateEditOrgConsentPageComponent';
import OrgConsentPageComponent from '../../component/orgConsent/OrgConsentPageComponent';
import AddOrganizationAccessPolicyMembersDrawerComponent
    from '../../component/securitySettings/accessPolicy/add/AddOrganizationAccessPolicyMembersDrawerComponent';
import { AttributeMappingPageComponentWithProviders } from '../../component/securitySettings/attributeMapping/AttributeMappingPageComponent';
import { ConfigureSSOPageWithProvider } from '../../component/securitySettings/ConfigureSSOPage';
import { SecuritySettingsPageComponentWithParams } from '../../component/securitySettings/SecuritySettings';
import { EncryptionConfigurationComponentWithParams } from '../../component/securitySettings/subcomponents/Encryption/EncryptionConfigureComponent';
import { AddEditIPRestrictionComponentWithParams } from '../../component/securitySettings/subcomponents/IPRestriction/AddEditIPRestrictionComponent';
import IPRestrictionBulkImportComponent from '../../component/securitySettings/subcomponents/IPRestriction/IPRestrictionBulkImportComponent';
import { AddEditTenantTagsLabelsComponentWithProvider } from '../../component/tags/addedit/labels/AddEditTenantTagsLabelsComponent';
import { AddEditTenantTagsPropertiesComponentWithProvider } from '../../component/tags/addedit/properties/AddEditTenantTagsPropertiesComponent';
import { AddEditTenantTagsPropertiesDrawerWithProvider } from '../../component/tags/addedit/properties/AddEditTenantTagsPropertiesDrawer';
import { TenantTagsComponentWithProvider } from '../../component/tags/base/TenantTagsViewComponent';
import TenantCreateComponent from '../../component/tenants/create/TenantCreateComponent';
import TenantAdminHome from '../../component/tenants/home/TenantAdminHome';
import { TenantMigrationDetailsDrawer } from '../../component/tenants/migrate/TenantMigrationDetailsDrawer';
import { TenantMigrationPage }
    from '../../component/tenants/migrate/TenantMigrationPage';
import { TenantServicesAddComponent } from '../../component/tenants/services/TenantServicesAddComponent';
import { TenantServicesComponentWithProvider } from '../../component/tenants/services/TenantServicesComponent';
import { TenantSettingsComponentWithProvider } from '../../component/tenants/settings/TenantSettingsComponent';
import ConfigureTenantLicensesComponent from '../../component/tenants/subcomponents/ConfigureTenantLicenseComponent';
import LogExportComponent from '../../component/tenants/subcomponents/LogExportComponent';
import SkipServiceConfirmationDialogComponent from '../../component/tenants/subcomponents/SkipServiceConfirmationDialogComponent';
import { ServicesUsagePageComponentWithProviders } from '../../component/tenants/subcomponents/usage/ServicesUsagePageComponent';
import { AddEditRobotAccountComponentWithParams } from '../../component/users/subcomponents/AddEditRobotAccountComponent';
import BulkInviteUserComponent from '../../component/users/subcomponents/BulkInviteUserComponent';
import { CreateEditGroupComponentWithParams } from '../../component/users/subcomponents/CreateEditGroupComponent';
import { InviteEditUserComponentWithParams } from '../../component/users/subcomponents/InviteEditUserComponent';
import { UsersPageComponentWithProvider } from '../../component/users/UsersPageComponent';
import { VpnGatewayAddComponent } from '../../component/vpnGateway/VpnGatewayAddComponent';
import { VpnGatewayAddEditConnectionComponentWithProviders } from '../../component/vpnGateway/VpnGatewayAddEditConnectionComponent';
import { VpnGatewayComponentWithProvider } from '../../component/vpnGateway/VpnGatewayComponent';
import { VpnGatewayConnectionsGridWithProviders } from '../../component/vpnGateway/VpnGatewayConnectionsGrid';
import {
    accountLogicalName,
    accountType as accountTypeSelector,
} from '../../store/selectors';
import CheckGuard from '../helpers/CheckGuard';
import { NavigateWithParams } from './NavigateWithParams';
import type { UiRouteObject } from './UiRouteObject';

const useOrgAdminPrivateShellRoutes = () => {
    const EnableEcommerceIntegration = useFeatureFlagValue(Features.EnableEcommerceIntegration.name);
    const EnableAirgappedLicenseActivation = useFeatureFlagValue(Features.EnableAirgappedLicenseActivation.name);
    const EnabledScheduledTenantMigration = useFeatureFlagValue(Features.EnabledScheduledTenantMigration.name);
    const EnableAttributeMapping = useFeatureFlagValue(Features.EnableAttributeMapping.name);
    const EnableUnifiedAuditExperience = useFeatureFlagValue(Features.EnableUnifiedAuditExperience.name);
    const EnableGovernanceModuleFederation = useFeatureFlagValue(Features.EnableGovernanceModuleFederation.name);
    const EnableOrgConsent = useFeatureFlagValue(Features.EnableAdminOrgConsent.name);

    const accountType = useSelector(accountTypeSelector);
    const accountName = useSelector(accountLogicalName);
    const {
        isAccountLicenseEnterpriseOrPro, isOffersRevampAndCommunity, isPro,
    } = useCheckLicense();

    const getRoute = useRouteResolver();

    return useMemo<UiRouteObject[]>(() => [
        {
            path: RouteNames.TenantSettings,
            element: <TenantSettingsComponentWithProvider />,
            children: [
                {
                    path: RouteNames.EditTenantMigration,
                    element: <TenantMigrationDetailsDrawer />,
                },
            ],
        },
        {
            path: RouteNames.TenantMigration,
            element: CheckGuard(EnabledScheduledTenantMigration, <TenantMigrationPage />),
        },
        {
            path: RouteNames.TenantServices,
            element: <TenantServicesComponentWithProvider />,
            children: [
                {
                    path: RouteNames.TenantServicesAdd,
                    element: <TenantServicesAddComponent />,
                },
                {
                    path: RouteNames.TenantConfigureService,
                    element: <ConfigureTenantLicensesComponent />,
                },
                {
                    path: `${RouteNames.TenantConfigureService}/:serviceType`,
                    element: <ConfigureTenantLicensesComponent />,
                },
                {
                    path: RouteNames.TenantServiceLogExport,
                    element: <LogExportComponent />,
                },
            ],
        },
        {
            path: RouteNames.TenantTagsPropertiesEdit,
            element: <AddEditTenantTagsPropertiesComponentWithProvider />,
            children: [
                {
                    path: RouteNames.TenantTagsPropertiesDrawerEdit,
                    element: <AddEditTenantTagsPropertiesDrawerWithProvider />,
                },
            ],
        },
        {
            path: RouteNames.TenantTagsPropertiesAdd,
            element: <AddEditTenantTagsPropertiesComponentWithProvider />,
            children: [
                {
                    path: RouteNames.TenantTagsPropertiesDrawerAdd,
                    element: <AddEditTenantTagsPropertiesDrawerWithProvider />,
                },
            ],
        },
        {
            path: RouteNames.TenantTagsLabelsEdit,
            element: <AddEditTenantTagsLabelsComponentWithProvider />,
        },
        {
            path: RouteNames.TenantTagsLabelsAdd,
            element: <AddEditTenantTagsLabelsComponentWithProvider />,
        },
        {
            path: RouteNames.TenantTags,
            children: [
                {
                    path: RouteNames.TenantTagsLabels,
                    element: <TenantTagsComponentWithProvider />,
                },
                {
                    path: RouteNames.TenantTagsProperties,
                    element: <TenantTagsComponentWithProvider />,
                },
            ],
        },
        {
            path: RouteNames.VpnGateway,
            element: <VpnGatewayComponentWithProvider />,
            children: [
                {
                    path: RouteNames.VpnGatewayCreateEdit,
                    element: <VpnGatewayAddComponent />,
                },
            ],
        },
        {
            path: RouteNames.VpnGatewayConnectionsCreateEdit,
            element: <VpnGatewayAddEditConnectionComponentWithProviders />,
        },
        {
            path: RouteNames.VpnGatewayConnections,
            element: <VpnGatewayConnectionsGridWithProviders />,
        },
        {
            path: RouteNames.TenantAudit,
            element: CheckGuard(
                EnableUnifiedAuditExperience,
                <AuditPageWithProvider />,
            ),
        },
        {
            path: RouteNames.TenantHome,
            element: <TenantAdminHome />,
        },
        {
            path: `${RouteNames.PortalAdminPrefix}/tenant/:tenantId`,
            element: <NavigateWithParams
                to={RouteNames.TenantHome}
                replace
            />,
        },
        {
            path: RouteNames.Services,
            element: <Navigate to={getRoute(RouteNames.OrganizationAdminHome)} />,
            children: [
                {
                    path: RouteNames.TenantLicensingUpgradeToEnterprise,
                    element: <UpgradeToEnterpriseDialogComponent />,
                },
                {
                    path: RouteNames.TenantConfigureSkip,
                    element: <SkipServiceConfirmationDialogComponent />,
                },
                {
                    path: RouteNames.TenantConfigure,
                    element: <ConfigureTenantLicensesComponent />,
                },
                {
                    path: RouteNames.OrchLogExport,
                    element: <LogExportComponent />,
                },
            ],
        },
        {
            path: RouteNames.Users,
            element: <UsersPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.UserInviteEdit,
                    element: <InviteEditUserComponentWithParams />,
                },
                {
                    path: RouteNames.UsersUserAddLicenseAllocation,
                    element: <AddUserAllocationComponent />,
                },
                {
                    path: RouteNames.UsersUserEditLicenseAllocation,
                    element: <EditUserAllocationComponent />,
                },
                {
                    path: RouteNames.UsersUserLicenseInheritance,
                    element: <InheritedLicenseComponent />,
                },
                {
                    path: RouteNames.BulkUserInvite,
                    element: <BulkInviteUserComponent />,
                },
            ],
        },
        {
            path: RouteNames.Groups,
            element: <UsersPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.GroupsEdit,
                    element: <CreateEditGroupComponentWithParams />,
                },
                {
                    path: RouteNames.GroupsAdd,
                    element: <CreateEditGroupComponentWithParams />,
                },
                {
                    path: RouteNames.GroupAllocationRule,
                    element: <AddEditGroupAllocationComponentWithParams />,
                },
            ],
        },
        {
            path: RouteNames.Robots,
            element: <UsersPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.RobotsAddEdit,
                    element: <AddEditRobotAccountComponentWithParams />,
                },
            ],
        },
        {
            path: RouteNames.Licensing,
            element: <LicensesWithParams />,
            children: [
                {
                    path: RouteNames.LicensingUpgradeToEnterprise,
                    element: <UpgradeToEnterpriseDialogComponent />,
                },
                {
                    path: RouteNames.LicensingUserAddLicenseAllocation,
                    element: <AddUserAllocationComponent />,
                },
                {
                    path: RouteNames.LicensingUserEditLicenseAllocation,
                    element: <EditUserAllocationComponent />,
                },
                {
                    path: RouteNames.LicensingUserLicenseInheritance,
                    element: <InheritedLicenseComponent />,
                },
                {
                    path: RouteNames.LicensingGroupAllocationAddEdit,
                    element: <AddEditGroupAllocationComponentWithParams />,
                },
                {
                    path: RouteNames.LicensingGroupAllocationView,
                    element: <ViewUserAllocationsInGroupComponent />,
                },
                {
                    path: RouteNames.LicensingTenantConfigure,
                    element: <ConfigureTenantLicensesComponent />,
                },
                {
                    path: RouteNames.BuyProSuccess,
                    element: CheckGuard(EnableEcommerceIntegration,
                        <EcommerceSuccessComponent />),
                },
                {
                    path: RouteNames.ViewCancelLicensePendingChange,
                    element:
                        CheckGuard(
                            EnableEcommerceIntegration && isAccountLicenseEnterpriseOrPro(accountType),
                            <EcommerceManageLicensePendingChangeComponent />
                            ,
                        ),
                },
                {
                    path: RouteNames.ActivateOffline,
                    element:
                        CheckGuard(
                            EnableAirgappedLicenseActivation,
                            <ActivateLicenseOfflineComponent />,
                        ),
                },
                {
                    path: RouteNames.LicensingActivateLicenseOnline,
                    element:
                        isPro
                            ? <EcommerceMonthlyUpsellComponent />
                            : <ActivateLicenseOnlineComponent />,
                },
            ],
        },
        {
            path: RouteNames.OrganizationAdminHome,
            element: <OrganizationAdminHomeComponent />,
        },
        {
            path: RouteNames.TenantCreate,
            element: <TenantCreateComponent />,
        },
        {
            path: RouteNames.OrganizationSettings,
            element: <OrganizationSettingsPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.OrganizationSettingsWarning,
                    element: <AccountLogicalNameWarningDialogComponent />,
                },
            ],
        },
        {
            path: RouteNames.OrganizationSettingsAdvanced,
            element: <OrganizationSettingsPageComponentWithProvider />,
            children: [
                {
                    path: RouteNames.EditOrganizationMigration,
                    element: <OrgMigrationDetailsDrawer />,
                },
            ],
        },
        {
            path: RouteNames.EmailSettings,
            element: <OrganizationEmailSettingsRevampComponentWithProvider />,
        },
        {
            path: RouteNames.AuditLogs,
            element: <AuditLegacyRoutingRedirectWithProvider />,
            children: [
                {
                    path: RouteNames.AuditLogDialog,
                    element: <AuditDialogComponentLegacy />,
                },
            ],
        },
        {
            path: RouteNames.Audit,
            element: <AuditLegacyRoutingRedirectWithProvider unifiedAuditRoute />,
        },
        {
            path: RouteNames.ExternalApplications,
            element: <ExternalAppsWithProvider />,
        },
        {
            path: RouteNames.ExternalApplicationsPAT,
            element: <ExternalAppsWithProvider />,
            children: [
                {
                    path: RouteNames.ExternalApplicationsPATSettings,
                    element: <ExternalApplicationsPATSettingsDrawerComponent />,
                },
            ],
        },
        {
            path: RouteNames.ExternalAppRegistrations,
            element: <ExternalAppsWithProvider />,
            children: [
                {
                    path: RouteNames.ExternalAppRegistrationsDetails,
                    element: <ExternalApplicationRegistrationDetailsDrawerComponent />,
                },
            ],
        },
        {
            path: RouteNames.ExternalApplicationAdd,
            element: <CreateEditExternalApplicationsWithProviders />,
            children: [
                {
                    path: RouteNames.ExternalApplicationAddScopesWithName,
                    element: <ExternalApplicationsScopesDrawerComponentWithParams />,
                },
                {
                    path: RouteNames.ExternalApplicationAddScopes,
                    element: <ExternalApplicationsScopesDrawerComponentWithParams />,
                },
            ],
        },
        {
            path: RouteNames.ExternalApplicationEdit,
            element: <CreateEditExternalApplicationsWithProviders />,
            children: [
                {
                    path: RouteNames.ExternalApplicationEditScopesWithName,
                    element: <ExternalApplicationsScopesDrawerComponentWithParams />,
                },
                {
                    path: RouteNames.ExternalApplicationEditScopes,
                    element: <ExternalApplicationsScopesDrawerComponentWithParams />,
                },
            ],
        },
        {
            path: RouteNames.ServicesUsage,
            element: <ServicesUsagePageComponentWithProviders />,
            children: [
                {
                    path: RouteNames.TenantServicesUsageAllocation,
                    element: <ConfigureTenantLicensesComponent />,
                },
            ],
        },
        {
            path: RouteNames.AuthSettingsDeprecated,
            element: <Navigate to={RouteNames.SecuritySettings.replace(':accountName', accountName)} />,
        },
        {
            path: RouteNames.AuthSettingsDeprecatedConfigure,
            element: <Navigate
                to={RouteNames.SecuritySettingsConfigure.replace(':accountName', accountName).replace(':type', 'aad')} />,
        },
        {
            path: RouteNames.SecuritySettingsConfigure,
            element: <ConfigureSSOPageWithProvider />,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRules,
            element: <SAMLProvisioningRulesComponentWithProviders />,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRulesAdd,
            element: <CreateEditSAMLProvisioningRuleComponentWithParams />,
        },
        {
            path: RouteNames.SecuritySettingsSAMLProvisioningRulesEdit,
            element: <CreateEditSAMLProvisioningRuleComponentWithParams />,
        },
        {
            path: RouteNames.SecuritySettingsAttributeMapping,
            element:
                CheckGuard(
                    EnableAttributeMapping &&
                    (AccountLicense[accountType] <= AccountLicense.TRIAL || isOffersRevampAndCommunity),
                    <AttributeMappingPageComponentWithProviders />
                ),
        },
        {
            path: RouteNames.SessionPolicy,
            element:
                CheckGuard(
                    (AccountLicense[accountType] <= AccountLicense.TRIAL || isOffersRevampAndCommunity),
                    <SecuritySettingsPageComponentWithParams />,
                ),
        },
        {
            path: RouteNames.IPRestriction,
            element:
                CheckGuard(
                    (AccountLicense[accountType] <= AccountLicense.TRIAL || isOffersRevampAndCommunity),
                    <SecuritySettingsPageComponentWithParams />,
                ),
            children:
            (AccountLicense[accountType] <= AccountLicense.PRO
                ? [
                    {
                        path: RouteNames.IPRestrictionAdd,
                        element: <AddEditIPRestrictionComponentWithParams />,
                    },
                    {
                        path: RouteNames.IPRestrictionEdit,
                        element: <AddEditIPRestrictionComponentWithParams />,
                    },
                    {
                        path: RouteNames.IPRestrictionBulkImport,
                        element: <IPRestrictionBulkImportComponent />,
                    },
                ]
                : undefined),
        },
        {
            path: RouteNames.Encryption,
            element: <SecuritySettingsPageComponentWithParams />,
            children: (AccountLicense[accountType] === AccountLicense.ENTERPRISE)
                ? [
                    {
                        path: RouteNames.EncryptionConfigure,
                        element: <EncryptionConfigurationComponentWithParams />,
                    },
                ]
                : undefined,
        },
        {
            path: RouteNames.OrganizationAccessPolicy,
            element: <SecuritySettingsPageComponentWithParams />,
            children: [
                {
                    path: RouteNames.OrganizationAccessPolicyAdd,
                    element: <AddOrganizationAccessPolicyMembersDrawerComponent />,
                },
            ],
        },
        {
            path: RouteNames.SecuritySettings,
            element: <SecuritySettingsPageComponentWithParams />,
            children: [
                {
                    path: RouteNames.ConfigureSSO,
                    element: <ConfigureSSOComponent />,
                },
            ],
        },
        ManageAccessRoutes,
        {
            path: RouteNames.AiTrustLayerSetting,
            element:
                CheckGuard(EnableGovernanceModuleFederation, <AiTrustLayerComponentWithProviders />),
        },
        {
            path: RouteNames.UserConsent,
            element: CheckGuard(
                EnableOrgConsent && AccountLicense[accountType] <= AccountLicense.PRO,
                <OrgConsentPageComponent />
            ),
        },
        {
            path: RouteNames.UserConsentCreate,
            element: CheckGuard(
                EnableOrgConsent && AccountLicense[accountType] <= AccountLicense.PRO,
                <CreateEditOrgConsentPageComponent />
            ),
        },
        {
            path: RouteNames.UserConsentEdit,
            element: CheckGuard(
                EnableOrgConsent && AccountLicense[accountType] <= AccountLicense.PRO,
                <CreateEditOrgConsentPageComponent />
            ),
        },
    ], [
        EnabledScheduledTenantMigration,
        EnableUnifiedAuditExperience,
        getRoute,
        EnableEcommerceIntegration,
        isAccountLicenseEnterpriseOrPro,
        accountType,
        EnableAirgappedLicenseActivation,
        isPro,
        accountName,
        EnableAttributeMapping,
        isOffersRevampAndCommunity,
        EnableGovernanceModuleFederation,
        EnableOrgConsent,
    ]);
};

export default useOrgAdminPrivateShellRoutes;
