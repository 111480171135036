import { Region } from '@experiences/constants';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { getFriendlyName } from '../../../common/constants/ServicesMapping';
import { getListOfParents } from './helpers/ServiceDependencyGraph';
import { useServiceDependency } from './helpers/useServiceDependency';

const useStyles = makeStyles(theme =>
    createStyles({
        deleteServiceConfirmation: { maxWidth: '417px' },
        deleteText: { color: theme.palette.semantic.colorErrorText },
        deleteButton: {
            color: theme.palette.semantic.colorForegroundInverse,
            backgroundColor: theme.palette.semantic.colorErrorIcon,
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        confirmDeleteSection: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        confirmDeleteWarningSection: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        textField: {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            width: '100%',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const ServiceDeleteDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog,
    tenant,
    service,
    tenantEdit,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const { servicesToHide } = useServiceDependency();
    const [ textFieldValue, setTextFieldValue ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const childDependencyList = useMemo(() => getListOfParents(service), [ service ]);

    const childrenString = useMemo(() => childDependencyList
        .filter(child => !servicesToHide.includes(child))
        .map(child => getFriendlyName(child))
        .join(', '), [ childDependencyList, servicesToHide ]);

    const showDependencyDeletionWarning = useMemo(() =>
        childDependencyList.filter(child => !servicesToHide.includes(child)).length > 0, [ childDependencyList, servicesToHide ]);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValue(event.target.value);
    }, []);

    const deleteAndClose = useCallback(async () => {
        setLoading(true);

        const removedService: { [key: string]: boolean } = {};
        childDependencyList.forEach(child => (removedService[child] = false));
        removedService[service] = false;
        try {
            await tenantEdit({
                name: tenant.name,
                services: [],
                color: tenant.color,
                region: Region.None,
            }, tenant, removedService);
            closeDialog(true);
        } catch (error) {
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
            setLoading(false);
        }
    }, [
        childDependencyList,
        service,
        tenantEdit,
        tenant,
        closeDialog,
        getErrorObject,
        getErrorMessage,
        setErrorMessage,
    ]);

    return (
        <div className={classes.deleteServiceConfirmation}>
            <UiText data-cy="delete-confirmation-notes">
                {translate({ id: 'CLIENT_DELETE_SERVICE_CONFIRMATION' }, { 0: getFriendlyName(service) })}
            </UiText>
            {showDependencyDeletionWarning && (
                <div className={classes.confirmDeleteWarningSection}>
                    <UiText data-cy="delete-confirmation-type-warning">
                        <FormattedMessage
                            id="CLIENT_SERVICE_DELETE_WARNING"
                            values={{
                                strong: (chunk: React.ReactNode[]) => <strong>
                                    {chunk}
                                </strong>,
                                em: (chunk: React.ReactNode[]) => <em>
                                    {chunk}
                                </em>,
                                service: <span>
                                    {getFriendlyName(service)}
                                </span>,
                                dependency: <span>
                                    {childrenString}
                                </span>,
                            }}
                        />
                    </UiText>
                </div>
            )}
            <div className={classes.confirmDeleteSection}>
                <UiText data-cy="delete-confirmation-type-reminder">
                    <FormattedMessage
                        id="CLIENT_SERVICE_DELETE"
                        values={{
                            strong: (chunk: React.ReactNode[]) => <strong>
                                {chunk}
                            </strong>,
                            0: <span className={classes.deleteText}>
                                {getFriendlyName(service)}
                            </span>,
                        }}
                    />
                </UiText>

                <TextField
                    className={classes.textField}
                    autoComplete="off"
                    variant="outlined"
                    id="standard-name"
                    onChange={handleChange}
                    value={textFieldValue}
                    placeholder={translate({ id: 'CLIENT_SERVICE_DELETE_CONFIRMATION_PLACEHOLDER' })}
                    data-cy="delete-confirmation-textfield"
                />
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    key="cancelButton"
                    className={classes.cancelButton}
                    onClick={() => closeDialog()}
                    color="primary"
                    data-cy="secondary-button-confirmation"
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiProgressButton
                    loading={loading}
                    key="primaryButton"
                    variant="contained"
                    color="inherit"
                    data-cy="primary-button-confirmation"
                    onClick={() => deleteAndClose()}
                    disabled={textFieldValue !== getFriendlyName(service)}
                    classes={{ colorInherit: classes.deleteButton }}
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </UiProgressButton>
            </div>
        </div>
    );
};

export default ServiceDeleteDialogBody;
