import { useLocalization } from '@experiences/locales';
import {
    init,
    loadRemote,
    registerRemotes,
} from '@module-federation/runtime';
import {
    useEffect,
    useState,
} from 'react';

import { RemoteModuleMapping } from './RemoteModuleMapping';

export const useRemoteModuleViewModel = (
    serviceName: string,
    host: React.RefObject<HTMLDivElement>,
    currentAccountLogicalName: string,
    moduleUrl: string,
) => {
    const [ remoteEntryLoaded, setRemoteEntryLoaded ] = useState<boolean>(false);
    const [ error, setError ] = useState<string>();
    const locale = useLocalization();

    // First useEffect for initialization
    useEffect(() => {
        init({
            name: 'main',
            remotes: [],
        });
    }, []);

    // Second useEffect for handling moduleUrl changes
    useEffect(() => {
        const loadComponent = async () => {
            if (!moduleUrl || !RemoteModuleMapping[serviceName]) {
                return;
            }

            try {
                registerRemotes([
                    {
                        name: RemoteModuleMapping[serviceName].options.remoteName,
                        entry: moduleUrl,
                    },
                ], { force: true });

                const module = await loadRemote(RemoteModuleMapping[serviceName].options.exposedModule) as any;
                module.attachMicrofrontend(
                    host.current,
                    locale,
                    `${window.origin}/${currentAccountLogicalName}/${serviceName}_}`,
                    currentAccountLogicalName
                );

                setRemoteEntryLoaded(true);
            } catch (err) {
                setError((err as Error).message);
            }
        };

        loadComponent();
    }, [ moduleUrl, serviceName, host, locale, currentAccountLogicalName ]);

    return {
        remoteEntryLoaded,
        error,
    };
};
