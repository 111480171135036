import { UiText } from '@experiences/ui-common';
import { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';

import { UiStack } from '../../../common/UiStack';
import { UiAccordion } from '../../../licensing/UiAccordion';

const A4ECustomizeComponent: React.FC = () => <UiStack
    direction='column'
    data-cy='tenant-customize'>
    <UiText variant={FontVariantToken.fontSizeL}>
Customize Autopilot for Everyone experience
    </UiText>
    {/* TODO add customize options */}
    <UiAccordion titleTranslationCode="STARTING_PROMPTS">
        <UiText>
Customize Contents
        </UiText>
    </UiAccordion>
</UiStack>;

export default A4ECustomizeComponent;
