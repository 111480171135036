import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import { ApButton } from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useMemo,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import useSWR from 'swr';

import {
    SpacingToken,
    UiStack,
} from '../../component/common/UiStack';
import { TenantStatusConstants } from '../../component/tenants/TenantConstants';
import { useTenantsContext } from '../../component/tenants/TenantsContextProvider';
import {
    AX_DIRECTORY_ID,
    getOrchRoleExistsForGroup,
    ORCH_ROLE,
    setOrchRoleForGroup,
} from '../../services/orchestrator/OrchRoleAssignmentService';
import { notificationType } from '../constants/Constant';
import type { ITenantService } from '../interfaces/tenant/tenant';
import {
    DeliveryType,
    ProductType,
    useDocumentationLinks,
} from './useDocumentationLink';
import { useUiSnackBar } from './useUiSnackBar';

export const useOrchRoleAssignmentForAX = ({
    tenantId, fetchRoles = true,
}: { tenantId?: string; fetchRoles?: boolean } = {}) => {
    const { formatMessage: translate } = useIntl();
    const createDialog = useShowDialog();
    const EnableOrchRoleAssignmentForAX = useFeatureFlagValue(Features.EnableOrchRoleAssignmentForAX.name);
    const createNotification = useUiSnackBar();
    const { data: tenants } = useTenantsContext();

    const validTenantsWithOrchExists = useMemo(() => EnableOrchRoleAssignmentForAX && tenants?.filter(
        (t: ITenantService) => t.tenant.status.toUpperCase() === TenantStatusConstants.ENABLED
            && t.tenant.tenantServiceInstances.some(s => s.serviceType === 'orchestrator' && s.status.toUpperCase() === TenantStatusConstants.ENABLED),
    ).length > 0, [ EnableOrchRoleAssignmentForAX, tenants ]);

    const {
        data: tenantWithOrchRole, isLoading: isLoadingTenantOrchRoleInfo, mutate, error,
    } = useSWR(validTenantsWithOrchExists && fetchRoles ? {
        tenantId,
        groupId: AX_DIRECTORY_ID,
        role: ORCH_ROLE.ALLOW_TO_BE_AUTOMATION_USER_ROLE,
    } : null, getOrchRoleExistsForGroup);

    const showUseOrchRoleAssignmentDialogForAX = useCallback(async () => {
        // Do not show this dialog if flag is off or there exists any tenant with the role
        if (!validTenantsWithOrchExists || tenantWithOrchRole || error) {
            return;
        }

        const proceed = await createDialog({
            icon: 'warning',
            title: translate({ id: 'CLIENT_ORCH_ROLE_ASSIGNMENT_DIALOG_TITLE' }),
            customDialogContent: OrchRoleAssignmentDialogBodyForAX,
            width: '512px',
        });

        if (proceed) {
            try {
                createNotification(translate({ id: 'CLIENT_ORCH_ROLE_ASSIGNMENT_NOTIFICATION_INPROGRESS' }), notificationType.INPROGRESS);
                await setOrchRoleForGroup({
                    groupId: AX_DIRECTORY_ID,
                    role: ORCH_ROLE.ALLOW_TO_BE_AUTOMATION_USER_ROLE,
                    tenantId,
                });
                mutate();
                createNotification(translate({ id: 'CLIENT_ORCH_ROLE_ASSIGNMENT_NOTIFICATION_SUCCESS' }), notificationType.SUCCESS);
            } catch (e) {
                createNotification(translate({ id: 'CLIENT_ORCH_ROLE_ASSIGNMENT_NOTIFICATION_FAILED' }), notificationType.ERROR);
            }
        }
    }, [
        validTenantsWithOrchExists,
        tenantWithOrchRole,
        createDialog,
        translate,
        tenantId,
        createNotification,
        mutate,
        error,
    ]);

    return {
        showUseOrchRoleAssignmentDialogForAX,
        isLoadingTenantOrchRoleInfo,
    };
};

export const OrchRoleAssignmentDialogBodyForAX: React.FC<IUiDialogHookCustomContent> = ({ closeDialog }) => {
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useDocumentationLinks();

    const docLink = useMemo(() => getLocalizedLink({
        product: ProductType.ORCHESTRATOR,
        delivery: DeliveryType.AUTOMATION_CLOUD,
        version: 'latest',
        articleSlug: '/user-guide/default-roles#allow-to-be-automation-user',
    }), [ getLocalizedLink ]);

    return (
        <>
            <div data-testid="orch-role-assignment-body">
                <UiText>
                    <FormattedMessage
                        id='CLIENT_ORCH_ROLE_ASSIGNMENT_DIALOG_BODY_1'
                        values={{
                            b: (msg: React.ReactNode[]) => <b>
                                {msg}
                            </b>,
                        }}
                    />
                </UiText>
                <br />
                <UiText>
                    <FormattedMessage id='CLIENT_ORCH_ROLE_ASSIGNMENT_DIALOG_BODY_2' />
                </UiText>
            </div>
            <UiStack
                direction='row'
                align='center'
                justify='end'
                gap={SpacingToken.XS}
                mt={SpacingToken.XL}
                data-testid="orch-role-assignment-dialog-actions"
            >
                <ApButton
                    onClick={() => {
                        closeDialog(false);
                    }}
                    variant='tertiary'
                    label={translate({ id: 'CLIENT_ORCH_ROLE_ASSIGNMENT_DIALOG_CLOSE' })}
                    data-testid="orch-role-assignment-dialog-close-button"
                />
                <ApButton
                    variant='secondary'
                    label={translate({ id: 'CLIENT_VIEW_DOCUMENTATION' })}
                    data-testid="orch-role-assignment-dialog-view-docs-button"
                    href={docLink}
                    onClick={(e) => {
                        e.preventDefault();
                        window.open(docLink, '_blank', 'noreferrer');
                    }}
                />
                <ApButton
                    onClick={() => {
                        closeDialog(true);
                    }}
                    variant='primary'
                    label={translate({ id: 'CLIENT_ASSIGN_ROLE' })}
                    data-testid="orch-role-assignment-dialog-assign-role-button"
                />
            </UiStack>
        </>
    );
};
