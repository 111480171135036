import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { GlobalStyles } from '@experiences/theme';
import Divider from '@mui/material/Divider';
import {
    createStyles,
    makeStyles,
} from '@mui/styles';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import ConsentForm from './ConsentForm';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        headerContainer: {
            width: '100%',
            padding: '8px 30px 0 30px',
        },
        headerDivider: { marginTop: '12px' },
    }),
}));

export const CreateEditOrgConsentPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();
    const { id } = useParams() as { id: string };

    const handleError = useCallback(
        async (error: any) => setErrorMessage(await getErrorMessage(error)),
        [ getErrorMessage, setErrorMessage ],
    );

    const breadcrumbLinks = useMemo(() => [
        {
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            link: RouteNames.UserConsent,
            name: translate({ id: 'CLIENT_USER_CONSENT' }),
        },
        {
            link: id ? RouteNames.UserConsentEdit : RouteNames.UserConsentCreate,
            name: translate({ id: id ? 'CLIENT_USER_CONSENT_EDIT' : 'CLIENT_USER_CONSENT_CREATE' }),
        },
    ], [ id, organizationName, translate ]);

    return (
        <UiPageContainer
            breadcrumb={<div className={classes.headerContainer}>
                <AdminBreadCrumbs
                    breadCrumbTrail={breadcrumbLinks}
                    disablePadding />
                <Divider className={classes.headerDivider} />
            </div>}
            position='center'
            tenantShellHidden
            enableBackground
            data-cy={`org-consent-${id ? 'edit' : 'create'}-page`}
            disableGutter={[ 'top' ]}
        >
            <ConsentForm onError={handleError} />
        </UiPageContainer>
    );
};
