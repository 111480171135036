import { getEnvVariableValue } from '@experiences/util';

import type { sidebarData } from '../../common/interfaces/sidebar';

export const SidebarK8s: sidebarData = {
    'sidebarContent': {
        'downloadStudioPosition': 0,
        'sidebarLinkContainersCollection': {
            'items': [
                {
                    'title': 'RESOURCES',
                    'sidebarLinksCollection': {
                        'items': [
                            {
                                'titleText': 'QUESTIONS',
                                'linkHref': `https://docs.uipath.com/automation-suite/automation-suite/${getEnvVariableValue('DOCUMENTATION_VERSION')}`,
                                'linkText': 'VISIT',
                            }, {
                                'titleText': 'AUTOMATION',
                                'linkHref': 'https://forum.uipath.com',
                                'linkText': 'COMMUNITY',
                            }, {
                                'titleText': 'TECHNICAL',
                                'linkHref': 'https://www.uipath.com/support',
                                'linkText': 'SUPPORT',
                            },
                        ],
                    },
                },
            ],
        },
    },
};
