import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { GlobalStyles } from '@experiences/theme';
import {
    UiProgressButton,
    UiSelect,
    useUiDataContext,
} from '@experiences/ui-common';
import { useModalState } from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import Info from '@mui/icons-material/Info';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import clsx from 'clsx';
import { produce } from 'immer';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useEffect,
} from 'react';
import {
    useFieldArray,
    useForm,
} from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
    CharacterLimitForNames,
    notificationType,
} from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type {
    DnsServer,
    ICreateEditVpnGatewayData,
    ICreateEditVpnGatewayPayload,
} from '../../services/hypervisor';
import {
    createVpnGateway,
    editVpnGateway,
} from '../../services/hypervisor';
import { accountLogicalName } from '../../store/selectors';
import { UiDrawer } from '../common/UiDrawer';
import UiForm from '../common/UiForm';
import { validateIPv4Range } from '../securitySettings/subcomponents/IPRestriction/IPRestrictionUtil';
import type { IVpnGatewayContext } from './interfaces/gateway';
import useVpnGatewayViewModel from './VpnGatewayViewModel';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        inputLabel: {
            fontWeight: 600,
            fontSize: '14px',
            color: theme.palette.semantic.colorForegroundDeEmp,
        },
        cancelButton: { marginRight: '10px' },
        button: { width: '100px ' },
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        fieldLabel: {
            display: 'inline-flex',
            paddingTop: '25px',
        },
        dnsServersSection: {
            display: 'flex',
            alignItems: 'center',
        },
        deleteButton: {
            color: theme.palette.semantic.colorErrorIcon,
            top: '14px',
            width: '24px',
        },
        firstChild: { top: '30px' },
        firstChildError: { top: '20px' },
        addMoreButton: {
            alignSelf: 'flex-start',
            paddingTop: '20px',
        },
        selectLabel: {
            position: 'relative',
            display: 'inline-flex',
            top: '21px',
        },
    }),
}));

export const VpnGatewayAddComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();
    const expressRouteEnabled = useFeatureFlagValue(Features.ExpressRouteEnabled.name);
    const {
        type, tenantId,
    } = useParams() as { type: 'add' | 'edit'; tenantId: string };
    const accountName = useSelector(accountLogicalName);

    const { setData } = useUiDataContext<IVpnGatewayContext>();

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const {
        gatewayData,
        refreshData,
    } = useVpnGatewayViewModel();

    const {
        open, close,
    } = useModalState(RouteNames.VpnGateway.replace(':tenantId', tenantId));

    const {
        register, handleSubmit, reset, formState: { errors }, control, watch, setValue, clearErrors,
    } = useForm({
        mode: 'onChange',
        criteriaMode: 'all',
        defaultValues: {
            Name: '',
            Type: '',
            VnetAddressSpace: '',
            DnsServers: [] as DnsServer[],
        },
    });

    const dnsServers: DnsServer[] = watch('DnsServers');

    const {
        fields, append,
    } = useFieldArray({
        control,
        name: 'DnsServers',
    });

    useEffect(() => {
        const defaults = {
            Name: gatewayData?.Name ?? '',
            Type: gatewayData?.GatewayType ?? '',
            VnetAddressSpace: gatewayData?.VnetAddressSpace ?? '',
            DnsServers: gatewayData?.DnsServers ? gatewayData?.DnsServers.map(val => ({ dnsServer: val })) : [],
        };
        reset(defaults);
    }, [ reset, gatewayData ]);

    const addDnsServer = useCallback(() => {
        append({ dnsServer: '' });
    }, [ append ]);

    const removeDnsServer = useCallback(
        (index: number) => {
            setValue(
                'DnsServers',
                dnsServers.filter((_: any, i) => i !== index),
            );
            clearErrors(`DnsServers.${index}`);
        },
        [ clearErrors, dnsServers, setValue ],
    );

    const onSubmit = useCallback(
        async (data: ICreateEditVpnGatewayData) => {
            try {
                if (type === 'add') {
                    const payload: ICreateEditVpnGatewayPayload = {
                        Name: data.Name,
                        DnsServers: dnsServers.map((x) => x.dnsServer),
                        Type: data.Type,
                        VnetAddressSpace: data.VnetAddressSpace,
                        Sku: data.Sku,
                    };
                    await createVpnGateway(payload, accountName, tenantId);
                    setData(produce(refreshData, (draftState) => {
                        draftState.refresh = true;
                        return;
                    }));
                    createNotification(translate({ id: 'CLIENT_VPN_GATEWAY_CREATED' }, { 0: data.Name }), notificationType.SUCCESS);
                } else if (type === 'edit' && gatewayData) {
                    const filteredItems = data.DnsServers?.filter((item: DnsServer) => item.dnsServer.trim()) ?? [];
                    const payload: ICreateEditVpnGatewayPayload = {
                        Name: data.Name,
                        DnsServers: filteredItems.map((x) => x.dnsServer),
                    };
                    await editVpnGateway(payload, accountName, tenantId, gatewayData?.Key);
                    setData(produce(refreshData, (draftState) => {
                        draftState.refresh = true;
                        return;
                    }));
                    createNotification(translate({ id: 'CLIENT_VPN_GATEWAY_UPDATED' }, { 0: data.Name }), notificationType.SUCCESS);
                }
                close();
            } catch (error) {
                const errorObject = await getErrorObject(error);
                const errorData = errorObject.response?.data;
                const errorResponse = isString(errorData) ? errorData : await getErrorMessage(errorObject);
                setErrorMessage(errorResponse);
            }
        },
        [
            type,
            gatewayData,
            close,
            dnsServers,
            accountName,
            tenantId,
            setData,
            refreshData,
            createNotification,
            translate,
            getErrorObject,
            getErrorMessage,
            setErrorMessage,
        ],
    );

    const gatewayNameWarning = translate({ id: 'CLIENT_VPN_GATEWAY_WARN_NAME' }, {
        0: CharacterLimitForNames.maxNameLength,
        1: 2,
    });
    return (
        <UiDrawer
            title={type === 'add' ? translate({ id: 'CLIENT_ADD_VPN_GATEWAY' }) :
                translate({ id: 'CLIENT_EDIT_VPN_GATEWAY' }, { 0: gatewayData?.Name })}
            drawerProps={{
                anchor: 'right',
                open,
                onClose: () => close(),
            }}
            themeProps={{ disableGutters: [ 'top', 'bottom', 'right' ] }}
            width="large"
        >
            <UiForm
                actions={<div className={classes.actions}>
                    <Button
                        className={clsx(classes.cancelButton, classes.button)}
                        onClick={() => close()}
                        color="primary"
                        data-cy="add-edit-vpn-gateway-discard"
                    >
                        {translate({ id: 'CLIENT_CANCEL' })}
                    </Button>
                    <UiProgressButton
                        innerButtonClass={classes.button}
                        type="submit"
                        variant="contained"
                        loading={false}
                        data-cy="vpn-gateway-add-button"
                    >
                        { type === 'add' ? translate({ id: 'CLIENT_CREATE_ACTION' }) : translate({ id: 'CLIENT_UPDATE' }) }
                    </UiProgressButton>
                </div>}
                isDrawer
                addScrollPadding
                onSubmit={handleSubmit(onSubmit)}>
                <InputLabel
                    id="gateway-type-label"
                    className={classes.selectLabel}
                    required>
                    <span>
                        {translate({ id: 'CLIENT_GATEWAY_TYPE' })}
                    </span>
                </InputLabel>
                <UiSelect
                    className={classes.fieldLabel}
                    dataCy='gateway-type-select'
                    disabled={type === 'edit'}
                    name="Type"
                    inputProps={{
                        id: 'select-component',
                        'aria-labelledby': 'gateway-type-label',
                    }}
                    required
                    fullWidth
                    options={expressRouteEnabled ? [ 'Vpn', 'ExpressRoute' ] : [ 'Vpn' ]}
                    defaultValue={gatewayData?.GatewayType}
                    error={!!errors.Type}
                    helperText={errors.Type?.type === 'required' && translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_GATEWAY_TYPE' }) }
                    )}
                    control={control} />
                <TextField
                    className={classes.fieldLabel}
                    data-cy='vpn-gateway-name-input'
                    label={translate({ id: 'CLIENT_NAME' })}
                    InputLabelProps={{ id: 'CLIENT_NAME' }}
                    fullWidth
                    variant="outlined"
                    required
                    helperText={errors.Name?.message}
                    defaultValue={gatewayData?.Name ?? ''}
                    error={!!errors.Name}
                    inputProps={register('Name', {
                        required: translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_VPN_GATEWAY_NAME_CREATE_EDIT' }) },
                        ),
                        maxLength: {
                            value: CharacterLimitForNames.maxNameLength,
                            message: gatewayNameWarning,
                        },
                        minLength: {
                            value: 2,
                            message: gatewayNameWarning,
                        },
                    })}
                />
                <Tooltip
                    title={translate({ id: 'CLIENT_VPN_GATEWAY_ADDRESS_SPACE_HELPER_TEXT' })}
                    placement="right-start">
                    <TextField
                        className={classes.fieldLabel}
                        data-cy='gateway-vnet-address-input'
                        disabled={type === 'edit'}
                        label={translate({ id: 'CLIENT_VPN_GATEWAY_ADDRESS_SPACE_CREATE' })}
                        InputLabelProps={{ id: 'CLIENT_VPN_VNET_ADDRESS_SPACE' }}
                        fullWidth
                        variant="outlined"
                        defaultValue={gatewayData?.VnetAddressSpace ?? ''}
                        error={!!errors.VnetAddressSpace}
                        helperText={errors.VnetAddressSpace ? errors.VnetAddressSpace?.message :
                            translate({ id: 'CLIENT_VPN_GATEWAY_ADDRESS_SPACE_HELPER_TEXT' })}
                        inputProps={register('VnetAddressSpace', {
                            required: translate(
                                { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                                { 0: translate({ id: 'CLIENT_VPN_GATEWAY_ADDRESS_SPACE_CREATE' }) },
                            ),
                            validate: (value: string) =>
                                validateIPv4Range(value, value) || translate({ id: 'CLIENT_VPN_GATEWAY_ADDRESS_ERROR_MESSAGE' }),
                        })}
                        required
                    />
                </Tooltip>
                { fields.map((val, idx) => (
                    <div
                        className={classes.dnsServersSection}
                        key={idx}
                        data-cy='vpn-dns-servers-section'
                    >
                        <TextField
                            className={classes.fieldLabel}
                            name={`DnsServers.${idx}.dnsServer`}
                            data-cy="dns-server-address-input"
                            disabled={false}
                            label={idx === 0 ? translate({ id: 'CLIENT_VPN_GATEWAY_DNS_ADDRESS_CREATE' }) : null}
                            fullWidth
                            variant="outlined"
                            defaultValue={val.dnsServer}
                            helperText={errors.DnsServers?.[idx]?.dnsServer?.message}
                            error={!!errors.DnsServers?.[idx]?.dnsServer}
                            inputProps={{
                                ...register(`DnsServers.${idx}.dnsServer`, {
                                    validate: (value: string) =>
                                        validateIPv4Range(value, value) || translate({ id: 'CLIENT_IP_ADDRESS_INVALID' }),
                                }),
                                'aria-label': translate(
                                    { id: 'CLIENT_VPN_GATEWAY_DNS_SERVER_ARIA_LABEL' },
                                    { 0: idx },
                                ),
                            }}
                        />
                        <Button
                            className={clsx((idx === 0 ? classes.firstChild : ''), classes.deleteButton,
                                (errors.DnsServers?.[0] ? classes.firstChildError : ''))}
                            size='medium'
                            startIcon={<RemoveCircle />}
                            data-cy='vpn-delete-dns-servers-button'
                            aria-label={translate(
                                { id: 'CLIENT_VPN_GATEWAY_DELETE_DNS_SERVER_ARIA_LABEL' },
                                { 0: idx },
                            )}
                            onClick={() => removeDnsServer(idx)}
                        />
                    </div>
                )
                )}
                <Tooltip
                    id="add-dns-server-tooltip"
                    title={translate({ id: 'CLIENT_VPN_GATEWAY_ADD_DNS_SERVER_TOOLTIP' })}>
                    <Button
                        className={classes.addMoreButton}
                        data-cy='vpn-add-dns-servers-button'
                        variant='text'
                        startIcon={<AddIcon />}
                        endIcon={<Info />}
                        onClick={() => addDnsServer()}
                        aria-label={fields.length !== 0 ? translate({ id: 'CLIENT_ADD_MORE' })
                            : translate({ id: 'CLIENT_VPN_GATEWAY_DNS_ADDRESS_ADD' })}
                        aria-describedby="add-dns-server-tooltip">
                        { fields.length !== 0 ? translate({ id: 'CLIENT_ADD_MORE' })
                            : translate({ id: 'CLIENT_VPN_GATEWAY_DNS_ADDRESS_ADD' }) }
                    </Button>
                </Tooltip>
            </UiForm>
        </UiDrawer>
    );
};

export default VpnGatewayAddComponent;
