import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import {
    UiAlertBanner,
    UiDataContextProvider,
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Refresh from '@mui/icons-material/Refresh';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import Tokens from '@uipath/apollo-core';
import clsx from 'clsx';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';
import type { Row } from 'react-table';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import type { IVpnGatewayConnectionPeering } from '../../services/hypervisor';
import {
    deleteVpnGatewayConnection,
    refreshVpnGatewayConnection,
} from '../../services/hypervisor';
import { accountLogicalName } from '../../store/selectors';
import { UiGrid } from '../common/UiGrid';
import {
    ButtonType,
    GridActionType,
} from '../common/UiGrid/grid';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import type { IVpnGatewayConnectionGridContext } from './interfaces/gateway';
import useVpnGatewayConnectionsGridViewModel from './VpnGatewayConnectionsGridViewModel';
import VpnGatewayDeleteDialogBody from './VpnGatewayDeleteDialogBody';

const useStyles = makeStyles(theme => ({
    title: {
        fontWeight: 'bold',
        fontSize: '24px',
        paddingBottom: '15px',
    },
    chip: {
        height: '24px',
        fontSize: '13px',
        fontWeight: 600,
    },
    connectingStatus: {
        backgroundColor: Tokens.Colors.ColorYellow200,
        color: Tokens.Colors.ColorWarningTextLight,
        borderColor: Tokens.Colors.ColorWarningIconLight,
        border: '2px solid',
    },
    failedStatus: {
        backgroundColor: Tokens.Colors.ColorErrorBackgroundLight,
        color: Tokens.Colors.ColorErrorTextLight,
        borderColor: Tokens.Colors.ColorErrorIconLight,
        border: '2px solid',
    },
    connectedStatus: {
        backgroundColor: Tokens.Colors.ColorSuccessBackgroundLight,
        color: Tokens.Colors.ColorSuccessTextLight,
        borderColor: Tokens.Colors.ColorSuccessIconLight,
        border: '2px solid',
    },
    addressSpacesCell: {
        whiteSpace: 'pre-line',
        textAlign: 'left',
    },
    addressSpaceText: {
        '&::after': { content: '\', \'' },
        '&:last-child::after': { content: '\'\'' },
    },
    addressSpacesMoreText: {
        color: Tokens.Colors.ColorPrimaryLight,
        fontWeight: 'bold',
    },
    nameCell: {
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
        backgroundColor: 'transparent',
    },
}));

const connectionStatusConstants = {
    connecting: 'connecting',
    connected: 'connected',
    failed: 'failed',
};

const maxAddressSpacesDisplayed = 3;

const VpnGatewayConnectionsGrid: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const accountName = useSelector(accountLogicalName);
    const getRoute = useRouteResolver();
    const createDialog = useShowDialog();
    const createNotification = useUiSnackBar();
    const navigate = useNavigateWithParams();
    const [ isExternal, setValue ] = useState(true);

    const {
        tenant,
        tenantId,
        vpnGatewayKey,
        gatewayData,
        isValidating,
        refreshData,
        internalConnections,
        externalConnections,
        atConnectionLimit,
        mutateGateway,
    } = useVpnGatewayConnectionsGridViewModel();

    useEffect(() => {
        if (gatewayData == null || Object.keys(gatewayData).length === 0) {
            navigate(getRoute(RouteNames.VpnGateway).replace(':tenantId', tenantId));
        }
    }, [ gatewayData, getRoute, navigate, tenantId ]);

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const handleChange = useCallback(
        (_: any, newValue: boolean) => {
            setValue(newValue);
        },
        [ setValue ],
    );

    const refreshConnection = useCallback(
        async (data: Row<IVpnGatewayConnectionPeering>) => {
            try {
                await refreshVpnGatewayConnection(accountName, tenantId, data.original.Key);
                await mutateGateway();
            } catch (error) {
                const errorObject = await getErrorObject(error);
                const errorData = errorObject.response?.data;
                const errorResponse = isString(errorData) ? errorData : await getErrorMessage(errorObject);
                setErrorMessage(errorResponse);
            }
        }, [ accountName, tenantId, mutateGateway, setErrorMessage, getErrorMessage, getErrorObject ]);

    const openEditDialog = useCallback(
        async (data: Row<IVpnGatewayConnectionPeering>) => {
            const editConfirmation = await createDialog({
                title: translate({ id: 'CLIENT_EDIT_VPN_GATEWAY' }, { 0: data.values.Name }),
                body: translate({ id: 'CLIENT_VPN_GATEWAY_EDIT_TEXT_WARNING' }),
                icon: 'warning',
                primaryButtonText: translate({ id: 'CLIENT_CONTINUE' }),
                showCancel: true,
            });
            if (editConfirmation) {
                navigate(getRoute(`${RouteNames.VpnGatewayConnections}/edit`).replace(':tenantId', tenantId)
                    .replace(':vpnGatewayKey', vpnGatewayKey),
                {
                    state: {
                        connection: data.original,
                        gatewayType: gatewayData?.GatewayType,
                    },
                });
            }
        }, [ createDialog, translate, navigate, getRoute, tenantId, vpnGatewayKey, gatewayData?.GatewayType ]);

    const openDeleteDialog = useCallback(
        async (data: Row) => {
            const deleteConfirm = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_SERVICE' }, { 0: data.values.Name }),
                customDialogContent: VpnGatewayDeleteDialogBody,
                customDialogContentProps: {
                    gateway: gatewayData,
                    tenantId,
                    accountName,
                    deleteFunction: deleteVpnGatewayConnection,
                    isConnection: true,
                    connection: data.original,
                },
                icon: 'error',
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
                showCancel: true,

            });
            if (deleteConfirm) {
                createNotification(translate({ id: 'CLIENT_VPN_CONNECTION_DELETED' }, { 0: data.values.Name }), notificationType.SUCCESS);
                await mutateGateway();
            }
        }, [ createDialog, translate, gatewayData, tenantId, accountName, createNotification, mutateGateway ]);

    const moreActions = useMemo(() => [
        {
            type: ButtonType.Button,
            label: translate({ id: 'CLIENT_EDIT' }),
            click: (row: Row<IVpnGatewayConnectionPeering>) => openEditDialog(row),
            dataCy: 'edit-connection',
            actionType: GridActionType.Row,
            ariaLabel: translate({ id: 'CLIENT_EDIT' }),
            icon: <MoreVertIcon />,
        },
        {
            type: ButtonType.Button,
            label: translate({ id: 'CLIENT_DELETE' }),
            click: (row: Row) => openDeleteDialog(row),
            dataCy: 'delete-connection',
            actionType: GridActionType.Row,
            ariaLabel: translate({ id: 'CLIENT_DELETE' }),
            icon: <MoreVertIcon />,
        },
    ], [ translate, openDeleteDialog, openEditDialog ]);

    const connectionActions = useMemo(() => [
        {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_REFRESH' }),
            click: () => mutateGateway(),
            dataCy: 'refresh-gateway',
            actionType: GridActionType.Main,
            ariaLabel: translate({ id: 'CLIENT_REFRESH' }),
            icon: <Refresh />,
            disable: gatewayData?.State === 'Failed',
        },
        {
            type: ButtonType.ButtonWithIcon,
            label: translate({ id: 'CLIENT_CONNECTION_CREATE' }),
            click: () => {
                navigate(getRoute(`${RouteNames.VpnGatewayConnections}/add`).replace(':tenantId', tenantId)
                    .replace(':vpnGatewayKey', vpnGatewayKey),
                {
                    state: {
                        gatewayType: gatewayData.GatewayType ?? '',
                        gatewayName: gatewayData.Name ?? '',
                        tenantName: tenant?.name ?? '',
                    },
                });
            },
            dataCy: 'add-connection',
            actionType: GridActionType.Main,
            ariaLabel: translate({ id: 'CLIENT_CONNECTION_CREATE' }),
            icon: <AddIcon />,
            disable: atConnectionLimit || !isExternal || gatewayData?.State === 'Failed',
        },
    ], [ translate, atConnectionLimit, isExternal, mutateGateway, navigate, getRoute, tenantId, vpnGatewayKey, gatewayData, tenant ]);

    const addressSpacesCell = useCallback((data: string[]) => {
        if (data) {
            const dataCopy = [ ...data ];
            dataCopy.sort();
            const tooltipArray: string[] = dataCopy.splice(maxAddressSpacesDisplayed);
            const tooltipText: string = tooltipArray.join(', ');
            return (
                <div className={classes.addressSpacesCell}>
                    {
                        dataCopy.map((value: string, idx: number) => (
                            <span
                                key={idx}
                                className={classes.addressSpaceText}>
                                {value}
                            </span>
                        ))
                    }
                    {tooltipArray.length !== 0 ?
                        <Tooltip
                            title={tooltipText}>
                            <span className={classes.addressSpacesMoreText}>
                                { translate({ id: 'CLIENT_CONNECTIONS_MORE_TOOLTIP' }, { 0: tooltipArray.length }) }
                            </span>
                        </Tooltip>
                        : undefined}
                </div>
            );
        }
        return null;
    }, [ translate, classes ]);

    const chipStyleClass = (status: string) => {
        switch (status.toLowerCase()) {
            case connectionStatusConstants.connecting:
                return classes.connectingStatus;
            case connectionStatusConstants.failed:
                return classes.failedStatus;
            case connectionStatusConstants.connected:
                return classes.connectedStatus;
        }
    };

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <>
            <UiPageContainer
                position='left'
                breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
                banner={atConnectionLimit ? <UiAlertBanner
                    type="warning"
                    closeable={false}>
                    <div>
                        {translate({ id: 'CLIENT_VPN_MAX_CONNECTIONS_WARNINGS' })}
                    </div>
                </UiAlertBanner> : undefined}
                loading={isValidating || refreshData.refresh || !gatewayData}>
                <UiText className={classes.title}>
                    { translate({ id: 'CLIENT_CONNECTIONS' }) }
                </UiText>
                <Tabs
                    value={isExternal}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    className="default"
                >
                    <Tab
                        value
                        label={
                            <FormattedMessage
                                id="CLIENT_VPN_GATEWAY_MY_CONNECTIONS"
                                values={{
                                    0: externalConnections ? externalConnections?.length : 0,
                                    1: gatewayData ? gatewayData?.MaxUserConnections : 0,
                                }}
                            />
                        }
                        className="default"
                        data-cy="my-connections-tab"
                    />
                    <Tab
                        value={false}
                        label={
                            <FormattedMessage
                                id="CLIENT_VPN_GATEWAY_SYSTEM_CONNECTIONS"
                                values={{ 0: internalConnections ? internalConnections?.length : 0 }}
                            />
                        }
                        className="default"
                        data-cy="systems-connections-tab"
                    />
                </Tabs>
                <UiGrid<IVpnGatewayConnectionPeering>
                    dataCy="vpn-gateway-connections-grid"
                    loading={isValidating}
                    data={isExternal ? (externalConnections ?? []) : (internalConnections ?? [])}
                    filters
                    pagination
                    tableHeight="600px"
                    search={isExternal}
                    searchPlaceholder={translate({ id: 'CLIENT_CONNECTION_SEARCH_PLACEHOLDER' })}
                    rowActions={isExternal ? moreActions : undefined}
                    tableActions={connectionActions}
                    hiddenColumns={isExternal ? [] : [ 'TargetIpAddress' ]}
                    columns={[
                        {
                            accessor: 'Name',
                            Header: translate({ id: 'CLIENT_NAME' }),
                            sortName: translate({ id: 'CLIENT_NAME' }),
                            width: isExternal ? 25 : 33,
                            sortType: 'alphanumeric',
                            Cell: ({ row }) => (
                                isExternal ?
                                    <Button
                                        className={classes.nameCell}
                                        variant='text'
                                        onClick={() => openEditDialog(row)}>
                                        {row.original.Name}
                                    </Button> : <UiText>
                                        {row.original.Name ? row.original.Name : translate({ id: 'CLIENT_VPN_PEERING_DEFAULT_ALIAS' })}
                                    </UiText>
                            ),
                        },
                        {
                            accessor: 'TargetIpAddress',
                            Header: translate({ id: 'CLIENT_PUBLIC_IP_ADDRESS' }),
                            width: isExternal ? 25 : 33,
                            sortType: 'alphanumeric',
                        },
                        {
                            accessor: 'TargetAddressSpaces',
                            Header: translate({ id: 'CLIENT_ADDRESS_SPACE' }),
                            width: isExternal ? 25 : 33,
                            sortType: 'alphanumeric',
                            Cell: ({ row }) => addressSpacesCell(row.original.TargetAddressSpaces),
                        },
                        {
                            accessor: 'State',
                            Header: translate({ id: 'CLIENT_CONNECTION_STATUS' }),
                            width: 25,
                            sortType: 'alphanumeric',
                            Cell: ({ row }) => (
                                <>
                                    <Chip
                                        color="warning"
                                        label={row.original.State}
                                        className={clsx(classes.chip, chipStyleClass(row.original.State.toLowerCase()))}
                                        size="small"
                                    />
                                    { row.original.State.toLowerCase() === connectionStatusConstants.failed ?
                                        <Button
                                            data-cy='retry-connection-button'
                                            onClick={() => refreshConnection(row)}>
                                            {translate({ id: 'CLIENT_RETRY' })}
                                        </Button> : undefined}
                                </>),
                        },
                    ]}
                />
            </UiPageContainer>
        </>
    );
};

export const VpnGatewayConnectionsGridWithProviders: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const {
        tenant,
        tenantId,
        vpnGatewayKey,
        gatewayData,
    } = useVpnGatewayConnectionsGridViewModel();

    const breadCrumbLinks = useMemo(() =>
        [
            {
                index: 0,
                link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                name: tenant?.name ?? translate({ id: 'CLIENT_TENANT' }),
            },
            {
                index: 1,
                link: RouteNames.VpnGateway.replace(':tenantId', tenantId),
                name: gatewayData?.Name ??
                    translate({ id: gatewayData?.GatewayType === 'ExpressRoute' ? 'CLIENT_EXPRESSROUTE' : 'CLIENT_GATEWAY' }),
            },
            {
                index: 2,
                link: RouteNames.VpnGatewayConnections.replace(':tenantId', tenantId).replace(':vpnGatewayKey', vpnGatewayKey),
                name: translate({ id: 'CLIENT_CONNECTIONS' }),
            },
        ], [ tenant, tenantId, translate, vpnGatewayKey, gatewayData ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <UiDataContextProvider<IVpnGatewayConnectionGridContext> initialState={{ refresh: false }}>
            <VpnGatewayConnectionsGrid />
            <UiSuspensefulOutlet />
        </UiDataContextProvider>
    </BreadcrumbProvider>;
};

export default VpnGatewayConnectionsGrid;
