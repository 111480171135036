import { getRegionTranslationId } from '@experiences/constants';
import { useLocalization } from '@experiences/locales';
import { UiText } from '@experiences/ui-common';
import {
    formatDate,
    getDateFromNow,
} from '@experiences/util';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { PortalAlertBar } from '@uipath/portal-shell-react';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
    MigrationEndWindowDays,
    MigrationStartWindowDays,
} from '../../../../common/interfaces/gws';
import {
    SpacingToken,
    UiStack,
} from '../../../common/UiStack';
import type { IOrgMigrationFormData } from './types';

const useStyles = makeStyles((theme) =>
    ({
        header: { textAlign: 'center' },
        fixedWidth: { width: '200px' },
        box: {
            outline: 'solid',
            outlineWidth: '1px',
            outlineColor: theme.palette.semantic.colorBorderDeEmp,
            borderRadius: '3px',
            padding: '16px 32px',
        },
    }),
);

export const OrgMigrationStepConfirm: React.FC = () => {
    const classes = useStyles();
    const language = useLocalization();
    const { formatMessage: translate } = useIntl();
    const {
        getValues, watch,
    } = useFormContext<IOrgMigrationFormData>();

    const hour = getValues('customHour') || getValues('hour');

    const windowStartDate = getDateFromNow(MigrationStartWindowDays);
    const windowEndDate = getDateFromNow(MigrationEndWindowDays);

    return (
        <UiStack
            direction="column"
            gap={SpacingToken.M}>

            <UiText
                variant={FontVariantToken.fontSizeLBold}
                className={classes.header}>
                {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_CONFIRM_TITLE' })}
            </UiText>

            <PortalAlertBar
                status='info'
                cancelable={false}>
                <UiText>
                    {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_ESTIMATED_DOWNTIME' })}
                </UiText>
            </PortalAlertBar>

            <UiStack
                className={classes.box}
                align='center'
                gap={SpacingToken.XL}>
                <UiStack
                    direction='column'
                    gap={SpacingToken.Micro}>
                    <UiText
                        className={classes.fixedWidth}
                        variant={FontVariantToken.fontSizeMBold}>
                        {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_SOURCE_REGION' })}
                    </UiText>
                    <UiText variant={FontVariantToken.fontSizeM}>
                        {translate({ id: getRegionTranslationId(watch('sourceRegion')) }) }
                    </UiText>
                </UiStack>
                <ArrowRightAltIcon />
                <UiStack
                    direction='column'
                    gap={SpacingToken.Micro}>
                    <UiText variant={FontVariantToken.fontSizeMBold}>
                        {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_DESTINATION_REGION' })}
                    </UiText>
                    <UiText variant={FontVariantToken.fontSizeM}>
                        {translate({ id: getRegionTranslationId(watch('destinationRegion')) }) }
                    </UiText>
                </UiStack>
            </UiStack>

            <UiStack
                className={classes.box}
                align='center'
                gap={SpacingToken.XL}>
                <UiStack
                    direction='column'
                    gap={SpacingToken.Micro}>
                    <UiText
                        className={classes.fixedWidth}
                        variant={FontVariantToken.fontSizeMBold}>
                        {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_DOWNTIME_WINDOW' })}
                    </UiText>
                    <UiText variant={FontVariantToken.fontSizeM}>
                        {`${hour.split('-')[0] + ' UTC'} - ${hour.split('-')[1] + ' UTC'}`}
                    </UiText>
                </UiStack>
                <ArrowRightAltIcon />
                <UiStack
                    direction='column'
                    gap={SpacingToken.Micro}>
                    <UiText variant={FontVariantToken.fontSizeMBold}>
                        {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_DATE_WINDOW' })}
                    </UiText>
                    <UiText variant={FontVariantToken.fontSizeM}>
                        {formatDate(windowStartDate.toISOString(), language)}
                        &nbsp;-&nbsp;
                        {formatDate(windowEndDate.toISOString(), language)}
                    </UiText>
                </UiStack>
            </UiStack>

            <UiStack
                className={classes.box}
                direction='column'>
                <UiText variant={FontVariantToken.fontSizeMBold}>
                    {translate({ id: 'CLIENT_ORGANIZATION_MIGRATION_WHATS_NEXT' })}
                </UiText>
                <ul>
                    {Array.from({ length: 3 }, (_, index) => (
                        <li key={index}>
                            <UiText display="inline">
                                {translate({ id: `CLIENT_ORGANIZATION_MIGRATION_CONFIRM_INFO_${index + 1}` })}
                            </UiText>
                        </li>
                    ))}
                </ul>
            </UiStack>
        </UiStack>
    );
};
