import buildQuery from 'odata-query';

import type {
    INotificationOdata,
    INotificationReadRequest,
} from '../../component/notifications/interfaces/notifications';
import {
    get,
    post,
} from '../utility/Requests.default';

const initialApiPath = '/api/notificationSettings/notificationEntry';
export const notificationEntryUri = initialApiPath + '/';
const getHeaders = (selectedTenantId: string, selectedAccountId: string) => ({
    extendRequestHeaders: 'true',
    'X-UiPath-Internal-TenantId': selectedTenantId,
    'X-UiPath-Internal-AccountId': selectedAccountId,
});
export function getUserNotificationEntries({
    selectedAccountId, selectedTenantId, options,
}: { url: string; selectedAccountId: string; selectedTenantId: string; options?: ODataQueryOptions }) {
    const query = buildQuery(options);
    return get<INotificationOdata>(`${notificationEntryUri}${query}`, { headers: getHeaders(selectedTenantId, selectedAccountId) });
}

export function updateNotificationForUnread(
    updateUserSubscriptions: INotificationReadRequest,
    selectedTenantId: string,
    selectedAccountId: string,) {
    return post<any>(`${notificationEntryUri}/UiPath.NotificationService.Api.UpdateRead`, {
        body: { ...updateUserSubscriptions },
        headers: getHeaders(selectedTenantId, selectedAccountId),
    });
}

export function deleteNotification(
    selectedNotificationId: string,
    selectedTenantId: string,
    selectedAccountId: string,) {
    return post<string>(`${notificationEntryUri}/UiPath.NotificationService.Api.DeleteBulk`, {
        body: { notifcationIds: [ selectedNotificationId ] },
        headers: getHeaders(selectedTenantId, selectedAccountId),
    });
}

interface ODataFilterProperty
    extends Record<string, ODataFilterValue | ODataFilterProperty | string | number | Date | null> { }

type ComparisonProperty = ODataFilterProperty | ODataComparisonProperty | string;

interface ODataComparisonProperty {
    or?: ComparisonProperty[];
    and?: ComparisonProperty[];
}

export interface ODataQueryOptions {
    filter?: string | ODataFilterProperty | ODataComparisonProperty;
    orderBy?: string[];
    top?: number;
    skip?: number;
    count?: boolean;
}

interface ODataFilterValue {
    eq?: number | string | boolean | null;
    ne?: number | string | boolean | null;
    gt?: number | string;
    ge?: number | string;
    lt?: number | string;
    le?: number | string;
    startswith?: string;
    endswith?: string;
    contains?: string;
    any?: string | number | boolean;
    all?: string | number | boolean;
    in?: string[] | number[];
}
