import { useUiDataContext } from '@experiences/ui-common';
import { produce } from 'immer';
import {
    useEffect,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import type { IVpnGatewayData } from '../../services/hypervisor';
import {
    getVpnGateway,
    vpnGatewaysUri,
} from '../../services/hypervisor';
import {
    getTenantById,
    tenantByIdUri,
} from '../../services/organization/TenantService';
import { accountLogicalName } from '../../store/selectors';
import type { IVpnGatewayConnectionGridContext } from './interfaces/gateway';

const useVpnGatewayConnectionsGridViewModel = () => {
    const accountName = useSelector(accountLogicalName);
    const {
        vpnGatewayKey, tenantId,
    } = useParams() as {
        vpnGatewayKey: string; tenantId: string;
    };

    const { data: tenant } = useSWR(
        {
            url: tenantByIdUri,
            id: tenantId,
        }, getTenantById,
    );

    const {
        data: refreshData, setData,
    } = useUiDataContext<IVpnGatewayConnectionGridContext>();

    const {
        data: data, isValidating, mutate: mutateGateway,
    } = useSWR(
        {
            requestUri: vpnGatewaysUri,
            accountLogicalName: accountName,
            selectedTenantId: tenantId,
            vpnGatewayKey,
        },
        getVpnGateway,
    );

    const gatewayData = useMemo(() => {
        const response = data?.value?.length ? data?.value[0] : {} as IVpnGatewayData;
        return response;
    }, [ data ]);

    const [ internalConnections, externalConnections ] = useMemo(() =>
        gatewayData?.Connections ?
            [
                gatewayData?.Connections?.filter(connection => connection.Internal).concat(gatewayData?.Peerings),
                gatewayData?.Connections?.filter(connection => !connection.Internal),
            ] : [ [], [] ],
    [ gatewayData ]);

    useEffect(() => {
        if (refreshData.refresh) {
            mutateGateway();
            setData(produce(refreshData, (draftState) => {
                draftState.refresh = false;
            }));
        }
    }, [ refreshData, mutateGateway, setData ]);

    const atConnectionLimit = useMemo(() => gatewayData ?
        gatewayData.Connections?.length === gatewayData.MaxUserConnections : false, [ gatewayData ]);

    return {
        tenant,
        tenantId,
        vpnGatewayKey,
        gatewayData,
        isValidating,
        refreshData,
        internalConnections,
        externalConnections,
        atConnectionLimit,
        mutateGateway,
    };
};

export default useVpnGatewayConnectionsGridViewModel;
