import { produce } from 'immer';
import type { AnyAction } from 'redux';

import { appState } from '../../common/constants/Constant';
import {
    FETCH_PROFILE_SUCCESS,
    UPDATE_ACCOUNT_USER,
    UPDATE_ORG_REGION,
    UPDATE_USER_GROUPS,
    USER_LOGOUT_REQUEST,
} from '../action/ActionTypes';

const initialState: Record<string, any> = {};

export default function(state = initialState, action: AnyAction) {
    switch (action.type) {
        case UPDATE_ACCOUNT_USER:
        case UPDATE_USER_GROUPS:
        case FETCH_PROFILE_SUCCESS:
            if (action.profile) {
                sessionStorage.setItem(appState.REDUX_STORE_STATE, JSON.stringify(action.profile));
            }
            return action.profile;
        case USER_LOGOUT_REQUEST:
            sessionStorage.removeItem(appState.REDUX_STORE_STATE);
            return {};
        case UPDATE_ORG_REGION:
            return produce(state, draft => {
                draft.accountUserDto.region = action.region;
            });
        default:
            return state;
    }
}
