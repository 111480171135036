import {
    useEffect,
    useState,
} from 'react';

import { getTenantById } from '../../../../services/organization/TenantService';
import { useTenantsContext } from '../../../tenants/TenantsContextProvider';
import type { IGridDataEntry } from './GridDataEntryTypes';

const tenantCache = JSON.parse(localStorage.getItem('tenantCache') ?? '{}');

export class TenantService {
    static getTenantCache() {
        return tenantCache;
    }

    static updateLocalStorage() {
        localStorage.setItem('tenantCache', JSON.stringify(tenantCache));
    }

    static constructTenantDict(tenants: any[]) {
        const dict = { ...tenantCache };

        tenants?.forEach((tenant) => {
            if (!dict[tenant.tenant.id]) {
                dict[tenant.tenant.id] = {
                    name: tenant.tenant.name,
                    status: tenant.tenant.status,
                };
                tenantCache[tenant.tenant.id] = {
                    name: tenant.tenant.name,
                    status: tenant.tenant.status,
                };
            }
        });

        this.updateLocalStorage();

        return dict;
    }

    static async fetchAndUpdateTenant(tenantId: string, tenantDict: Record<string, { name: string; status: string }>) {
        if (tenantCache[tenantId]) {
            return tenantCache[tenantId].name;
        }

        try {
            const tenant = await getTenantById({
                url: '/api/organization/tenantById',
                id: tenantId,
            });
            if (tenant) {
                tenantCache[tenantId] = {
                    name: tenant.name,
                    status: tenant.status,
                };
                tenantDict[tenantId] = {
                    name: tenant.name,
                    status: tenant.status,
                };
                this.updateLocalStorage();
                return tenant.name;
            }
        } catch (error) {
            console.error(`Error fetching tenant with ID ${tenantId}:`, error);
        }

        return 'Tenant (deleted)';
    }
}

export const useProcessedAuditData = (mockAuditData: any[]): [IGridDataEntry[], boolean] => {
    const { data: tenants } = useTenantsContext();
    const [ processedAuditData, setProcessedAuditData ] = useState<IGridDataEntry[]>([]);
    const [ loading, setLoading ] = useState(true);

    useEffect(() => {
        const processAuditData = async () => {
            const tenantDict = TenantService.constructTenantDict(tenants);
            const processedData = await Promise.all(
                mockAuditData.map(async (auditRecord) => {
                    let tenantName = tenantDict[auditRecord.tenant]?.name;

                    if (!tenantName) {
                        tenantName = await TenantService.fetchAndUpdateTenant(auditRecord.tenant, tenantDict);
                    }

                    if (tenantName && tenantDict[auditRecord.tenant]?.status === 'disabled') {
                        tenantName += ' (disabled)';
                    }

                    return {
                        ...auditRecord,
                        tenant: tenantName || 'Tenant (deleted)',
                    };
                })
            );
            setProcessedAuditData(processedData);
            setLoading(false);
        };

        processAuditData();
    }, [ mockAuditData, tenants ]);

    return [ processedAuditData, loading ];
};
