import {
    AccountLicense,
    ContactUsLinks,
} from '@experiences/constants';
import {
    useEcommerceTelemetry,
    useIsEcommerceEnabled,
} from '@experiences/ecommerce';
import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import {
    LicensesEvent,
    portalTelemetry,
    SeverityLevel,
} from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import {
    useLocalizedLinks,
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { DialogEventNames } from '@uipath/portal-shell-util';
import React, {
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import * as RouteNames from '../../common/constants/RouteNames';
import useCheckLicense from '../../common/hooks/useCheckLicense';
import type { ILicenseAndKey } from '../../common/interfaces/licenses';
import { checkBusinessEmail } from '../../services/licensing/LicenseService';
import {
    fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    licenseManagementAccountUrl,
} from '../../services/licensing/management/AccountService';
import {
    accountLogicalName,
    emailSelector,
} from '../../store/selectors';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        button: {
            margin: '0px 8px',
            padding: '0px 16px',
            minWidth: '121px',
        },
        content: { marginTop: '8px' },
    }),
}));

const LicenseHeaderActionComponent: React.FC = () => {

    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const isEcommerceEnabled = useIsEcommerceEnabled();
    const {
        isAccountLicenseEnterpriseOrPro, isFreeOrCommunity, canHaveOnlineEnterpriseActivation,
    } = useCheckLicense();
    const { logEvent } = useTelemetryHelper();

    const EnableAirgappedLicenseActivation = useFeatureFlagValue(Features.EnableAirgappedLicenseActivation.name);
    const EnableUpgradeToTrialRevamp = useFeatureFlagValue(Features.EnableUpgradeToTrialRevamp.name);

    const getLocalizedLink = useLocalizedLinks();
    const logEcommerceEvent = useEcommerceTelemetry();

    const { data: licenseAndKey } = useSWR<ILicenseAndKey, Error>(
        licenseManagementAccountUrl,
        fetchAccountLicenseWithMlKeysAndUpdateAccountType,
    );

    const [ isBusinessEmail, setIsBusinessEmail ] = useState(false);

    const [ license ] = useMemo(() => [ licenseAndKey?.accountLicense ], [ licenseAndKey ]);
    const currentAccountName = useSelector(accountLogicalName);
    const email = useSelector(emailSelector);

    const { formatMessage: translate } = useIntl();

    useEffect(() => {
        async function checkEmailValidity() {
            const valid = await checkBusinessEmail(email ?? '');
            setIsBusinessEmail(valid);
        }
        checkEmailValidity();
    }, [ email ]);

    return (
        <div>
            {(license && AccountLicense[license.accountType] === AccountLicense.TRIAL && !isEcommerceEnabled && (
                <Button
                    className={classes.button}
                    variant="text"
                    onClick={() => window.location.assign(getLocalizedLink(ContactUsLinks) ?? '')}
                    data-cy="licenses-header-contact-us-button"
                >
                    {translate({ id: 'CLIENT_CONTACT_US' })}
                </Button>
            )) ||
                (license && isFreeOrCommunity(license.accountType) && (
                    <Button
                        variant={isEcommerceEnabled ? 'outlined' : 'contained'}
                        onClick={async () => {
                            portalTelemetry.trackTrace({
                                message: `Clicked on request enterprise trial button to open form with account name ${currentAccountName}`,
                                severityLevel: SeverityLevel.Info,
                            });
                            logEvent(LicensesEvent.ClickedStartTrial);
                            if (EnableUpgradeToTrialRevamp) {
                                const event = new CustomEvent(
                                    'openApDialog',
                                    {
                                        detail: {
                                            eventName: isBusinessEmail
                                                ? DialogEventNames.UpgradeToTrialDialogBusinessEmail
                                                : DialogEventNames.UpgradeToTrialDialogPersonalEmail,
                                        },
                                    });
                                window.document.dispatchEvent(event);
                            } else {
                                navigate(getRoute(RouteNames.LicensingUpgradeToEnterprise));
                            }
                        }}
                        className={classes.button}
                        id="upgradeToEnterprise"
                        data-cy="license-header-upgrade-to-enterprise-button"
                    >
                        {translate({ id: 'CLIENT_START_PRO_TRIAL' })}
                    </Button>
                ))}
            {isEcommerceEnabled && license && !isAccountLicenseEnterpriseOrPro(license.accountType) && (
                <Button
                    variant="contained"
                    onClick={() => {
                        logEcommerceEvent('Licenses.UpgradeToEnterprise', { InvocationMethod: 'Licenses Tab' });
                        navigate(getRoute(RouteNames.BuyProPresets));
                    }}
                    data-cy="license-header-buy-enterprise-button"
                >
                    {translate({ id: 'CLIENT_BUY_NOW' })}
                </Button>
            )}
            {EnableAirgappedLicenseActivation ? (
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate(getRoute(RouteNames.ActivateOffline),
                            {
                                state: {
                                    previousLocation: location.pathname,
                                    isUpdateLicense: true,
                                },
                            });
                    }}
                    className={classes.button}
                    data-cy="license-header-activate-offline"
                >
                    {translate({ id: 'CLIENT_LICENSE_EXPIRED_ACTION_BANNER' })}
                </Button>
            ) : canHaveOnlineEnterpriseActivation && (
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate(getRoute(RouteNames.LicensingActivateLicenseOnline),
                            {
                                state: {
                                    previousLocation: location.pathname,
                                    isUpdateLicense: false,
                                    isEnterpriseActivation: true,
                                },
                            });
                    }}
                    className={classes.button}
                    data-cy="license-header-activate-enterprise-button"
                >
                    {translate({ id: 'CLIENT_ENTERPRISE_ACTIVATION' })}
                </Button>
            )}
        </div>
    );
};

export default LicenseHeaderActionComponent;
