import {
    Entity,
    type EntityEnumType,
} from '@experiences/interfaces';
import { UiSuspensefulOutlet } from '@experiences/ui-common';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import * as RouteNames from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import { isHostModeSelector } from '../../store/selectors';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import UsersPageAADBanner from './subcomponents/UsersPageAADBanner';
import UsersPageTabs from './subcomponents/UsersPageTabs';

const UsersPageComponent: React.FC<{ entityType: EntityEnumType }> = ({ entityType }) => {
    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            banner={<UsersPageAADBanner />}
            position='left'
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
        >
            <UsersPageTabs entityType={entityType} />
        </UiPageContainer>
    );
};

export const UsersPageComponentWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationName = useOrganizationName();

    const isHostMode = useSelector(isHostModeSelector);

    const isGroup = useMatch({
        path: RouteNames.Groups,
        end: false,
    });
    const isRobot = useMatch({
        path: RouteNames.Robots,
        end: false,
    });

    const entityType = useMemo(() => {
        if (isGroup) {
            return Entity.GROUPS;
        } else if (isRobot) {
            return Entity.ROBOTS;
        }
        return Entity.USERS;
    }, [ isGroup, isRobot ]);

    const breadcrumbs = useMemo(() => [
        {
            index: 0,
            link: RouteNames.OrganizationAdminHome,
            name: organizationName,
        },
        {
            index: 1,
            link: RouteNames.Users,
            name: translate({
                id: isHostMode
                    ? 'CLIENT_USERS'
                    : 'CLIENT_ACCOUNTS_AND_GROUPS',
            }),
        },
        {
            index: 1,
            link: RouteNames.Groups,
            name: translate({
                id: isHostMode
                    ? 'CLIENT_USERS'
                    : 'CLIENT_ACCOUNTS_AND_GROUPS',
            }),
        },
        {
            index: 1,
            link: RouteNames.Robots,
            name: translate({
                id: isHostMode
                    ? 'CLIENT_USERS'
                    : 'CLIENT_ACCOUNTS_AND_GROUPS',
            }),
        },
    ], [ isHostMode, organizationName, translate ]);

    return <BreadcrumbProvider
        breadcrumbs={breadcrumbs}
        legacy>
        <UsersPageComponent entityType={entityType} />
        <UiSuspensefulOutlet />
    </BreadcrumbProvider>;
};

export default UsersPageComponent;
