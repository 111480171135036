export const AboutAccountsLink = '/admin-guide/about-accounts';

export const GroupsAndRolesLink = '/admin-guide/about-accounts';

export const EnablingDisablingServicesLink = '/admin-guide/managing-tenants#enabling-or-disabling-services';

export const AboutLicensingLink = '/admin-guide/about-licensing';

export const LicensingRobotServicesLink = '/admin-guide/about-licensing#robots--services-licenses';

export const ManagingUsersBulkAddingLink = '/admin-guide/managing-user-accounts#adding-users-in-bulk';

export const DataResidencyCloudLink = '/admin-guide/data-residency-cloud';

export const LeavingOrganizationLink = '/admin-guide/managing-user-preferences#leaving-an-organization';

export const LicensingManagementOptionsLink = '/overview/license-management-options';

export const LegacyLicenseManagementLink = '/license-management-options#legacy-license-management';

export const IPRestrictionLink = '/admin-guide/restricting-access-by-ip';

export const IPRestrictionImportCsvLink = '/admin-guide/restricting-access-by-ip#csv-import';

export const SessionPolicyLink = '/admin-guide/session-policy';

export const EncryptionLink = '/admin-guide/encryption';

export const EnablingCustomerManagedKeyLink = '/admin-guide/customer-managed-key#enabling-the-customer-managed-key';

export const CustomerManagedKeyBestPracticesLink = '/admin-guide/customer-managed-key#best-practices-for-using-customer-managed-keys';

export const CustomerManagedKeySwitchLink = '/admin-guide/switch-from-customer-managed-to-uipath-managed-keys';

export const PortalTagManagementOrganizingResourcesLink = '/admin-guide/managing-tags';

export const ConfiguringVPNForCloudRobotsLink = '/admin-guide/configuring-vpn-for-cloud-robots';

export const DirectorySSOLink = '/admin-guide/authentication-settings#models-that-use-directory-accounts';

export const KerberosSetupLink = '/installation-guide/setting-up-kerberos-authentication';
