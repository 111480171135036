import { Entitlements } from '@experiences/constants';
import { useCentralErrorSetter } from '@experiences/error';
import { useUiDataContext } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Button from '@mui/material/Button';
import React, {
    createRef,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../../../common/constants/Constant';
import * as RouteNames from '../../../../common/constants/RouteNames';
import { useUiSnackBar } from '../../../../common/hooks/useUiSnackBar';
import type { BulkInviteIpRangeData } from '../../../../common/interfaces/iprestriction';
import {
    addIpNetwork,
    getIpNetworkByOrganizationId,
    ipNetworkUrl,
} from '../../../../services/access-policy/IPNetworkService';
import { consumeEntitlement } from '../../../../services/licensing/EntitlementsService';
import { accountGlobalId } from '../../../../store/selectors';
import { parseFile } from './IPRestrictionUtil';

const useIPRestrictionBulkImportViewModel = () => {
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const partitionGlobalId = useSelector(accountGlobalId);
    const { formatMessage: translate } = useIntl();
    const setErrorMessage = useCentralErrorSetter();

    const { setData } = useUiDataContext<{ refresh: boolean }>();

    const {
        data: ipRangesData, mutate,
    } = useSWR(
        {
            url: ipNetworkUrl,
            organizationId: partitionGlobalId,
        },
        getIpNetworkByOrganizationId,
    );

    const [ loading, setLoading ] = useState(false);
    const [ showErrorSummary, setShowErrorSummary ] = useState(false);

    const inputRef = createRef<HTMLInputElement>();
    const [ file, setFile ] = useState<File | null>(null);
    const [ completed, setCompleted ] = useState<number>(0);
    const [ fileErrorId, setFileErrorId ] = useState<string>('');
    const [ total, setTotal ] = useState<number>(0);
    const [ badEntries, setBadEntries ] = useState<BulkInviteIpRangeData[]>([]);
    const [ progressMessage, setProgressMessage ] = useState<string>('');

    const createNotification = useUiSnackBar();

    const close = useCallback(() =>
        navigate(getRoute(RouteNames.IPRestriction)),
    [ getRoute, navigate ],
    );

    const uploadFile = useCallback(
        async (fileData: File) => {
            try {
                const ipRangesArr = ipRangesData?.map(val => val.ipNetwork) ?? [];
                setFileErrorId('');
                setCompleted(0);
                setTotal(0);
                setLoading(true);

                if (!fileData?.name?.endsWith('.csv')) {
                    setFileErrorId('CLIENT_INVALID_CSV');
                    setShowErrorSummary(true);
                    setLoading(false);
                    return;
                }

                setProgressMessage(translate({ id: 'CLIENT_PARSING_FILE' }, { 0: fileData.name }));
                const parsedData: (BulkInviteIpRangeData[] | undefined) = await parseFile(fileData, ipRangesArr);
                if (!parsedData || parsedData.length === 0) {
                    setFileErrorId('CLIENT_ERROR_PARSING_CSV');
                    setShowErrorSummary(true);
                    setLoading(false);
                    return;
                }

                const badData = parsedData.filter(data => data.errors.length > 0);
                setTotal(parsedData.length);
                setCompleted(c => c + badData.length);

                if (badData.length > 0) {
                    setBadEntries([ ...badData ]);
                    setShowErrorSummary(true);
                    setLoading(false);
                    setData({ refresh: true });
                } else {
                    const date = new Date().toLocaleDateString();
                    const mappedIpNetworkData = parsedData.map(entry => entry.ip);
                    const payload = {
                        name: translate({ id: 'CLIENT_IP_RESTRICTION_IMPORTED_NAME' }, { date }),
                        ipNetworks: mappedIpNetworkData,
                    };

                    await consumeEntitlement(Entitlements.IpRestrictions);
                    await addIpNetwork(partitionGlobalId, payload);

                    mutate();
                    createNotification(translate({ id: 'CLIENT_IP_RESTRICTION_IMPORT_SUCCESS' }), notificationType.SUCCESS);
                    setData({ refresh: true });
                    close();
                }
            } catch (error) {
                const errorMessage = (error as Error).message;
                setErrorMessage(errorMessage);
            }

        },
        [ ipRangesData, translate, setData, partitionGlobalId, mutate, createNotification, close, setErrorMessage ],
    );

    const dialogActions = useMemo(() => {
        if (loading) {
            return [];
        }

        const actions = [];
        if (showErrorSummary) {
            actions.push(
                <Button
                    variant='contained'
                    data-cy='bulk-import-ok'
                    onClick={close}
                >
                    {translate({ id: 'CLIENT_OK' })}
                </Button>,
            );
        } else {
            actions.push(
                <Button
                    onClick={close}
                    data-cy='bulk-import-cancel'
                    color='primary'>
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>,
                <Button
                    variant='contained'
                    data-cy='bulk-import-upload-file'
                    onClick={() => inputRef.current?.click()}
                >
                    {translate({ id: 'CLIENT_UPLOAD_FILE' })}
                </Button>,
            );
        }

        return actions;
    }, [ showErrorSummary, close, inputRef, translate, loading ]);

    useEffect(() => {
        if (loading) {
            setProgressMessage(translate({ id: 'CLIENT_IP_RESTRICTION_IMPORTING_IP_RANGES_PROGRESS' }, {
                0: completed,
                1: total,
            }));
        }
    }, [ completed, total, translate, loading ]);

    useEffect(() => {
        if (file) {
            uploadFile(file);
            setFile(null);
        }
    }, [ file, uploadFile, setFile ]);

    return {
        inputRef,
        fileErrorId,
        loading,
        progressMessage,
        dialogActions,
        showErrorSummary,
        badEntries,
        total,
        close,
        setFile,
        uploadFile,
    };
};

export default useIPRestrictionBulkImportViewModel;
