import { UiText } from '@experiences/ui-common';
import { useAuthContext } from '@experiences/util';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApDataGridColumn,
    ApDataGridColumnDropdownFilter,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridWrapper,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { accountGlobalId } from '../../../store/selectors';
import {
    SpacingToken,
    UiStack,
} from '../../common/UiStack';
import { AuditLogService } from './AuditTabServices/AuditLogService';
import {
    filterByProduct,
    handleNextRecord,
    handlePreviousRecord,
    handleRowSelection,
    processGridData,
} from './AuditTabServices/AuditTabHelpers';
import type { IGridDataEntry } from './AuditTabServices/GridDataEntryTypes';
import { useProcessedAuditData } from './AuditTabServices/TenantService';

export const AuditTab: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const [ loading, setLoading ] = useState(true);
    const [ auditData, setAuditData ] = useState<IGridDataEntry[]>([]);
    const [ filteredData, setFilteredData ] = useState<IGridDataEntry[]>([]);
    const { token } = useAuthContext();
    const accountId = useSelector(accountGlobalId);
    const [ distinctProducts, setDistinctProducts ] = useState<Array<{ label: string; value: string }>>([]);
    const [ selectedProduct, setSelectedProduct ] = useState<{ label: string; value: string }>();
    const [ selectedRecord, setSelectedRecord ] = useState<IGridDataEntry | null>(null);
    const [ currentIndex, setCurrentIndex ] = useState<number>(0);

    // Function to extract distinct products from audit logs
    const getDistinctProductsFromAuditLogs = (auditLogs: IGridDataEntry[]) => {
        const products = auditLogs.map(log => ({
            id: log.product,
            name: log.product,
        }));
        const distinct = Array.from(new Set(products.map(p => p.id))) // Filter distinct products by id
            .map(id => products.find(p => p.id === id)!); // Find the product corresponding to the distinct id
        return distinct.map(p => ({
            label: p.name,
            value: p.id,
        }));
    };

    const fetchData = async () => {
        setLoading(true);
        try {
            const auditLogs = await AuditLogService.fetchAuditLogsBatch(token, accountId);
            setAuditData(auditLogs);

            // Extract distinct products from the audit logs and set state
            const products = getDistinctProductsFromAuditLogs(auditLogs);
            setDistinctProducts(products);
        } catch (error) {
            console.error('Error fetching audit logs:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [ token ]);

    const [ processedAuditData ] = useProcessedAuditData(auditData);

    useEffect(() => {
        setFilteredData(processGridData(processedAuditData, {})); // Initialize with empty filters
    }, [ processedAuditData ]);

    // Updated function to handle grid changes such as filtering, sorting, and pagination
    const onGridChange = useCallback(
        (filters: any) => {
            const updatedFilters = {
                ...filters,
                product: selectedProduct?.value || undefined, // Add product filter if selected
            };

            const updatedData = processGridData(processedAuditData, updatedFilters);

            setFilteredData(updatedData); // Update the grid data with the filtered data
        },
        [ processedAuditData, selectedProduct ]
    );

    const handleRefresh = useCallback(() => {
        fetchData(); // Re-fetch data when refresh button is clicked
    }, [ token ]);

    const handleRowClick = (row: IGridDataEntry) => {
        handleRowSelection(filteredData, row, setSelectedRecord, setCurrentIndex);
    };

    const handleCloseSidebar = () => {
        setSelectedRecord(null);
    };

    const handleNext = () => {
        handleNextRecord(filteredData, currentIndex, setSelectedRecord, setCurrentIndex);
    };

    const handlePrevious = () => {
        handlePreviousRecord(filteredData, currentIndex, setSelectedRecord, setCurrentIndex);
    };

    return (
        <div style={{
            display: 'flex',
            flexWrap: 'nowrap',
            width: '100%',
        }}>
            <UiStack
                direction="column"
                mt={SpacingToken.L}
                gap={SpacingToken.XS}
                data-testid="audit-tab-container"
                style={{
                    flex: selectedRecord ? '0 0 70%' : '1',
                    minWidth: 0,
                }}
            >
                <UiText variant={FontVariantToken.fontSizeLBold}>
                    {translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_TAB' })}
                </UiText>
                <ApDataGridWrapper
                    data={filteredData}
                    loading={loading}
                    selectable={false}
                    onChange={onGridChange} // Pass the onGridChange handler to the grid
                    rowClick={({ row }) => handleRowClick(row)}
                    data-testid="audit-grid"
                >
                    <ApDataGridHeader search>
                        <ApDataGridHeaderButton
                            id="refresh-audit"
                            key="refresh-audit"
                            type="main"
                            buttonType="mat-stroked-button"
                            visible
                            color="primary"
                            text={translate({ id: 'CLIENT_REFRESH' })}
                            label={translate({ id: 'CLIENT_REFRESH' })}
                            onClick={handleRefresh}
                            data-testid="refresh-audit-button"
                        />
                    </ApDataGridHeader>
                    <ApDataGridColumn
                        property="date"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_DATE' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="user"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_USER' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="tenant"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_TENANT' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="product"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_PRODUCT' })}
                        sortable
                    >
                        <ApDataGridColumnDropdownFilter
                            items={[
                                {
                                    label: translate({ id: 'CLIENT_GRID_FILTER_ALL' }),
                                    value: 'all',
                                },
                                ...distinctProducts, // Spread distinct product options
                            ]}
                            value={selectedProduct}
                            filterChange={(model) => {
                                const {
                                    selectedProduct, filteredData,
                                } = filterByProduct(model, distinctProducts, processedAuditData, processGridData);
                                setSelectedProduct(selectedProduct);
                                setFilteredData(filteredData);
                            }}
                            showAllOption={false}
                            data-testid="product-dropdown"
                        />
                    </ApDataGridColumn>

                    <ApDataGridColumn
                        property="feature"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_FEATURE' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="modelOutput"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_MODEL_OUTPUT' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="modelUsed"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_MODEL_USED' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="modelLocation"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_MODEL_LOCATION' })}
                        sortable
                    />
                    <ApDataGridColumn
                        property="status"
                        title={translate({ id: 'CLIENT_AI_TRUST_LAYER_AUDIT_STATUS' })}
                        sortable
                    />
                    <ApDataGridFooter
                        length={filteredData.length} // Use filteredData length
                        pageSize={50} // Use pagination top value
                        pageSizes={[ 50, 100 ]} // Page size options
                    />
                </ApDataGridWrapper>
            </UiStack>
            {selectedRecord && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    height: '100vh',
                    width: '20%',
                    borderLeft: '1px solid #ccc',
                    padding: '16px',
                    display: 'flex',
                    flexDirection: 'column',
                    backgroundColor: '#fff',
                    zIndex: 1000,
                }}>
                    <DialogTitle sx={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '20px',
                        fontWeight: 'bold',
                        borderBottom: '1px solid #CFD8DD',
                    }}>
                        Details
                        <IconButton
                            sx={{ ml: 'auto' }}
                            onClick={handleCloseSidebar}>
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <div style={{
                        marginTop: '16px',
                        overflowY: 'auto',
                        maxHeight: 'calc(100vh - 150px)',
                    }}>
                        <p>
                            <strong>
                                Product:
                            </strong>
                            {' '}
                            {selectedRecord.product}
                        </p>
                        <p>
                            <strong>
                                Feature:
                            </strong>
                            {' '}
                            {selectedRecord.feature}
                        </p>
                        <p>
                            <strong>
                                Tenant/User:
                            </strong>
                            {' '}
                            {selectedRecord.tenant}
                        </p>
                        <p>
                            <strong>
                                Model used:
                            </strong>
                            {' '}
                            {selectedRecord.modelUsed}
                        </p>
                        <p>
                            <strong>
                                Model location:
                            </strong>
                            {' '}
                            {selectedRecord.modelLocation}
                        </p>
                        <p>
                            <strong>
                                Status:
                            </strong>
                            {' '}
                            {selectedRecord.status}
                        </p>
                        <hr style={{ margin: '16px 0' }} />
                        <p>
                            <strong>
                                Input:
                            </strong>
                            {' '}
                            {selectedRecord.modelOutput}
                        </p>
                        <p>
                            <strong>
                                Output:
                            </strong>
                            {' '}
                            {selectedRecord.modelOutput}
                        </p>
                        <hr style={{ margin: '16px 0' }} />
                        <p>
                            <strong>
                                Request ID:
                            </strong>
                            {' '}
                            {selectedRecord.id}
                        </p>
                        <p>
                            <strong>
                                Action ID:
                            </strong>
                            {' '}
                            {selectedRecord.id}
                        </p>
                    </div>
                    <div style={{
                        marginTop: 'auto',
                        display: 'flex',
                        justifyContent: 'right',
                        gap: '20px',
                    }}>
                        <Button
                            variant="outlined"
                            startIcon={<ArrowBackIcon />}
                            onClick={handlePrevious}
                            disabled={currentIndex <= 0}
                            sx={{
                                textTransform: 'none',
                                color: '#1976D2',
                                borderColor: '#1976D2',
                            }}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            onClick={handleNext}
                            disabled={currentIndex >= filteredData.length - 1}
                            sx={{
                                textTransform: 'none',
                                backgroundColor: '#1976D2',
                                color: '#fff',
                            }}
                        >
                            Next
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};
