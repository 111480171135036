import {
    OrganizationSettingTab,
    type OrganizationSettingTabType,
} from '@experiences/interfaces';
import {
    UiCopyButton,
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';

import {
    EmailSettings,
    LoginCustomization,
    OrganizationAdminHome,
    OrganizationSettings,
    OrganizationSettingsAdvanced,
} from '../../common/constants/RouteNames';
import { useOrganizationName } from '../../common/hooks/useOrganizationName';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import { profile } from '../../store/selectors';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from './AdminBreadCrumbs';
import OrganizationSettingsTabs from './subcomponents/OrganizationSettingsTabs';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        cancelButton: { marginRight: '10px' },
        generalSettingsTabContent: { marginTop: '-8px' },
        supportIDAction: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
        },
        supportID: {
            fontWeight: 600,
            marginLeft: '4px',
            marginRight: '4px',
        },
    }),
);

// ts-unused-exports:disable-next-line
export const OrganizationSettingsPageComponent: React.FC<{ type: OrganizationSettingTabType }> = ({ type }) => {
    const { formatMessage: translate } = useIntl();
    const classes = useStyles();

    const accountProfile = useSelector(profile);

    const { licenseCode: supportId } = useMemo(() => accountProfile.accountUserDto, [ accountProfile ]);

    const { breadcrumbs } = useBreadcrumbs();

    return (
        <UiPageContainer
            position="center"
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            primaryActions={
                !process.buildConfigs.hideForMSI && supportId !== 'Unlicensed' && <div className={classes.supportIDAction}>
                    <UiText data-cy="organization-settings-primary-supportId">
                        {`${translate({ id: 'CLIENT_SUPPORT_ID' })}:`}
                    </UiText>
                    <UiText
                        className={classes.supportID}
                        data-cy="organization-settings-form-supportId">
                        {supportId}
                    </UiText>
                    <UiCopyButton
                        textToCopy={supportId}
                        aria-label={translate({ id: 'CLIENT_COPY_SUPPORT_ID' })}
                        data-cy="organization-settings-form-primary-supportId-copy-button"
                    />
                </div>
            }
        >
            <div style={{
                maxWidth: '900px',
                height: '100%',
            }}>
                <OrganizationSettingsTabs type={type} />
            </div>
        </UiPageContainer>
    );
};

export const OrganizationSettingsPageComponentWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const organizationName = useOrganizationName();

    const breadCrumbLinks = useMemo(() => [
        {
            index: 0,
            link: OrganizationAdminHome,
            name: organizationName ?? translate({ id: 'CLIENT_HOST' }),
        },
        {
            index: 1,
            link: OrganizationSettings,
            name: translate({ id: 'CLIENT_SETTINGS' }),
        },
    ], [ organizationName, translate ]);

    const isEmail = useMatch({
        path: EmailSettings,
        end: false,
    });
    const isLoginCustomization = useMatch(LoginCustomization);
    const isAdvancedSettings = useMatch({
        path: OrganizationSettingsAdvanced,
        end: false,
    });

    const type = useMemo(() => {
        if (isEmail) {
            return OrganizationSettingTab.EMAIL;
        } else if (isLoginCustomization) {
            return OrganizationSettingTab.LOGIN_CUSTOMIZATION;
        } else if (isAdvancedSettings) {
            return OrganizationSettingTab.ADVANCED;
        }
        return OrganizationSettingTab.GENERAL;
    }, [ isEmail, isLoginCustomization, isAdvancedSettings ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <OrganizationSettingsPageComponent type={type} />
        <UiSuspensefulOutlet />
    </BreadcrumbProvider>;
};

export default OrganizationSettingsPageComponent;
