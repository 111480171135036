import {
    Features,
    useFeatureFlagValue,
} from '@experiences/feature-flags';
import { useUiDataContext } from '@experiences/ui-common';
import { produce } from 'immer';
import {
    useEffect,
    useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import useSWR from 'swr';

import {
    getAllVpnGateways,
    getRobotUnits,
    getVpnGateway,
    robotUnitsApiPath,
    vpnGatewaysUri,
} from '../../services/hypervisor';
import {
    getTenantById,
    tenantByIdUri,
} from '../../services/organization/TenantService';
import { accountLogicalName } from '../../store/selectors';
import type { IVpnGatewayContext } from './interfaces/gateway';

const useVpnGatewayViewModel = () => {
    const vpnGatewayCost = Number(useFeatureFlagValue(Features.VpnGatewayCost.name));
    const accountName = useSelector(accountLogicalName);
    const { tenantId } = useParams() as { tenantId: string };

    const { data: tenant } = useSWR(
        {
            url: tenantByIdUri,
            id: tenantId,
        },
        getTenantById,
    );

    const {
        data: refreshData, setData,
    } = useUiDataContext<IVpnGatewayContext>();

    const {
        data: responseData, isLoading, mutate: mutate,
    } = useSWR(
        {
            requestUri: vpnGatewaysUri,
            accountLogicalName: accountName,
            selectedTenantId: tenantId,
        },
        getAllVpnGateways,
    );

    const gatewayData = useMemo(() => {
        const data = responseData?.value[0];
        return data;
    }, [ responseData ]);

    const { data: robotUnits } = useSWR(
        {
            url: robotUnitsApiPath,
            accountLogicalName: accountName,
            selectedTenantId: tenantId,
        },
        getRobotUnits,
    );

    const insufficentRUs = useMemo(() => robotUnits && (robotUnits?.Expired ||
        robotUnits?.Total < vpnGatewayCost), [ robotUnits, vpnGatewayCost ]);

    const {
        isValidating: gatewayLoading, mutate: mutateGateway,
    } = useSWR(
        gatewayData ? {
            requestUri: `${vpnGatewaysUri}`,
            accountLogicalName: accountName,
            selectedTenantId: tenantId,
            vpnGatewayKey: gatewayData.Key,
        } : null,
        getVpnGateway,
    );

    useEffect(() => {
        if (refreshData.refresh) {
            mutate();
            setData(produce(refreshData, (draftState) => {
                draftState.refresh = false;
            }));
        }
    }, [ refreshData, mutate, setData ]);

    return {
        tenant,
        tenantId,
        gatewayData,
        isLoading,
        gatewayLoading,
        insufficentRUs,
        refreshData,
        mutateGateway,
    };
};

export default useVpnGatewayViewModel;
