import type { IPortalUser } from '@experiences/interfaces';
import type { LanguageCulture } from '@experiences/locales';
import {
    fixName,
    getFirstNameFromFullName,
    getFullName,
    getSurnameFromFullName,
} from '@experiences/util';

import type { IGroup } from '../common/interfaces/cis/group';
import type { IUpdateRobotDto } from '../common/interfaces/cis/robot';
import type {
    IBasicUserDto,
    IUpdateUserDto,
} from '../common/interfaces/cis/user';
import type {
    AddEditRobotAccountData,
    BulkInviteUserData,
    IAddEditUserData,
    IEditGroupMembershipData,
    InviteEditUserData,
    SimpleGroup,
} from '../component/users/interfaces/user';
import { getNameFromEmail } from './EmailUtil';

interface IAddEditDirectoryUserData extends Partial<IEditGroupMembershipData> {
    email: string;
}

export function mapInviteUserDto(data: InviteEditUserData) {
    const groupIDs = getGroupIdsToAdd(data.groupIds);
    return (data.email as string[]).map(email => ({
        name: getNameFromEmail(email),
        email,
        groupIDs,
    }));
}

export function mapBulkInviteUserDto(bulkInviteData: BulkInviteUserData[], language: LanguageCulture) {
    return bulkInviteData.map(data => ({
        name: getNameFromEmail(data.email),
        email: data.email,
        groupIDs: getGroupIdsToAdd(data.groupIds),
        language,
    }));
}

export function mapUpdateRobotDto(data: AddEditRobotAccountData, partitionGlobalId?: string): IUpdateRobotDto {
    const groupIDsToAdd = getGroupIdsToAdd(data.groupIds);
    const groupIDsToRemove = getGroupIdsToRemove(data.groupIds);

    return {
        name: data.name,
        displayName: data.name,
        partitionGlobalId,
        groupIDsToAdd,
        groupIDsToRemove,
    };
}

export function mapBasicUserDto(data: IAddEditUserData, partitionGlobalId: string): IBasicUserDto {
    const { groupIds } = data;
    const groupIDs = groupIds && getGroupIdsToAdd(groupIds);
    const userName = fixName(data.userName);
    const name = fixName(data.firstName);
    const surname = fixName(data.lastName);
    const displayName = getFullName(name, surname);

    return {
        name,
        surname,
        displayName,
        email: data.email ?? '',
        groupIDs,
        partitionGlobalId,
        password: data.password || undefined,
        userName,
        bypassBasicAuthRestriction: data.bypassBasicAuthRestriction,
    };
}

export function mapUpdateBasicUserDto(data: IAddEditUserData): IUpdateUserDto {
    const { groupIds } = data;
    const groupIDsToAdd = groupIds && getGroupIdsToAdd(groupIds);
    const groupIDsToRemove = groupIds && getGroupIdsToRemove(groupIds);
    const name = fixName(data.firstName);
    const surname = fixName(data.lastName);
    const displayName = getFullName(name, surname);

    return {
        name,
        surname,
        displayName,
        email: data.email ?? '',
        groupIDsToAdd,
        groupIDsToRemove,
        password: data.password || undefined,
        bypassBasicAuthRestriction: data.bypassBasicAuthRestriction,
    };
}

export function mapDirectoryUserDto(data: IAddEditDirectoryUserData, partitionGlobalId: string): IBasicUserDto {
    const {
        groupIds, email,
    } = data;
    const groupIDs = groupIds && getGroupIdsToAdd(groupIds);
    return {
    // TODO: Add the type `UserType.DirectoryUser` when the API is ready.
        name: getNameFromEmail(email),
        email: email || undefined,
        groupIDs,
        partitionGlobalId,
        userName: email,
    };
}

export function mapUserDto(data: InviteEditUserData, user?: IPortalUser): IUpdateUserDto {
    const groupIDsToAdd = getGroupIdsToAdd(data.groupIds);
    const groupIDsToRemove = getGroupIdsToRemove(data.groupIds);
    const userName = fixName(data.userName);
    const name = getFirstNameFromFullName(userName);
    const surname = getSurnameFromFullName(userName);
    const displayName = getFullName(name, surname);
    return {
        name,
        surname,
        displayName,
        email: user?.emailId ?? (data.email as string),
        groupIDsToAdd,
        groupIDsToRemove,
    };
}

export default function reduceGroupCIS(groups?: IGroup[], userGroupIds?: string[]) {
    return (groups ?? []).reduce((acc, group) => {
        let enabled = userGroupIds ? userGroupIds.indexOf(group.id) > -1 : false;
        if (group.name === 'Everyone') {
            enabled = true;
        }
        return {
            ...acc,
            [group.id]: enabled,
        };
    }, {} as SimpleGroup);
}

function getGroupIdsToAdd(groupIds: SimpleGroup): string[] {
    return Object.keys(groupIds).filter(key => groupIds[key] === true);
}

function getGroupIdsToRemove(groupIds: SimpleGroup): string[] {
    return Object.keys(groupIds).filter(key => groupIds[key] === false);
}
