import {
    UiAlertBanner,
    UiDataContextProvider,
    UiSuspensefulOutlet,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import Language from '@mui/icons-material/Language';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import React, { useMemo } from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';
import { useSelector } from 'react-redux';

import { ConfiguringVPNForCloudRobotsLink } from '../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../common/constants/RouteNames';
import { useDocumentationLinks } from '../../common/hooks/useDocumentationLink';
import BreadcrumbProvider, { useBreadcrumbs } from '../../common/providers/BreadcrumbProvider';
import lockVpnIcon from '../../images/lockVpnIcon.svg';
import { accountLogicalName } from '../../store/selectors';
import UiPageContainer from '../common/UiPageContainer/UiPageContainer';
import AdminBreadCrumbs from '../organizationsettings/AdminBreadCrumbs';
import type { IVpnGatewayContext } from './interfaces/gateway';
import { VpnGatewayCard } from './VpnGatewayCard';
import useVpnGatewayViewModel from './VpnGatewayViewModel';

const useStyles = makeStyles(theme =>
    createStyles({
        actions: {
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
        },
        divider: { margin: '24px 0px' },
        error: {
            margin: '20px 24px',
            flex: '1 1 100%',
        },
        infoText: {
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            maxWidth: '458px',
            textAlign: 'center',
        },
        sectionButton: {
            marginTop: '20px',
            marginBottom: '6px',
            width: 'fit-content',
            padding: '18px 20px',
        },
        sectionHeader: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.semantic.colorForegroundInvDeEmp,
            borderRadius: '8px',
            alignItems: 'center',
            padding: '32px',
        },
        headerText: {
            fontWeight: 'bold',
            fontSize: '18px',
            padding: '8px',
        },
        statusButton: { '&.Mui-disabled': { pointerEvents: 'auto' } },
        tenantSettingsContainer: {
            display: 'flex',
            width: '100%',
        },
        cardIcon: { fontSize: '35px' },
        loader: {
            margin: '100px auto',
            display: 'flex',
        },
        a: {
            color: theme.palette.blue[600],
            fontWeight: 'bold',
        },
    }),
);

const VpnGatewayComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const getRoute = useRouteResolver();
    const navigate = useNavigateWithParams();
    const accountName = useSelector(accountLogicalName);
    const getLocalizedLink = useDocumentationLinks();

    const {
        tenant,
        tenantId,
        gatewayData,
        isLoading,
        insufficentRUs,
        refreshData,
    } = useVpnGatewayViewModel();

    const { breadcrumbs } = useBreadcrumbs();

    const gatewayBanners = useMemo(() => (
        <>
            { insufficentRUs ?
                <UiAlertBanner
                    type="warning"
                    closeable={false}
                    dataCy='insufficent-ru-banner'>
                    <span>
                        {translate({ id: 'CLIENT_INSUFFICIENT_RU_VPN_GATEWAY_BANNER_TEXT' })}
                            &nbsp;
                    </span>
                    <Link
                        href={getRoute(RouteNames.Licensing)}
                        target="_blank"
                        rel="noreferrer"
                        underline="hover"
                    >
                        {translate({ id: 'CLIENT_GO_TO_LICENSES' })}
                    </Link>
                </UiAlertBanner> : undefined}
            {
                gatewayData?.Freezing ?
                    <div>
                        <UiAlertBanner
                            data-cy='expired-vpn-gateway-banners'
                            type="warning"
                            closeable={false}>
                            <FormattedMessage
                                key={2}
                                id="CLIENT_RU_EXPIRED_VPN_GATEWAY_BANNER_TEXT"
                                values={{
                                    a: (msg: any) =>
                                        (
                                            <a
                                                href={getRoute(RouteNames.BuyProPresets)}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {msg}
                                            </a>
                                        ) as any,
                                    b: (msg: React.ReactNode[]) => <b>
                                        {msg}
                                    </b>,
                                }}
                            />
                        </UiAlertBanner>
                        <UiAlertBanner
                            data-cy='expired-vpn-gateway-banners'
                            type="info"
                            closeable={false}>
                            <FormattedMessage
                                key={2}
                                id="CLIENT_GATEWAY_REMOVAL_WARNING_BANNER_TEXT"
                                values={{
                                    b: (msg: React.ReactNode[]) => <b>
                                        {msg}
                                    </b>,
                                    0: gatewayData.Freezing.DaysToFreeze,
                                }}
                            />
                        </UiAlertBanner>
                    </div> : undefined
            }
        </>
    ), [ insufficentRUs, translate, getRoute, gatewayData ]);

    return (
        <UiPageContainer
            position='left'
            breadcrumb={<AdminBreadCrumbs breadCrumbTrail={breadcrumbs} />}
            banner={gatewayBanners}
            loading={isLoading || refreshData.refresh}>
            {
                gatewayData && tenant ?
                    <div data-cy='vpn-gateway-card-section'>
                        <VpnGatewayCard
                            key={gatewayData.Key}
                            gwKey={gatewayData.Key}
                            titleIcon={
                                <Language
                                    className={classes.cardIcon}
                                    style={{ color: tenant?.color }}
                                />
                            }
                            style={{
                                height: '254px',
                                maxWidth: '338px',
                            }}
                            dataCy='vpn-gateway-card'
                            tenant={tenant}
                            accountName={accountName}
                            disableAdd={insufficentRUs ?? true}
                        />
                    </div>
                    :
                    <div className={classes.sectionHeader}>
                        <img
                            src={lockVpnIcon}
                            alt='lock-vpn-icon'
                        />
                        <UiText className={classes.headerText}>
                            {translate({ id: 'CLIENT_TENANT_CREATE_VPN_GATEWAY' }, { 0: tenant?.name })}
                        </UiText>
                        <UiText className={classes.infoText}>
                            {translate({ id: 'CLIENT_GATEWAY_INFO' })}
                        </UiText>
                        <Button
                            className={classes.sectionButton}
                            size="small"
                            variant="contained"
                            data-cy="tenant-vpn-gateway-create-button"
                            onClick={() => {
                                navigate(getRoute(`${RouteNames.VpnGateway}/add`).replace(':tenantId', tenantId));
                            }}
                            disabled={tenant?.status === 'Disabled' || insufficentRUs}
                        >
                            {translate({ id: 'CLIENT_ADD_VPN_GATEWAY' })}
                        </Button>
                        <Button
                            variant='text'
                            href={getLocalizedLink({ articleSlug: ConfiguringVPNForCloudRobotsLink })}
                            target='_blank'>
                            {translate({ id: 'CLIENT_LEARN_MORE' })}
                        </Button>
                    </div>
            }
        </UiPageContainer>
    );
};

export const VpnGatewayComponentWithProvider: React.FC = () => {
    const { formatMessage: translate } = useIntl();

    const {
        tenant,
        tenantId,
        gatewayData,
    } = useVpnGatewayViewModel();

    const breadCrumbLinks = useMemo(() =>
        [
            {
                index: 0,
                link: RouteNames.TenantHome.replace(':tenantId', tenantId),
                name: tenant?.name ?? '',
            },
            {
                index: 1,
                link: RouteNames.VpnGateway.replace(':tenantId', tenantId),
                name: gatewayData?.Name ??
                    translate({ id: gatewayData?.GatewayType === 'ExpressRoute' ? 'CLIENT_EXPRESSROUTE' : 'CLIENT_GATEWAY' }),
            },
        ],
    [ tenant, tenantId, translate, gatewayData ]);

    return <BreadcrumbProvider
        breadcrumbs={breadCrumbLinks}
        legacy>
        <UiDataContextProvider<IVpnGatewayContext> initialState={{ refresh: false }}>
            <VpnGatewayComponent />
            <UiSuspensefulOutlet />
        </UiDataContextProvider>
    </BreadcrumbProvider>;
};

export default VpnGatewayComponent;
