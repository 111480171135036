import { IntlProvider } from '@experiences/locales';
import { ApolloThemeProvider } from '@experiences/theme';
import React from 'react';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { SWRConfig } from 'swr';

import store from './store';

export const App = ({ router }: { router: any }) => (
    <Provider store={store}>
        <IntlProvider>
            <ApolloThemeProvider>
                <SWRConfig
                    value={{
                        revalidateOnFocus: false,
                        dedupingInterval: ((window as any).Cypress ? 0 : window.env?.SWR_DEDUPE_INTERVAL) ?? 2000,
                    }}
                >
                    <RouterProvider router={router} />
                </SWRConfig>
            </ApolloThemeProvider>
        </IntlProvider>
    </Provider>
);
