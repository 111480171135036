import {
    AccountLicense,
    getRegionTranslationId,
} from '@experiences/constants';
import {
    OrganizationSettingsEvent,
    OrgSelfServeMigrationEvent,
} from '@experiences/telemetry';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import clsx from 'clsx';
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { DataResidencyCloudLink } from '../../../common/constants/documentation/DocumentationLinks.default';
import * as RouteNames from '../../../common/constants/RouteNames';
import useCheckLicense from '../../../common/hooks/useCheckLicense';
import { useDocumentationLinks } from '../../../common/hooks/useDocumentationLink';
import { MigrationAvailability } from '../../../common/interfaces/gws';
import { getOrganizationMigrationStatus } from '../../../services/global-workflow-service/OrgMigration';
import {
    accountCreatedOn,
    accountLogicalName,
    organizationRegion,
} from '../../../store/selectors';
import { useTelemetryHelper } from '../../../telemetry/TelemetryHelper';
import { UiStack } from '../../common/UiStack';
import { UiUpgradeChip } from '../../common/UiUpgradeChip';

const useStyles = makeStyles((theme) => ({
    moveOrganizationBox: {
        marginTop: '24px',
        marginBottom: '20px',
        margin: '0 2px',
    },
    moveOrganizationBoxDisabled: { backgroundColor: theme.palette.semantic.colorBackgroundDisabled },
    moveOrganizationTitleSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginTop: '16px',
    },
    requestMove: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        border: 'solid 1px',
        borderColor: theme.palette.semantic.colorBorderDeEmp,
        marginTop: '8px',
        borderRadius: '4px',
        padding: '0 12px',
        position: 'relative',
        height: '40px',
    },
    moveButtonWrapper: {
        position: 'absolute',
        right: '4px',
        whiteSpace: 'nowrap',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    tooltip: { align: 'center' },
    chipSpacer: { marginLeft: '8px' },
    infoLink: {
        fontSize: '14px',
        fontWeight: 600,
        margin: '12px 0 12px 0',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    icon: {
        height: '16px',
        width: '16px',
        marginLeft: '4px',
    },
}),
);

function isUnderOneHour(orgCreatedOn: string): boolean {
    const now = new Date();
    const diffInMilliseconds = now.getTime() - new Date(orgCreatedOn).getTime();
    const diffInHours = diffInMilliseconds / 1000 / 60 / 60;
    return diffInHours < 1;
}

export const OrganizationRegion: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLinks = useDocumentationLinks();
    const { isFreeOrCommunityRevamp } = useCheckLicense();
    const { logEvent } = useTelemetryHelper();

    const currentAccountName = useSelector(accountLogicalName);
    const currentRegion = useSelector(organizationRegion);
    const orgCreatedOn = useSelector(accountCreatedOn);

    const [ orgMigrationStatus, setOrgMigrationStatus ] = useState< string | undefined >();

    const {
        data: migrationDetails, isLoading: isScheduledMigrationStatusLoading,
    } = useSWR({
        accountName: currentAccountName,
        url: 'getOrganizationMigrationStatus',
    }, getOrganizationMigrationStatus);

    const orgRecentlyCreated = useMemo(() => isUnderOneHour(orgCreatedOn), [ orgCreatedOn ]);

    const navigateToMigration = useCallback(async () => {
        if (orgMigrationStatus === MigrationAvailability.SCHEDULED) {
            logEvent(OrgSelfServeMigrationEvent.ViewMigrationDetails);
            navigate(getRoute(RouteNames.EditOrganizationMigration));
        } else {
            logEvent(OrgSelfServeMigrationEvent.OpenMigration, { ServerRegion: currentRegion });
            navigate(getRoute(RouteNames.OrganizationMigration));
        }
    }, [ currentRegion, getRoute, logEvent, navigate, orgMigrationStatus ]);

    useEffect(() => {
        setOrgMigrationStatus(migrationDetails?.status);
    }, [ migrationDetails ]);

    const moveButtonText = useMemo(() => {
        let id = 'CLIENT_CHANGE_REGION';
        if (orgRecentlyCreated) {
            id = 'CLIENT_JOBSTATUS_INPROGRESS';
        } else if (orgMigrationStatus === MigrationAvailability.ENABLED) {
            id = 'CLIENT_CHANGE_REGION';
        } else if (orgMigrationStatus === MigrationAvailability.SCHEDULED) {
            id = 'CLIENT_MIGRATION_SCHEDULED';
        } else if (orgMigrationStatus === MigrationAvailability.RUNNING) {
            id = 'CLIENT_MIGRATION_RUNNING';
        } else if (orgMigrationStatus === MigrationAvailability.PENDINGFINALIZATION) {
            id = 'CLIENT_MIGRATION_COMPLETED';
        }
        return translate({ id });
    }, [ orgRecentlyCreated, orgMigrationStatus, translate ]);

    const isMigrationButtonDisabled = useMemo(() => {
        if (orgRecentlyCreated) {
            return true;
        }
        return orgMigrationStatus === MigrationAvailability.RUNNING || orgMigrationStatus === MigrationAvailability.PENDINGFINALIZATION;
    }, [ orgRecentlyCreated, orgMigrationStatus ]);

    return (
        <div
            className={classes.moveOrganizationBox}
            data-cy="organization-migration-section">
            <div className={classes.moveOrganizationTitleSection}>
                <UiText
                    color="var(--color-foreground-de-emp)"
                    variant={FontVariantToken.fontSizeMBold}>
                    {translate({ id: 'CLIENT_REGION' })}
                </UiText>
                {isFreeOrCommunityRevamp &&
                    <UiUpgradeChip
                        className={classes.chipSpacer}
                        licenseType={AccountLicense.PRO}
                        title={translate({ id: 'CLIENT_CHIP_MIGRATION_HEADER' })}
                        description={translate({ id: 'CLIENT_CHIP_MIGRATION_DESCRIPTION' })}
                        iconDescription={translate({ id: 'CLIENT_CHIP_MIGRATION_ICON_DESCRIPTION' })}
                        icon={<LanguageOutlinedIcon />}
                        telemetryTitle={OrganizationSettingsEvent.Advanced} />}
            </div>
            <div className={clsx(classes.requestMove, isFreeOrCommunityRevamp && classes.moveOrganizationBoxDisabled)}>
                <UiText
                    variant={FontVariantToken.fontSizeM}
                    data-cy="organization-migration-title">
                    {translate({ id: getRegionTranslationId(currentRegion) })}
                </UiText>
                {!isFreeOrCommunityRevamp && (
                    <span className={classes.moveButtonWrapper}>
                        <UiProgressButton
                            disabled={isMigrationButtonDisabled}
                            size="small"
                            loading={isScheduledMigrationStatusLoading}
                            onClick={navigateToMigration}
                            data-cy="organization-migration-button"
                            variant="text">
                            {moveButtonText}
                        </UiProgressButton>
                        {(orgMigrationStatus === MigrationAvailability.PENDINGFINALIZATION || orgRecentlyCreated) && (
                            <Tooltip
                                data-cy="organization-migration-tooltip"
                                className={classes.tooltip}
                                title={orgRecentlyCreated ?
                                    translate({ id: 'CLIENT_MIGRATION_TOO_SOON' })
                                    : translate({ id: 'CLIENT_MIGRATION_COMPLETED_TOOLTIP' })}>
                                <InfoOutlinedIcon fontSize='small' />
                            </Tooltip>)}
                    </span>
                )}
            </div>
            <UiStack>
                <Link
                    className={classes.infoLink}
                    onClick={() => logEvent(OrgSelfServeMigrationEvent.ReadMore, { Location: 'advanced-settings-page' })}
                    target="_blank"
                    href={getLocalizedLinks({ articleSlug: DataResidencyCloudLink })}>
                    {translate({ id: 'CLIENT_READ_REGION_RESIDENCY' })}
                    <OpenInNewIcon className={classes.icon} />
                </Link>
            </UiStack>
        </div>
    );
};
