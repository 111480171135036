import { ContactTechnicalSupportLinks } from '@experiences/constants';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { useLocalizedLinks } from '@experiences/util';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import React from 'react';
import { useIntl } from 'react-intl';

import {
    SpacingToken,
    UiStack,
} from '../../common/UiStack';

const useStyles = makeStyles({
    root: {
        maxWidth: '456px',
        padding: '4px',
    },
});

export const TenantMigrationNotAvailableDialog: React.FC<IUiDialogHookCustomContent> = ({ closeDialog }) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const getLocalizedLink = useLocalizedLinks();

    return (
        <UiStack
            className={classes.root}
            gap={SpacingToken.M}
            direction="column">
            <UiText>
                {translate({ id: 'CLIENT_TENANT_MIGRATION_NOT_AVAILABLE_REASON_1' })}
            </UiText>
            <UiText>
                {translate({ id: 'CLIENT_MIGRATION_NOT_AVAILABLE_REASON_2' })}
            </UiText>
            <UiStack
                direction="row"
                gap={SpacingToken.S}
                justify="end">
                <Button
                    variant="text"
                    data-cy="cancel-button"
                    onClick={() => closeDialog()}>
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <Button
                    variant="contained"
                    data-cy="file-ticket-button"
                    onClick={() => closeDialog()}
                    href={getLocalizedLink(ContactTechnicalSupportLinks)!} // OK: "en" default is defined in map
                    target="_blank"
                    rel="noreferrer"
                >
                    {translate({ id: 'CLIENT_FILE_SUPPORT_TICKET' })}
                </Button>
            </UiStack>
        </UiStack>
    );
};
