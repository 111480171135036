import React, { useState } from 'react';

import A4ECustomizeComponent from './subcomponents/A4ECustomizeComponent';
import A4ESolutionComponent from './subcomponents/A4ESolutionComponent';
import { SolutionDeploymentState } from './utils/A4ESolutionUtils';

const A4EComponent: React.FC = () => {
    const [ deploymentState, setDeploymentState ] = useState<SolutionDeploymentState>(SolutionDeploymentState.NONE);
    return <div>
        <A4ESolutionComponent onDeploymentStateChange={setDeploymentState} />
        {deploymentState >= SolutionDeploymentState.INSTALLED && <A4ECustomizeComponent />}
    </div>;
};

export default A4EComponent;
