import { GlobalStyles } from '@experiences/theme';
import {
    UiSelect,
    UiText,
} from '@experiences/ui-common';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import { FontVariantToken } from '@uipath/apollo-core';
import React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
    dhGroupOptions,
    ikeEncryptionOptions,
    ikeIntegrityOptions,
    ipSecEncryptionOptions,
    ipSecIntegrityOptions,
    pfsGroupOptions,
} from './VpnGatewayConstants';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    ...createStyles({
        ipSecSection: {
            marginTop: '16px',
            display: 'flex',
            justifyContent: 'space-between',
            position: 'relative',
        },
        ikePhase: {
            flex: '1 1 0px',
            marginRight: '24px',
        },
        ipsecPolicyInput: { marginBottom: '16px' },
        sectionTitle: { margin: '10px 0 10px 0' },
    }),
}));

const IPSEC_SA_SECONDS_MIN = 300;
const IPSEC_SA_VALUE_MIN = 1024;
const IPSEC_SA_VALUE_MAX = 2147483647;

export const VpnGatewayIPSecPolicyComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const {
        control,
        register,
        formState: { errors },
    } = useFormContext();

    function validateIpSecSAValue(val: string) {
        const value = Number(val);
        return value === 0 || (value >= IPSEC_SA_VALUE_MIN && value <= IPSEC_SA_VALUE_MAX);
    }

    return (
        <div className={classes.ipSecSection}>
            <div className={classes.ikePhase}>
                <UiText
                    className={classes.sectionTitle}
                    align='left'
                    variant={FontVariantToken.fontSizeH4}>
                    {translate({ id: 'CLIENT_VPN_CUSTOM_IPSEC_IKE_TITLE' })}
                </UiText>
                <UiSelect
                    className={classes.ipsecPolicyInput}
                    dataCy='ike-encryption-select'
                    name="IpSecPolicy.IkeEncryption"
                    inputLabel={translate({ id: 'CLIENT_IKE_ENCRYPTION' })}
                    fullWidth
                    error={!!errors.IkeEncryption}
                    helperText={(errors.IkeEncryption?.type === 'required' &&
                    translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_IKE_ENCRYPTION' }) }
                    ))}
                    options={ikeEncryptionOptions}
                    control={control}
                    required
                />
                <UiSelect
                    className={classes.ipsecPolicyInput}
                    dataCy='ike-integrity-select'
                    name="IpSecPolicy.IkeIntegrity"
                    inputLabel={translate({ id: 'CLIENT_IKE_INTEGRITY' })}
                    fullWidth
                    error={!!errors.IkeIntegrity}
                    helperText={(errors.IkeIntegrity?.type === 'required' &&
                    translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_IKE_INTEGRITY' }) }
                    ))}
                    options={ikeIntegrityOptions}
                    control={control}
                    required
                />
                <UiSelect
                    className={classes.ipsecPolicyInput}
                    dataCy='ike-dhgroup-select'
                    name="IpSecPolicy.DhGroup"
                    inputLabel={translate({ id: 'CLIENT_IKE_DHGROUP' })}
                    inputProps={{ id: 'CLIENT_IKE_DHGROUP' }}
                    fullWidth
                    options={dhGroupOptions}
                    control={control}
                    required
                    error={!!errors.DhGroup}
                    helperText={(errors.DhGroup?.type === 'required' &&
                    translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_IKE_DHGROUP' }) }
                    ))}
                />
            </div>
            <div className={classes.ikePhase}>
                <UiText
                    className={classes.sectionTitle}
                    align='left'
                    variant={FontVariantToken.fontSizeH4}>
                    {translate({ id: 'CLIENT_VPN_CUSTOM_IPSEC_TITLE' })}
                </UiText>
                <UiSelect
                    className={classes.ipsecPolicyInput}
                    dataCy='ipsec-encryption-select'
                    name="IpSecPolicy.IPsecEncryption"
                    inputLabel={translate({ id: 'CLIENT_IPSEC_ENCRYPTION' })}
                    fullWidth
                    error={!!errors.IpSecEncryption}
                    helperText={(errors.IpSecEncryption?.type === 'required' &&
                    translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_IPSEC_ENCRYPTION' }) }
                    ))}
                    options={ipSecEncryptionOptions}
                    control={control}
                    required
                />
                <UiSelect
                    className={classes.ipsecPolicyInput}
                    dataCy='ipsec-integrity-select'
                    name="IpSecPolicy.IPsecIntegrity"
                    inputLabel={translate({ id: 'CLIENT_IPSEC_INTEGRITY' })}
                    fullWidth
                    error={!!errors.IpSecIntegrity}
                    helperText={(errors.IpSecIntegrity?.type === 'required' &&
                    translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_IPSEC_INTEGRITY' }) }
                    ))}
                    options={ipSecIntegrityOptions}
                    control={control}
                    required
                />
                <UiSelect
                    className={classes.ipsecPolicyInput}
                    dataCy='ipsec-pfsgroup-select'
                    name="IpSecPolicy.PfsGroup"
                    inputLabel={translate({ id: 'CLIENT_IPSEC_PFSGROUP' })}
                    fullWidth
                    error={!!errors.PfsGroup}
                    helperText={(errors.PfsGroup?.type === 'required' &&
                    translate(
                        { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                        { 0: translate({ id: 'CLIENT_IPSEC_PFSGROUP' }) }
                    ))}
                    options={pfsGroupOptions}
                    control={control}
                    required
                />
                <TextField
                    className={classes.ipsecPolicyInput}
                    data-cy="ipsec-lifetime-kb-input"
                    label={translate({ id: 'CLIENT_IPSEC_SA_LIFETIME_KB' })}
                    fullWidth
                    type='number'
                    variant="outlined"
                    required
                    helperText={errors?.SAKilobytes?.message as string | undefined}
                    error={!!errors?.SAKilobytes}
                    inputProps={register('IpSecPolicy.SaDataSizeKilobytes', {
                        required: translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_IPSEC_SA_LIFETIME_KB' }) },
                        ),
                        validate: (value: string) =>
                            validateIpSecSAValue(value) || translate({ id: 'CLIENT_IPSEC_SA_VALUE_ERROR_MESSAGE' }),
                    })}
                />
                <TextField
                    className={classes.ipsecPolicyInput}
                    data-cy="ipsec-lifetime-sec-input"
                    label={translate({ id: 'CLIENT_IPSEC_SA_LIFETIME_SECONDS' })}
                    fullWidth
                    variant="outlined"
                    required
                    type='number'
                    helperText={errors?.SASeconds?.message as string | undefined}
                    error={!!errors?.SASeconds}
                    inputProps={register('IpSecPolicy.SaLifeTimeSeconds', {
                        required: translate(
                            { id: 'CLIENT_REQUIRED_FIELD_ERROR_SPECIFIC' },
                            { 0: translate({ id: 'CLIENT_IPSEC_SA_LIFETIME_SECONDS' }) },
                        ),
                        validate: (value: number) =>
                            value >= IPSEC_SA_SECONDS_MIN || translate({ id: 'CLIENT_IPSEC_SA_SECONDS_ERROR_MESSAGE' }),
                    })}
                />
            </div>
        </div>
    );
};
