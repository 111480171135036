import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import {
    UiProgressButton,
    UiText,
} from '@experiences/ui-common';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import isString from 'lodash/isString';
import React, {
    useCallback,
    useState,
} from 'react';
import {
    FormattedMessage,
    useIntl,
} from 'react-intl';

import { vpnGatewaysUri } from '../../services/hypervisor';

const useStyles = makeStyles(theme =>
    createStyles({
        deleteGatewayConfirmation: { maxWidth: '417px' },
        deleteText: { color: theme.palette.semantic.colorErrorText },
        deleteButton: {
            color: theme.palette.semantic.colorForegroundInverse,
            backgroundColor: theme.palette.semantic.colorErrorIcon,
            '&:hover': { backgroundColor: `${theme.palette.semantic.colorErrorText} !important` },
        },
        buttonContainer: {
            marginTop: '1em',
            display: 'flex',
            justifyContent: 'flex-end',
        },
        confirmDeleteSection: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        confirmDeleteWarningSection: {
            marginTop: '1.5em',
            marginBottom: '1.5em',
        },
        textField: {
            marginTop: '0.5em',
            marginBottom: '0.5em',
            width: '100%',
        },
        cancelButton: { marginRight: '10px' },
    }),
);

const VpnGatewayDeleteDialogBody: React.FC<IUiDialogHookCustomContent> = ({
    closeDialog, gateway, tenant, deleteFunction, accountName, isConnection, connection, tenantId,
}) => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();

    const {
        getErrorObject, getErrorMessage,
    } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const [ textFieldValue, setTextFieldValue ] = useState('');
    const [ loading, setLoading ] = useState(false);

    const handleChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setTextFieldValue(event.target.value);
    }, []);

    const deleteAndClose = useCallback(async () => {
        setLoading(true);

        try {
            if (isConnection) {
                await deleteFunction(accountName, tenantId, connection.Key);
            } else {
                await deleteFunction(vpnGatewaysUri, accountName, tenant.id, gateway.Key);
            }
            closeDialog(true);
        } catch (error) {
            const errorObject = await getErrorObject(error);
            const data = errorObject.response?.data;
            const errorResponse = isString(data) ? data : await getErrorMessage(errorObject);
            setErrorMessage(errorResponse);
            setLoading(false);
        }
    }, [ gateway, tenant, accountName, deleteFunction, connection, closeDialog, getErrorObject, getErrorMessage, setErrorMessage, isConnection, tenantId ]);

    return (
        <>
            <div className={classes.confirmDeleteWarningSection}>
                <UiText data-cy="delete-confirmation-type-warning">
                    <FormattedMessage
                        id="CLIENT_VPN_GATEWAY_DELETE_TEXT_WARNING"
                        values={{
                            b: (chunk: React.ReactNode[]) => <b>
                                {chunk}
                            </b>,
                            0: isConnection ? connection.Name : gateway.Name,
                            1: translate({ id: isConnection ? 'CLIENT_CONNECTION' : 'CLIENT_GATEWAY' }),
                            2: isConnection ? gateway.Name : tenant.name,
                            3: translate({ id: isConnection ? 'CLIENT_GATEWAY' : 'CLIENT_TENANT' }),
                        }}
                    />
                </UiText>
            </div>
            <div className={classes.confirmDeleteSection}>
                <UiText data-cy="delete-confirmation-type-reminder">
                    <FormattedMessage
                        id="CLIENT_SERVICE_DELETE"
                        values={{
                            strong: (chunk: React.ReactNode[]) => <strong>
                                {chunk}
                            </strong>,
                            0: <span className={classes.deleteText}>
                                {isConnection ? connection.Name : gateway.Name}
                            </span>,
                        }}
                    />
                </UiText>
                <TextField
                    className={classes.textField}
                    autoComplete="off"
                    variant="outlined"
                    id="standard-name"
                    onChange={handleChange}
                    value={textFieldValue}
                    placeholder={isConnection ? translate({ id: 'CLIENT_CONNECTION_NAME' }) : translate({ id: 'CLIENT_VPN_GATEWAY_NAME_CREATE_EDIT' })}
                    data-cy="delete-confirmation-textfield"
                />
            </div>
            <div className={classes.buttonContainer}>
                <Button
                    key="cancelButton"
                    className={classes.cancelButton}
                    onClick={() => closeDialog()}
                    color="primary"
                    data-cy="secondary-button-confirmation"
                >
                    {translate({ id: 'CLIENT_CANCEL' })}
                </Button>
                <UiProgressButton
                    loading={loading}
                    key="primaryButton"
                    variant="contained"
                    color="inherit"
                    data-cy="primary-button-confirmation"
                    onClick={() => deleteAndClose()}
                    disabled={isConnection ? textFieldValue !== connection.Name : textFieldValue !== gateway.Name}
                    classes={{ colorInherit: classes.deleteButton }}
                >
                    {translate({ id: 'CLIENT_DELETE' })}
                </UiProgressButton>
            </div>
        </>
    );
};

export default VpnGatewayDeleteDialogBody;
