import { accountGlobalId } from '@experiences/ecommerce';
import {
    useCentralErrorSetter,
    useGetErrorInfo,
} from '@experiences/error';
import { UiText } from '@experiences/ui-common';
import { useShowDialog } from '@experiences/util';
import React, {
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import useCheckAuthTypes from '../../../auth/hooks/CheckAuthType';
import { notificationType } from '../../../common/constants/Constant';
import { useUiSnackBar } from '../../../common/hooks/useUiSnackBar';
import type { IAuthenticationIDPSettingPayload } from '../../../services/identity/AuthenticationSettingService';
import {
    AuthenticationSettingType,
    authenticationSettingUrl,
    BulkAuthenticationSettingKey,
    deleteIDPSetting,
    getAllAuthenticationSettings,
    updateIDPSetting,
} from '../../../services/identity/AuthenticationSettingService';

export interface IAuthSettingsData {
    directConnection: string;
}

export const initialData: IAuthSettingsData = { directConnection: AuthenticationSettingType.NoSetting };

const useExternalIdentityProvidersForm = (reset: (_?: any) => void) => {
    const { formatMessage: translate } = useIntl();

    const createNotification = useUiSnackBar();
    const createDialog = useShowDialog();
    const { getErrorMessage } = useGetErrorInfo();
    const setErrorMessage = useCentralErrorSetter();

    const {
        checkTokenAuthIsMicrosoft, checkTokenAuthIsGoogle,
    } = useCheckAuthTypes();

    const partitionGlobalId = useSelector(accountGlobalId);

    const [ loading, setLoading ] = useState(false);

    const {
        data: authenticationSetting, mutate,
    } = useSWR(
        {
            url: `${authenticationSettingUrl}/getAll`,
            partitionGlobalId,
        },
        getAllAuthenticationSettings,
    );

    useEffect(() => {
        if (authenticationSetting?.[BulkAuthenticationSettingKey.NoSetting]) {
            reset({ directConnection: AuthenticationSettingType.NoSetting });
        } else if (authenticationSetting?.[BulkAuthenticationSettingKey.Microsoft]) {
            reset({ directConnection: AuthenticationSettingType.Microsoft });
        } else if (authenticationSetting?.[BulkAuthenticationSettingKey.Google]) {
            reset({ directConnection: AuthenticationSettingType.Google });
        }
    }, [ authenticationSetting, reset ]);

    const handleNoSetting = useCallback(async () => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_AAD_DIALOG_SAVE_CHANGES' }),
            body: <UiText>
                {translate({ id: 'CLIENT_AAD_DIALOG_DESCRIPTION' })}
            </UiText>,
            icon: 'warning',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_AAD_DIALOG_SAVE' }),
        });
        if (!proceed) {
            return;
        }
        try {
            await deleteIDPSetting(partitionGlobalId);
            createNotification(
                translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS_SUCCESSFULLY_CHANGED' }),
                notificationType.SUCCESS
            );
            mutate();
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        }
    }, [
        createDialog,
        translate,
        partitionGlobalId,
        createNotification,
        mutate,
        setErrorMessage,
        getErrorMessage,
    ]);

    const handleEnforceProvider = useCallback(async (directConnection: string) => {
        const proceed = await createDialog({
            title: translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS_CHANGE' }),
            body: (
                <div>
                    <UiText style={{ marginBottom: '12px' }}>
                        {translate({ id: `CLIENT_AUTHENTICATION_SETTINGS_CHANGE_MICROSOFT_DESCRIPTION_1` })}
                    </UiText>
                    <UiText>
                        {translate({ id: `CLIENT_AUTHENTICATION_SETTINGS_CHANGE_MICROSOFT_DESCRIPTION_2` })}
                    </UiText>
                </div>
            ),
            icon: 'warning',
            showCancel: true,
            primaryButtonText: translate({ id: 'CLIENT_CONTINUE' }),
        });

        if (!proceed) {
            return;
        }

        try {
            if (
                (!checkTokenAuthIsMicrosoft && directConnection === AuthenticationSettingType.Microsoft)
                || (!checkTokenAuthIsGoogle && directConnection === AuthenticationSettingType.Google)
            ) {
                const changeConfirmation = await createDialog({
                    title: translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS_CHANGE' }),
                    body: (
                        <div>
                            <UiText style={{ marginBottom: '12px' }}>
                                {translate({ id: `CLIENT_AUTHENTICATION_SETTINGS_CHANGE_GOOGLE_DESCRIPTION_1` })}
                            </UiText>
                            <UiText>
                                {translate({ id: `CLIENT_AUTHENTICATION_SETTINGS_CHANGE_GOOGLE_DESCRIPTION_2` })}
                            </UiText>
                        </div>
                    ),
                    icon: 'warning',
                    showCancel: false,
                    primaryButtonText: translate({ id: 'CLIENT_OK' }),
                });

                if (!changeConfirmation) {
                    return;
                }
            }

            setLoading(true);

            const payload: IAuthenticationIDPSettingPayload = {
                partitionGlobalId,
                allowedIdp: directConnection === AuthenticationSettingType.Microsoft ? 'UiPath-AADV2' : 'google-oauth2',
            };

            await updateIDPSetting(payload);
            createNotification(
                translate({ id: 'CLIENT_AUTHENTICATION_SETTINGS_SUCCESSFULLY_CHANGED' }),
                notificationType.SUCCESS
            );
            mutate();
        } catch (error) {
            setErrorMessage(await getErrorMessage(error));
        } finally {
            setLoading(false);
        }
    }, [
        createDialog,
        translate,
        checkTokenAuthIsMicrosoft,
        checkTokenAuthIsGoogle,
        partitionGlobalId,
        createNotification,
        mutate,
        setErrorMessage,
        getErrorMessage,
    ]);

    const onSubmit = useCallback(async (data: { directConnection: string }) => {
        if (data.directConnection === AuthenticationSettingType.NoSetting) {
            handleNoSetting();
        } else if (
            data.directConnection === AuthenticationSettingType.Microsoft
            || data.directConnection === AuthenticationSettingType.Google
        ) {
            handleEnforceProvider(data.directConnection);
        }
    }, [ handleNoSetting, handleEnforceProvider ]);

    return {
        onSubmit,
        loading,
    };
};

export default useExternalIdentityProvidersForm;
