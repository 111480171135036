import { post } from '../utility/Requests.default';

export const DashboardUrls = { GetDashboard: '/api/insights/dashboard/url/embed' };

interface IInsightsDashboardResult {
    url: string;
    folderPermissionsSuccess: boolean;
}

export interface IInsightsDashboard {
    isSuccess: boolean;
    error: null | string;
    result: IInsightsDashboardResult;
}

export async function getDashboardUrl({
    url, accountLogicalName, tenantName, id, folder, locale, theme,
}: {
    url: string;
    accountLogicalName: string;
    tenantName: string;
    id: string;
    folder?: string | undefined;
    locale: string;
    theme: string; }) {

    const payload = {
        dashboardId: id,
        tenantName,
        folder,
        locale,
        theme,
    };

    const result = await post<string>(DashboardUrls.GetDashboard, {
        body: payload,
        urlParams: {
            accountLogicalName,
            tenantName,
        },
    });

    return JSON.parse(result) as IInsightsDashboard;
}
