import { UiText } from '@experiences/ui-common';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { FontVariantToken } from '@uipath/apollo-core';
import {
    ApDataGridColumn,
    ApDataGridColumnDropdownFilter,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApTooltip,
} from '@uipath/portal-shell-react';
import React, {
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import type { DataSet } from '../../../services/ecs/Ecs.types';
import { getDataSets } from '../../../services/ecs/EcsService';
import { getTenants } from '../../../services/organization/TenantService';
import { accountGlobalId } from '../../../store/selectors';
import {
    SpacingToken,
    UiStack,
} from '../../common/UiStack';

async function getDataSetsWithTenant(organizationId: string, tenantId: string) {
    const dataSets = await getDataSets(organizationId, tenantId);
    return dataSets.map(ds => ({
        ...ds,
        tenant: tenantId,
    }) as DataSet);
}

export const ContextGroundingTab: React.FC = () => {
    const { formatMessage: translate } = useIntl();
    const organizationId = useSelector(accountGlobalId);
    const [ selectedTenant, setSelectedTenant ] = useState<{ label: string; value: string }>();
    const [ filteredDataSets, setFilteredDataSets ] = useState<DataSet[]>([]);

    const {
        data: dataSets, isLoading: isLoadingDataSets,
    } = useSWR(selectedTenant?.value ? [ 'datasets', organizationId, selectedTenant ] : undefined, () => getDataSetsWithTenant(organizationId, selectedTenant!.value));

    const {
        data: tenants, isLoading: isLoadingTenants,
    } = useSWR({
        url: 'tenants',
        organizationGuid: organizationId,
        accountName: 'not-used',
        includeTenantServices: true,
    }, getTenants);

    useEffect(() => {
        setSelectedTenant(prev => {
            if (prev?.value) {
                return prev;
            }
            return {
                label: tenants?.[0]?.name ?? '',
                value: tenants?.[0]?.id ?? '',
            };
        });
    }, [ tenants ]);

    const onDeleteItems = useCallback((items?: DataSet[]) => {
        // TODO: IMPLEMENT the delete logic...
        console.log('onDeleteItems', items);
    }, []);

    const onGridChange = useCallback(({
        filter, sort, pageIndex, pageSize, searchTerm,
    }: any) => {
        console.log('onGridChange', {
            filter,
            sort,
            pageIndex,
            pageSize,
            searchTerm,
        });
        // TODO: IMPLEMENT filtering the data set...

        setFilteredDataSets(dataSets ?? []);
    }, [ dataSets ]);

    return (
        <UiStack
            direction="column"
            mt={SpacingToken.L}
            gap={SpacingToken.XS}>
            <UiText variant={FontVariantToken.fontSizeLBold}>
                {translate({ id: 'CLIENT_AI_TRUST_LAYER_CONTEXT_GROUNDING_TITLE' })}
            </UiText>
            <UiText>
                {translate({ id: 'CLIENT_AI_TRUST_LAYER_CONTEXT_GROUNDING_DESCRIPTION' })}
            </UiText>

            <ApDataGridWrapper
                data={filteredDataSets}
                loading={isLoadingDataSets || isLoadingTenants}
                selectable
                onChange={onGridChange}
                dataCy="context-grounding-grid">

                <ApDataGridHeader<DataSet> search>

                    <ApDataGridHeaderButton<DataSet>
                        id='add-context'
                        key='add-context'
                        type='main'
                        buttonType='mat-flat-button'
                        visible
                        color='primary'
                        text={translate({ id: 'CLIENT_ADD' })}
                        label={translate({ id: 'CLIENT_ADD' })}
                        onClick={() => {}}
                        dataCy='add-context-button'
                    />
                    <ApDataGridHeaderButton<DataSet>
                        id='delete'
                        key='delete'
                        type='action'
                        buttonType='mat-flat-button'
                        color="warn"
                        text={translate({ id: 'CLIENT_DELETE' })}
                        label={translate({ id: 'CLIENT_DELETE' })}
                        icon='delete'
                        onClick={onDeleteItems}
                        dataCy='delete-groups-button'
                    />
                </ApDataGridHeader>

                <ApDataGridColumn<DataSet>
                    property="schema.indexName"
                    title={translate({ id: 'CLIENT_INDEX_NAME' })}
                    width={20}
                    searchable
                    sortable
                />
                <ApDataGridColumn<DataSet>
                    property="description"
                    title={translate({ id: 'CLIENT_DESCRIPTION' })}
                    width={10}
                    sortable
                />
                <ApDataGridColumn<DataSet>
                    property="status"
                    title={translate({ id: 'CLIENT_INGESTION_STATUS' })}
                    width={5}
                    sortable
                />
                <ApDataGridColumn<DataSet>
                    property="datasource.name"
                    title={translate({ id: 'CLIENT_DATA_SOURCE' })}
                    width={20}
                    sortable
                />

                <ApDataGridColumn<DataSet>
                    property="tenant"
                    title={translate({ id: 'CLIENT_TENANT' })}
                    width={20}
                    visible={false}>
                    <ApDataGridColumnDropdownFilter {...{
                        items: tenants?.map(t => ({
                            label: t.name,
                            value: t.id,
                        })) ?? [],
                        value: selectedTenant,
                        filterChange: (model) => {
                            setSelectedTenant({
                                label: '',
                                value: model?.value as string ?? '',
                            });
                        },
                        showAllOption: false,
                    }} />
                </ApDataGridColumn>

                <ApDataGridRowActions>
                    <ApDataGridRowButton<DataSet>
                        id='row-actions'
                        key='row-actions'
                        label={translate({ id: 'CLIENT_ACTIONS' })}
                        icon='more_vert'
                        dataCy='edit-user-allocations-button'
                        render={(item) => <MoreActions
                            actions={[
                                {
                                    id: 'edit',
                                    label: translate({ id: 'CLIENT_EDIT' }),
                                    onClick: () => {
                                        // TODO: IMPLEMENT the edit logic...
                                    },
                                },
                                {
                                    id: 'delete',
                                    label: translate({ id: 'CLIENT_DELETE' }),
                                    onClick: () => onDeleteItems([ item ]),
                                },
                            ]}
                            item={item} />}
                    />
                </ApDataGridRowActions>

                <ApDataGridFooter
                    length={filteredDataSets.length}
                    pageSizes={[ 5, 10, 25, 50 ]}
                />

            </ApDataGridWrapper>

        </UiStack>
    );
};

const MoreActions = ({
    actions, item,
}: { actions: any[]; item: DataSet }) => {

    const [ open, setOpen ] = useState(false);
    const { formatMessage: translate } = useIntl();

    const rootRef = useRef<HTMLDivElement>(null);

    return (
        <div ref={rootRef}>
            <ApTooltip content={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}>
                <IconButton
                    aria-label={translate({ id: 'CLIENT_SHOW_MORE_ACTIONS' })}
                    onClick={e => setOpen(true)}
                    data-cy="show-more-actions-button">
                    <MoreVertIcon />
                </IconButton>
            </ApTooltip>
            <Menu
                anchorEl={rootRef.current}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={open}
                onClose={e => setOpen(false)}
                data-cy="show-more-actions-menu">
                {actions.map((action, p) => (
                    <MenuItem
                        key={`${item.id}-${action.id}`}
                        disabled={action.disabled}
                        onClick={action.onClick}
                        data-cy={action.dataCy}>
                        <span>
                            {action.label}
                        </span>
                    </MenuItem>
                ))}
            </Menu>
        </div>
    );
};
