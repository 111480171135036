import { GlobalStyles } from '@experiences/theme';
import {
    useNavigateWithParams,
    useRouteResolver,
    useShowDialog,
} from '@experiences/util';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import {
    ApDataGridColumn,
    ApDataGridFooter,
    ApDataGridHeader,
    ApDataGridHeaderButton,
    ApDataGridRowActions,
    ApDataGridRowButton,
    ApDataGridWrapper,
    ApTooltip,
} from '@uipath/portal-shell-react';
import clsx from 'clsx';
import intersection from 'lodash/intersection';
import React, {
    useCallback,
    useMemo,
} from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { notificationType } from '../../common/constants/Constant';
import * as RouteNames from '../../common/constants/RouteNames';
import { useLicenseExpired } from '../../common/hooks/useLicenseExpired';
import { useUiSnackBar } from '../../common/hooks/useUiSnackBar';
import type { IGroup } from '../../common/interfaces/cis/group';
import type { IGroupAllocations } from '../../common/interfaces/licenses';
import AllocateLicenseIcon from '../../images/icons/AllocateLicenseIcon';
import {
    deleteGroups,
    getGroups,
    getGroupsWithLicenses,
    groupUrl,
} from '../../services/identity/GroupService';
import {
    accountGlobalId,
    EnableUserLicensingSelector,
    groupIdsForUser,
    isAdminSelector,
    isUnlicensedSelector,
} from '../../store/selectors';
import { decodeSanitizedHtml } from '../../util/DecodeSanitizedHtml';
import { ActionIconSize } from '../common/UiGrid/constants';
import { UserGroup } from '../common/UserGroups';

type IGroupWithLicenses = IGroup & Partial<IGroupAllocations>;

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    actionIconButton: {
        width: ActionIconSize + 'px',
        height: ActionIconSize + 'px',
        padding: '8px',
    },
    truncate: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}));

const GroupsPageComponent: React.FC = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const createNotification = useUiSnackBar();

    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();

    const isAdmin = useSelector(isAdminSelector);
    const partitionGlobalId = useSelector(accountGlobalId);
    const EnableUserLicensing = useSelector(EnableUserLicensingSelector);
    const isUnlicensedMode = useSelector(isUnlicensedSelector);
    const currentGroupIds = useSelector(groupIdsForUser);
    const isLicenseExpired = useLicenseExpired();

    const createDialog = useShowDialog();

    const dataUrl = useMemo(() => {
        const baseUrl = EnableUserLicensing && isAdmin ? `${groupUrl}/licenses` : `${groupUrl}`;
        return `${baseUrl}?partitionGlobalId=${partitionGlobalId}`;
    }, [ EnableUserLicensing, isAdmin, partitionGlobalId ]);

    const {
        data: groups, isLoading: loading, mutate,
    } = useSWR(
        {
            url: dataUrl,
            partitionGlobalId,
        },
        EnableUserLicensing && isAdmin ? getGroupsWithLicenses : getGroups,
    );

    const openDeleteDialog = useCallback(
        async (rows: IGroupWithLicenses[] | IGroupWithLicenses) => {
            const groupsToDelete = Array.isArray(rows) ? rows.map(row => row) : [ rows ];

            const proceed = await createDialog({
                title: translate({ id: 'CLIENT_DELETE_GROUP' }),
                body: `${translate(
                    { id: 'CLIENT_SHOULD_DELETE_GROUPS' },
                    {
                        0: groupsToDelete.length === 1
                            ? decodeSanitizedHtml(groupsToDelete[0].name)
                            : translate({ id: 'CLIENT_SELECTED_GROUPS_TEXT' }),
                    },
                )} ${translate({ id: 'CLIENT_MULTIPLE_CHECKED_GROUPS_DELETE' })}`,
                icon: 'warning',
                showCancel: true,
                primaryButtonText: translate({ id: 'CLIENT_DELETE' }),
            });
            if (proceed) {
                const groupIDs = groupsToDelete.map(group => group.id);
                const includesCurrentUser = intersection(currentGroupIds, groupIDs).length > 0;
                await deleteGroups(partitionGlobalId, { groupIDs }, includesCurrentUser);
                if (groupsToDelete.length === 1) {
                    createNotification(translate({ id: 'CLIENT_GROUP_DELETED' },
                        { 0: decodeSanitizedHtml(groupsToDelete[0].name) }), notificationType.SUCCESS);
                } else {
                    createNotification(translate({ id: 'CLIENT_GROUPS_DELETED' }), notificationType.SUCCESS);
                }
                mutate();
            }
        },
        [ createDialog, translate, currentGroupIds, partitionGlobalId, mutate, createNotification ],
    );

    return (
        <ApDataGridWrapper<IGroupWithLicenses>
            data={groups ?? []}
            unpaginated
            initialFilters={{
                sort: [
                    {
                        field: 'type',
                        direction: 'asc',
                        title: translate({ id: 'CLIENT_EXTERNAL_APP_TYPE' }),
                    },
                ],
            }}
            loading={loading}
            refreshable
            disableSelectionByEntry={(entry: IGroupWithLicenses) => entry?.type === 0 ? translate({ id: 'CLIENT_DISABLED' }) : null}
            refresh={mutate}
            dataCy="ap-data-grid"
        >
            <ApDataGridHeader<IGroupWithLicenses> search>
                <ApDataGridHeaderButton<IGroupWithLicenses>
                    id='addGroups'
                    key='addGroups'
                    type='main'
                    buttonType='mat-flat-button'
                    color='primary'
                    text={translate({ id: 'CLIENT_GROUPS_ADD_GROUP' })}
                    label={translate({ id: 'CLIENT_GROUPS_ADD_GROUP' })}
                    icon='add'
                    onClick={() => navigate(`${getRoute(RouteNames.Groups)}/add`)}
                    dataCy='add-groups-button'
                    visible={isAdmin}
                />
                <ApDataGridHeaderButton<IGroupWithLicenses>
                    id='delete'
                    key='delete'
                    type='action'
                    buttonType='mat-flat-button'
                    visible={isAdmin}
                    color="warn"
                    text={translate({ id: 'CLIENT_DELETE' })}
                    label={translate({ id: 'CLIENT_DELETE' })}
                    icon='delete'
                    onClick={(rows) => openDeleteDialog(rows ?? [])}
                    dataCy='delete-groups-button'
                />
            </ApDataGridHeader>

            <ApDataGridColumn<IGroupWithLicenses>
                property="name"
                title={translate({ id: 'CLIENT_DISPLAY_NAME' })}
                width={EnableUserLicensing && isAdmin ? 30 : 100}
                sortable
                searchable
            />

            <ApDataGridColumn<IGroupWithLicenses>
                property="type"
                sort="asc"
                title={translate({ id: 'CLIENT_EXTERNAL_APP_TYPE' })}
                sortable
                visible={false}
            />

            { EnableUserLicensing && isAdmin && !isUnlicensedMode && (
                <ApDataGridColumn<IGroupWithLicenses>
                    property='userBundleLicenses'
                    title={translate({ id: 'CLIENT_LICENSES_ALLOCATION_RULES' })}
                    width={70}
                    render={row => {
                        const licenseList = row.useExternalLicense
                            ? translate({ id: 'CLIENT_GROUPS_EXTERNAL_LICENSE' })
                            : row.userBundleLicenses?.map(ubl => translate({ id: `CLIENT_${ubl}` })).join(', ');

                        return (
                            <ApTooltip content={licenseList && licenseList.length !== 0 ? licenseList : ''}>
                                <div
                                    className={classes.truncate}
                                    data-cy="license-allocation-rule">
                                    {licenseList && licenseList.length !== 0 ? licenseList : translate({ id: 'CLIENT_NO_ALLOCATION_RULE' })}
                                </div>
                            </ApTooltip>
                        );

                    }}
                />
            )}

            {isAdmin && (
                <ApDataGridRowActions>
                    { EnableUserLicensing && !isUnlicensedMode && (
                        <ApDataGridRowButton<IGroupWithLicenses>
                            id='allocateLicense'
                            label={translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE' })}
                            dataCy='ui-grid-edit-group-allocations-button'
                            render={row => (
                                <ApTooltip content={translate({ id: 'CLIENT_EDIT_GROUP_ALLOCATION_RULE' })}>
                                    <IconButton
                                        className={clsx(classes.actionIconButton, classes.icon, classes.svgIcon)}
                                        disabled={isLicenseExpired}
                                        onClick={() => navigate(`${getRoute(RouteNames.Groups)}/allocations/edit`,
                                            {
                                                state: {
                                                    group: row,
                                                    previousLocation: location.pathname,
                                                },
                                            })}
                                        data-cy='ui-grid-edit-group-allocations-button'
                                        aria-label='row action'
                                    >
                                        <AllocateLicenseIcon />
                                    </IconButton>
                                </ApTooltip>
                            )}
                            disabled={() => isLicenseExpired} />
                    )}

                    <ApDataGridRowButton<IGroupWithLicenses>
                        id='edit'
                        label={translate({ id: 'CLIENT_EDIT' })}
                        icon='edit'
                        onClick={(row) => navigate(`${getRoute(RouteNames.Groups)}/edit/${row.id}`)}
                        disabled={(row) => row.id === UserGroup.Everyone ? translate({ id: 'CLIENT_DISABLED' }) : false}
                    />

                    <ApDataGridRowButton<IGroupWithLicenses>
                        id='delete'
                        label={translate({ id: 'CLIENT_DELETE' })}
                        icon='delete'
                        onClick={openDeleteDialog}
                        disabled={(row) => row.type === 0 ? translate({ id: 'CLIENT_DISABLED' }) : false}
                    />
                </ApDataGridRowActions>
            )}

            <ApDataGridFooter
                pageSizes={[ 5, 10, 25, 50 ]}
            />

        </ApDataGridWrapper>
    );
};

export default GroupsPageComponent;
