import type { IDirectoryEntry } from '../../common/interfaces/cis/directory';
import { DirectoryEntityType } from '../../common/interfaces/cis/directory';
import {
    get,
    post,
} from '../utility/Requests.default';

const userPartitionUrl = `${process.buildConfigs.identityApiBaseRoute}/Directory`;

export type SourceFilters = 'localUsers' | 'directoryUsers' | 'localGroups' | 'directoryGroups' | 'robotAccounts';

export function getDirectoryEntities(
    {
        url: _url, partitionGlobalId, sourceFilter, startsWith,
    }: {
        url: string;
        partitionGlobalId: string;
        sourceFilter: SourceFilters[];
        startsWith: string;
    }) {
    return get<IDirectoryEntry[]>(`${userPartitionUrl}/Search/${partitionGlobalId}`, {
        urlParams: {
            sourceFilter,
            startsWith,
        },
    });
}
export function resolveByName(
    {
        url: _url, partitionGlobalId, entityName, entityType,
    }: {
        url: string;
        partitionGlobalId: string;
        entityName: string;
        entityType: DirectoryEntityType;
    }) {
    return post<IDirectoryEntry>(`${userPartitionUrl}/ResolveByName/${partitionGlobalId}`, {
        body: {
            entityName,
            entityType: entityType === DirectoryEntityType.user ? 'user' : 'group',
        },
    });
}
