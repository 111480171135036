import type { IUiDialogHookCustomContent } from '@experiences/interfaces';
import { UiText } from '@experiences/ui-common';
import { useTheme } from '@mui/material/styles';
import { ApButton } from '@uipath/portal-shell-react';
import React from 'react';
import { useIntl } from 'react-intl';

import {
    SpacingToken,
    UiStack,
} from '../common/UiStack';

export const ConsentUnsavedChangesDialogBody: React.FC<IUiDialogHookCustomContent> = ({ closeDialog }) => {
    const theme = useTheme();
    const { formatMessage: translate } = useIntl();

    return <>
        <UiText color={theme.palette.semantic.colorForeground}>
            {translate({ id: 'CLIENT_CONSENT_UNSAVED_CHANGES_BODY' })}
        </UiText>
        <UiStack
            direction='row'
            align='center'
            justify='end'
            gap={SpacingToken.XS}
            pt={SpacingToken.M}
        >
            <ApButton
                onClick={() => {
                    closeDialog(true);
                }}
                variant='secondary'
                label={translate({ id: 'CLIENT_DISCARD_CHANGES' })}
            />
            <ApButton
                onClick={() => closeDialog(false)}
                variant='primary'
                label={translate({ id: 'CLIENT_CONTINUE_EDITING' })}
            />
        </UiStack>
    </>;
};
