export class A4EError extends Error {
    status: number;
    errorsCodes: string[];

    constructor(message: string, status: number, errorsCodes: string[]) {
        super(message);
        this.status = status;
        this.errorsCodes = errorsCodes;
    }
}

export interface AutomationSolutionsError {
    title: string;
    status: number;
    errors: {
        [key: string]: Array<{
            errorCode: string;
            message: string;
        }>;
    };
    errorCodes: string[];
}

interface SolutionsRequest {
    tenantName: string;
}

export interface AutopilotSolution {
    name: string;
    version: string;
}

export type SolutionPackagePublishStatusRequest = SolutionsGetPackageRequest;

export interface SolutionPublishStatusResponse {
    status: string;
    packageName: string;
    packageVersion: string;
}

export interface SolutionsGetPackageRequest extends SolutionsRequest {
    packageName: string;
    packageVersion: string;
}

export interface SolutionsPackageResponse {
    key: string;
    packageName: string;
    packageVersion: string;
    publishDate: string;
    authorName: string;
    authorEmail: string;
    description: string;
    state: string;
    solutionRootFolderName: string;
}

export interface SolutionsGetDeploymentsRequest extends SolutionsRequest{
    orderByColumn: string;
    orderByDirection: string;
    skip?: number;
    operation?: string;
    operationStatus?: string;
    activationStatus?: string;
    packageName: string;
    packageVersion?: string;
    take: number;
}

export interface SolutionsGetDeploymentsResponse {
    count: number;
    values: SolutionDeployment[];
}

export interface SolutionDeployment {
    key: string;
    installDeploymentKey: string;
    instanceId: string;
    configurationKey: string;
    packageVersionKey: string;
    packageKey: string;
    packageName: string;
    packageVersion: string;
    installedRootFolderKey: string;
    startTime: string;
    endTime: string;
    actions: string[];
    name: string;
    type: string;
    operation: string;
    operationStatus: string;
    activationStatus: string;
}

export interface SolutionsDeloyPackageRequest extends SolutionsRequest {
    packageName: string;
    packageVersion: string;
    deploymentName: string;
    solutionFolderName: string;
}

export interface SolutionsDeloyPackageResponse extends SolutionsRequest {
    pipelineDeploymentId: string;
}

export interface SolutionsGetPipelineDeploymentStatusRequest extends SolutionsRequest {
    pipelineDeploymentId: string;
}

export interface SolutionsPipelineDeploymentStatusResponse {
    status: string;
    validationResult: Array<{
        success: boolean;
        validationErrors: any[];
        conflictErrors: any[];
    }>;
    deploymentResult: SolutionsDeploymentStatusResponse;
    conflictFixingErrors: any[];
    deploymentScheduleErrors: any[];
}

export interface SolutionsGetDeploymentStatusRequest extends SolutionsRequest {
    instanceId: string;
}

export interface SolutionsDeploymentStatusResponse {
    deploymentKey: string;
    installDeploymentKey: string;
    deploymentName: string;
    configurationKey: string;
    installedRootFolderKey: string;
    packageName: string;
    packageVersion: string;
    packageVersionKey: string;
    status: string;
    authorName: string;
    startDate: string;
    endDate: string;
    errorMessage: string[];
    actions: string[];
    operation: string;
    services: Array<{
        serviceName: string;
        status: string;
        updateDate: string;
        serviceErrorMessages: string[];
    }>;
}

export interface SolutionsUninstallDeploymentRequest extends SolutionsRequest {
    deploymentName: string;
}

export interface SolutionsUninstallDeploymentResponse {
    instanceId: string;
    scheduled: boolean;
}
