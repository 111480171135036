import { OrgConsentEvent } from '@experiences/telemetry';
import { GlobalStyles } from '@experiences/theme';
import { UiText } from '@experiences/ui-common';
import {
    useNavigateWithParams,
    useRouteResolver,
} from '@experiences/util';
import { makeStyles } from '@mui/styles';
import { FontVariantToken } from '@uipath/apollo-core';
import { ApButton } from '@uipath/portal-shell-react';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import * as RouteNames from '../../common/constants/RouteNames';
import { ReactComponent as PersonaLock } from '../../images/personLock.svg';
import { useTelemetryHelper } from '../../telemetry/TelemetryHelper';
import {
    SpacingToken,
    UiStack,
} from '../common/UiStack';

const useStyles = makeStyles(theme => ({
    ...GlobalStyles(theme),
    container: {
        backgroundColor: theme.palette.semantic.colorForegroundInvDeEmp,
        borderRadius: '4px',
    },
    textContainer: {
        maxWidth: '500px',
        textAlign: 'center',
    },
}));

export const NoConsents = () => {
    const classes = useStyles();
    const { formatMessage: translate } = useIntl();
    const navigate = useNavigateWithParams();
    const getRoute = useRouteResolver();
    const { logEvent } = useTelemetryHelper();
    // const getLocalizedLink = useDocumentationLinks();

    const createConsent = useCallback(() => {
        logEvent(OrgConsentEvent.CreateConsent);
        navigate(getRoute(RouteNames.UserConsentCreate));
    }, [ logEvent, navigate, getRoute ]);

    return (
        <UiStack
            className={classes.container}
            direction="column"
            align="center"
            p={SpacingToken.XL}
            gap={SpacingToken.M}>
            <PersonaLock className={classes.svgIcon} />
            <UiStack
                className={classes.textContainer}
                direction="column"
                gap={SpacingToken.Micro}>
                <UiText variant={FontVariantToken.fontSizeLBold}>
                    {translate({ id: 'CLIENT_USER_CONSENT_LANDING_TITLE' })}
                </UiText>
                <UiText variant={FontVariantToken.fontSizeM}>
                    {translate({ id: 'CLIENT_USER_CONSENT_LANDING_SUBTITLE' })}
                </UiText>
            </UiStack>
            <ApButton
                label={translate({ id: 'CLIENT_USER_CONSENT_CREATE' })}
                data-cy="consent-create-button"
                onClick={() => createConsent()} />
            {/* <ApButton
                variant="tertiary"
                label={translate({ id: 'CLIENT_LEARN_MORE' })}
                data-cy="consent-learn-more"
                onClick={() => window.open(getLocalizedLink({ articleSlug: 'todo' }), '_blank')} /> */}
        </UiStack>
    );
};

